import React, {useState,useEffect} from 'react'
import {fetchStates,fetchblocks,fetchdistrict} from '../utils/AreaUtils';
import {getCategories,getProduct} from '../utils/Apicore'
import {Form,Button} from 'react-bootstrap'
import {strings} from '../utils/translated_strings'
import {API} from '../config'
import {getToken,signout} from '../auth'
// import {fetchCattleType} from '../core/CattlePostAds/CattleApiCore'
// import {fetchChemicalProducts} from '../core/FertilizersAndPestisidesAds'
// import {fetchLabourExpertise} from '../core/'

import {  getProductByCategory } from '../utils/Apicore';
import { fetchCattleType } from  '../core/CattlePostAds/CattleApiCore'
import { fetchMachinery } from '../core/MachineryPostAds/MachineryCoreApi';
import { fetchWoodType } from '../core/TreeAndWoodAds/TreeAndWoodsApiCore';
import { fetchServiceWork } from '../core/ServiceInRentPostAds/ServiceInRentApiCore';
import { fetchLabourExpertise } from '../core/LabourInRent/LabourInRentApiCore';
import { fetchChemicalProducts } from '../core/FertilizersAndPestisidesAds/FertilizersAndPesitisidesApiCore';
import { fetchOtherProductsTypes } from '../core/OtherProducts/OtherProductsApiCore';

const AdsReports = () => {
    const [states,setStates] = useState([])
    const [districts,setDistricts] = useState([])
    const [blocks,setBlocks] = useState([])
    const [categories,setCategories] = useState([])

    const [category,setCategory] = useState('')
    const [deleteAdsCount,setDeletedAdsCount] = useState(0)


    useState(()=>{
        fetchStates()
        .then(response => {
            return response.json()
        })
        .then(data => {
            setStates(data)
        })
        .catch(err=>{

        })
        getCategories()
        .then(response=>{
            return response.json()
        })
        .then(data=>{
            setCategories(data)
        })
        .catch(err=>{

        })
    },[])

    const [products,setProducts] = useState([])
    useEffect(()=>{
        
        if(category) {
            
            if(category.toLowerCase().includes('grain') | category.toLowerCase().includes('oilseed')   
            | category.toLowerCase().includes('fruit') | category.toLowerCase().includes('plant')
            | category.toLowerCase().includes('pulse') | category.toLowerCase().includes('vegetable')
            | category.toLowerCase().includes('flower') | category.toLowerCase().includes('dairy') ) 
            {
                let new_products=[];
                
                getProductByCategory(category)
                .then(response=>{
                    return response.json()
                })
                .then(data => {
                    
                    data.map((d,i)=>{
                        new_products.push(d.product_name)
                    })
                    setProducts(new_products)
                })
                .catch(err=>{
                    console.log(err)
                })
            }
            if(category.toLowerCase().includes('cattle')){
                let new_products = [];
                fetchCattleType()
                .then(response => {
                    return response.json()
                })
                .then(data => {
                    data.map((d,i)=>{
                        new_products.push(d.cattle_name)
                    })
                    setProducts(new_products)
                })
                .catch(err=>{

                })
            }
            if(category.toLowerCase().includes('machinary')){
                let new_products = [];
                fetchMachinery()
                .then(response=>{
                    return response.json()
                })
                .then(data => {
                    
                    data.map((d,i)=>{
                        new_products.push(d.machine_name)
                    })
                    setProducts(new_products)
                })
                .catch(err=>{

                })
            }
            if(category.toLowerCase().includes('wood')){
                let new_products = [];
                fetchWoodType()
                .then(response=>{
                    return response.json()
                })
                .then(data => {
                    data.map((d,i)=>{
                        new_products.push(d.wood_name)
                    })
                    setProducts(new_products)
                })
                .catch(err=>{

                })
            }
            if(category.toLowerCase().includes('service')) {         
                let new_products = [];
                fetchServiceWork()
                .then(response=>{
                    return response.json()
                })
                .then(data => {
                    
                    data.map((d,i)=>{
                        new_products.push(d.work_name)
                    })
                    setProducts(new_products)
                })
                .catch(err=>{

                })
            }
            if(category.toLowerCase().includes('labour')) {
                let new_products = [];
                fetchLabourExpertise()
                .then(response=>{
                    return response.json()
                })
                .then(data => {
                    data.map((d,i)=>{
                        new_products.push(d.expertise)
                    })
                    setProducts(new_products)
                })
                .catch(err=>{

                })
            }
            if(category.toLowerCase().includes('fertilizer')) {
                let new_products = [];
                fetchChemicalProducts('')
                .then(response=>{
                    return response.json()
                })
                .then(data => {
                    data.map((d,i)=>{
                        new_products.push(d.chemical_product_name)
                    })
                    setProducts(new_products)
                })
                .catch(err=>{

                })
            }
            if(category.toLowerCase().includes('other')) {
                let new_products = [];
                fetchOtherProductsTypes()
                .then(response=>{
                    return response.json()
                })
                .then(data => {
                    data.map((d,i)=>{
                        new_products.push(d.type_of_product)
                    })
                    setProducts(new_products)
                })
                .catch(err=>{

                })
            }            
        }
    },[category])

    const handleStateChange = (name,func,setFunc) => (event) => {
        setLocationParam({...locationParam,[name]:event.target.value})
        func(event.target.value).then(response=> {
            return response.json()
        })
        .then(data => {
                setFunc(data)
        })
        .catch(err => {
            console.log(err)
        });
    }    
    const[locationParam,setLocationParam] = useState({
        state:'',
        district:'',
        block:''
    })

    const [superCategory,setSuperCategory] = useState("Sellads")

    const [location,setLocation] = useState(false)
    
    const [product,setProduct] = useState('')

    const submitToCount = (e) => {
        e.preventDefault()
        let state= locationParam.state;
        let district = locationParam.district;
        let block = locationParam.block;
        let selectedCategory = category;
        let selectedSuperCategory = superCategory;
        let token = getToken()
        let url = `${API}/ads/adminadscount/?super_category=${superCategory}&state=${state}&district=${district}&block=${block}&category=${selectedCategory}&adminoperation=true`
        fetch(url,{
            method:'GET',
            headers:{
                Accept:'application/json',
                "Content-Type":"application/json",
                "Authorization":`Token ${token}`
            }
        })
        .then(response=>{
            return response.json()
        })
        .then(data=>{
            if(data.detail) {
                if(data.detail.toLowerCase().includes('token')) {
                    signout(()=>{
                        window.location.reload(false)
                    })
                } 
            } else {
                setAdsCount(data.adscount)
            }
        }).catch(err=>{
             
        })
    }
    const [adscount,setAdsCount] = useState(0);
    const setLocationForm = () => {
        return(
        <>
        <Form inline onSubmit={submitToCount}>
        <Form.Control as="select" size="sm" onChange={(e)=>{
            setSuperCategory(e.target.value)
        }}
         value={superCategory}  className=" col-12 col-lg-4 ml-2 mt-2" >
             <option key={1}>Sellads</option>
             <option key={2}>BuyOffer</option>
             <option key={3}>BuyBackOffer</option>
        </Form.Control>
        <Form.Control as="select" size="sm" onChange={(e)=>{
            setCategory(e.target.value)
        }} value={category} className=" col-12 col-lg-4 mt-2 ml-2" >
            <option key="category">Select Category</option>
            {
                categories.map((c,i) => {
                    return <option key={i}>{c.category_name}</option>
                })
            }
        </Form.Control>
        <Form.Control as="select" size="sm" value={product} onChange={e=>{
            setProduct(e.target.value)
        }} className="ml-2 col-12 col-lg-4 mt-2">
        <option key="Select product">Select Product</option>
        {
            products.map((d,i) => {
                    return <option  key={i}>{d}</option>
            })
        }
        </Form.Control>        
        <Form.Control as="select" size="sm" value={locationParam.state} onChange={handleStateChange('state',fetchdistrict,setDistricts)} className=" col-12 col-lg-4 mt-2 ml-2">
            <option key="Select Your State">{strings.select_your_state}</option>
            {
                states.map((india_states,i) => {
                        return <option  key={i}>{india_states.state_name}</option>
                })
            }
        </Form.Control>
        
        <Form.Control as="select" size="sm" value={locationParam.district} onChange={handleStateChange('district',fetchblocks,setBlocks)} className=" col-12 col-lg-4 mt-2 ml-2">
            <option key="Select Your district">{strings.select_your_district}</option>
            {
                districts.map((district,i) => {
                        return <option  key={i}>{district.district_name}</option>
                })
            }
            
        </Form.Control>
            
        <Form.Control as="select" size="sm" value={locationParam.block} onChange={e=>{setLocationParam({...locationParam,block:e.target.value})}} className="ml-2 col-12 col-lg-4 mt-2">
        <option key="Select Your block">{strings.select_your_block}</option>
        {
            blocks.map((block,i) => {
                    return <option  key={i}>{block.block_name}</option>
            })
        }
        </Form.Control>
        <br />
        <Button variant="success" size="sm" className="ml-2" type="submit">Submit</Button>
        </Form>
        </>
        )
    }
    return (
        <div className="row ">
            <div className="col-12 mx-auto">
                {setLocationForm()}
            </div>
            <div className="col-12 mx-auto">
                <Form inline>
                    <Form.Label className="h3 mr-2">
                        To view total ads count 
                    </Form.Label >
                    <Button variant="success" className="my-4" size="sm" onClick={
                        (e) => {
                            e.preventDefault()
                            let token = getToken()
                            fetch(`${API}/ads/adminadscount/?all=True&adminoperation=true`,{
                                method:"GET",
                                headers:{
                                    Accept:'application/json',
                                    "Content-Type":"application/json",
                                    "Authorization":`Token ${token}`
                                }
                            })
                            .then(response=>{
                                return response.json()
                            })
                            .then(data=>{
                                if(data.detail) {
                                    if(data.detail.toLowerCase().includes('token')) {
                                        signout(()=>{
                                            window.location.reload(false)
                                        })
                                    } else {
                                        
                                    }
                                } else {
                                    setAdsCount(data.adscount)
                                }
                            }).catch(err=>{
                                
                            })
                        }
                    }>All Ads Count</Button>
                </Form>
            </div>        
            <div className="col-12 mx-auto">
                <p><span className="h3">Total Advertisement Count : {adscount} </span></p>
            </div>
            <div className="col-12 mx-auto">
                <Form inline>
                    <Form.Label className="h3 mr-2">
                        To view total ads count 
                    </Form.Label >
                    <Button variant="success" className="my-4" size="sm" onClick={
                        (e) => {
                            e.preventDefault()
                            let token = getToken()
                            fetch(`${API}/ads/admindeleteadscount/?adminoperation=true`,{
                                method:"GET",
                                headers:{
                                    Accept:'application/json',
                                    "Content-Type":"application/json",
                                    "Authorization":`Token ${token}`
                                }
                            })
                            .then(response=>{
                                return response.json()
                            })
                            .then(data=>{
                                if(data.detail) {
                                    if(data.detail.toLowerCase().includes('token')) {
                                        signout(()=>{
                                            window.location.reload(false)
                                        })
                                    } else {
                                        
                                    }
                                } else {
                                    setDeletedAdsCount(data.deletedads)
                                }
                            }).catch(err=>{
                                 
                            })
                        }
                    }>Deleted Ads Count</Button>
                </Form>
            </div>        
            <div className="col-12 mx-auto">
                <p><span className="h3">Total Deleted Advertisement Count : {deleteAdsCount} </span></p>
            </div>
            <div className="col-12 mx-auto">
                <Form inline>
                    <Form.Label className="h3 mr-2">
                        Delete 30 days old days 
                    </Form.Label >
                    <Button variant="success" className="my-4" size="sm" onClick={
                        (e) => {
                            e.preventDefault()
                            let token = getToken()
                            fetch(`${API}/ads/deletemontholdads/?adminoperation=true`,{
                                method:"GET",
                                headers:{
                                    Accept:'application/json',
                                    "Content-Type":"application/json",
                                    "Authorization":`Token ${token}`
                                }
                            })
                            .then(response=>{
                                return response.json()
                            })
                            .then(data=>{
                                if(data.detail) {
                                    if(data.detail.toLowerCase().includes('token')) {
                                        signout(()=>{
                                            window.location.reload(false)
                                        })
                                    } else {
                                        
                                    }
                                } else {
                                    setDeletedAdsCount(data.deletedads)
                                }
                            }).catch(err=>{
                                 
                            })
                        }
                    }>Deleted Ads Count</Button>
                </Form>
            </div>                                 
        </div>
    )
}

export default AdsReports;
