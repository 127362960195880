import React,{useState,useEffect} from 'react';
import { getToken, isAuthenticated, signout }   from '../auth';
import { Link } from 'react-router-dom';
import DashBoardLayout from './DashBoardLayout';
import { strings } from '../utils/translated_strings';
import {Tab,Row,Col,ListGroup,Nav,Button, Form} from 'react-bootstrap'
import {API} from '../config'
import Layout from './Layout'
import MyAds from './MyAds'
const UserDashboard = () => {
    const {
            token
        }   = isAuthenticated();

        const [userdetails , setUserDetails] = useState()
        const [error,setError] = useState(false)
        const [loading,setLoading] = useState(true)
        const [displayPic,setDisplayPic] = useState()
        useEffect(() => {
            
            fetch(`${API}/edituserinfo/`,{
                method : "GET",
                headers : {
                    Accept:'application/json',
                    Authorization:`Token ${token}`
                }
            })
            .then(response => {
                return response.json()
            })
            .then(data => {
                if(data.detail) {
                    if(data.detail.toLowerCase().includes('token')) {
                        signout(()=>{
                            window.location.reload(false)
                        })
                    } else {
                        setError(data.detail)
                    }
                }
                else{
                    setUserDetails(data)
                    setBio(data.bio)
                    setNewPhoneNumber(data.phone_number)
                    setDisplayPic(data.image)   
                    setLoading(false)
                }
            })
            .catch(err=>{
                
                setError(err)
            })
            
        }, [])
        const userLinks = () => {
            return (
                    <>
                    <ListGroup>
                        <ListGroup.Item action href="#link1">
                        Link 1
                        </ListGroup.Item>
                        <ListGroup.Item action href="#link2">
                        Link 2
                        </ListGroup.Item>
                    </ListGroup>
                    
                    <Tab.Content>
                        <Tab.Pane eventKey="#link1">
                        {userInfo()}
                        </Tab.Pane>
                        <Tab.Pane eventKey="#link2">
                        
                        </Tab.Pane>
                    </Tab.Content>
                    </>
            )
        }

        const [phoneEditForm,setPhoneEditForm] = useState(false)
        const [newPhoneNumber,setNewPhoneNumber] = useState()
        const [newPhoneNumberError,setNewPhoneNumberError] = useState('')
        const handlePhoneNumberChange = (e) => {
            
            setNewPhoneNumber(e.target.value)
        }
        const isPhoneNumberValid = () => {
            if (newPhoneNumber.length < 10) {
                setNewPhoneNumberError("Invalid phone number")
                return false
            }
            return true
        }

        const savePhoneNumber = (e) => {
            e.preventDefault()
            if(isPhoneNumberValid() === false)
                return

            let new_phone_number = newPhoneNumber
            var fd = new FormData()
            fd.append('update_phone_number',"True")
            fd.append('phone_number',newPhoneNumber)
            fetch(`${API}/edituserinfo/`,{
                method : "PUT",
                headers : {
                    Accept:'application/json',
                    Authorization:`Token ${token}`
                },
                body:fd
            })
            .then(response => {
                return response.json()
            }).then(data=>{
                if(data.detail) {
                    if(data.detail.toLowerCase().includes('token')) {
                        signout(()=>{
                            window.location.reload(false)
                        })
                    } else {
                        setError(data.detail)
                    }
                } else {
                    setUserDetails({...userdetails,phone_number:new_phone_number})
                    setPhoneEditForm(false)
                }
            }).catch(err=>{
                
            })
        }

        const [profilePic,setProfilePic] = useState(null)
        const [profilePicError,setProfilePicError] = useState('')
        const handleImageSelection = (e) => {
            e.preventDefault()
            const reader =new FileReader()
            reader.onload = () => {
                if(reader.readyState === 2) {
                    setDisplayPic(reader.result)
                }
            }
            reader.readAsDataURL(e.target.files[0])
            setProfilePic(e.target.files[0])
        
        }    

        const RemoveProfile = () => {
            

            var fd = new FormData()
            fd.append('remove_profile_pic',"True")
            
            fetch(`${API}/edituserinfo/`,{
                method : "PUT",
                headers : {
                    Accept:'application/json',
                    Authorization:`Token ${token}`
                },
                body:fd
            })
            .then(response => {
                return response.json()
            }).then(data=>{
                if(data.detail) {
                    if(data.detail.toLowerCase().includes('token')) {
                        signout(()=>{
                            window.location.reload(false)
                        })
                    } else {
                        setError(data.detail)
                    }
                } else {
                    window.location.reload(false)
                }
            }).catch(err=>{
                 
            })            
        }
        const saveImage = () => {
            if (profilePic == null)
            {
                setProfilePicError("Please select profile picture")
                return 
            }
            var fd = new FormData()
            fd.append('updated_profile_pic','True')
            fd.append('image',profilePic)
            fetch(`${API}/edituserinfo/`,{
                method : "PUT",
                headers : {
                    Accept:'application/json',
                    Authorization:`Token ${token}`
                },
                body:fd
            })
            .then(response => {
                return response.json()
            }).then(data=>{
                if(data.detail) {
                    if(data.detail.toLowerCase().includes('token')) {
                        signout(()=>{
                            window.location.reload(false)
                        })
                    } else {
                        setError(data.detail)
                    }
                } else {
                    window.location.reload(false)
                }
            }).catch(err=>{
                
            })   
        }
        const [passwords,setPasswords] = useState(
            {
                'password1':'',
                'password2':''
            }
        )
        const [passwordError,setPasswordError]  = useState()
        const [changePassword,setChangePassword] = useState(false)
        const [changeBio,setChangeBio] = useState(false)
        const handlePasswordSelection = (name) => (e) => {
            e.preventDefault()
            setPasswords({...passwords,[name]:e.target.value})
            setPasswordError('')
        }

        const submitNewPassword = (e) =>  {
            e.preventDefault()
            if(passwords.password1!==passwords.password2){
                setPasswordError("Password doesn't match")
                return
            }
            if(passwords.passwords1 === '' | passwords.password2 === '') {
                setPasswordError('Enter new password')
                return
            }
            if(passwords.password1.length < 6) {
                setPasswordError('password length must be greater than 6')
                return
            }
            var fd = new FormData()
            fd.append('update_password','True')
            fd.append('password',passwords.password1)
            fetch(`${API}/edituserinfo/`,{
                method : "PUT",
                headers : {
                    Accept:'application/json',
                    Authorization:`Token ${token}`
                },
                body:fd
            })
            .then(response => {
                return response.json()
            }).then(data=>{
                if(data.detail) {
                    if(data.detail.toLowerCase().includes('token')) {
                        signout(()=>{
                            window.location.reload(false)
                        })
                    } else {
                        setError(data.detail)
                    }
                }else {
                    window.location.reload(false)
                }
            })   
        }

        const [bio,setBio] = useState('bio')
        const [bioError,setBioError] = useState(false)
        const saveBio = (e) => {
            e.preventDefault()
            if(bio.lenght > 1000) 
            {
                setBioError('Bio cannot be more than 1000 words') 
                return
            }
            let token = getToken()
        
            var fd = new FormData()
            fd.append('update_bio',"True")
            fd.append('bio',bio)
            fetch(`${API}/edituserinfo/`,{
                method : "PUT",
                headers : {
                    Accept:'application/json',
                    Authorization:`Token ${token}`
                },
                body:fd
            })
            .then(response => {
                return response.json()
            }).then(data=>{
                if(data.detail) {
                    if(data.detail.toLowerCase().includes('token')) {
                        signout(()=>{
                            window.location.reload(false)
                        })
                    } else {
                        setError(data.detail)
                    }
                }else {
                    setChangeBio(false)
                }
            })
        }

        const handleBioChange = (e) => {
            setBio(e.target.value)
        }

        const userInfo = () => {
            return (
                        <>
                        <p className="list-group-item mb-0">{userdetails.first_name} {userdetails.last_name} </p> 
                        <div className="list-group-item flex-column  inline">
                            {
                                phoneEditForm ?
                                <Form inline onSubmit={savePhoneNumber}>
                                    <Form.Label>New Phone Number:</Form.Label>
                                    <Form.Control type="text" defaultValue={userdetails.phone_number} onChange={handlePhoneNumberChange} className="col-4" />
                                    <Form.Label style={{fontSize:"0.8em",color:"red"}}>{newPhoneNumberError}</Form.Label>
                                    <Button type="sumbit" size="sm" variant="light">save</Button>
                                </Form>
                                :
                            <>
                            <p>Phone Number: <span className="float-right">{userdetails.phone_number}</span>
                            <Button onClick={(e)=>{
                                e.preventDefault()
                                setPhoneEditForm(true)
                            }} className="float-right" size="sm" variant="light"><i className="fas fa-edit"></i></Button>
                            </p>
                            </>
                            }
                        </div> 
                        <div className="list-group-item flex-column  inline">

                            <>
                            <Form inline>
                                <Form.Label className="mt-2 btn btn-default btn-outline-secondary btn-sm mx-auto" >
                                    Select profile picture <Form.Control size="sm" variant="warning" type="file" hidden onChange={handleImageSelection}  className="mt-2"/>
                                </Form.Label>    
                                {
                                profilePic?      
                                <>                  
                                <Button onClick={saveImage} className="ml-4" size="sm" variant="info">Save</Button>   
                                <Button onClick={(e)=>{
                                    setProfilePic('')
                                    setDisplayPic(userdetails.image)
                                    window.location.reload(false)
                                }} className=" ml-4" size="sm" variant="info">Clear</Button>    
                                </>
                                :''
                                }
                                <Button onClick={RemoveProfile} className="ml-4 float-right" size="sm" variant="danger">Delete</Button>
                            </Form>
                            </>

                            
                            
                        </div>   
                        <div className="list-group-item flex-column  inline">
                            {
                                changePassword ?
                            <Form inline>
                                <Form.Label className="mx-auto">New Password:</Form.Label>
                                <Form.Control size="sm" type="password" onChange={handlePasswordSelection("password1")} className="col-lg-4 col-12"></Form.Control>
                                <Form.Label className="mx-auto">Confirm Password:</Form.Label>
                                <Form.Control size="sm" type="password" onChange={handlePasswordSelection("password2")} className="col-12 col-lg-4"></Form.Control>
                                <Form.Label className=" text-small" style={{fontSize:"0.8em",color:"red"}}>
                                    {passwordError}
                                </Form.Label>
                                <div className="container-fluid mt-3">
                                    <div className="row">
                                    <Col lg={6} xs={12} className="mx-auto">
                                    <Button onClick={submitNewPassword} variant="info" className="mx-4 col-12 col-lg-4 mt-2" size="sm">Submit</Button>
                                    
                                    <Button onClick={(e)=>{
                                        e.preventDefault()
                                        setChangePassword(false)

                                    }} variant="info" className="mx-4  col-12 col-lg-4 mt-2" size="sm">cancel</Button>
                                    </Col>
                                    </div>
                                </div>
                            </Form>
                            :
                            <Form inline>
                                <div className="pl-0 col-6">
                                <Form.Label className="float-left mr-5 pr-5">
                                    Change Your Password:
                                </Form.Label>
                                </div>
                                <div className="col-6  pr-0">
                            <Button onClick={(e) =>
                                {
                                e.preventDefault(); 
                                setChangePassword(true)
                                }
                            } className="float-right mx-auto " size="sm" variant="info">Change</Button>
                            </div>
                            </Form>
                            }
                        </div>     
                        <div className="list-group-item flex-column  inline">
                            <Form inline onSubmit={saveBio}>
                                <Form.Label>User Description:</Form.Label>
                                {
                                    changeBio ?
                                    <>
                                <Form.Control as="textarea" onChange={handleBioChange} defaultValue={userdetails.bio}  className="mx-auto col-8" size="sm" placeholder="placeholder"/>
                                <Button type="submit" className="mx-auto ml-5" size="sm" variant="info">Save</Button>                                                                    
                                </>
                                :
                                <>
                                <p className=" mx-auto">
                                    {bio}
                                </p>
                                <Button onClick={(e) =>
                                {
                                e.preventDefault(); 
                                setChangeBio(true)
                                
                                }}   className="float-right  ml-5" size="sm" variant="info">Change</Button>                                                                                                                                    
                                </>
                                }
                                        
                                </Form>
                        </div>
                        <div className="list-group-item flex-column  inline mb-5">
                            <Form inline onSubmit={saveBio}>
                                <Form.Label>Show Mobile Number:</Form.Label>
                                <Form.Control defaultChecked={userdetails.show_mobile_number} type="checkbox" className="float-right ml-5" onChange={handleShowMobile} />
                            </Form>
                        </div>                                        
                        </>
            )
        }
        const [showMobileNumber,setShowMobileNumber] = useState(false)
        const handleShowMobile = (e) => {
            let localMobileNumber = e.target.checked;
            setShowMobileNumber(e.target.checked);
            
            let token = getToken()
            var fd = new FormData()
            fd.append('update_show_mobile_number',localMobileNumber)
            fetch(`${API}/edituserinfo/`,{
                method : "PUT",
                headers : {
                    Accept:'application/json',
                    Authorization:`Token ${token}`
                },
                body:fd
            })
            .then(response => {
                return response.json()
            }).then(data=>{
                if(data.detail) {
                    if(data.detail.toLowerCase().includes('token')) {
                        signout(()=>{
                            window.location.reload(false)
                        })
                    } else {
                        setError(data.detail)
                    }
                }
            })
        
        }
     
        const showError = () => {
            return <div className="alert alert-info col-6">
                {error}
            </div>
        }
        return (
            <>
            <Layout>
            {
                loading ? 
                <h1 className="h4 mx-auto text-muted">Loading...</h1>
                :
                ( error ?
                    showError()
                    :
            <DashBoardLayout 
                title={strings.dashboard}
                description={userdetails.first_name.concat(" "+userdetails.last_name)}
                className="container"
                image={displayPic}
            >

                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Row>
                    <Col sm={6}>
                    <Nav variant="pills" className="flex-row">
                        <Nav.Item>
                        <Nav.Link eventKey="first">User Profile</Nav.Link>
                        </Nav.Item>
                        {/* <Nav.Item>
                        <Nav.Link eventKey="second">My Ads</Nav.Link>
                        </Nav.Item> */}
                    </Nav>
                    </Col>
                    <Col sm={12}>
                    <Tab.Content>
                        <Tab.Pane className="rounded-0" eventKey="first">
                            {userInfo()}
                        </Tab.Pane>
                        {/* <Tab.Pane className="rounded-0" eventKey="second">
                            <MyAds />
                        </Tab.Pane> */}
                    </Tab.Content>
                    </Col>
                </Row>
                </Tab.Container>                

            </DashBoardLayout>
                )
                }
                </Layout>
            </>
        )
}

export default UserDashboard;
