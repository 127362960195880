import React from 'react';
import {getEKLanguage} from './language_utils'
import {API} from '../config'
import {getToken, isAuthenticated } from '../auth';

export const filterCategories = (data) => {
    var tmp = ['Grain','oilseed','Fruit','Pulse','Vegetable','Flowers','Medicinal'];
    var len = tmp.length
    var o_categories=[];
    for(var i = 0 ; i < data.length ; i++) {
        for(var idx=0; idx < tmp.length ; idx++) {
            if(data[i].category_name_en.toLowerCase().includes(tmp[idx].toLowerCase())){
                
                o_categories.push(data[i]);
            }
        }
    }
    return o_categories
}

export const filterCategories2 = (data) => {
    var tmp = ['Grain','oilseed','Fruit','Pulse','Vegetable','Flowers','Medicinal'];
    var len = tmp.length
    var o_categories=[];
    for(var i = 0 ; i < data.length ; i++) {
        for(var idx=0; idx < tmp.length ; idx++) {
            if(data[i].category_name.toLowerCase().includes(tmp[idx].toLowerCase())){
                
                o_categories.push(data[i]);
            }
        }
    }
    return o_categories
}

export const getBreed = (product_name) => {
    const lang = getEKLanguage()
    return fetch(`${API}/ads/productbreed/?search=${product_name}`,{
        method: "GET",
        headers: {
            Accept:'application/json',
            "Content-Type":"application/json",
            "Accept-Language":`${lang}`
        }
    })
}

export const getCategories = () => {
    const lang = getEKLanguage()
    return fetch(`${API}/ads/allcategory/`,{
        method: "GET",
        headers: {
            Accept:'application/json',
            "Content-Type":"application/json",
            "Accept-Language":`${lang}`
        }
    })
}

export const getStatus = (product_name) => {
    const lang = getEKLanguage()
    return fetch(`${API}/ads/productstatus/?search=${product_name}`,{
        method: "GET",
        headers: {
            Accept:'application/json',
            "Content-Type":"application/json",
            "Accept-Language":`${lang}`
        }
    })
}

export const getProductPackaging = () => {
    const lang = getEKLanguage()
    return fetch(`${API}/ads/productpackaging/`,{
        method: "GET",
        headers: {
            Accept:'application/json',
            "Content-Type":"application/json",
            "Accept-Language":`${lang}`
        }
    })
}


export const getProduct = () => {
    const lang = getEKLanguage()
    return fetch(`${API}/ads/product/`,{
        method: "GET",
        headers: {
            Accept:'application/json',
            "Content-Type":"application/json",
            "Accept-Language":`${lang}`
        }
    })
}

export const getProductByCategory = (category) => {
    const lang = getEKLanguage()
    return fetch(`${API}/ads/products/?search=${category}`,{
        method: "GET",
        headers: {
            Accept:'application/json',
            "Content-Type":"application/json",
            "Accept-Language":`${lang}`
        }
    })
}

export const getAdvertisement = (id) => {
    const lang = getEKLanguage()
    let token = getToken()

    return fetch(`${API}/ads/agriads/${id}/?toedit=optm/`,{
        method: "GET",
        headers: {
            Accept:'application/json',
            "Content-Type":"application/json",
            "Accept-Language":`${lang}`,
            "Authorization":`Token ${token}`
        }
    })
}

