import React from 'react'
import Layout from '../core/Layout'
import {Link}  from 'react-router-dom'
const GeneralFAQ = () => {
    return (
        <Layout>
        <div className="container-fluid">
            <div className="row">
                <div className="col-8 mx-auto">

<h5>All about profile edition</h5>
<ul className="circle">
<li>How long can my bio be?
    <p>Keep your bio short and sweet. It should be a maximum of 140 characters (App) and 200 characters (website).</p>
</li>

    <li>
        How often can I edit my profile?
        <p>You can edit your bio or About Me section as often as you like. Simply go to -</p>
        App: Go to My Account → View and edit profile → Edit Profile
        You will have the option to update your name, phone number, link with email etc.
        <h5>Better Profile Tips:</h5>
        <ol className="number">
            <li>
            You can gain more credibility for your profile by linking with different social media indicators.
            </li>
            <li>
            Connect using Facebook, Google. It is also likely to fetch you better responses and gain trust of other users like yourself. 
            </li>
        </ol>
     </li>

    <li>How do I add a picture to my profile and can I change it later?
    <p>You can play around with your Profile picture. Feel free to use from below options:</p>
        <ol className="number">
            <li>Facebook</li>
            <li>Google Login</li>
            <li>Import from gallery or </li>
            <li>Take a cool pic right away</li>
        </ol>
    </li>
    </ul>
<p>That’s not it, if you are not satisfied you have the option to “Remove Picture"</p>

<h5>How to create an account on EKRISHIBAZAAR.COM?</h5>
<p>
EKRISHIBAZAAR.COM brings to you a quick and convenient option to create your account. Here we go!
</p>
<p>
Users are given 3 options:
</p>
<p className="text-underline">
Please note: 
</p>
<ol className="number">
    <li>
    For Facebook, users will have to provide a valid FB login and password.
    <p>
    (Note: Only if Facebook user ID is Mobile number instead of email ID)
    </p>
    </li>
    <li>
    For Phone*, Users will need to have an active and valid Mobile Number. So that they can receive an OTP (One Time Phone verification code) to activate their account.
    Once the account is created, your profile will look as below. E.g. Facebook registered users will look like below:
    <p>
        <span className="font-weight-bold">Note:</span> Landline phones are not acceptable.
    </p>
    </li>
    
</ol>


<h5>How do I change my phone number in the app?</h5>
<p>It’s a 1 minute task to update your phone number. Ensure that the number you wish to update is not already linked to some EKRISHIBAZAAR.COM account. </p>
<p>Note: An OTP will be sent for verification. Please insert the same which will be sent on the phone number you wish to update.</p>
<ol className="number">
    <li>Tap on My Account & select “View and Edit Profile” </li>
    <li>You will find an option to “Edit Profile”</li>
    <li>Next, under “Contact Information” you can modify your “Phone Number”. Tap on the Phone Number option.  </li>
    <li>On the next screen, you can input the new number that you wish to update.</li>
</ol>
 
<h5>How do I change password for "My Account"</h5>
<p>Just four easy steps and Finish!</p>
<ol className="number">
    <li>
Under “My Account” tap on settings.
    </li>
    <li>
Next, you will find an option to select “Privacy”
</li>
<li>
Finally, you’ll find an option to re-set password!
</li>
<li>
Simply enter your old password, and update the password of your choice. Once done tap on “Save”
</li>
</ol>
<h5>How do I delete my account?</h5>

<h5>Logout steps:</h5>
<ol className="number">
    <li>Tap on “My Account” and select “Settings” </li>
    <li>Under Setting an option for “Logout” will appear. </li>
    <li>Tap on Logout and a final confirmation for Logout will appear.</li>
</ol>
<h5>Account Deletion Steps:  </h5>
<ol className="number">
    <li>
Tap on “My Account” and select “Settings” 
</li>
<li>
Under Setting an option for “Deactivate account” will appear. 
</li>
<li>
Tap on “Deactivate account & delete my data” and a final confirmation for Deactivate will appear.
</li>
</ol>
<h5>Technical Issue(s) Troubleshooting - App</h5>

<p>Facing these Issues:-  </p>
<ol className="number">
<li>Unable to post an Ad?</li>
<li>Unable to access your account?</li>
<li>Unable to access the EKRISHIBAZAAR.COM App?</li>
</ol>
<p>Not an issue! </p>

<p>
<span className="font-weight-bold">Re-install App:</span> Simply uninstall and reinstall your App. Ensure to download the latest version of EKRISHIBAZAAR.COM App from the App store. 
</p>
<p>
If you are still having problems, please go ‘My account’ then tap on ‘Make a complaint’   with the following details in the description field:
</p>
<ol className="number">
<li>Category/subcategory name</li>
<li>Screenshot of error displayed.</li>
</ol>

<h5>Clear cache & cookies:</h5>
<ol className="number">
    <li>Go to Phone Settings.</li>
    <li>Under settings, go to Apps.</li>
    <li>Find the EKRISHIBAZAAR.COM app and click on storage.</li>
    <li>Click "Clear Cache".</li>
</ol>
Or
<h5>Go to Phone Settings:</h5>
<ol className="number">
    <li>Under settings, go to "Additional/More Settings"</li>
    <li>Next go to Applications/Application Manager</li>
    <li>Next find "All Apps"</li>
    <li>Under Apps, click on EKRISHIBAZAAR.COM App</li>
    <li>Next go to: Clear Data & Clear Cache.</li>
</ol>







<h5>Why is my account banned?</h5>
<p>If you find that you are receiving a notification that your account is block, 
this means that you've either breached one of our <Link to="/adpostingguidelines">Posting rules</Link> or there is a non-compliant activity as per our Trust and Safety rules relating to your account.
Business Users in case of Account banned are requested to contact their designated Account Manager.  
</p>



                    
                </div>
            </div>
        </div>
        </Layout>
    )
}

export default GeneralFAQ;