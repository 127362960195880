import React,{useState,useEffect} from 'react';
import {Form,Button} from 'react-bootstrap'
import {API} from '../config'
import {getToken,signout} from '../auth/'
import {fetchStates,fetchblocks,fetchdistrict} from '../utils/AreaUtils';
import {strings} from '../utils/translated_strings'

const SendNotification = () => {
    const [notificationText,setNotificationText] = useState()
    const [notificationTextError,setNotificationTextError] = useState()
    const [error,setError] = useState(false)
    const [success,setSuccess] = useState(false)
    const [locationError,setLocationError] = useState(false)
    const handleNormal = (e) => {
        e.preventDefault()
        let token = getToken()
        var fd = new FormData()
        if (to==='All' || to==='Blocked'){
            fd.append('to',to)
        }
        else {
            if(locationParam.state.length === 0){
                setLocationError('select state')
                return
            }
            fd.append('state',locationParam.state)
            if(locationParam.district > 0)
                fd.append('district',locationParam.district)
            if(locationParam.block > 0 ) 
                fd.append('block',locationParam.block)
        }
        fd.append('notification',notificationText)

        fetch(`${API}/edituserinfo/?adminoperation=true`,{
            method:'POST',
            headers:{
                Accept:'application/json',
                "Authorization":`Token ${token}`
            },
            body:fd
        }).then(response => {
            return response.json()
        }).then(data=>{
            if(data.detail) {
                if(data.detail.toLowerCase().includes('token')) {
                    signout(()=>{
                        window.location.reload(false)
                    })
                } else {
                    setError(data.detail)
                }
            } else {
                setSuccess(data.notification_success)
            }
        }).catch(err=>{
            
        })
    }

    const [states,setStates] = useState([])
    const [districts,setDistricts] = useState([])
    const [blocks,setBlocks] = useState([])

    useEffect(()=>{
        fetchStates()
        .then(response=>{
            return response.json()
        })
        .then(data=>{
            setStates(data)
        })
        .catch(err=>{

        })
    },[])

    const [to,setTo] = useState('All')
    const[locationParam,setLocationParam] = useState({
        state:'',
        district:'',
        block:''
    })

    const handleStateChange = (name,func,setFunc) => (event) => {
        setLocationParam({...locationParam,[name]:event.target.value})
        func(event.target.value).then(response=> {
            return response.json()
        })
        .then(data => {
                setFunc(data)
        })
        .catch(err => {
            console.log(err)
        });
    }

    const [location,setLocation] = useState(false)
    const setLocationForm = () => {
        return(
        <>
        <Form.Control as="select" size="sm" value={locationParam.state} onChange={handleStateChange('state',fetchdistrict,setDistricts)} className="col-4 mt-2">
            <option key="Select Your State">{strings.select_your_state}</option>
            {
                states.map((india_states,i) => {
                        return <option  key={i}>{india_states.state_name}</option>
                })
            }
        </Form.Control>
        
        <Form.Control as="select" size="sm" value={locationParam.district} onChange={handleStateChange('district',fetchblocks,setBlocks)} className="col-4 mt-2">
            <option key="Select Your district">{strings.select_your_district}</option>
            {
                districts.map((district,i) => {
                        return <option  key={i}>{district.district_name}</option>
                })
            }
            
        </Form.Control>
            
        <Form.Control as="select" size="sm" value={locationParam.block} onChange={e=>{setLocationParam({...locationParam,block:e.target.value})}} className="col-4 mt-2">
        <option key="Select Your block">{strings.select_your_block}</option>
        {
            blocks.map((block,i) => {
                    return <option  key={i}>{block.block_name}</option>
            })
        }
        </Form.Control>
        </>
        )
    }
    return (
        <div className="row ">
            <div className="col-12 col-lg-8 mx-auto">
            {/* onClick={handleNormal('all')} */}
                <Form className="my-4 mx-auto" onSubmit={handleNormal}>
                    <Button className="ml-2" onClick={e=>{
                        e.preventDefault()
                        setTo('All')
                        setLocation(false)
                    }}
                     size="sm">All</Button>
                    <Button onClick={e=>{
                        e.preventDefault()
                        setTo('Blocked')
                        setLocation(false)
                    }} className="ml-2" size="sm">Blocked</Button>
                    <Button onClick={e=>{
                        e.preventDefault()
                        
                        setLocation(true)
                        setTo(false)
                    }} className="ml-2" size="sm">Location</Button>
                    {
                        location ?
                        setLocationForm():
                        ''
                    }
                    <Form.Row className="mt-4">
                    <Form.Label>
                        Type Message to send notification <span className="h6 mr-3">{
                            to === 'All'?
                            'to all users'
                            :
                            (
                                to === 'Blocked'?
                                'to blocked users':
                                (
                                    location ?
                                    'to users from location you select'
                                    :
                                    ''
                                )
                            )
                        } </span>
                    </Form.Label>
                    </Form.Row>
                    <Form.Row>
                    {
                        notificationTextError?
                        <p style={{color:"red" , fontSize:"0.8em"}}>{notificationTextError}</p>
                        :
                        ''
                    }
                    
                    <Form.Control  as="textarea" rows={3} onChange={(e)=>{
                        e.preventDefault()
                        setNotificationText(e.target.value)
                        if(e.target.value.length > 255) {
                            setNotificationTextError("Message cannot be greater than 255 words")
                        } else{
                            setNotificationTextError(false)
                        }
                    }} className="col-6" />
                    </Form.Row>
                    <Button size="sm" type="submit" className="mt-3" variant="success">Send Notification</Button>
                </Form>
            </div>
        </div>
    )
}

export default SendNotification;