import React from 'react';
import Menu from './Menu'
import Footer from './Footer';
import {Redirect} from 'react-router-dom'
import {signout,isAuthenticated} from '../auth'

const Layout = ({
    title = "Title",
    description="Description",
    className,
    children
}) => {
    const next = () =>{
                       return  <Redirect to={{
                            pathname:"/admin/signin",
                            state: { from:"/admin/dashboard" }
                        }} />
    }
    return (
        <>
        <main>
            {
                isAuthenticated().is_staff ?
                <>
                
                    {signout("/signin")}
                 
                 </>
                :
              <>  
                <Menu />
            <div >
                <h1 className="hidden m-0 p-0"></h1>
                <div className="wrapper">
                    
                    {children}
                </div>
            </div>
            </>
            }
            

            

        </main>
        <Footer />
        </>
    )
}

export default Layout;