import { getToken, isAuthenticated } from '../../auth'
import {API} from '../../config'
import {getEKLanguage} from '../../utils/language_utils'

export const fetchCattleType = () => {
    let lang = getEKLanguage()
    return fetch(`${API}/ads/cattletypes/`,{
        "method":"GET",
        "headers":{
            Accept:'application/json',
            "Content-Type":"application/json",
            "Accept-Language":`${lang}`
        }
    })
}
export const fetchCattleBreed = (token,cattle_breed) => {
    let lang = getEKLanguage()
    
    return fetch(`${API}/ads/cattlebreeds/?search=${cattle_breed}`,{
        "method":"GET",
        "headers":{
            Accept:'application/json',
            "Content-Type":"application/json",
            "Accept-Language":`${lang}`
        }
    })
}

export const fetchPriceStatus = () => {
    let lang = getEKLanguage()
    
    return fetch(`${API}/ads/pricestatus/`,{
        "method":"GET",
        "headers":{
            Accept:'application/json',
            "Content-Type":"application/json",
            "Accept-Language":`${lang}`
        }
    })
}


export const fetchMilkPerDay = () => {
    let lang = getEKLanguage()
    
    return fetch(`${API}/ads/milkperday/`,{
        "method":"GET",
        "headers":{
            Accept:'application/json',
            "Content-Type":"application/json",
            "Accept-Language":`${lang}`
        }
    })
}

export const getCattleAdvertisement = (id) => {
    let lang = getEKLanguage()
    let token = getToken()
    var fd = new FormData() 
    return fetch(`${API}/ads/cattleads/${id}/?toedit=optm/`,{
        "method":"GET",
        "headers":{
            Accept:'application/json',
            "Content-Type":"application/json",
            "Accept-Language":`${lang}`,
            "Authorization":`Token ${token}`
        }
    })
}
