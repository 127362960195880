import React from "react";
//import AdminDashboard from "./AdminDashboard";
import AdminNavbar from './AdminNavbar';


const AdminDashboardBase = ({title = "Title",image,description,className,children}) => (
    <>
        <AdminNavbar />
        <div className="container">
        <div className="row">
        <div className="jumbotron col-lg-8 col-12 mx-auto d-flex mt-0" style={{height:"300px"}}>
            
                <div className="col-6 mx-auto">
                    <h1 className="h4 ">Welcome, </h1>
                    <p className="h4 mb-2 ">{description}</p>
                </div>
            <div>

            </div>
        </div>
        </div>
        </div>
        <div className={className}>
            {children}
        </div>
    </>
)

export default AdminDashboardBase;