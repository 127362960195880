import React ,{useState,useEffect} from 'react';
import {Form,FormControl,Button,Spinner} from 'react-bootstrap';
import {Redirect} from 'react-router-dom';
import {fetchWoodType,fetchWoodAvailableType,fetchWoodAndTreeAds} from './TreeAndWoodsApiCore';
import TreeAndWoodReview from './TreeAndWoodReview'


import {getEKLanguage} from '../../utils/language_utils'

import {API} from '../../config'

import {validateSession,isAuthenticated, signout} from '../../auth'
import Layout from '../Layout';
import {strings} from '../../utils/translated_strings'
import {fetchStates,fetchdistrict,fetchblocks} from '../../utils/AreaUtils'

const TreeAndWoodEditAds = (props) => {
    const [id,setId]=useState()
    const [adposted,setAdPosted] = useState(false)
    const [treePostFields,setTreePostFields] = useState({
        wood_name:'',
        wood_available_type:'',
        quantity:'',
        product_price:0,
        photo1:"",
        photo2:"",
        photo3:"",
        state:'',
        district:'',
        block:'',
        village:'',         
        super_category:'',
        additional_information:'',
    })    

    const ErrorInitState = {
        wood_name_error:'' ,
        wood_available_type_error:'',
        quantity_error:'', 
        product_price_error:'',
        additional_information_error:'', 
        state_error:'',
        district_error:'',
        block_error:'',          
    }     

    const isValid = () => {
        let flag = true;
        let wood_name_error = '' ;
        let wood_available_type_error = '';
        let quantity_error = '' ;
        let product_price_error = '';
        let state_error='';
        let district_error='';
        let block_error='';        

        if(treePostFields.wood_name === '') {
            wood_name_error = "Please select wood type"
            flag = false;
        }
        if(treePostFields.wood_available_type === '') {
            wood_available_type_error = "Please select how wood is available"
            flag = false;
        }
        if(treePostFields.quantity === 0 || treePostFields.quantity === '') {
            quantity_error = "Quantity must be greator than 0"
            flag = false;
        }
        if(treePostFields.product_price === 0 || treePostFields.product_price === '') {
            product_price_error = "Price must be greator than 0"
            flag = false;
        }
        if(treePostFields.state === '')
        {
            state_error="Please select your state name"
        }
        if(treePostFields.district ==='')
        {
            district_error = "Please select your district"
        }
        if(treePostFields.block === '')
        {
            block_error = 'Please select your block'
        }          
        setTreePostFieldsErrors({wood_name_error,product_price_error,quantity_error,product_price_error})
        return flag
    }
    useEffect(()=>{
        fetchblocks(treePostFields.district)
        .then(response=>{
            return response.json()
        })
        .then(data=>{
            setBlocks(data)
        })
    },[treePostFields.district])

    useEffect(()=>{
        fetchdistrict(treePostFields.state)
        .then(response=>{
            return response.json()
        })
        .then(data=>{
            setDistricts(data)
        })
    },[treePostFields.state])

    const [states,setStates] = useState([])
    const [districts,setDistricts] = useState([])
    const [blocks,setBlocks] = useState([])

    const handleStateChange = (name,func,setFunc) => (event) => {
        setTreePostFields({...treePostFields,[name]:event.target.value })
        func(event.target.value).then(response=> {
            return response.json()
        })
        .then(data => setFunc(data))
        .catch(err => {
            console.log(err)
        });
    }     

    const [treePostFieldsErrors,setTreePostFieldsErrors] = useState(ErrorInitState)

    const [loading,setLoading] = useState(true)
    const [error,setError] = useState(false)
    const [woodTypes,setWoodTypes] = useState([])
    const [availables,setAvailables] = useState([])
    const [review,setReview] = useState(false)
    const [photos,setPhotos] = useState({
        photo1:"https://via.placeholder.com/300",
        photo2:"https://via.placeholder.com/300",
        photo3:"https://via.placeholder.com/300"
    })
    const destructAndSetAllData = (d) => {
        //handleCategorySelection(d.wood_type.category.category_name)
        fetchStates()
        .then(response=>{
            return response.json()
        })
        .then (data=>{
            setStates(data)
        })        

        setTreePostFields({ id:d.id, 
            wood_name : d.wood_type.wood_name, 
            wood_available_type  : d.available.wood_available_type, 
            quantity  : d.quantity, 
            product_price:d.price,
            additional_information:d.additional_information,
            state:d.state.state_name,
            district:d.district.district_name,
            block:d.block.block_name,
            village:d.village
        })

        setPhotos({photo1:d.product_image1,photo2:d.product_image2,photo3:d.product_image3})
    }

    useEffect(()=>{
        validateSession()
        .then(response => {
            return response.json()
        }).then(data => {
            if(data.detail){
                setError("You are not logged in")    
            }
        }).catch(err=>{
            setError("You are not logged in")    
        })
                
        fetchWoodType()
        .then(response => {
            return response.json()
        })
        .then(data => {
            setWoodTypes(data)
        })
        .catch(error=>{
            setError(error)
        })

        fetchWoodAvailableType()
        .then(response => {
            return response.json()
        })
        .then(data => {
            setAvailables(data)
        })
        .catch(error=>{
            setError(error)
        })   
        fetchWoodAndTreeAds(props.match.params.id)
        .then(response=>{
            return response.json()
        })
        .then(data => {
            if(data.detail)
            {
                setError(data.detail)
                setLoading(false)
            }
            else {
                destructAndSetAllData(data)
                setLoading(false)
            }
        })
        .catch(err=>{
            setError(err)
        })
        setLoading(false)    
    },[])

    
    const handleCategorySelection = (selected) => {    
        setTreePostFields({...treePostFields,category_name:selected})
        
        //getProduct(selected)
    }
    const user_info = isAuthenticated()  
    const submitAdvertisement = () => {
        let lang = getEKLanguage()
        let token =  user_info.token
        var fd = new FormData();
        let photo1 = null
        let photo2 = null
        let photo3 = null
        if(treePostFields.photo1 !== photos.photo1)
            photo1 = treePostFields.photo1
        if(treePostFields.photo2 !== photos.photo2)
            photo2 = treePostFields.photo2
        if(treePostFields.photo3 !== photos.photo3)
            photo3 = treePostFields.photo3
        
        fd.append('wood_name',treePostFields.wood_name)
        fd.append('wood_available_type',treePostFields.wood_available_type)
        fd.append('quantity',treePostFields.quantity)
        fd.append('product_price',treePostFields.product_price)
        fd.append('additional_information',treePostFields.additional_information)
        fd.append('photo1',treePostFields.photo1)
        fd.append('photo2',treePostFields.photo2)
        fd.append('photo3',treePostFields.photo3)
        fd.append('state',treePostFields.state)
        fd.append('district',treePostFields.district)
        fd.append('block',treePostFields.block)
        fd.append('village',treePostFields.village)         

        fetch(`${API}/ads/treeandwoodads/${treePostFields.id}/`,{
            method: "PUT",
            headers: {
                Accept:'application/json',
                "Accept-Language":`${lang}`,
                Authorization:`Token ${token}`
            },
            body: fd
        })
        .then(response => {
            return response.json()
        })
        .then( data => {
            if(data.detail) {
                if(data.detail.toLowerCase().includes('token')) {
                    signout(()=>{
                        window.location.reload(false)
                    })
                } else {
                    setError(data.detail)
                }
            } else {
             setId(data.id)
            }
        })
        .catch(err => {
                       
            setError(err)
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if(isValid()){
            setAdPosted(true)
            submitAdvertisement()
            setTreePostFieldsErrors(ErrorInitState)
            setReview(false)
        }
    }

    const handleReview = (e) => {
        e.preventDefault()
        if(review === true){
            setReview(false)
            return
        }
        if(isValid())
        {
            
            setReview(true)
        }
        else{
            setReview(false)
        }
    }    

    const handleSelection = (name) => (e) => {
        setTreePostFields({...treePostFields,[name]:e.target.value})
    }

    const handleImageSelection = (name) => (e) => {
        e.preventDefault()
        const reader =new FileReader()
        reader.onload = () => {
            if(reader.readyState === 2) {
                setPhotos({...photos,[name]:reader.result})
            }
        }
        reader.readAsDataURL(e.target.files[0])
        setTreePostFields({...treePostFields,[name]:e.target.files[0]})
        
    }

    const redirectToItem = () => {
        if(id) {
            return <Redirect to={`/item/detail/tree-and-wood/${id}/`} />
        }
    }    

    const TreeAndWood = () => (
        <React.Fragment>
        <Form className="mt-4 mx-auto" onSubmit={handleSubmit}>            
            <h3 className="gray heading">Enter details </h3>
            <hr />

                <Form.Row>
                    <Form.Label  column sm="4">
                            Select Type of Wood:
                    </Form.Label>                
                    <FormControl as="select" value={treePostFields.wood_name} onChange={handleSelection("wood_name")} size="sm" className="col-lg-4 mt-2">                        
                    <option key="Select wood type">types of wood</option>
                        {
                            woodTypes.map((d,id) => {
                                return <option key={id}>{d.wood_name}</option>
                            })
                        }
                    </FormControl>            
                    <div className="d-block" style={{fontSize:"0.8em",color:"red"}}>{treePostFieldsErrors.wood_name_error}</div>
                </Form.Row>
                <Form.Row>
                    <Form.Label  column sm="4">
                            Available Type:
                    </Form.Label>                
                    <FormControl as="select" value={treePostFields.wood_available_type} onChange={handleSelection("wood_available_type")} size="sm" className="col-lg-4 mt-2">                        
                    <option key="Select availability">Available as</option>
                        {
                            availables.map((d,id) => {
                                return <option key={id}>{d.wood_available_type}</option>
                            })
                        }
                    </FormControl>            
                    <div className="d-block" style={{fontSize:"0.8em",color:"red"}}>{treePostFieldsErrors.wood_available_type_error}</div>
                </Form.Row>
                <Form.Row>
                    <Form.Label  column sm="4">
                            Quantity:
                    </Form.Label>                
                    <FormControl type="text" value={treePostFields.quantity} onChange={handleSelection("quantity")} size="sm" className="col-lg-1 mt-2"/>                        
                    <div className="d-block" style={{fontSize:"0.8em",color:"red"}}>{treePostFieldsErrors.quantity_error}</div>
                </Form.Row>  
                <Form.Row>
                    <Form.Label  column sm="4">
                            Price:
                    </Form.Label>                
                    <FormControl type="text" value={treePostFields.product_price} onChange={handleSelection("product_price")} size="sm" className="col-lg-1 mt-2"/>                        
                    <div className="d-block" style={{fontSize:"0.8em",color:"red"}}>{treePostFieldsErrors.product_price_error}</div>
                </Form.Row> 
                <Form.Row>
                        <Form.Label column sm="4">
                                Select Your State
                        </Form.Label>         
                               
                        <FormControl size="sm" as="select"  defaultValue={treePostFields.state} onChange={handleStateChange("state",fetchdistrict,setDistricts)} className="col-lg-4 mt-2">
                            
                            {
                                states.map(d=>{
                                    return <option key={d.id}>{d.state_name}</option>
                                })
                            }
                        </FormControl>            
                        <div style={{fontSize:"0.8em",color:"red"}}>{treePostFieldsErrors.state_error}</div>
                    </Form.Row>
                    <Form.Row>
                        <Form.Label column sm="4">
                                Select Your District
                        </Form.Label>                
                        <FormControl size="sm" as="select" defaultValue={treePostFields.district} onChange={handleStateChange("district",fetchblocks,setBlocks)} className="col-lg-4 mt-2">
                                
                                {
                                    districts.map(d => {
                                        return <option key={d.id}>{d.district_name}</option>
                                    })
                                }
                        </FormControl>            
                        <div style={{fontSize:"0.8em",color:"red"}}>{treePostFieldsErrors.district_error}</div>
                    </Form.Row>
                    <Form.Row>
                        <Form.Label column sm="4">
                                Select Your Block
                        </Form.Label>                
                        <FormControl size="sm" as="select" defaultValue={treePostFields.block} onChange={handleSelection("block")} className="col-lg-4 mt-2">
                                
                                {
                                    blocks.map(d => {
                                        return <option key={d.id}>{d.block_name}</option>
                                    })
                                }
                        </FormControl>            
                        <div style={{fontSize:"0.8em",color:"red"}}>{treePostFieldsErrors.blocks_error}</div>
                    </Form.Row>
                    <Form.Row>
                        <Form.Label column sm="4">
                                Enter your village name
                        </Form.Label>                
                        <FormControl size="sm" type="text" value={treePostFields.village} onChange={handleSelection("village")} className="col-lg-4 mt-2" />
                    </Form.Row>                      
                <Form.Row>
                        <Form.Label column sm="4">
                            Additional Information:
                        </Form.Label>
                    <FormControl as="textarea" style={{resize:"none"}} rows={3}  value={treePostFields.additional_information} onChange={handleSelection("additional_information")} className="col-lg-4 mt-2 mr-sm-2" size="sm"  placeholder=""/>
                    <div style={{fontSize:"0.8em",color:"red"}}>{treePostFieldsErrors.additional_information_error}</div>
                </Form.Row>  
                <Form.Row className="m-auto ">
                        <div className="col-2">
                        <label className="mt-2 btn btn-default btn-outline-secondary btn-sm mx-auto" >
                            Click to Browse <input type="file" hidden onChange={handleImageSelection("photo1")}  className="mt-2"/>
                        </label>
                        <img src={photos.photo1}
                            style={{height:"150px",width:"150px"}} alt="photo1"/>
                        </div>
                        <div className="col-2 ml-4">
                        <label className="mt-2 btn btn-default btn-outline-secondary btn-sm mx-auto">
                            Click to Browse <input type="file" hidden onChange={handleImageSelection("photo2")}  className="mt-2"/>
                        </label>
                        <img src={photos.photo2}
                            style={{height:"150px",width:"150px"}} alt="photo2"/>                        
                        </div>
                        {/* //style={{display:"inline-block",width:"100px",height:"100px",backgroundImage:`url(${data.photo3})`}} */}
                        <div className="col-2 ml-4">
                            <label className="btn mt-2 btn-default btn-outline-secondary btn-sm" >
                                Click to Browse<input type="file" hidden onChange={handleImageSelection("photo3")}  className="mt-2"/>
                            </label>
                            <img src={photos.photo3} style={{height:"150px",width:"150px"}} alt="photo3"/>                        
                        </div>
                    </Form.Row>
                    <div className="form-row col-10 mt-4">
                        <div className="col-3">
                            <Button  variant="info" onClick={handleReview}>Review</Button>
                        </div>
                        <div className="col-3 ml-auto">
                        <Button  className=" " type="submit" variant="info">
                                {
                                    adposted?
                                    <>
                                        <Spinner animation="border" role="status">
                                        <span className="sr-only">Saving...</span>
                                        </Spinner>                                    
                                    </>:

                                    <span>Submit</span>
                                }
                            </Button>
                        </div>
                    </div>                                                  
        </Form>
        {redirectToItem()}
        </React.Fragment>
    )

    return (
        <>
        <Layout>
        
        {
        review ?
            
            <>
            <TreeAndWoodReview postData={treePostFields} photos={photos}/>
            <div className="container">
                <div className="row">
                <Button  className="m-auto" variant="info" onClick={handleReview}>Go back to edit</Button>
                <div className="col-3 ml-auto">
                <Button onClick={handleSubmit} className=" " type="submit" variant="info">
                                {
                                    adposted?
                                    <>
                                        <Spinner animation="border" role="status">
                                        <span className="sr-only">Saving...</span>
                                        </Spinner>                                    
                                    </>:

                                    <span>Submit</span>
                                }
                            </Button>
                        </div>
                </div>
            </div>
            </>
            :
            loading ? 
            '':
            (
                error?
            <div className="alert alert-warning col-6 mx-auto">
                <h3>{error}</h3>
            </div>
            :
            <div className="col-lg-10 col-12 ml-auto" >
                {TreeAndWood()}
            </div>
            )
        }
        </Layout>
        </>        
    )
}

export default TreeAndWoodEditAds;