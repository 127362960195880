import React, {useState,useEffect} from 'react';
import {Form, FormControl, Button, Col, Row} from 'react-bootstrap';
import {getProductPackaging ,getProduct,getBreed,getStatus,getCategories} from '../utils/Apicore'
import {strings} from '../utils/translated_strings'
import Menu from '../core/Menu'
import {API} from '../config';
import {getEKLanguage} from '../utils/language_utils'
import {validateSession,isAuthenticated} from '../auth/'
import SearchCategories from './SearchCategories'
// import ReviewPost from './ReviewPost';

import {StateComponent,fetchStates,fetchdistrict,fetchblocks} from '../utils/AreaUtils'
import  {Redirect}  from 'react-router-dom';
import AgriculturalProductPost from './AgriculturalPostAds/AgriculturalProductPost';
import MachineryPostAds from './MachineryPostAds/MachineryPostAds';
import CattlePostAds from './CattlePostAds/CattlePostAds';
import TreeAndWoodAds from './TreeAndWoodAds/TreeAndWoodAds';
import ServiceInRentPostAds from './ServiceInRentPostAds/ServiceInRentPostAds'
import LabourInRentPostAds from './LabourInRent/LabourInRentAds'
import FertilizersAndPestisidesPostAds from './FertilizersAndPestisidesAds/FertilizersAndPestisidesAds';
import OtherAgriProductAds from './OtherProducts/OtherProductsPost'
import Layout from './Layout';



import { filterCategories , filterCategories2} from '../utils/Apicore';

const PostAd = (props) => {

    const [categories,setCategories] = useState([])

    const [searchtext,setSearchText] = useState('') 
    
    const [changeit, setChangeit] = useState(false)

    const [error,setError] = useState(false)
    const [engCategories,setEngCategories] = useState([])
    const [engSelectedCategory,setEngSelectedCategory] = useState(false)

    const getCategoriesInEnglish = () => {
        fetch(`${API}/ads/allcategory/`,{
            method: "GET",
            headers: {
                Accept:'application/json',
                "Content-Type":"application/json"
            }
        })
        .then(response => {
            return response.json()
        })
        .then(data => {
            
            if(props.match.params.super_category!=="BuyBackOffer")
            {
                setEngCategories(data)
                setEngSelectedCategory(data[0].category_name)
            } else {
                var eng = filterCategories2(data)
                setEngCategories(eng)
                setEngSelectedCategory(eng[0].category_name)
            }
        })
    }
    useEffect(() => {
        validateSession()
        .then(response => {
            return response.json()
        }).then(data => {
            
            if(data.detail){
                setError("You are not logged in")    
            }
        }).catch(err=>{
            setError("You are not logged in")    
        })
        getCategoriesInEnglish();
        getCategories()
        .then(response => {
            return response.json()
        })
        .then(data => {
            // setCategories(data);
            if(props.match.params.super_category !== "BuyBackOffer"){
                setCategories(data);
                setSelectedCategory(data[0].category_name);
            } else {
                var o_categories = filterCategories(data);
                setCategories(o_categories)
                setSelectedCategory(o_categories[0].category_name);
            }   
            
        })
        .catch(err=> {
            setError(err)
        })

        setChangeit(false)
    }, [])

    const handleCategory = (e) => {    
        setSelectedCategory(e.target.value)
        let ix = categories.findIndex((c,idx) => {
        if(c.category_name === e.target.value)
        {
            setEngSelectedCategory(engCategories[idx].category_name);
            return idx
        }  
        } )
    }

    const [selectedCategory,setSelectedCategory] = useState()

    const displayFormBasedOnCategory = () => {
        
        if(engSelectedCategory) {
            
            if(engSelectedCategory.toLowerCase().includes('grain') | engSelectedCategory.toLowerCase().includes('oilseed')   
            | engSelectedCategory.toLowerCase().includes('fruit') | engSelectedCategory.toLowerCase().includes('plant')
            | engSelectedCategory.toLowerCase().includes('pulse') | engSelectedCategory.toLowerCase().includes('vegetable')
            | engSelectedCategory.toLowerCase().includes('flower') | engSelectedCategory.toLowerCase().includes('dairy') ) 
            {
                
                return <AgriculturalProductPost super_category={props.match.params.super_category} props={selectedCategory} />
            }
            if(engSelectedCategory.toLowerCase().includes('cattle')){
                return <CattlePostAds super_category={props.match.params.super_category} />
            }
            if(engSelectedCategory.toLowerCase().includes('machinary')){
                return <MachineryPostAds super_category={props.match.params.super_category} />
            }
            if(engSelectedCategory.toLowerCase().includes('wood')){
                return <TreeAndWoodAds super_category={props.match.params.super_category} />
            }
            if(engSelectedCategory.toLowerCase().includes('service')) {         
                return <ServiceInRentPostAds super_category={props.match.params.super_category} />
            }
            if(engSelectedCategory.toLowerCase().includes('labour')) {
                return <LabourInRentPostAds super_category={props.match.params.super_category} />
            }
            if(engSelectedCategory.toLowerCase().includes('fertilizer')) {
                return <FertilizersAndPestisidesPostAds super_category={props.match.params.super_category} />
            }
            if(engSelectedCategory.toLowerCase().includes('other')) {
                
                return <OtherAgriProductAds super_category={props.match.params.super_category} />
            }            
        }
    }

    return (
        <Layout>
        <div className="container-fluid">
            <div className="row">
                { props.match.params.super_category === "Sellads"?
                <h3 className="mt-4 mx-auto text-muted">POST YOUR AD</h3>
                :
                    (
                        props.match.params.super_category === "BuyOffer" ?
                    <h3 className="mt-4 mx-auto text-muted">POST YOUR BUYING OFFER</h3>
                    :
                    <h3 className="mt-4 mx-auto text-muted">POST YOUR BUYING BACK OFFER</h3>
                    )
                }
            </div>
        </div>
            <SearchCategories super_category={props.match.params.super_category} setCategorySearch={cty =>{
                    let ix = categories.findIndex((c,idx) => {
                        if(c.category_name === cty)
                        {
                            setEngSelectedCategory(engCategories[idx].category_name);
                            return idx
                        }
                    });
                    setSelectedCategory(cty) 
                } 
            }/>
            <div className="container">
                <div className="form-row">
                <div className="col-8 mx-auto " >
                    <Form className="mt-4 mx-auto">
                    <Form.Row>
                        <Form.Label column sm="4">
                        Selected Category
                        </Form.Label>
                        
                            {changeit === true ?
                                <React.Fragment>
                                    <FormControl value={selectedCategory} as="select"  onChange={handleCategory} className="style-signup-boxes mt-2 mr-2">
                                        <option key="Select your category">Select Your Category</option>
                                        {
                                            categories.map(d => {
                                                return <option key={d.id}>{d.category_name}</option>
                                            })
                                        }
                                    </FormControl>
                                    <Button variant="outline-success" size="sm" onClick={()=>setChangeit(false)} >{strings.save}</Button>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <span className="inline">{selectedCategory}</span>
                                    <Button onClick={()=>setChangeit(true)} 
                                            variant="link" className="pt-0" style={{textDecoration:"underline"}}>
                                                {strings.changeit}
                                    </Button>
                                </React.Fragment>
                    }
                    
        </Form.Row>
        </Form>
        </div>

            {displayFormBasedOnCategory()}
            
            
        </div>            
        </div>
        <>
        
        </>
        </Layout>
    )
}

export default PostAd;