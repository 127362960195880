import React from 'react'
import Layout from '../core/Layout'

const BeVerifiedUser = () => {
    return (
        <Layout>
        <div className="container-fluid">
            <div className="row">
                <div className="col-8 mx-auto">
                    <h3>Why do other listings show a verified user tag but my listing does not has any such tag?</h3>
                    <p>Any seller who successfully completes his or her KYC is shown to buyers as a verified user. As a seller, you can also do your KYC free of cost and be shown to buyers as a verified user.</p>

                    <h3>What is KYC?</h3>
                    <p>KYC is short for Know Your Customer. KYC is often used by banks and financial institutions to verify the identification and contact details of their customers. </p>
                    <p>At <span className="font-weight-bold">EKRISHIBAZAAR.COM</span>, we also verify users, especially sellers, on our platform. Sellers have to submit a </p>
                    <ul className="circle">
                        <li >Live selfie</li>
                        <li >Live picture of front page of a Govt issued Id card</li>
                        <li >Live picture of back page of the same Govt Id card</li>
                    </ul>
                    
                    <p className="mt-1">This information is manually verified by our team.</p>

                    <h5>At EKRISHIBAZAAR.COM, we value your Safety</h5>
                    <p>We are dedicated in making sure that our platform provides the best way for our customers to connect safely all the time.   </p>

                    <p>
                        We urge our users to follow the government general/special guidelines (as lockdown) applicable to your location while meeting buyers and sellers. <span className="font-weight-bold">EKRISHIBAZAAR.COM</span> urges all users to stay home and stay safe as much as possible. In-case of meeting other users, strictly abide by govt issued safety guidelines.   
                        In the meanwhile feel free to browse and add products which you like by clicking the add to the favorite button.
                    </p>

                    <h5>EKRISHIBAZAAR.COM is taking the following measures to ensure a safe exchange</h5>
                    <p><span className="h6">No suspicious items on our platforms:</span> Listings for selling cure or immunity boosters against Coronavirus – are not allowed</p>
                    <p><span className="h6">Right Products at right Prices:</span> We allow listings for selling only new masks & sanitizers which are sold in accordance with the prices set by the Government. Our technical filters are configured to enforce these guidelines. Our team of site auditors combs through the site to remove any listings which do not conform to these guidelines and might find a way through our filters.</p>

                    <h6>EKRISHIBAZAAR.COM also urges its users to be safe.</h6>
                    <ol className="number" start="1">
                        <li>
                        Practice Safe Hygiene: Maintain a safe distance and wash sanitize your hands frequently. Wear a mask, if you feel the need.
                        </li>
                        <li>
                        Do not buy used masks and gloves: These can be potentially infected and cause more harm than good.
                        </li>
                        <li>
                        Do not buy any medicines claiming to be a cure: There is no specific treatment yet known for Covid-19, so healthcare providers treat the clinical symptoms (e.g. fever, difficulty breathing) of patients. Beware of such Ayurvedic or herbal medicines claiming to be a cure for the disease.
                        </li>
                        <li>
                        Do not upload or provide any false or unverified information concerning coronavirus in your listings or through our chat feature which would likely create panic among the public and disturb the public order and social tranquillity
                        </li>
                        <li>
                        Sanitize all products after buying.
                        </li>
                        <li>
                        Be on the lookout and report any suspicious listings or any content which does not meet our guidelines
                        </li>
                        <li>
                        Please follow the lockdown guidelines applicable to your location while meeting buyers and sellers.
                        </li>
                    </ol>                        
                    <p>For any concerns or suggestions please write to us at at <span className="font-weight-bold">info@ekrishibazaar.com</span></p>

<h5>Why should I do my KYC?</h5>
<p>Once you successfully complete your KYC, you get the following benefits:</p>
<ol className="number">
<li>Your ads are shown to buyers with a <span className="font-weight-bold ">verified user</span> tag. This highlights your ad and makes it stand out.</li>
<li>Buyers want to interact with genuine & serious sellers. When they see you are verified, your ad will attract more buyers so that you can sell faster.</li>

</ol>

<h5>What is the cost of KYC?</h5>
<p>Well, it will cost you a big zero :)</p>
<p>
    Thats right, we offer KYC free of cost. We are grateful you have chosen <span className="font-weight-bold">EKRISHIBAZAAR.COM</span> to sell your items and we offer this free service as a token to appreciate your gesture and help you stand out as a serious seller. Without spending any money, you complete your KYC and get the <span className="font-weight-bold ">verified user</span> badge to stand out.
</p>

<h5>How can I start my KYC?</h5>

<p>KYC can only be done from <span className="font-weight-bold">EKRISHIBAZAAR.COM</span> android app only for now. You can follow these steps:</p>
Please open the <span className="font-weight-bold">EKRISHIBAZAAR.COM</span>. 
<ol className="number">
    <li>
Login to your account.
</li>
<li>
Tap on <span className="font-weight-bold">My Account</span> icon on the bottom navigation bar and then select any of your ads.
</li>
<li>
You will see a "Start KYC" 
</li>
<li>
You will see a popup with more details of KYC. It will tell you the documents you can use. Please read the information carefully.
</li>




<li>Once you have read and understood the details and you want to move forward, please click on <span className="font-weight-bold">Verify now</span> button again.</li>
<li>The app may ask permission to open camera. Please provide the permission. Without this, you cannot do your KYC. If you denied the permission by mistake, you can click on <span className="font-weight-bold">Verify now</span> button (step 6) again to start the process.</li>
<li>Your camera will start automatically. Please capture your selfie with your face clearly visibly (Images shown below are just for demo).</li>
<li>You will be shown a preview of selfie. Please take another selfie if image is not clear. Else you can continue to next step.</li>
<li>You will see a camera inside a frame. Please place the front page of your Govt Id card and fit it within the frame. Please ensure good lighting so that the text on the Govt Id is clearly visible and readable in camera. Take the image.</li>
<li>You will be shown a preview of the front page. Please take another image (Retake) if the text on the Id or your face is not clear. Else you can continue to next step.</li>
<li>You will again see a camera insde a frame. This time, please place the back page of your Govt Id card and fit it within the frame. Again, please ensure good lighting so that the text on the Govt Id is clearly visible. Take the image.</li>
<li>You will be shown a preview of the back page. Please take another image (Retake) if the text on the Id or your face is not clear. Else you can continue to next step.</li>
<li>Your details will be automatically submitted to <span className="font-weight-bold">EKRISHIBAZAAR.COM</span>. Please wait for a few seconds for submission to complete.</li>
<li>Once details are successfully submitted, you will see a ""Verification in progress"" massage.</li>
</ol>
If you face any problem during the process and/or have to start again, don't worry. You can try it as many times as you want till your details are successfully submitted."


<h5>I don't see any option for starting my KYC</h5>
<p>Right now, KYC is available to selective users. You will be able to do your KYC if you:-</p>

<ol className="number">
<li>Are using the latest version of <span className="font-weight-bold">EKRISHIBAZAAR.COM</span> android app?</li>
<li>Have posted an Ad to sell any Agriculture machinery product or buy offer/buyback offer post.</li>
<li>Are from one of any specific location which was decided by <span className="font-weight-bold">EKRISHIBAZAAR.COM</span>.</li>
</ol>
<h5>Which documents can I use for KYC?</h5>
<p>Right now we support only the following documents for KYC:</p>
<ol className="number">
<li>Aadhaar Card</li>
<li>Driving License</li>
<li>Passport</li>
<li>Voter Id</li>
</ol>

    <h5>What happens after my KYC details are submitted?</h5>
    <p>Once you submit your KYC details, it goes to our moderators who will manually review your details. If all your submitted images match our criteria, you will be marked as a <span className="font-weight-bold ">verified user</span>. 
    However, if we are not able to approve your KYC, you will get a notification and you can submit your details again.</p>

<h5>How long will it take to verify my KYC details?</h5>
<p>If you have your Govt Id ready, it will take you 2 minutes to submit your details. You only have to take 3 pictures:</p>
    <ol className="number">
    <li>Your selfie</li>
    <li>Picture of your Govt Id front page</li>
    <li>Picture of your Govt Id back page</li>
    </ol>

<p>Don't worry, you don't have to fill any long forms. We will take care of the rest. Just make sure your face is clear in selfie and the text in your Govt Id is readable in the pictures you take.
Once you have submitted your details, it will take us maximum of 48 hour to verify your details. Our verification team works from 10AM - 5 PM. Any verification request you submit beyond these hours will be processed on next working day with same turnaround time. Please expect on Sundays and public holidays.</p>



<h5>Will I get notified once my KYC is approved</h5>
<p>
    Yes, you will get a notification on app as well as SMS from <span className="font-weight-bold">EKRISHIBAZAAR.COM</span> with confirmation of your approval. We will also notify you in case we are not able to verify all your details so that you can try again.
    Who can see my personal information?
    No one. No other buyer or dealer or <span className="font-weight-bold">EKRISHIBAZAAR.COM</span> employee (except higher authority person) will be able to view your information. However, we are bound by law to share your details with police or other authorities in instance of a wrongdoing.
    Will <span className="font-weight-bold">EKRISHIBAZAAR.COM</span> share my personal information with anyone?
    Never. The information you submit is used only for your KYC verification. It will never with shared with anyone within <span className="font-weight-bold">EKRISHIBAZAAR.COM</span> or outside. We are however obligated to share your information with authorities as per prevailing rules and regulations.
</p>

<h5>Can I remove my personal information?</h5>
<p>Once you are verified, it is valid till you delete your account. If you have interacted with any buyer on the platform, we reserve the right to retain your personal details for a reasonable time as required by law. Rest assured, your information is totally safe with us and will not be shared with anyone else.</p>

<h5>Why is my KYC rejected?</h5>
Your KYC can be rejected for multiple reasons, including:
<ol className="number">
<li>Blurred images</li>
<li>Face not clear in selfie or Govt Id</li>
<li>Face in selfie no matching with photo in Govt Id</li>
<li>Not using original document, etc.</li>
</ol>
You will be able to find the exact reason when you restart the KYC process from My Ads > Preview Ad page (please refer answer to question "How can I start my KYC?")

<h5>Can I do my KYC again?</h5>
Yes, you can submit your KYC details again if it gets rejected. There is no additional cost or limit on attempts. However, we request you to use this feature judiciously and try to correct problems in previous attempts.

                </div>
            </div>
        </div>
        </Layout>
    )
}

export default BeVerifiedUser;