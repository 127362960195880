import React ,{useState,useEffect} from 'react';
import {Form,FormControl,Button,Spinner} from 'react-bootstrap';
import {Redirect} from 'react-router-dom';
import {fetchMachineStatus,fetchWarrantyStatus,fetchMachineBrand,fetchMachinery} from './MachineryCoreApi';
import MachineReviewPost from './MachineryReview';
import {isAuthenticated, signout} from '../../auth/';
import {getEKLanguage} from '../../utils/language_utils'
import {API} from '../../config';

import {strings} from '../../utils/translated_strings'
import {fetchStates,fetchdistrict,fetchblocks} from '../../utils/AreaUtils'

const MachineryPostAds = ({super_category}) => {
    
    const [machinePostFields,setMachinePostFields] = useState({
        machine_name:'',
        machine_brand:'',
        machine_status:'',
        months_in_use:0,
        warranty_status:'',
        remaining_warranty_month:0,
        product_price:0,
        packed_by:'Seller',
        photo1:"",
        photo2:"",
        photo3:"",
        state:'',
        district:'',
        block:'',
        village:'',          
        super_category:'',
        additional_information:'',
    })    

    const ErrorInitState = {
        machine_name_error:'' ,
        machine_brand_error:'',
        machine_status_error:'', 
        months_in_use_error:'', 
        remaining_warranty_month_error:"",
        product_price_error:'', 
        preganency_month_error:'',
        price_fixed_error:'',
        packed_by_error:'',
        warranty_status_error:'',
        state_error:'',
        district_error:'',
        block_error:'',         
    } 

    const [machinePostFieldsErrors,setMachinePostFieldsErrors] = useState(ErrorInitState)

    const [loading,setLoading] = useState(true)
    const [error,setError] = useState(false)
    const [machines,setMachines] = useState([])
    const [machinebrands,setMachineBrands] = useState([])
    const [machinestatuses,setMachineStatuses] = useState([])
    const [warrantystatuses,setWarrantyStatuses] = useState([])
    const [review,setReview] = useState(false)
    const [photos,setPhotos] = useState({
        photo1:"https://via.placeholder.com/300",
        photo2:"https://via.placeholder.com/300",
        photo3:"https://via.placeholder.com/300"
    })    

    useEffect(()=>{
        fetchStates().
        then(response => {return response.json()})
        .then(data=>{
             setStates(data)
        })
        .catch(err=>{
            setError(err)
        })

        fetchMachinery()
        .then(response => {
            return response.json()
        })
        .then(data => {
            setMachines(data)
        })
        .catch(error=>{
            setError(error)
        })

        fetchMachineBrand()
        .then(response => {
            return response.json()
        })
        .then(data => {
            setMachineBrands(data);
        })
        .catch(error=>{
            setError(error)
        })      
        
        fetchWarrantyStatus()
        .then(response => {
            return response.json()
        })
        .then(data => {
            setWarrantyStatuses(data)
        })
        .catch(error=>{
            setError(error)
        })

        fetchMachineStatus()
        .then(response => {
            return response.json()
        })
        .then(data => {
            setLoading(false)
            setMachineStatuses(data)
        })
        .catch(error=>{
            setError(error)
        })          
    },[])

    const [id,setId] = useState()
    const user_info = isAuthenticated()   
    const submitAdvertisement = () => {

        let lang = getEKLanguage()
        let token = user_info.token 
        
        var fd = new FormData();

        fd.append('machine_name',machinePostFields.machine_name)
        fd.append('machine_brand',machinePostFields.machine_brand)
        fd.append('machine_status',machinePostFields.machine_status)
        fd.append('months_in_use',machinePostFields.months_in_use)
        fd.append('warranty_status',machinePostFields.warranty_status)
        fd.append('remaining_warranty_month',machinePostFields.remaining_warranty_month)
        fd.append('product_price',machinePostFields.product_price)
        fd.append('additional_information',machinePostFields.additional_information)
        fd.append('packed_by',machinePostFields.packed_by)
        fd.append('photo1',machinePostFields.photo1)
        fd.append('photo2',machinePostFields.photo2)
        fd.append('photo3',machinePostFields.photo3)    
        fd.append('super_category',super_category)
        fd.append('state',machinePostFields.state)
        fd.append('district',machinePostFields.district)
        fd.append('block',machinePostFields.block)
        fd.append('village',machinePostFields.village)         

        fetch(`${API}/ads/agricultralmachinaryads/`,{
            method: "POST",
            headers: {
                Accept:'application/json',
                "Accept-Language":`${lang}`,
                Authorization:`Token ${token}`
            },
            body: fd
        })
        .then(response => {
            return response.json()
        })
        .then( data => {
            if(data.detail) {
                if(data.detail.toLowerCase().includes('token')) {
                    signout(()=>{
                        window.location.reload(false)
                    })
                } else {
                    setError(data.detail)
                }
            } else {
             setId(data.id)
            }
        })
        .catch(err => {
            setError(err)
        }) 
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        if(isValid()){
            setAdPosted(true)
            submitAdvertisement()
            setMachinePostFieldsErrors(ErrorInitState)
            setReview(false)
        }
    }

    const isValid = () =>{
        let flag = true;
        let machine_name_error='' ;
        let machine_brand_error='';
        let machine_status_error='' ;
        let months_in_use_error='' ;
        let remaining_warranty_month_error="";
        let warranty_status_error='';
        let product_price_error='';
        let packed_by_error='';
        let state_error='';
        let district_error='';
        let block_error='';          

        if(machinePostFields.machine_name_error==='')
        {
            machine_name_error="Please select machine name";
            flag = false;
        }
        if(machinePostFields.machine_brand_error==='')
        {
            machine_status_error="Please select brand of machine";
            flag = false;
        }        
        if(machinePostFields.machine_status_error==='')
        {
            machine_status_error="Please selet if machine is new or used";
            flag = false;
        }
        if(machinePostFields.months_in_use_error<0)
        {
            months_in_use_error="Months in use cannot be less than 0";
            flag = false;
        }
        if(machinePostFields.remaining_warranty_month_error < 0 )
        {
            remaining_warranty_month_error="Warranty months cannot be less than 0";
            flag = false;
        }
        if(machinePostFields.packed_by_error === '')
        {
            packed_by_error="Please select who will pay for packaging";
            flag = false;
        }
        if(machinePostFields.warranty_status === '' ) {
            warranty_status_error = 'Please select warranty status';
            flag = false;
        }

        if(machinePostFields.product_price < 0){
            product_price_error = 'Price cannot be less than 0';
            flag = false;
        }
        if(machinePostFields.state === '')
        {
            state_error="Please select your state name"
        }
        if(machinePostFields.district ==='')
        {
            district_error = "Please select your district"
        }
        if(machinePostFields.block === '')
        {
            block_error = 'Please select your block'
        }           
        setMachinePostFieldsErrors({state_error,block_error,district_error,product_price_error,machine_name_error,machine_brand_error,machine_status_error,months_in_use_error,remaining_warranty_month_error,packed_by_error})
        return flag
    }    
    const [states,setStates] = useState([])
    const [districts,setDistricts] = useState([])
    const [blocks,setBlocks] = useState([])

    const handleStateChange = (name,func,setFunc) => (event) => {
        setMachinePostFields({...machinePostFields,[name]:event.target.value })
        func(event.target.value).then(response=> {
            return response.json()
        })
        .then(data => setFunc(data))
        .catch(err => {
            console.log(err)
        });
    }    
    const handleReview = (e) => {
        e.preventDefault()
        if(review === true){
            setReview(false)
            return
        }
        if(isValid())
        {
            setReview(true)
        }
        else{
            setReview(false)
        }
    }    

    const handleSelection = (name) => (e) => {
        setMachinePostFields({...machinePostFields,[name]:e.target.value})
    }

    const handleImageSelection = (name) => (e) => {
        e.preventDefault()
        const reader =new FileReader()
        reader.onload = () => {
            if(reader.readyState === 2) {
                setPhotos({...photos,[name]:reader.result})
            }
        }
        reader.readAsDataURL(e.target.files[0])
        setMachinePostFields({...machinePostFields,[name]:e.target.files[0]})
        
    }  
    
    const redirectToItem = () => {
        if(id) {
            return <Redirect to={`/item/detail/agricultural-machinary/${id}/`} />
        }
    }        

    const machineryForm = () => (
        <React.Fragment>
        <Form className="mt-4 mx-auto" onSubmit={handleSubmit}>            
            <h3 className="gray heading">Enter details </h3>
            <hr />

                <Form.Row>
                    <Form.Label  column sm="4">
                            Select Type of Machinary:
                    </Form.Label>                
                    <FormControl as="select" value={machinePostFields.machine_name} onChange={handleSelection("machine_name")} size="sm" className="col-lg-4 mt-2">                        
                    <option key="Select machine name">Select Type of machinary</option>
                        {
                            machines.map((d,id) => {
                                return <option key={id}>{d.machine_name}</option>
                            })
                        }
                    </FormControl>            
                    <div className="d-block" style={{fontSize:"0.8em",color:"red"}}>{machinePostFieldsErrors.machine_name_error}</div>
                </Form.Row>

                <Form.Row>
                    <Form.Label  column sm="4">
                            Select Product Brand:
                    </Form.Label>                
                    <FormControl as="select" value={machinePostFields.machine_brand} onChange={handleSelection("machine_brand")} size="sm" className="col-lg-4 mt-2">                        
                    <option key="Select Product Brand">Select brand</option>
                        {
                            machinebrands.map((d,id) => {
                                return <option key={id}>{d.machinery_brand}</option>
                            })
                        }
                    </FormControl>            
                    <div style={{fontSize:"0.8em",color:"red"}}>{machinePostFieldsErrors.machine_brand_error}</div>
                </Form.Row>

                <Form.Row >
                    <Form.Label column sm="4">
                            Status of Product:
                    </Form.Label>                
                    <FormControl as="select" value={machinePostFields.machine_status}  onChange={handleSelection("machine_status")} size="sm" className="col-lg-2 mt-2">                          
                        <option key="Select status">Select status</option>
                            {
                                machinestatuses.map((d,id) => {
                                    return <option key={id}>{d.machine_status}</option>
                                })
                            }
                    </FormControl>
                    <Form.Label column sm="1" className="mr-1">
                            Used:
                    </Form.Label>                
                    <FormControl type="text" value={machinePostFields.months_in_use} onChange={handleSelection("months_in_use")} size="sm" className="col-lg-1 ml-2 mt-2" />
                    <Form.Label column >
                            Month
                    </Form.Label>                
                    <div style={{fontSize:"0.8em",color:"red"}}>{machinePostFieldsErrors.machine_status_error}</div>
                </Form.Row>

                <Form.Row >
                    <Form.Label column sm="4">
                            Warranty Status:
                    </Form.Label>    
                    <FormControl as="select" value={machinePostFields.warranty_status} onChange={handleSelection("warranty_status")} size="sm" className="col-lg-2 mt-2" >                        
                        <option key="select warranty status">Select</option>
                        {
                            warrantystatuses.map((d,id)=>{
                                return <option key={id}>{d.warranty_status}</option>
                            })
                        }
                    </FormControl>
                    <Form.Label column sm="1">
                            Available:
                    </Form.Label>
                    <FormControl type="text" value={machinePostFields.remaining_warranty_month} onChange={handleSelection("remaining_warranty_month")} size="sm" className="col-lg-1 ml-2 mt-2" />                        
                    <Form.Label column sm="1">
                            Month
                    </Form.Label>    
                    <div style={{fontSize:"0.8em",color:"red"}}>{machinePostFieldsErrors.remaining_warranty_month_error}</div>
                </Form.Row>                

                <Form.Row >
                    <Form.Label column sm="4">
                        { super_category === "Sellads"?
                            <span>Price</span>
                            :
                            <span>Expected Price</span>
                        }
                    </Form.Label>                
                    <FormControl type="text" value={machinePostFields.product_price} onChange={handleSelection("product_price")} size="sm" className="mr-lg-1 col-lg-1 mt-2"/>                        
                    <div style={{fontSize:"0.8em",color:"red"}}>{machinePostFieldsErrors.product_price_error}</div>
                </Form.Row> 
                <Form.Row>
                        <Form.Label column sm="4">
                            Who will pay for packaging or loading charges:
                        </Form.Label>
                    <FormControl as="select" size="sm" className="mr-lg-1 col-lg-1 mt-2"   value={machinePostFields.packed_by} onChange={handleSelection("packed_by")}>
                        <option>Seller</option>
                        <option>Buyer</option>
                    </FormControl>
                </Form.Row>                 


                <Form.Row>
                        <Form.Label column sm="4">
                                Select Your State
                        </Form.Label>                
                        <FormControl size="sm" as="select"  value={machinePostFields.state} onChange={handleStateChange("state",fetchdistrict,setDistricts)} className="col-lg-4 mt-2">
                            <option key="Select Your state">{strings.select_your_state}</option>
                            {
                                states.map(d=>{
                                    return <option key={d.id}>{d.state_name}</option>
                                })
                            }
                        </FormControl>            
                        <div style={{fontSize:"0.8em",color:"red"}}>{machinePostFieldsErrors.state_error}</div>
                    </Form.Row>
                    <Form.Row>
                        <Form.Label column sm="4">
                                Select Your District
                        </Form.Label>                
                        <FormControl size="sm" as="select" value={machinePostFields.district} onChange={handleStateChange("district",fetchblocks,setBlocks)} className="col-lg-4 mt-2">
                                <option key="Select Your district">{strings.select_your_district}</option>
                                {
                                    districts.map(d => {
                                        return <option key={d.id}>{d.district_name}</option>
                                    })
                                }
                        </FormControl>            
                        <div style={{fontSize:"0.8em",color:"red"}}>{machinePostFieldsErrors.district_error}</div>
                    </Form.Row>
                    <Form.Row>
                        <Form.Label column sm="4">
                                Select Your Block
                        </Form.Label>                
                        <FormControl size="sm" as="select" value={machinePostFields.block} onChange={handleSelection("block")} className="col-lg-4 mt-2">
                        <option key="Select Your block">{strings.select_your_block}</option>
                                {
                                    blocks.map(d => {
                                        return <option key={d.id}>{d.block_name}</option>
                                    })
                                }
                        </FormControl>            
                        <div style={{fontSize:"0.8em",color:"red"}}>{machinePostFieldsErrors.blocks_error}</div>
                    </Form.Row>
                    <Form.Row>
                        <Form.Label column sm="4">
                                Enter your village name
                        </Form.Label>                
                        <FormControl size="sm" type="text" value={machinePostFields.village} onChange={handleSelection("village")} className="col-lg-4 mt-2" />
                    </Form.Row>                                    
                



                <Form.Row>
                        <Form.Label column sm="4">
                            Additional Information:
                        </Form.Label>
                    <FormControl as="textarea" style={{resize:"none"}} rows={3}  value={machinePostFields.additional_information} onChange={handleSelection("additional_information")} className="col-lg-4 mt-2 mr-sm-2" size="sm"  placeholder=""/>
                    <div style={{fontSize:"0.8em",color:"red"}}>{machinePostFields.additional_information_error}</div>
                </Form.Row>  
                <Form.Row className="m-auto ">
                        <div className="col-2">
                        <label className="mt-2 btn btn-default btn-outline-secondary btn-sm mx-auto" >
                            Click to Browse <input type="file" hidden onChange={handleImageSelection("photo1")}  className="mt-2"/>
                        </label>
                        <img src={photos.photo1}
                            style={{height:"150px",width:"150px"}} alt="photo1"/>
                        </div>
                        <div className="col-2 ml-4">
                        <label className="mt-2 btn btn-default btn-outline-secondary btn-sm mx-auto">
                            Click to Browse <input type="file" hidden onChange={handleImageSelection("photo2")}  className="mt-2"/>
                        </label>
                        <img src={photos.photo2}
                            style={{height:"150px",width:"150px"}} alt="photo2"/>                        
                        </div>
                        {/* //style={{display:"inline-block",width:"100px",height:"100px",backgroundImage:`url(${data.photo3})`}} */}
                        <div className="col-2 ml-4">
                            <label className="btn mt-2 btn-default btn-outline-secondary btn-sm" >
                                Click to Browse<input type="file" hidden onChange={handleImageSelection("photo3")}  className="mt-2"/>
                            </label>
                            <img src={photos.photo3} style={{height:"150px",width:"150px"}} alt="photo3"/>                        
                        </div>
                    </Form.Row>
                    <div className="form-row col-10 mt-4">
                        <div className="col-3">
                            <Button  variant="info" onClick={handleReview}>Review</Button>
                        </div>
                        <div className="col-3 ml-auto">
                        <Button  className=" " type="submit" variant="info">
                                {
                                    adposted?
                                    <>
                                        <Spinner animation="border" role="status">
                                        <span className="sr-only">Saving...</span>
                                        </Spinner>                                    
                                    </>:

                                    <span>Submit</span>
                                }
                            </Button>     
                        </div>
                    </div>                                              
        </Form>
        {redirectToItem()}        
        </React.Fragment>
    )

    const [adposted,setAdPosted] = useState(false)

    return (
        <>
        {
        review ?
            <>
            <MachineReviewPost postData={machinePostFields} photos={photos}/>
            <div className="container">
                <div className="row">
                <Button  className="m-auto" variant="info" onClick={handleReview}>Go back to edit</Button>
                <div className="col-3 ml-auto">
                <Button onClick={handleSubmit} className=" " type="submit" variant="info">
                                {
                                    adposted?
                                    <>
                                        <Spinner animation="border" role="status">
                                        <span className="sr-only">Saving...</span>
                                        </Spinner>                                    
                                    </>:

                                    <span>Submit</span>
                                }
                            </Button>     
                </div>                
                </div>
            </div>

            </>

        :
            loading ? 
            '':
            (
                error?
            <div className="alert alert-warning col-6 mx-auto">
                <h3>{error}</h3>
            </div>                
                :
            <div className="col-lg-10 col-12 ml-auto" >
                {machineryForm()}
            </div>
            )
        }
        </>
    )
}

export default MachineryPostAds;