import React from 'react';
import Card from './Card';
import CattleCard from './CattlePostAds/CattleCard';
import FnPCard from './FertilizersAndPestisidesAds/FnPCard';
import LabourCard from './LabourInRent/LabourCard'
import MachinaryCard from './MachineryPostAds/MachinaryCard'
import ServiceCard from './ServiceInRentPostAds/ServiceCard'
import TnWCard from './TreeAndWoodAds/TnWCard'
import OtherProductsCard from './OtherProducts/OtherProductsCard'

export const DisplaySearchResult = ({resultsToRender,enableEditButton=false,filter=""}) => {
    const createCard = (results,i) => {
          if(results.type === 'agriculturalads')
          {
            return <Card key={i} ad={results} editButton={enableEditButton} />
          } else if(results.type === 'cattleads')
          {
            return <CattleCard key={i} ad={results} editButton={enableEditButton} />
          } else if(results.type === 'fertilizerandpestisidesads') {
            return <FnPCard key={i} ad={results} editButton={enableEditButton} />
          } else if(results.type === 'labourinrentads') {
            return <LabourCard key={i} ad={results} editButton={enableEditButton} />
          } else if(results.type === 'machinaryads') {
            return <MachinaryCard key={i} ad={results} editButton={enableEditButton} />
          } else if(results.type === 'serviceinrentads') {
            return <ServiceCard key={i} ad={results} editButton={enableEditButton} />
          } else if(results.type === 'treeandwoodsads') {
            return <TnWCard key={i} ad={results} editButton={enableEditButton} />
          } else if(results.type === 'otheragriads') {
            return <OtherProductsCard key={i} ad={results} editButton={enableEditButton} />
          }
          
        }
    
    return (
                <React.Fragment>
                  
                {
                    
                    resultsToRender.map((results,i) => ( 
                      (filter===results.super_category ?
                        createCard(results,i)
                      :
                        createCard(results,i)
                      )
                      
                    ))
                }
                </React.Fragment>

    )
}