import React , {useState, useEffect , Fragment} from 'react';
import {getEKLanguage} from '../utils/language_utils'
import {strings} from '../utils/translated_strings'
import { DisplaySearchResult } from './DisplaySearchResult';
import Layout from './Layout';
import {API} from '../config'
import { isAuthenticated , signout} from '../auth';
import {Button} from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import {Tab,Tabs} from 'react-bootstrap';

const MyAds = () => {
    const [data,setData] = useState([])
    const [next_url,setNextUrl] = useState()
    const [loading,setLoading] = useState(true)
    const [error,setError] = useState(false)

    const fetchMoreResults = (url) => {
        const lang = getEKLanguage()
        let userinfo = isAuthenticated()

        return fetch(url,{
          method: "GET",
          headers: {
              Accept:'application/json',
                "Content-Type":"application/json",
                "Accept-Language":`${lang}`,
                Authorization: `Token ${userinfo.token}`
          }
        })    
    }

    const handleMoreEvent = () => {
        if((next_url!==undefined) && (next_url !== null)) {
            
            fetchMoreResults(next_url)
            .then(response=> {
              return response.json()
            })
            .then(d => {              
              setData([...data,...d.results])
              setNextUrl(d.next)
              //setSearchResult({...searchResult,next_url:data.next})
            })
            .catch(err => {
                setError(true);
            })
        }
      }    
    const username = isAuthenticated();

    const loadMyAds = () => {
        let lang = getEKLanguage()
        setLoading(true)
        fetchMoreResults(`${API}/ads/userads/`)
        .then(response => {return response.json()})
        .then(data => {
            if(data.detail) {
                if(data.detail.toLowerCase().includes('token')){
                    signout(()=>{
                        window.location.reload(false)
                    })
                }else {
                    setError(data.detail)
                }
            } else {
            setData(data.results)
            setNextUrl(data.next)            
            }
        }).catch(err => {
            setError(err)
        })        
        setLoading(false)
    }


    const loadMyAdsWithSuperCategory = (searchTerm) => {
        let lang = getEKLanguage()
        setLoading(true)
        fetchMoreResults(`${API}/ads/userads/?supercategory=${searchTerm}`)
        .then(response => {return response.json()})
        .then(data => {
            setData(data.results)
            setNextUrl(data.next)            
        }).catch(err => {
            setError(err)
        })        
        setLoading(false)
    }


    useEffect(()=>{
        loadMyAds()
    },[])
            
    const [superFilter,setSuperFilter] = useState('Sellads')
    const showUserFilter = (name) => {
        setSuperFilter(name)
    }
    return (
        <>
        <Layout>
            {
                loading ? ''
                :
                <div className="container-fluid ">
                    <div className="mt-2 row mx-auto text-secondary">
                        <h3 className="">Please check all your advertisement</h3>
                    </div>
                    <div className="row mx-auto text-secondary">
                        <Button variant="success" size="sm" className="green ml-4" onClick={(e)=>{
                            e.preventDefault()
                            loadMyAdsWithSuperCategory('Sellads')
                        }}>Sell Ads</Button>
                        <Button variant="success" size="sm" className="green ml-4" onClick={(e)=>{
                            e.preventDefault()
                            loadMyAdsWithSuperCategory('BuyOffer')
                        }}>Buy Offer</Button>
                        <Button variant="success" size="sm" className="green ml-4" onClick={(e)=>{
                            e.preventDefault()
                            loadMyAdsWithSuperCategory('BuyBackOffer')
                        }}>Buy Back Offer</Button>                                                
                    </div>
                    <div className="container-fluid p-0">
                        <div className="infinite row m-0">
                            <InfiniteScroll dataLength={data.length}
                                next={handleMoreEvent}
                                hasMore={true}
                                className="col-lg-12 col-12 flex flex-wrap"
                                style={{width:"100%",display:"flex"}}
                                >                  
                                <DisplaySearchResult resultsToRender={data} enableEditButton={true}/>  
                            </InfiniteScroll>
                        </div>
                    </div>

                </div>      
            }
        </Layout>
        </>
    )
}

export default MyAds;
