import { getToken } from '../../auth';
import {API} from '../../config';
import {getEKLanguage} from '../../utils/language_utils';

export const fetchOtherProductsTypes = () => {
    let lang = getEKLanguage()
    
    return fetch(`${API}/ads/otheragriproducttypes/`,{
        "method":"GET",
        "headers":{
            Accept:'application/json',
            "Content-Type":"application/json",
            "Accept-Language":`${lang}`,
        }
    })
}

export const fetchOtherProductsAds = (id) => {
    let lang = getEKLanguage()
    var fd = new FormData()
    let token = getToken()
    fd.append('toedit', 'optm')
    return fetch(`${API}/ads/otheragriproducts/${id}/?toedit=optm/`,{
        "method":"GET",
        "headers":{
            Accept:'application/json',
            "Content-Type":"application/json",
            "Accept-Language":`${lang}`,
            Authorization:`Token ${token}`
        }
    })
}