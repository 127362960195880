import React,{useState} from 'react'
import {Form,Button} from 'react-bootstrap'
import {Redirect} from 'react-router-dom'
import { getToken,signout } from '../auth'
import {API} from '../config'

const OfferForm = ({post_id,category,price,vid}) => {
    const [values,setValues] = useState({
        phonenumber:'',
        offered_price:'',
    })



    const [formErrors,setFormErrors] = useState({
        'phonenumber_error':'',
        'offered_price_error':''
    })
    const [success,setSuccess] = useState(false)
    const [error,setError] = useState(false)
    
    const isValid = () => {
        let flag = true
        let phonenumber_error = ''
        let offered_price_error = ''
        if(values.phonenumber.length < 10) {
            phonenumber_error = 'Enter valid phone number'
            flag = false
        } 
        if(values.offered_price < 0 || values.offered_price==='')
        {
            offered_price_error = 'Enter valid offer price'
            flag = false
        }
        setFormErrors({phonenumber_error,offered_price_error})
        return flag
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if(isValid()!==true){
            return 
        }
        var fd = new FormData()
        fd.append('postid',post_id)
        fd.append('category',category)
        fd.append('price',price)
        fd.append('offered_price',values.offered_price)
        fd.append('phonenumber',values.phonenumber)
        fd.append('vid',vid)
        let token = getToken()
        fetch(`${API}/ads/offer/`,{
            method:'POST',
            headers:{
                Accept:'application/json',
                Authorization:`Token ${token}`
            },
            body:fd
        })
        .then(response=>{
            return response.json()
        })
        .then(data=>{
            if(data.detail) {
                if(data.detail.toLowerCase().includes('token')) {
                    signout(()=>{
                        window.location.reload(false)
                    })
                } else {
                    setError(data.detail)
                }
            } else {
                setSuccess(true)
            }
        })
        .catch(err=>{
            
        })
    }

    const handleChange = (name) => (e) =>{
        setValues({...values,[name]:e.target.value})
        setFormErrors({phonenumber_error:'',offered_price_error:''})
    }
    
    const showError = () => {
         
       if(error){
        
            return <div className="alert alert-warning col-6 mx-auto" style={{fontSize:"0.8em"}} >
                {error}
            </div>
    }
            
        else {
            return ''
        }
        
    }

    const  showSuccess = () => {
        if(success)
        {
            return <Redirect to="/" />
        }
    }

    const form = () => (
        <Form onSubmit={handleSubmit} className="mt-3">
            {showError()}
            <Form.Row>
            <Form.Label className="text-white mt-2 ml-2 inline">
                Contant Number:
            </Form.Label>
            <Form.Control type="text" onChange={handleChange('phonenumber')} placeholder="Phone Number"  className="col-6 mx-auto" size="sm" />
            <div className="ml-4" style={{fontSize:"0.8em",color:"red"}}>{formErrors.phonenumber_error}</div>
            </Form.Row>
            <Form.Row>
            <Form.Label className="text-white mt-2 ml-2 inline">
                Actual Price - {price}
            </Form.Label>
            </Form.Row>
            <Form.Row>
            <Form.Label className="text-white mt-2 ml-2">
                Make your offer:
            </Form.Label>
            
            <Form.Control type="text" onChange={handleChange('offered_price')} placeholder="offer price" className="col-6 mx-auto" size="sm" />
            <div className="ml-4" style={{fontSize:"0.8em",color:"red"}}>{formErrors.offered_price_error}</div>
            </Form.Row>
            <Form.Row>
            <Button className="mx-auto mb-2" variant="success" type="submit" size="sm">Submit</Button>
            </Form.Row>
            {showSuccess()}
        </Form>
    )
    return (
    <>

          {form()}
        
    </>
    )
}

export default OfferForm;