import React ,{ useState , useEffect } from 'react';
import {getEKLanguage} from '../utils/language_utils';
import {Form,Image, Row,Col,Button } from 'react-bootstrap';
import {strings} from '../utils/translated_strings';
import {API} from '../config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { filterCategories } from '../utils/Apicore';

const getCategory = (category_url) => {
    let lang = getEKLanguage();
    return fetch(category_url,{
        method: "GET",
        headers: {
            Accept:'application/json',
               "Content-Type":"application/json",
               "Accept-Language":`${lang}`
        }
    })
    .then(response=> {
        return response.json()
    })
}
export const SearchCategories = ({setCategorySearch,super_category}) => {

    const [searchtext,setSearchText] = useState('')
    const [values,setValues] = useState({
        result_count:0,
        results:[],
        next_url:'',
        prev_url:'',
    });

    const [category,setCategory] = useState([])

    const [initState, setInitState] = useState({results:[],next_url:''})

    const [error,setError] = useState(false)
    const [searchWarning,setSearchWarning] = useState(false)

   
    //const [allCategorybrowsed,setAllCategoryBrowsed] = useState(false)

    useEffect(()=>{
        if(super_category === "BuyBackOffer")
        {
            fetchCategory(`${API}/ads/categorylongpage/`)
        } else {
            fetchCategory(`${API}/ads/category/`)
        }
    },[]);    

    const fetchCategory = (category_url) => {
            getCategory(category_url)
            .then(data => {
                setError(false);
                if(initState.results.length===0)
                {
                    setInitState({results:data.results,next_url:data.next});
                }
                 if(super_category === "BuyBackOffer"){
                     
                     var o_categories = filterCategories(data.results);
                     setCategory( [...category,...o_categories]);                     
                 } else {
                    setCategory([...category, ...data.results]);
                    // setCategories(o_categories);
                }                
                setValues({...values,next_url:data.next,prev_url:data.previous,result_count:data.count });            
             } )
            .catch(err => {
                setError(true);
            })
    };
    const clearCategory = () => {
        setCategory([]);
    }
    const handleCategoryLoad = () => {
        if(values.next_url !== null){
            fetchCategory(values.next_url)
        }
    };

    const handleClick = (name) => (e) => {
        setCategorySearch(name);
    }
    const handleCategoryLoadReset = () => {
        clearCategory();
        setValues({...values,next_url:initState.next_url})
        setCategory(initState.results)
    };

    const handleSearch = (query) => {
        
        if(query.searchtext !== "")
        {
            const lang = getEKLanguage()
            return fetch(`${API}/ads/categorybyproduct/?product=`+query.searchtext,{
            method: "GET",
            headers: {
                Accept:'application/json',
                    "Content-Type":"application/json",
                    "Accept-Language":`${lang}`                   
                }
            })
            .then(response=> {
                return response.json()
            })
            .then(data =>  {
                setValues({...values,next_url:null,prev_url:null,result_count:data.count })
                if(data.length>0){
                    setCategory([data[0]])
                    setCategorySearch(data[0].category_name)
                    setSearchWarning(false)
                }
                else {
                    setSearchWarning(true)
                }
            })
            .catch(err => {
                setError(true);
            })
        }
        else {
            setCategory(initState.results)
        }
    }    

    const triggerSearch = (query) => (e) => {
        e.preventDefault();
        handleSearch({searchtext});   
    }

    return (
        <React.Fragment>
        
        <div className="container-fluid">


                        <div className="row" style={{display:"flex"}}>
                            <div className="col-md-4 m-auto" >
                                {
                                    searchWarning?
                                    <div className="alert alert-info">
                                        {searchtext} was not found
                                    </div>
                                    :''
                                }
                                <Form className="form-signin">
                                    <Form.Group className="mt-2" style={{display:"flex"}}>
                                    <Form.Control value={searchtext}  type="text" className = "zero-border"
                                    placeholder={strings.type_your_product_name_and_get_category} onChange={ (e) => { setSearchWarning(false); setSearchText(e.target.value)}} />
                                    <Button type="submit" className="inline zero-border"  onClick={triggerSearch({searchtext})}>
                                        <FontAwesomeIcon icon={faSearch} />
                                    </Button>
                                    </Form.Group>
                                </Form>
                            </div>
                    </div>
            </div>            
            <div className="container">
            { error ? 
                    <div className="alert alert-warning"> 
                        <h3>Something went wrong!!</h3>
                    </div>
                    :                    
                    <Row >
                    <div className="container">
                        <div className="row">
                            <h4 className="mx-auto text-muted"> Browse Categories</h4>
                        </div>
                    </div>                        
                    {   
                        category.map(  (r,id) => {
                            return (
                                
                                <React.Fragment key={id}>
                                <Col className="mt-3 mb-3 forhover" xs={4} md={2}>
                                    {/* {JSON.stringify(r)} */}
                                    <Image src={r.image} className="center mx-auto category-image" roundedCircle  style={{height:"60%",width:"60%",display:"block"}} onClick={handleClick(r.category_name)}/>
                                    <p className="mt-3 text-center">{r.category_name}</p>
                                </Col>
                                
                                </React.Fragment>
                            )})
                    }
                    </Row>
                }
{
                    super_category !== "BuyBackOffer" ?
                    <div className="row mt-5">
                            <Button className="btn btn-primary m-auto" onClick=
                                { values.next_url === null ? handleCategoryLoadReset : handleCategoryLoad}
                            >
                                { values.next_url === null ? strings.see_less : strings.see_more }
                            </Button>
                    </div>                
                :
                ''
                }                
                {/* <div className="row mt-5">
                        <Button className="btn btn-primary m-auto" onClick=
                            { values.next_url === null ? handleCategoryLoadReset : handleCategoryLoad}
                        >
                            { values.next_url === null && category.length>1 ? strings.see_less : strings.see_more }
                        </Button>
                </div>                 */}
            </div>

        </React.Fragment>
    )
}

export default SearchCategories;