import React ,{useState,useEffect} from 'react';
import {Form,FormControl,Button,Spinner} from 'react-bootstrap';
import {Redirect} from 'react-router-dom';
import {fetchLabourExpertise,fetchLabourCharges,fetchLabourReachingTimeOnSite,fetchAvailableLabour} from './LabourInRentApiCore';
import LabourInRentReviewPost from './LabourInRentReviewPost'

import {getEKLanguage} from '../../utils/language_utils'

import {API} from '../../config'

import {isAuthenticated, signout} from '../../auth'
import {strings} from '../../utils/translated_strings'
import {fetchStates,fetchdistrict,fetchblocks} from '../../utils/AreaUtils'

const LabourInRentPostAds = ({super_category}) => {

    const [adposted,setAdPosted] = useState(false)

    const [labourInRentPostFields,setlabourInRentPostFields] = useState({
        available_labour : '',
        labour_expertise :'',
        reaching_on_time:'',
        product_price:'',
        price_per:'',
        photo1:"",
        photo2:"",
        photo3:"",
        state:'',
        district:'',
        block:'',
        village:'',        
        super_category:'',
        additional_information:'',
    })    

    const ErrorInitState = {
        available_labour_error: '',
        labour_expertise_name_error:'',
        reaching_on_time_error:'',
        product_price_error:'', 
        price_per_error:'',
        additional_information_error:'', 
        state_error:'',
        district_error:'',
        block_error:'',          
    }     

    const isValid = () => {
        let flag = true;
        let available_labour_error = '' ;
        let labour_expertise_name_error = '';
        let reaching_on_time_error = '';
        let price_per_error = '';
        let product_price_error = '' ;
        let state_error='';
        let district_error='';
        let block_error='';        
        
        if(labourInRentPostFields.available_labour === '') {
            available_labour_error = "Please select availab"
            flag = false
        }
        if(labourInRentPostFields.labour_expertise === '') {
            labour_expertise_name_error = "Please select labour expertise"
            flag = false;
        }
        if(labourInRentPostFields.reaching_on_time === '') {
            reaching_on_time_error = "Please select reaching time"
            flag = false;
        }
        if(labourInRentPostFields.product_price === 0 || labourInRentPostFields.product_price === '') {
            product_price_error = "Price must be greator than 0"
            flag = false;
        }
        if(labourInRentPostFields.price_per === '') {
            price_per_error = "Please select pricing option"
            flag = false;
        }
        if(labourInRentPostFields.state === '')
        {
            state_error="Please select your state name"
        }
        if(labourInRentPostFields.district ==='')
        {
            district_error = "Please select your district"
        }
        if(labourInRentPostFields.block === '')
        {
            block_error = 'Please select your block'
        }          
        setlabourInRentPostFieldsErrors({state_error,block_error,district_error,available_labour_error,price_per_error,product_price_error,labour_expertise_name_error,reaching_on_time_error})
        return flag
    }

    const [states,setStates] = useState([])
    const [districts,setDistricts] = useState([])
    const [blocks,setBlocks] = useState([])

    const handleStateChange = (name,func,setFunc) => (event) => {
        setlabourInRentPostFields({...labourInRentPostFields,[name]:event.target.value })
        func(event.target.value).then(response=> {
            return response.json()
        })
        .then(data => setFunc(data))
        .catch(err => {
            console.log(err)
        });
    }

    const [labourInRentPostFieldsErrors,setlabourInRentPostFieldsErrors] = useState(ErrorInitState)
    const [id,setId] = useState()
    const [loading,setLoading] = useState(true)
    const [error,setError] = useState(false)
    
    const [availableLabours,setAvailableLabours] = useState([])
    const [reachingTimes,setReachingTimes] = useState([])
    const [chargesBy,setChargesBy] = useState([])
    const [serviceMachinary,setServiceMachinary] = useState([])
    const [labourExpertise,setLabourExpertise] = useState([])

    const [review,setReview] = useState(false)
    const [photos,setPhotos] = useState({
        photo1:"https://via.placeholder.com/300",
        photo2:"https://via.placeholder.com/300",
        photo3:"https://via.placeholder.com/300"
    })

    useEffect(()=>{
        fetchStates().
        then(response => {return response.json()})
        .then(data=>{
             setStates(data)
        })
        .catch(err=>{
            setError(err)
        })

        fetchAvailableLabour()
        .then(response => {
            return response.json()
        })
        .then(data => {
            
            setAvailableLabours(data)
        })
        .catch(error=>{
            setError(error)
        })

        fetchLabourReachingTimeOnSite()
        .then(response => {
            return response.json()
        })
        .then(data => {
            setReachingTimes(data)
        })
        .catch(error=>{
            setError(error)
        })   

        fetchLabourExpertise().
        then(response => {
            return response.json()
        }).then(data=>{
            setLabourExpertise(data)
        })

        fetchLabourCharges().
        then(response => {
            return response.json()
        }).then(data=>{
            setChargesBy(data)
            setLoading(false)
        })
        .catch(error=>{
            setError(error)
        })
    },[])

    const redirectToItem = () => {
        if(id) {
            return <Redirect to={`/item/detail/labours-in-rent/${id}/`} />
        }
    }        
    const user_info = isAuthenticated()   
    const submitAdvertisement = () => {
        let lang = getEKLanguage()
        let token = user_info.token
        
        var fd = new FormData();
        fd.append('labour_expertise',labourInRentPostFields.labour_expertise)
        fd.append('available_labour',labourInRentPostFields.available_labour)
        fd.append('reaching_on_time',labourInRentPostFields.reaching_on_time)
        fd.append('product_price',labourInRentPostFields.product_price)
        fd.append('price_by',labourInRentPostFields.price_per)
        fd.append('additional_information',labourInRentPostFields.additional_information)
        fd.append('photo1',labourInRentPostFields.photo1)
        fd.append('photo2',labourInRentPostFields.photo2)
        fd.append('photo3',labourInRentPostFields.photo3)    
        fd.append('super_category',super_category)
        fd.append('state',labourInRentPostFields.state)
        fd.append('district',labourInRentPostFields.district)
        fd.append('block',labourInRentPostFields.block)
        fd.append('village',labourInRentPostFields.village)        

        fetch(`${API}/ads/labourinrentads/`,{
            method: "POST",
            headers: {
                Accept:'application/json',
                "Accept-Language":`${lang}`,
                Authorization:`Token ${token}`
            },
            body: fd
        })
        .then(response => {
            return response.json()
        })
        .then( data => {
            if(data.detail) {
                if(data.detail.toLowerCase().includes('token')) {
                    signout(()=>{
                        window.location.reload(false)
                    })
                } else {
                    setError(data.detail)
                }
            } else {
               setId(data.id)
            }
        })
        .catch(err => {
            
        })        
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if(isValid()){
            setAdPosted(true)
            submitAdvertisement()
            setlabourInRentPostFields(ErrorInitState)
            setReview(false)
        }
    }

    const handleReview = (e) => {
        e.preventDefault()
        if(review === true){
            setReview(false)
            return
        }
        if(isValid())
        {
            setReview(true)
        }
        else{
            setReview(false)
        }
    }    

    const handleSelection = (name) => (e) => {
        setlabourInRentPostFields({...labourInRentPostFields,[name]:e.target.value})
    }

    const handleImageSelection = (name) => (e) => {
        e.preventDefault()
        const reader =new FileReader()
        reader.onload = () => {
            if(reader.readyState === 2) {
                setPhotos({...photos,[name]:reader.result})
            }
        }
        reader.readAsDataURL(e.target.files[0])
        setlabourInRentPostFields({...labourInRentPostFields,[name]:e.target.files[0]})
    }

    const ServiceForm = () => (
        <React.Fragment>
        <Form className="mt-4 mx-auto" onSubmit={handleSubmit}>            
            <h3 className="gray heading">Enter details </h3>
            <hr />

                <Form.Row>
                    <Form.Label  column sm="4">
                            Available Labour:
                    </Form.Label>                
                    <FormControl as="select" value={labourInRentPostFields.available_labour} onChange={handleSelection("available_labour")} size="sm" className="col-lg-4 mt-2">                        
                    <option key="Select machine">number of labour</option>
                        {
                            availableLabours.map((d,id) => {
                                return <option key={id}>{d.available_labour}</option>
                            })
                        }
                    </FormControl>            
                    <div className="d-block" style={{fontSize:"0.8em",color:"red"}}>{labourInRentPostFieldsErrors.available_labour_error}</div>
                </Form.Row>
                <Form.Row>
                    <Form.Label  column sm="4">
                            Expert:
                    </Form.Label>                
                    <FormControl as="select" value={labourInRentPostFields.labour_expertise} onChange={handleSelection("labour_expertise")} size="sm" className="col-lg-4 mt-2">                        
                    <option key="Select work">select labor expertise</option>
                        {
                            labourExpertise.map((d,id) => {
                                return <option key={id}>{d.expertise}</option>
                            })
                        }
                    </FormControl>            
                    <div className="d-block" style={{fontSize:"0.8em",color:"red"}}>{labourInRentPostFieldsErrors.labour_expertise_error}</div>
                </Form.Row>
                <Form.Row>
                    <Form.Label  column sm="4">
                            Reaching Time on site:
                    </Form.Label>
                                    
                    <FormControl as="select" value={labourInRentPostFields.reaching_on_time} onChange={handleSelection("reaching_on_time")} size="sm" className="col-lg-4 mt-2">                        
                    <option key="Select timing">select reaching time</option>
                        {
                            reachingTimes.map((d,id) => {
                                return <option key={id}>{d.reaching_time_status}</option>
                            })
                        }
                    </FormControl>            
                    <div className="d-block" style={{fontSize:"0.8em",color:"red"}}>{labourInRentPostFieldsErrors.reaching_on_time_error}</div>
                </Form.Row>  
                <Form.Row>
                    <Form.Label  column sm="4">
                            Price:
                    </Form.Label>                
                    <FormControl type="text" value={labourInRentPostFields.product_price} onChange={handleSelection("product_price")} size="sm" className="col-lg-1 mt-2"/>                        
                    <div className="d-block" style={{fontSize:"0.8em",color:"red"}}>{labourInRentPostFieldsErrors.product_price_error}</div>

                    <FormControl as="select" value={labourInRentPostFields.price_per} onChange={handleSelection("price_per")} size="sm" className="col-lg-2 ml-2 mt-2">                        
                    <option key="Select pricing option">pricing option</option>
                        {
                            chargesBy.map((d,id) => {
                                return <option key={id}>{d.per}</option>
                            })
                        }
                    </FormControl>            
                    <div className="d-block" style={{fontSize:"0.8em",color:"red"}}>{labourInRentPostFieldsErrors.price_per_error}</div>
                </Form.Row> 

                <Form.Row>
                        <Form.Label column sm="4">
                                Select Your State
                        </Form.Label>                
                        <FormControl size="sm" as="select"  value={labourInRentPostFields.state} onChange={handleStateChange("state",fetchdistrict,setDistricts)} className="col-lg-4 mt-2">
                            <option key="Select Your state">{strings.select_your_state}</option>
                            {
                                states.map(d=>{
                                    return <option key={d.id}>{d.state_name}</option>
                                })
                            }
                        </FormControl>            
                        <div style={{fontSize:"0.8em",color:"red"}}>{labourInRentPostFieldsErrors.state_error}</div>
                    </Form.Row>
                    <Form.Row>
                        <Form.Label column sm="4">
                                Select Your District
                        </Form.Label>                
                        <FormControl size="sm" as="select" value={labourInRentPostFields.district} onChange={handleStateChange("district",fetchblocks,setBlocks)} className="col-lg-4 mt-2">
                                <option key="Select Your district">{strings.select_your_district}</option>
                                {
                                    districts.map(d => {
                                        return <option key={d.id}>{d.district_name}</option>
                                    })
                                }
                        </FormControl>            
                        <div style={{fontSize:"0.8em",color:"red"}}>{labourInRentPostFieldsErrors.district_error}</div>
                    </Form.Row>
                    <Form.Row>
                        <Form.Label column sm="4">
                                Select Your Block
                        </Form.Label>                
                        <FormControl size="sm" as="select" value={labourInRentPostFields.block} onChange={handleSelection("block")} className="col-lg-4 mt-2">
                        <option key="Select Your block">{strings.select_your_block}</option>
                                {
                                    blocks.map(d => {
                                        return <option key={d.id}>{d.block_name}</option>
                                    })
                                }
                        </FormControl>            
                        <div style={{fontSize:"0.8em",color:"red"}}>{labourInRentPostFieldsErrors.blocks_error}</div>
                    </Form.Row>
                    <Form.Row>
                        <Form.Label column sm="4">
                                Enter your village name
                        </Form.Label>                
                        <FormControl size="sm" type="text" value={labourInRentPostFields.village} onChange={handleSelection("village")} className="col-lg-4 mt-2" />
                    </Form.Row>                                    
                


                <Form.Row>
                    <Form.Label column sm="4">
                        Additional Information:
                    </Form.Label>
                    <FormControl as="textarea" style={{resize:"none"}} rows={3}  value={labourInRentPostFields.additional_information} onChange={handleSelection("additional_information")} className="col-lg-4 mt-2 mr-sm-2" size="sm"  placeholder=""/>
                    <div style={{fontSize:"0.8em",color:"red"}}>{labourInRentPostFieldsErrors.additional_information_error}</div>
                </Form.Row>  
                <Form.Row className="m-auto ">
                        <div className="col-2">
                        <label className="mt-2 btn btn-default btn-outline-secondary btn-sm mx-auto" >
                            Click to Browse <input type="file" hidden onChange={handleImageSelection("photo1")}  className="mt-2"/>
                        </label>
                        <img src={photos.photo1}
                            style={{height:"150px",width:"150px"}} alt="photo1"/>
                        </div>
                        <div className="col-2 ml-4">
                        <label className="mt-2 btn btn-default btn-outline-secondary btn-sm mx-auto">
                            Click to Browse <input type="file" hidden onChange={handleImageSelection("photo2")}  className="mt-2"/>
                        </label>
                        <img src={photos.photo2}
                            style={{height:"150px",width:"150px"}} alt="photo2"/>                        
                        </div>
                        {/* //style={{display:"inline-block",width:"100px",height:"100px",backgroundImage:`url(${data.photo3})`}} */}
                        <div className="col-2 ml-4">
                            <label className="btn mt-2 btn-default btn-outline-secondary btn-sm" >
                                Click to Browse<input type="file" hidden onChange={handleImageSelection("photo3")}  className="mt-2"/>
                            </label>
                            <img src={photos.photo3} style={{height:"150px",width:"150px"}} alt="photo3"/>                        
                        </div>
                    </Form.Row>
                    <div className="form-row col-10 mt-4">
                        <div className="col-3">
                            <Button  variant="info" onClick={handleReview}>Review</Button>
                        </div>
                        <div className="col-3 ml-auto">
                        <Button  className=" " type="submit" variant="info">
                                {
                                    adposted?
                                    <>
                                        <Spinner animation="border" role="status">
                                        <span className="sr-only">Saving...</span>
                                        </Spinner>                                    
                                    </>:

                                    <span>Submit</span>
                                }
                            </Button>     
                        </div>
                    </div>                                                  
        </Form>
        {redirectToItem()}
        </React.Fragment>
    )

    return (
        <>
        
        {
        review ?
            
            <>
            <LabourInRentReviewPost postData={labourInRentPostFields} photos={photos}/>
            <div className="container">
                <div className="row">
                <Button  className="m-auto" variant="info" onClick={handleReview}>Go back to edit</Button>
                <div className="col-3 ml-auto">
                <Button onClick={handleSubmit} className=" " type="submit" variant="info">
                                {
                                    adposted?
                                    <>
                                        <Spinner animation="border" role="status">
                                        <span className="sr-only">Saving...</span>
                                        </Spinner>                                    
                                    </>:

                                    <span>Submit</span>
                                }
                            </Button>     
                        </div>                
                </div>
            </div>
            </>
            :
            loading ? 
            '':
            (
                error ?
            <div className="alert alert-warning col-6 mx-auto">
                <h3>{error}</h3>
            </div>                
                :
            <div className="col-lg-10 col-12 ml-auto" >
                {ServiceForm()}
            </div>
            )
        }
        </>        
    )
}

export default LabourInRentPostAds;