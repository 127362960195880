import {API} from '../config';

export const getEKLanguage = (key) => {
  if(typeof window === 'undefined') {
    
    return false
  } 
  if(localStorage.getItem('eklang')) {
      
      return JSON.parse(localStorage.getItem('eklang'));
  }
  return false
}

export const setEKLanguage = (lang) => {
  if (typeof window === 'undefined'){
    
      return false
  } 
  else {
      localStorage.setItem('eklang',JSON.stringify(lang));
      return true
  }
}

export const getLanguageList = () => {
  return fetch(`${API}/supportedlanguages/`,{
      method: "GET",
      headers: {
          Accept:'application/json',
          "Content-Type":"application/json"
      },
  })
  .then(response=> {
      return response.json()
  })
  .catch(err => {
       console.log(err); 
  ;})
}