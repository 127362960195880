import React from 'react';
import Layout from '../core/Layout'

import img1 from "../assets/trust/1.png";
import img2 from "../assets/trust/2.png";
import img3 from "../assets/trust/3.png";
import img4 from "../assets/trust/4.png";
import img5 from "../assets/trust/5.png";
import img6 from "../assets/trust/6.png";
import img7 from "../assets/trust/7.png";
import img8 from "../assets/trust/8.png";
import img9 from "../assets/trust/9.png";
import img10 from "../assets/trust/10.png";
import img11 from "../assets/trust/11.png";

const TrustAndSafety = () => {
    return (
        <Layout>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-8 mx-auto">
                        <h3>Fraudsters may pose as defense personnel</h3>
                            <p>
                            Although we invest significantly in ensuring only genuine users are active on the platform, your safety is just as much your responsibility as it is ours.
                            Fraudsters are constantly using new ways to cheat users. 
                            <br />
                            Fraudsters may pose as Defense personnel and share fake defense ID proofs to gain your trust
                            </p>
                            <br />
                            <p>
                            These fraudsters may act as a seller or buyer to defraud users. 
                            <h5>Fraudsters posing as defense personnel and posting fake Ads as a seller</h5>
                            <ol className="number">
                                <li>
                                These fraudsters will post fake Ads of Cars, Bikes with lucrative prices
                                </li>
                                <li>
                            	They will share fake defense ID proofs to gain your trust
                                </li>
                                <li>
                            	After gaining your trust, they will ask for advance payment for the product as token amount, airport charges, etc.
                                </li>
                                <li>
                            	After you have paid the amount, they will stop responding to your calls and messages
                                </li>
                            </ol>
                            </p>
                            <h5>
                            Fraudsters posing as defense personnel and contacting you as a Buyer
                            </h5>
                            <ol className="number">
                                <li>
                                These fraudsters will contact you as buyers and agree to your asking price
                                </li>
                                <li>
                                They will urge to make advance payment for the product
                                </li>
                                <li>
                                They will make a payment of small amount through UPI apps to gain your trust and act as a legitimate buyer
                                </li>
                                <li>
                                After sending the small amount, they may send you QR code for a bigger amount
                                </li>
                                <li>
                                Scanning these QR codes will deduct money from your account
                                </li>
                            </ol>
                            Please be careful while transacting on platform!       

                            <p>
                            I shared my EKRISHIBAZAAR.COM password/OTP with someone
                            We suggest you to immediately reset the password of your EKRISHIBAZAAR.COM account and report the issue by calling our safety helpline +91-0000000000 or write contact us page to us. (Please ensure that you are calling from your registered EKRISHIBAZAAR.COM mobile number.)
                            EKRISHIBAZAAR.COM never asks for your credentials such as password/OTP. We advise our customers to completely ignore such communications and report such cases by reaching out to us on above contact details.
                            </p>
                            <br />
                            <p>
                            Be cautious of Fraudulent Calls about KYC authentication or expiration, Account Block and Fake Rewards!  
                            </p>
                            <br />
                            <p>
                            In case, somebody calls you to download any app such as QuickSupport or Anydesk etc to complete or renew your KYC, please do not download such softwareas they could be used by fraudsters to take over your account and data.
                            </p>
                            <br />
                            <h5>If some unauthorized activity has happened on your EKRISHIBAZAAR.COM account, please provide the details mentioned below along with complete case summary:</h5>
                            <ol className="number">
                                <li>Caller/fraudster number from which you got the call asking for OTP/password</li>
                                <li>Registered mobile number</li>
                                <li>Any other activity such as Ad ID of the ad posted by fraudster on your account, details of the potential ads/sellers that the fraudster has reached out to through your account</li>
                            </ol>

                            <p>
                            We also recommend you file a complaint to the nearest Cybercrime department illustrating the incident happened during the time of EKRISHIBAZAAR.COM account takeover. Meanwhile, we will investigate your case and help you in taking all the necessary actions.
                            </p>
                            <br />
                            <h5>QR Code Scams</h5>
                            <p>
                            The fraudster will reach out to you or engage you in a conversation as a buyer and will share a QR code to pay advance/token amount.
                            </p>
                            <p>
                            Alert: DO NOT SCAN THIS QR CODE as this will result in money getting debited from your account.
                            <br />
                            Please refer the screenshots below to save yourself from this type of fraud: 
                            </p>
                            <ol className="number">
                            <li>The fraudster will create a QR code with a high amount and will share it with you through WhatsApp, Email or other platforms as shown below:-
                                <br />

                                <img alt='img' src={img1} />
                            </li>
                            
                            <li>If the user shares the QR code through WhatsApp then the message will look something like this:-
                                <br />
                                <img alt='img' src={img2} />
                                <img alt='img' src={img3} />
                            </li>
                            
                            <li>After sharing the QR code, the user will ask you to select “Scan QR code” option on the app and select QR code from photo gallery
                                <br />
                                <img alt='img' src={img4} />
                            </li>
                            
                            
                            <li>After scanning the QR code from photo gallery, you will be asked to Proceed with the payment
                            <br />
                                <img alt='img' src={img5} />                                
                            </li>
                            
                            <li>After clicking on “Proceed”, you will be asked to Enter your UPI PIN and money will be deducted from your account.
                            <br />
                                <img alt='img' src={img6} />                                
                            </li>
                            </ol>
                            <h5>
                            EKRISHIBAZAAR.COM urges you to NEVER scan any QR code or enter UPI PIN to receive payments from other users.
                            </h5>
                            <p>
                            As a seller, you should be extra careful while receiving any payment through bank-affiliated wallets. Instead of receiving the payment, you might, unfortunately, give away your money.     
                            </p>

                            <br />
                            <img alt='img' src={img7} />

                            <br />
                            Please remember:
                            <ol className="number">
                                <li>If you receive a SMS requesting you to send money, DO NOT proceed</li>
                            	<li>If buyer insists you to fill PIN no, stop interacting with the buyer</li>
                            	<li>Do not EVER enter your PIN                   </li>
                            </ol>
                            
                            <ol className="number">
                            <li>Do not enter PIN: If you receive any request to enter PIN no, it is a scam. Receiving money requires no PIN

                                <br />
                                <img alt='img' src={img8} />
                                <img alt='img' src={img9} />
                            </li>
                            
                            
                            <li>Payment SMS: If you receive a message stating money will be deducted or debited, BEWARE! You should always receive a message stating money will be credited if you are expecting payment.
                                <br />
                            <img alt='img' src={img10} />
                            </li>
                            
                                <li>Watch out for words like Send, Requested by, Pay, etc. They all indicate that money is about to be debited from your account.
                                <br />
                            <img alt='img' src={img11} />
                                </li>
                            </ol>
                            
                            <p>

                            What should I do if the buyer is threatening me/using bad language?
                            <br />
                            We have mechanisms to help you keep unwanted users at bay. Although, we inspect the quality of our site regularly to maintain good standards, it is never possible to know the intention of a user.
                            <br/>
                            Do not worry! If you come across a buyer, who is engaging in unacceptable conversation or using derogatory language, you can instantly block the user on EKRISHIBAZAAR.COM. This will ensure his messages don’t reach you again. If the user has your phone number, do block his WhatsApp & Calls. Alternatively, you may report the user. 
                            <br />
                            Blocking a user: 
                            Simply follow the steps below:  
                            </p>
                            <ol className="number">
                            <li>Tap on the 3 dotted icon at the top of chat page </li>
                            <li>You will see an option to ‘Block User’. Tap on it and a Pop-Up will appear for confirmation.</li>
                            <li>Select "Block" and the user will no longer be able to contact you via EKRISHIBAZAAR.COM.</li>
                            </ol>
                            <p>
                            What are the indications that a buyer is a potential fraud?

                            Although we invest significantly in ensuring only genuine users are active on the platform, your safety is just as much your responsibility as it is ours. It is important to exercise caution and follow general safety guidelines on any internet platform as you would in the offline/physical world.
                            So, here are tips for spotting buyers who are possible fraud: 
                            </p>
                            <ol className="number">
                                <li>
                            UPI Fraud: Buyer deceives to have sent you money, but the amount is deducted from your account via Google-Pe, Phone-Pe, etc. 
                            </li>
                            <li>
                            Asking for Advance Product: The buyer demands you send the item before they pay for it or provides fake payment proofs
                            </li>
                            <li>
                            Fake payment proofs: The buyer will ask for item without verifying it & deceive by sending a fake payment receipt, message etc.
                            </li>
                            <li>
                            Insist on non-cash methods: Typically, the buyer insists on using a cheque. 
                            </li>
                            </ol>
                            <p>
                            Note cheques could get bounced and banks bear no responsibility
                            
                            Seeking personal information: The Buyer asks for personal information like your ID, bank details, your email address, your debit/credit card number 
                            Note: If you suspect a buyer to be fraud, please open their profile from the chat window that opened for you, tap on the three dots in top right corner and tap on ‘Report User’.  It’s simple!  
                            For any clarification, write to us or call us at 0000000000 for any specialized assistance. Please ensure that you are calling from your registered EKRISHIBAZAAR.COM mobile number.
                            </p>

                            <p>
                            How to safely use EKRISHIBAZAAR.COM and protect oneself from fraud?
                            </p>
                            <ol className="number">
                            <li>Meeting face-to-face: Always insist on meeting with the buyer personally and exchanging your item for money. Use a safe location to meet.</li>
                            <li>Chat Conversation: For a safer transaction, try using the EKRISHIBAZAAR.COM chat option. Avoid other channels like WhatsApp/Skype/Messengers etc.</li>
                            <li>Avoid partial payments: Take the full payment decided mutually at the time of the delivering item to buyer.      </li>
                            <li>Payment Method: Watch out for fake currency & payment link scams. Banks will not honour fake currency/money order and will hold you responsible for it.</li>
                            <li>Buyer’s Personal Information: Verify the buyer’s details if there is a bulk order request such as photo ID and proof of address. </li>
                            <li>Realistic product description: Ensure your item meets the features you list in your ad to avoid misunderstanding with the buyer. </li>
                            <li>Personal information: Don’t share any private details or financial information except the one necessary. We advise that sensitive information such as (IMEI no.) or (PCBA no.), should not be disclosed to any user.</li>
                            <li>Suspicious Chats: Being careful on the platform ensures you have a good and genuine experience. Be cautious of users using guile messages to trap you.</li>
                            </ol>
                            They will try to persuade you to contact them outside EKRISHIBAZAAR.COM chat by sharing their phone numbers. If you ever find user sharing his phone number in different patterns, beware!  
                            <br />
                            They will mention the phone number in unusual combination such as alpha-numeric, containing unnecessary decimals, special characters and space between numbers.
                            <br />

                            How can I report a seller or Ad?
                            <br />
                            Report a User
                            <br />
                            Be a part of the EKRISHIBAZAAR.COM fraternity and if you notice an issue with a Seller or an Ad, please report the instance to us. We have made it easier not only to report a suspicious ad but also a suspicious user as well. 
                            <br />
                            Note: Don't forget that you need to be logged in order to report a User. 
                            <br />
                            All you need to do is: 
                            <ol className="number">
                            <li>First note down Virtual ID of user (for user) /Post ID (for ad post). </li>
                            <li>Tap on ‘complaint button’ at the bottom of  My Account. </li>
                            <li>On next screen, tap on ‘ Make a Complaint’</li>
                            <li>Fill the form and send.    </li>
                            </ol>
                            
                            How can I block a seller from sending messages?
                            You no longer want to receive messages from a seller? No issues, simply block the user and save from unwanted chats.
                            <ol className="number">
                            <li>Just go to chat section and tap on “Block User”. </li>
                            <li>Tap on the option “Block” for confirmation. The seller will no longer be able to send messages.</li>
                            </ol>
                            
                            <h4>I am a victim of fraud</h4>
                            
                            First, we apologize for the inconvenience caused. We would like to immediately assist you. Regrettably, if you have experienced fraud, please follow these steps:   
                            <br />
                            <ol className="number">
                                <li>
                            Without delay, file a complaint with the relevant legal Authorities. You can reach out to local cyber cell or file a FIR at police station
                            </li>
                            <li>
                            Make complaint to us of the scammer on the App or Web or write to us or call us at 00000000000. We will investigate it straight away.
                            </li>
                            <li>
                            Please keep all relevant information handy to support your case i.e. payment proofs, chat history, call recordings, etc.  
                            </li>
                            </ol>
                            Note: Please ensure that you are calling us from your registered EKRISHIBAZAAR.COM mobile number.




                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default TrustAndSafety;