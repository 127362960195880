import React ,{useState,useEffect} from 'react';
import {Form,FormControl,Button,Spinner} from 'react-bootstrap';
import {Redirect} from 'react-router-dom';
import {getCattleAdvertisement,fetchCattleType,fetchPriceStatus,fetchCattleBreed,fetchMilkPerDay} from './CattleApiCore';
import CattleReviewPost from './CattleReviewPost';
import Layout from '../Layout';
import {getEKLanguage} from '../../utils/language_utils'

import {API} from '../../config'

import {validateSession, isAuthenticated, signout} from '../../auth'
import {strings} from '../../utils/translated_strings'
import {fetchStates,fetchdistrict,fetchblocks} from '../../utils/AreaUtils'

const CattlePostAds = (props) => {
    const [cattlePostFields,setCattlePostFields] = useState({
        id:'',
        category:'',
        cattle_name:'' ,
        cattle_breed:'',
        number_of_child:'', 
        milk_produced:'', 
        if_preganent:"No",
        product_price:0, 
        preganency_month:0,
        price_fixed:'',
        photo1:"",
        photo2:"",
        photo3:"",
        state:'',
        district:'',
        block:'',
        village:'',        
        additional_information:'', 
    })

    const ErrorInitState = {
        cattle_name_error:'' ,
        cattle_breed_error:'',
        number_of_child_error:'', 
        milk_produced_error:'', 
        if_preganent_error:"",
        product_price_error:'', 
        preganency_month_error:'',
        price_fixed_error:'',
        state_error:'',
        district_error:'',
        block_error:'',         
    } 

    useEffect(()=>{
        fetchblocks(cattlePostFields.district)
        .then(response=>{
            return response.json()
        })
        .then(data=>{
            setBlocks(data)
        })
    },[cattlePostFields.district])

    useEffect(()=>{
        fetchdistrict(cattlePostFields.state)
        .then(response=>{
            return response.json()
        })
        .then(data=>{
            setDistricts(data)
        })
    },[cattlePostFields.state])

    const destructAndSetAllData = (d) => {

        fetchStates()
        .then(response=>{
            return response.json()
        })
        .then (data=>{
            setStates(data)
        })

        setCattlePostFields({ id:d.id, 
        category : d.cattle_type.category.category_name, 
        cattle_name  : d.cattle_type.cattle_name, 
        cattle_breed  : d.cattle_breed.cattle_breed, 
        number_of_child : d.number_of_child,
        milk_produced : d.milk_produced.milk_per_day,
        if_preganent  : d.if_preganent,
        preganency_month : d.preganency_month,
        price_fixed:d.price_fixed.price_status,
        product_price:d.price,
        product_price_by:d.product_price_by,
        state:d.state.state_name,
        district:d.district.district_name,
        block:d.block.block_name,
        village:d.village,
        additional_information:d.additional_information
        })

        let photo1, photo2, photo3;
        if(d.product_image1 === null) {
            photo1 = photos.photo1
        } else {
            photo1 = d.product_image1
        }
        if(d.product_image2 === null) {
            photo2 = photos.photo2
        } else {
            photo2 = d.product_image2
        } 
        if(d.product_image3 === null ) {
            photo3 = photos.photo3
        } else {
            photo3 = d.product_image3
        }
        setPhotos({photo1,photo2,photo3})
    }

    const [cattlePostFieldsErrors,setCattlePostFieldsErrors] = useState(ErrorInitState)
    const [id,setId] = useState(false)
    const [loading,setLoading] = useState(true)
    const [error,setError] = useState(false)
    const [cattles,setCattles] = useState([])
    const [breeds,setBreeds] = useState([])
    const [priceStatus,setPriceStatus] = useState([])
    const [milkperday,setMilkPerDay] = useState([])
    const [review,setReview] = useState(false)
    const [photos,setPhotos] = useState({
        photo1:"https://via.placeholder.com/300",
        photo2:"https://via.placeholder.com/300",
        photo3:"https://via.placeholder.com/300"
    })    

    const user_related_info = isAuthenticated()

    useEffect(()=>{
        validateSession()
        .then(response => {
            return response.json()
        }).then(data => {
            if(data.detail){
                setError("You are not logged in")    
            }
        }).catch(err=>{
            setError("You are not logged in")    
        }) 

        
        fetchCattleType()
        .then(response => {
            return response.json()
        })
        .then(data => {
            
            setCattles(data)
        })
        .catch(error=>{
            setError(error)
        })
  
        
        fetchMilkPerDay()
        .then(response => {
            return response.json()
        })
        .then(data => {
            setMilkPerDay(data)
        })
        .catch(error=>{
            setError(error)
        })

        fetchPriceStatus()
        .then(response => {
            return response.json()
        })
        .then(data => {
            setLoading(false)
            setPriceStatus(data)
            setCattlePostFields({...cattlePostFields,price_fixed:data[0].price_status})
        })
        .catch(error=>{
            setError(error)
        })       
        getCattleAdvertisement(props.match.params.id)
        .then(response => {
            return response.json()
        })
        .then(data => {
            if(data.detail)
            {
                setError(data.detail)
                setLoading(false)
            }
            else {
                destructAndSetAllData(data)
                setLoading(false)
            }
        })
        setLoading(false)           
    },[])

    useEffect(()=>{
        fetchCattleBreed(user_related_info.token,cattlePostFields.cattle_name)
        .then(response => {
            return response.json()
        })
        .then(data => {
            setBreeds(data);
        })
        .catch(error=>{
            setError(error)
        })
    },[cattlePostFields.cattle_name])    


    const submitAdvertisement = () => {
        let lang = getEKLanguage()
        let token = user_related_info.token 
        var fd = new FormData();

        let photo1 = null
        let photo2 = null
        let photo3 = null
        if(cattlePostFields.photo1 !== photos.photo1)
        {
            
            photo1 = cattlePostFields.photo1
        }
        if(cattlePostFields.photo2 !== photos.photo2)
            photo2 = cattlePostFields.photo2
        if(cattlePostFields.photo3 !== photos.photo3)
            photo3 = cattlePostFields.photo3


        fd.append('category',cattlePostFields.category)
        fd.append('cattle_name',cattlePostFields.cattle_name)
        fd.append('cattle_breed',cattlePostFields.cattle_breed)
        fd.append('number_of_child',cattlePostFields.number_of_child)
        fd.append('milk_produced',cattlePostFields.milk_produced)
        fd.append('product_price',cattlePostFields.product_price)
        fd.append('if_preganent',cattlePostFields.if_preganent)
        fd.append('preganency_month',cattlePostFields.preganency_month)
        fd.append('price_status',cattlePostFields.price_fixed)
        fd.append('additional_information',cattlePostFields.additional_information)
        fd.append('photo1',photo1)
        fd.append('photo2',photo2)
        fd.append('photo3',photo3)
        fd.append('state',cattlePostFields.state)
        fd.append('district',cattlePostFields.district)
        fd.append('block',cattlePostFields.block)
        fd.append('village',cattlePostFields.village)        
        
        fetch(`${API}/ads/cattleads/${cattlePostFields.id}/`,{
            method: "PUT",
            headers: {
                Accept:'application/json',
                "Accept-Language":`${lang}`,
                Authorization:`Token ${token}`
            },
            body: fd
        })
        .then(response => {
            return response.json()
        })
        .then( data => {
            if(data.detail) {
                if(data.detail.toLowerCase().includes('token')) {
                    signout(()=>{
                        window.location.reload(false)
                    })
                } else {
                    setError(data.detail)
                }
            } else {
             setId(data.id)
            }
        })
        .catch(err => {
           setError(err)
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        if(isValid()){
            setAdPosted(true)
            submitAdvertisement()
            setCattlePostFieldsErrors(ErrorInitState)
            setReview(false)
        }
    }

    const redirectToItem = () => {
        if(id) {
            return <Redirect to={`/item/detail/cattles/${id}`} />
        }
    }    

    const isValid = () =>{
        let flag = true;
        let cattle_type_error='' ;
        let cattle_breed_error='';
        let number_of_child_error=''; 
        let milk_produced_error='';
        let product_price_error=''; 
        let preganency_month_error='';

        if(cattlePostFields.cattle_type==='')
        {
            cattle_type_error="Please select cattle type";
            flag = false;
        }
        if(cattlePostFields.cattle_breed==='')
        {
            cattle_breed_error="Please select cattle breed";
            flag = false;
        }        
        if(cattlePostFields.number_of_child<0)
        {
            number_of_child_error="Number of child cannot be less than 0";
            flag = false;
        }
        if(cattlePostFields.milk_produced==='')
        {
            milk_produced_error="Milk produced cannot be less than 0";
            flag = false;
        }
        if(cattlePostFields.product_price <= 0 )
        {
            product_price_error="Price must be greater than zero";
            flag = false;
        }
        if(cattlePostFields.preganency_month < 0)
        {
            preganency_month_error="Preganency month cannot be less than 0";
            flag = false;
        }
        setCattlePostFieldsErrors({cattle_type_error,cattle_breed_error,number_of_child_error,milk_produced_error,product_price_error,preganency_month_error})
        return flag
    }    
    const handleReview = (e) => {
        e.preventDefault()
        if(review === true){
            setReview(false)
            return
        }
        if(isValid())
        {
            setReview(true)
        }
        else{
            setReview(false)
        }
    }    

    const [states,setStates] = useState([])
    const [districts,setDistricts] = useState([])
    const [blocks,setBlocks] = useState([])

    const handleStateChange = (name,func,setFunc) => (event) => {
        setCattlePostFields({...cattlePostFields,[name]:event.target.value })
        func(event.target.value).then(response=> {
            return response.json()
        })
        .then(data => setFunc(data))
        .catch(err => {
            console.log(err)
        });
    }    

    const handleSelection = (name) => (e) => {
        setCattlePostFields({...cattlePostFields,[name]:e.target.value})
    }

    const handleImageSelection = (name) => (e) => {
        e.preventDefault()
        const reader =new FileReader()
        reader.onload = () => {
            if(reader.readyState === 2) {
                setPhotos({...photos,[name]:reader.result})
            }
        }
        reader.readAsDataURL(e.target.files[0])
        setCattlePostFields({...cattlePostFields,[name]:e.target.files[0]})
        //setData({...data,[name]:f})
    }    

    const cattleForm = () => (
        <React.Fragment>
        <Form className="mt-4 mx-auto" onSubmit={handleSubmit}>            
            <h3 className="gray heading">Enter details </h3>
            <hr />

                <Form.Row>
                    <Form.Label className="text-sm-left" column sm="4">
                            Select Cattle Type:
                    </Form.Label>                
                    <FormControl as="select" value={cattlePostFields.cattle_name} onChange={handleSelection("cattle_name")} size="sm" className="col-lg-4 mt-2">                        
                    <option key="Select Cattle Type">Select Cattle Type</option>
                        {
                            cattles.map(d => {
                                return <option key={d.id}>{d.cattle_name}</option>
                            })
                        }
                    </FormControl>            
                    <div style={{fontSize:"0.8em",color:"red"}}>{cattlePostFieldsErrors.cattle_type_error}</div>
                </Form.Row>

                <Form.Row>
                    <Form.Label className="text-sm-left" column sm="4">
                            Select Cattle Breed:
                    </Form.Label>                
                    <FormControl as="select" value={cattlePostFields.cattle_breed} onChange={handleSelection("cattle_breed")} size="sm" className="col-lg-4 mt-2">                        
                    <option key="Select Cattle Breed">Select Cattle Breed</option>
                        {
                            breeds.map(d => {
                                return <option key={d.id}>{d.cattle_breed}</option>
                            })
                        }
                    </FormControl>            
                    <div style={{fontSize:"0.8em",color:"red"}}>{cattlePostFieldsErrors.cattle_breed_error}</div>
                </Form.Row>

                <Form.Row >
                    <Form.Label column sm="4">
                            Number of Births:
                    </Form.Label>                
                    <FormControl type="text" value={cattlePostFields.number_of_child}  onChange={handleSelection("number_of_child")} size="sm" className="col-lg-4 mt-2"/>                          
                    <div style={{fontSize:"0.8em",color:"red"}}>{cattlePostFieldsErrors.number_of_child_error}</div>
                </Form.Row>

                <Form.Row >
                    <Form.Label column sm="4">
                            Milk Produced(litre per day):
                    </Form.Label>    
                    <FormControl as="select" value={cattlePostFields.milk_produced} onChange={handleSelection("milk_produced")} size="sm" className="col-lg-4 mt-2" >                        
                        <option key="select milk produced">Select</option>
                        {
                            milkperday.map((d,i)=>{
                                return <option key={i}>{d.milk_per_day}</option>
                            })
                        }
                    </FormControl>
                    <div style={{fontSize:"0.8em",color:"red"}}>{cattlePostFieldsErrors.milk_produced_error}</div>
                </Form.Row>

                <Form.Row >
                    <Form.Label column sm="4">
                            Price:
                    </Form.Label>                
                    <FormControl type="text" value={cattlePostFields.product_price} onChange={handleSelection("product_price")} size="sm" className="mr-lg-1 col-lg-1 mt-2"/>                        
                    <FormControl as="select" value={cattlePostFields.price_fixed} onChange={handleSelection("price_fixed")} size="sm" className="col-lg-2 mt-2">
                        {
                            priceStatus.map((d) => 
                            {
                                return <option key={d.id}>{d.price_status}</option>
                            })
                        }
                    </FormControl>                       
                    <div style={{fontSize:"0.8em",color:"red"}}>{cattlePostFieldsErrors.product_price_error}</div>
                </Form.Row> 
                <Form.Row >
                    <Form.Label column sm="4">
                            Preganent:
                    </Form.Label>                
                    <FormControl as="select" value={cattlePostFields.if_preganent} onChange={handleSelection("if_preganent")} size="sm" className="col-lg-1 mt-2">                        
                        <option>No</option>
                        <option>Yes</option>
                    </FormControl>
                    <div style={{fontSize:"0.8em",color:"red"}}>{cattlePostFieldsErrors.if_preganent_error}</div>
                </Form.Row>
                {
                    cattlePostFields.if_preganent === 'Yes'?
                    <Form.Row >
                        <Form.Label column sm="4">
                            Preganency Month:
                        </Form.Label>                
                        <FormControl type="text" value={cattlePostFields.preganency_month} onChange={handleSelection("preganency_month")} size="sm" className="col-lg-4 mt-2"/>                        
                        <div style={{fontSize:"0.8em",color:"red"}}>{cattlePostFieldsErrors.preganency_month_error}</div>
                    </Form.Row>                                 
                    :
                    ''
                }

                    <Form.Row>
                        <Form.Label column sm="4">
                                Select Your State
                        </Form.Label>                
                        <FormControl size="sm" as="select"  defaultValue={cattlePostFields.state} onChange={handleStateChange("state",fetchdistrict,setDistricts)} className="col-lg-4 mt-2">
                            
                            {
                                states.map(d=>{
                                    return <option key={d.id}>{d.state_name}</option>
                                })
                            }
                        </FormControl>            
                        <div style={{fontSize:"0.8em",color:"red"}}>{cattlePostFieldsErrors.state_error}</div>
                    </Form.Row>
                    <Form.Row>
                        <Form.Label column sm="4">
                                Select Your District
                        </Form.Label>                
                        <FormControl size="sm" as="select" defaultValue={cattlePostFields.district} onChange={handleStateChange("district",fetchblocks,setBlocks)} className="col-lg-4 mt-2">
                                
                                {
                                    districts.map(d => {
                                        return <option key={d.id}>{d.district_name}</option>
                                    })
                                }
                        </FormControl>            
                        <div style={{fontSize:"0.8em",color:"red"}}>{cattlePostFieldsErrors.district_error}</div>
                    </Form.Row>
                    <Form.Row>
                        <Form.Label column sm="4">
                                Select Your Block
                        </Form.Label>                
                        <FormControl size="sm" as="select" defaultValue={cattlePostFields.block} onChange={handleSelection("block")} className="col-lg-4 mt-2">
                                
                                {
                                    blocks.map(d => {
                                        return <option key={d.id}>{d.block_name}</option>
                                    })
                                }
                        </FormControl>            
                        <div style={{fontSize:"0.8em",color:"red"}}>{cattlePostFieldsErrors.blocks_error}</div>
                    </Form.Row>
                    <Form.Row>
                        <Form.Label column sm="4">
                                Enter your village name
                        </Form.Label>                
                        <FormControl size="sm" type="text" value={cattlePostFields.village} onChange={handleSelection("village")} className="col-lg-4 mt-2" />
                    </Form.Row>                                    
                


                <Form.Row>
                        <Form.Label column sm="4">
                            Additional Information:
                        </Form.Label>
                    <FormControl as="textarea" style={{resize:"none"}} rows={3}  value={cattlePostFields.additional_information} onChange={handleSelection("additional_information")} className="col-lg-4 mt-2 mr-sm-2" size="sm"  placeholder=""/>
                    <div style={{fontSize:"0.8em",color:"red"}}>{cattlePostFieldsErrors.additional_information_error}</div>
                </Form.Row>  

                <Form.Row className="m-auto ">
                        <div className="col-2">
                        <label className="mt-2 btn btn-default btn-outline-secondary btn-sm mx-auto" >
                            Click to Browse <input type="file" hidden onChange={handleImageSelection("photo1")}  className="mt-2"/>
                        </label>
                        <img src={photos.photo1}
                            style={{height:"150px",width:"150px"}} alt="photo1"/>
                        </div>
                        <div className="col-2 ml-4">
                        <label className="mt-2 btn btn-default btn-outline-secondary btn-sm mx-auto">
                            Click to Browse <input type="file" hidden onChange={handleImageSelection("photo2")}  className="mt-2"/>
                        </label>
                        <img src={photos.photo2}
                            style={{height:"150px",width:"150px"}} alt="photo2"/>                        
                        </div>
                        {/* //style={{display:"inline-block",width:"100px",height:"100px",backgroundImage:`url(${data.photo3})`}} */}
                        <div className="col-2 ml-4">
                            <label className="btn mt-2 btn-default btn-outline-secondary btn-sm" >
                                Click to Browse<input type="file" hidden onChange={handleImageSelection("photo3")}  className="mt-2"/>
                            </label>
                            <img src={photos.photo3} style={{height:"150px",width:"150px"}} alt="photo3"/>                        
                        </div>
                    </Form.Row>
                    <div className="form-row col-10 mt-4">
                        <div className="col-3">
                            <Button  variant="info" onClick={handleReview}>Review</Button>                           
                        </div>
                        <div className="col-3 ml-auto">
                        <Button  className=" " type="submit" variant="info">
                                {
                                    adposted?
                                    <>
                                        <Spinner animation="border" role="status">
                                        <span className="sr-only">Saving...</span>
                                        </Spinner>                                    
                                    </>:

                                    <span>Submit</span>
                                }
                            </Button>     
                        </div>
                    </div>                                              
        </Form>
        {redirectToItem()}
        </React.Fragment>
    )

    const [adposted,setAdPosted] = useState(false)
    return (
        <>
        <Layout>
        {
        review ?
            <>
            <CattleReviewPost postData={cattlePostFields} photos={photos}/>
            <div className="container">
                <div className="row">
                <Button  className="m-auto" variant="info" onClick={handleReview}>Go back to edit</Button>
                    <div className="col-3 ml-auto">
                    <Button onClick={handleSubmit} className=" " type="submit" variant="info">
                                {
                                    adposted?
                                    <>
                                        <Spinner animation="border" role="status">
                                        <span className="sr-only">Saving...</span>
                                        </Spinner>                                    
                                    </>:

                                    <span>Submit</span>
                                }
                            </Button>     
                    </div> 
                </div>
            </div>

            </>

        :
            loading ? 
            '':
            (
                error?
            <div className="alert alert-warning col-6 mx-auto">
                <h3>{error}</h3>
            </div>
            :
            <div className="col-lg-10 col-12 ml-auto" >
                {cattleForm()}
            </div>
            )
        }
        </Layout>
        </>
    )
}

export default CattlePostAds;