import React from 'react'
import Layout from '../core/Layout'

const ItemsNotAllowed = () => {
    return (
        <Layout>
        <div className="container-fluid">
            <div className="row">
                <div className="col-8 mx-auto">
                <h5>Items not allowed on e-Krishibazaar</h5>
                <ol className="number">
                    <li>A-Z of prohibited products and services:</li>
                <li>Admissions including Direct Admissions in colleges/universities/reputed institutes.</li>
<li>Adoption of Children & Babies</li>
<li>Ads for buying products/services like "Looking for Bride", "Looking for a girl friend/boyfriend" etc. Please look at the ads posted on e-Krishibazaar.com and contact the us.</li>
<li>Adult/Sexually Oriented/Pornographic Material” or "Child pornographic material.</li>
<li>Advance bookings or Products which are yet to be ready to sell or pre-orders except for grains, fruits, flowers, vegetables, oilseeds, pulses, medicinal plant or other crop which is in farm. </li>
<li>Advance fees, registration fees or payment, etc.</li>
<li>Alcohol, E-cigarette and other Tobacco Products.</li>
<li>Allopathic medicine. Any kind of new or used medicine.</li>
<li>Any animal under the pets category as defined under the prevention of cruelty to animals (Pet Shop) Rules, 2018 or any other kind of live animals. </li>

<li>Any kind of animal body part of animal skin and bone or any product made of such material.</li>
<li>Astrology or any kind of spiritual/supernatural services/remedies like (black magic, Vashikaran, love spells/problems/jadu/ Vashikaran). Any services where facts are not public and can’t be scientifically ascertained in general.</li>
<li>Blogging or any discussion or information published on the Internet.</li>
<li>Brand new phones/newly launched/recently purchased without a bill, Jio Phones, etc.</li>
<li>Burglary Tools</li>
<li>Charity or voluntary giving of help or products for free.</li>
<li>Chemicals i.e. “Hazardous Chemicals, Pharmaceutical products (prescribed or not), Restricted, Perishable Items”, and “Radioactive materials & substances”</li>
<li>Child labor or Slavery i.e. Any services which fall within the ambit of child welfare laws of the country.</li>
<li>Counterfeit Products/Clones, Replicas or demo sets.</li>
<li>Crypto-currency (e.x. Bitcoin, Litecoin, bitcoin mining rigs & affiliated services/products). </li>
<li>Cylinders and Products without the approval and registration of government agencies such as CNG, Pepper Spray, LPG.</li>
<li>Database - Selling of user database or leads or sale of any information duly collected from the customers which can benefit others knowingly or unknowingly.</li>
<li>Dating services for people looking for relationship, dating and/or friendship</li>
<li>Direct admissions to colleges/universities/reputed institutes through management quota or donations. </li>
<li>Discriminating on the basis of race, caste, religion or creed mentioning in ads.</li>
<li>Education certificates (fake) for High school diplomas, university, medals, etc.</li>
<li>Escort Services or adult (cross-gender) massage.</li>
<li>Firecrackers, Explosives and Explosive Substances</li>
<li>Foreign exchange including collecting and effecting/remitting payments directly /indirectly outside India in any form towards overseas foreign exchange trading through electronic/internet trading portals.</li>
<li>Free services of any kind with no clearly defined benefits.</li>
<li>Government IDs and Licenses Services.</li>
<li>items or government-issued items such as medals, rewards & certificates</li>
<li>Government job of any type (except the third-party vendor payroll if mentioned), backdoor jobs, Captcha work, work from home via Facebook, Foreign jobs, Chit funds job or schemes.</li>
<li>Government service - Only consultancy/guidance is allowed  </li>
<li>Guaranteed services for approval and acceptance of government documents or ID's (Aadhar card, PAN, Passport, Visa, etc.)</li>
<li>Hellevator scheme- Matrix sites or sites using a matrix scheme approach</li>
<li>Human, Blood, Bodily Fluids, Human Parts, and Remains </li>
<li>Illegal Goods/Embargoed Goods/Contraband goods</li>
<li>Indian currency notes – Old, New or Error notes. Coins considered as legal tender.</li>
<li>Hacking/piracy- Devices infringing intellectual property rights such as unlocking devices which include Mod chips or other devices designed to circumvent copyright protection</li>
<li>IPR media violation- Media infringing intellectual property rights, which includes unauthorized copies of books, music, movies, and other licensed or protected materials</li>
<li>IPR software violation- Software infringing intellectual property rights, which includes unauthorized copies of the software, video games, and other licensed or protected materials, including OEM or bundled software</li>
<li>Ivory products</li>
<li>Items that hurt the Laws for the Protection of Historical, Artistic and Cultural significance</li>
<li>Loans offered by private parties or institutions not authorized and regulated as per RBI guidelines.</li>
<li>Luxury items with unrealistic pricing/not corresponding to market pricing.</li>
<li>Medical Equipment requiring licenses (such as Ultrasound machines, X-Ray Machines, etc).</li>
<li>Medicines prescribe as prescription-strength medicine, aesthetic, steroids, & food supplements</li>
<li>Miracle cures which include unsubstantiated cures, remedies or other items marketed as quick health fixes</li>
<li>Multi-Level Marketing Services (MLM), SMS Sending, Email sending/processing, Champ cash, Network marketing jobs, and affiliated home-based jobs.</li>
<li>Offensive goods, the crime that includes crime scene photos or items, such as personal belongings, associated with criminals</li>
<li>Offensive Material - examples include ethnically or racially offensive material</li>
<li>Photos of artists/singers/entertainers are not allowed unless with permit</li>
<li>Pirated Cable box descramblers and jail-broken/modified gaming consoles.</li>
<li>Police, Army, Navy and Airforce Related Items</li>
<li>Political affiliations, admission, and blogging</li>
<li>Software & movie piracy, software to unlock phones/video games or Stocks and Other Securities</li>
<li>Stocks, shares and other securities intangible in nature</li>
<li>Surrogacy</li>
<li>The government has declared face masks and hand sanitizers as essential commodities under the 'Essential Commodities Act, 1955' up to June 30, 2020. This is to ensure price regulation and availability of these products. E-Krishibazaar supports this move and only allow these products at a regulated price.</li>
<li>Tickets/vouchers/passes for many events, games, matches or concerts, lottery tickets, sweepstakes entries, subscriptions, etc.</li>
<li>Traffic devices, which include radar detectors/hammers, license plate covers, traffic signal changers /sensors, and related products.</li>
<li>Transfer of membership/subscription/certificates/timeshare etc.</li>
<li>Transfer or selling license for pharmacy or liquor shop or any such institution which is controlled by the government</li>
<li>Used Cosmetics, undergarments, personal hygiene & grooming items (e.g. epilators, shavers, etc).</li>
<li>Weapons, Air Gun, artillery including arms, any type of edged tools resulting in affliction including daggers, swords, serrated objects, knives, and similar items.  </li>
<li>Please note that while this list is exhaustive, it’s not just limited to the mentioned items & also encompasses anything violating company policies.</li>
</ol>

                </div>
            </div>
        </div>
        </Layout>
    )
}

export default ItemsNotAllowed;