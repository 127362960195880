import React from 'react'
import Layout from './Layout'

const ContactUs = () => {
    return (
        <Layout>
            <div className="container mt-4">
                <div className="row">
                <div className="col-12">
                        <p className="h3 mx-auto" style={{color:"green"}}>eKrishi Bazaar</p>
                    </div>
                </div>
                <div className="row">
                    
                    <div className="col-6 col-lg-6 my-4">
                        <h1 className="h5">Head Office</h1>
                        <p>Rajajipuram, Lucknow</p>
                        <p>Uttar Pradesh - 226017 </p>
                        <p>Email: info@ekrishibazaar.com</p>

                        <h1 className="h5">Regional Offices</h1>
                        <p className="h5">Noida</p>
                        <p>Sector-63, Noida </p>
                        <p>Uttar Pradesh - 201301</p>
                        <p>Email: info@ekrishibazaar.com</p>
                        <p className="h6">To Know More Regional offices</p>
                        <a href="/"> <p className="text-center font-weight-bold" style={{color:"#0069D9"}}>Click here</p></a>
                    </div>
                    <div className="col-lg-4 col-12 text-center">
                        <h5>Follow Us</h5>
                        <p><span className="h6"><a href="https://www.facebook.com/ekrishibazaar">Facebook</a> </span> 
                        
                        <span className="h6"><a href="https://www.twitter.com/ekrishibazaar">Twitter</a></span></p>
                        
                        <h5>For Help</h5>
                        <p>Whatsapp: 7291905182</p>
                        <p>help@ekrishibazaar.com</p>

                        <h5>Sponsorship</h5>
                        <p>sponsorship@ekrishibazaar.com</p>
                        <p>To know price and other details</p>
                        <a href="/"> <p className="text-center font-weight-bold" style={{color:"#0069D9"}}>Click here</p></a>
                    </div>
                </div>
                
            </div>
        </Layout>
    )
}

export default ContactUs;