import React,{useState,useEffect} from 'react';
import {Form,Button} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {API} from '../config'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import {getToken,signout} from '../auth'
import {DisplaySearchResult} from '../core/DisplaySearchResult'
import InfiniteScroll from 'react-infinite-scroll-component';

const UserInteraction = () => {
    const [userdetails,setUserDetails] = useState(false)
    const [adsdetails,setAdsDetails] = useState()
    const [searchtext,setSearchText] = useState('');
    const [notificationText,setNotificationText] = useState('')
    const [next_url,setNextUrl] = useState(null)
    const [success,setSuccess] = useState(false)
    const [error,setError] = useState(false)
    const [loading, setLoading] = useState(false)
    const triggerSearch = (e) => {
        setUserDetails(false)
        setLoading(true)
        e.preventDefault()
        let token = getToken()
        if(searchtext === '')
            return
        var fd = new FormData()
        fd.append('vid', searchtext)
        fd.append('phone_number',searchtext)
        fd.append('get_user_information','True')
        fetch(`${API}/edituserinfo/?adminoperation=true`,{
            method:'POST',
            headers:{
                Accept:'application/json',
                "Authorization":`Token ${token}`
            },
            body:fd
        }).then(response => {
            return response.json()
        }).then(data => {
            if(data.detail) {
                if(data.detail.toLowerCase().includes('token')) {
                    signout(()=>{
                        window.location.reload(false)
                    })
                } else {
                    setError(data.detail)
                }
            }
            else{
                
                setUserDetails(data.data) 
            }
        }).catch(err=>{
            
          setError(true)
        })

        
        setLoading(false)
        
    }

    useEffect(()=>{
        let token = getToken()
        var fd_ads = new FormData()
        fd_ads.append('vid',searchtext)
        fetch(`${API}/ads/userads/?vid=${searchtext}&adminoperation=true`,{
            method:'GET',
            headers:{
                Accept:'application/json',
                "Authorization":`Token ${token}`
            }
        }).then(response => {
            return response.json()
        }).then(data => {
            if(data.detail) {
                if(data.detail.toLowerCase().includes('token')) {
                    signout(()=>{
                        window.location.reload(false)
                    })
                } else {
                    setError(data.detail)
                }
            } else {
                
                setAdsDetails(data.results)
                setNextUrl(data.next)
                
            }
        }).catch(err=>{
            
        })
    },[userdetails])

    const loadForm = () =>{
        return (
        <Form className="mx-auto mt-4">

                <Form.Label className="h6">
                    Search User By user id or Mobile Number
                </Form.Label>
                <Form.Row className="col-12 mx-auto">
                <Form.Control type="text" 
                onChange={ (e) => {e.preventDefault(); setSearchText(e.target.value)}}
                className="col-lg-8 col-10" placeholder="Type VID or Mobile Number"/>

                <Button type="submit" size="sm" className="inline zero-border"  onClick={triggerSearch}>
                                <FontAwesomeIcon icon={faSearch} />
                </Button>

                </Form.Row>
        </Form>
        )
    }

    const handleMoreEvent = () => {
      if(next_url !== null) {
        var fd_ads = new FormData()
        fd_ads.append('vid',searchtext)
        var token = getToken()
        fetch(`${API}/ads/userads/?adminoperation=true`,{
            method:'POST',
            headers:{
                Accept:'application/json',
                "Authorization":`Token ${token}`
            },
            body:fd_ads
        }).then(response=> {
            return response.json()
          })
          .then(data => {
            if(data.detail) {
                if(data.detail.toLowerCase().includes('token')) {
                    signout(()=>{
                        window.location.reload(false)
                    })
                } else {
                    setError(data.detail)
                }
            } else{
            setAdsDetails([...adsdetails,...data.results])
            setNextUrl(data.next)
            }
          })
          .catch(err => {
            
              setError(true);
          })
      }
    }

    const userDisplayForm = () => {
        return (
            <Form inline>
                <Form.Row>
                    <img src={userdetails.image} className="img-thumbnail" style={{backgroundColor:"grey", height:"200px",width:"200px"}}/>
                </Form.Row>
            </Form>
        )
    }
    const onNotificationSubmit = (e) => {
        e.preventDefault()
        let token = getToken()
        var fd = new FormData()
        fd.append('vid',userdetails.vid)
        fd.append('notification',notificationText)

        fetch(`${API}/edituserinfo/?adminoperation=true`,{
            method:'POST',
            headers:{
                Accept:'application/json',
                "Authorization":`Token ${token}`
            },
            body:fd
        }).then(response => {
            return response.json()
        }).then(data=>{
            if(data.detail) {
                if(data.detail.toLowerCase().includes('token')) {
                    signout(()=>{
                        window.location.reload(false)
                    })
                } else {
                    setError(data.detail)
                }
            }else {
                setSuccess(data.notification_success)
            }
        }).catch(err=>{
             
        })
    }

    const handleUserOperation = (name) => (e) => {
        let token = getToken()
        var fd = new FormData()
        fd.append('vid',userdetails.vid)
        fd.append(name,true)
        fetch(`${API}/edituserinfo/?adminoperation=true`,{
            method:'POST',
            headers:{
                Accept:'application/json',
                "Authorization":`Token ${token}`                    
            },
            body:fd
        }).then(response=> {
            return response.json()
        })
        .then(data => {
            if(data.detail) {
                if(data.detail.toLowerCase().includes('token')) {
                    signout(()=>{
                        window.location.reload(false)
                    })
                } else {
                    setError(data.detail)
                }
            } else {
                if(data.data){
                    setSuccess(data.message)
                    setUserDetails(data.data)
                } else {
                    setUserDetails(data)
                }
            }
        })
        .catch(err => {
                        
            setError(true);
        })
    }
    const showSuccess = () => {
        return (
            
                success ?
                
                <div className="alert alert-success alert-dismissible fade show col-lg-6 col-12 mx-auto"  role="alert">
                    {success}
                    <button type="button" onClick={(e)=> {
                        e.preventDefault()
                        setSuccess(false)
                    }} className="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>                                    
                </div>
            :
            ''
            
        )
    }

    const showError = () => {
        return (
            error ? 
            <div className="alert alert-warning alert-dismissible fade show col-lg-6 col-12 mx-auto"  role="alert">
                {error}
                <button type="button" onClick={(e)=> {
                        e.preventDefault()
                        setError(false)
                    }} className="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>                
            </div>
            :
            ''
        )
    }

    return (
        <>
        <div className="row ">
            <div className="col-lg-6 col-8 mx-auto">
            {loadForm()}
            {showError()}
            {showSuccess()}
            </div>
        </div>

        {/* <div className="col-12">
            <div className="row">
                {showError()}
                {showSuccess()}
            </div>
        </div> */}

        <div className="container-fluid">
            <div className="row">
            {
                userdetails && adsdetails ?
                <>
            <div className="col-lg-2 col-12 mt-4 ">

                    <img   src={userdetails.image} style={{width:"200px",height:"200px"}}/>

            </div>
                <div className="col-lg-6 col-12 d-flex my-auto mx-auto " style={{border:"1px",borderBlockColor:"black"}}>
                <div className="col-6">
                    <p className="h6 mt-4">User Information</p>
                    <p> <span className="h6 ">User Name:</span> {userdetails.first_name} {userdetails.last_name}</p>
                    <p><span className="h6">User Status: </span>
                    </p>
                    <p>
                        <span className="h6">isBlocked</span>: {
                            userdetails.is_blocked ? 
                            "Yes":
                            "No"
                        } 
                        
                    </p>
                    <p>
                        <span className="h6">isVerified</span>: {
                            userdetails.is_verified ? 
                            "Yes":
                            "No"
                        }
                    </p>
                    <p> <span className="h6">Vid:</span> {userdetails.vid}</p>
                    {
                        userdetails.phone_number ? 
                        <p> <span className="h6">Phone Number:</span> {userdetails.phone_number}</p>
                        :
                        ''
                    }
                    </div>
                    <div className="col-6 mt-4">
                    <p className="h6"> Location  </p>
                    
                    <p><span className="h6">State: </span>{userdetails.state} </p>
                    <p><span className="h6">District: </span> {userdetails.district} </p>
                    <p><span className="h6">Block: </span> {userdetails.block}</p>
                    <p><span className="h6">Village: </span> {userdetails.village}</p>
                    </div>
                </div>



                <div className="col-lg-4 col-12 inline my-auto mx-auto " style={{border:"1px",borderBlockColor:"black"}}>
                    <Form className="mt-4" onSubmit={onNotificationSubmit}>
                        <Form.Label >
                            Send notification to user
                        </Form.Label>
                        <Form.Control as="textarea" rows={3} onChange={(e) => {
                            setNotificationText(e.target.value)
                        }}/>
                        <Button size="sm" className="mt-2 mx-auto" type="submit">send notification</Button>
                    </Form>
                </div>
                <div className="container-fluid">
                    <hr />
                    <div className="row">
                        <div className="col-12 col-lg-10 inline mx-auto">
                            <Form>
                                <Form.Label>
                                    User Operation: 
                                </Form.Label>
                                <Button size="sm" onClick={handleUserOperation('unblock')} className="mx-2">UnBlock User</Button>
                                <Button size="sm" onClick={handleUserOperation('block')} className="mx-2">Block User</Button>
                                <Button size="sm" onClick={handleUserOperation('verify')} className="mx-2">Make Verified</Button>
                                <Button size="sm" onClick={handleUserOperation('delete_user')} className="mx-2">Delete User</Button>                        
                            </Form>

                        </div>
                    </div>
                </div>
                
                
                    <div className="container-fluid">
                    <hr />
                        <div className="row">
                        <InfiniteScroll dataLength={adsdetails.length}
                                next={handleMoreEvent}
                                hasMore={true}
                                className="col-lg-12 col-12 flex flex-wrap"
                                style={{width:"100%"}}
                                >
                                    <DisplaySearchResult enableEditButton={true} resultsToRender={adsdetails} />
                                </InfiniteScroll>
                        </div>
                    </div>                
                    </>
                :
                (
                    loading ?
                    <h6 className="mx-auto">Loading...</h6> :
                    ''
                    
                )
                
            }
            </div>
        </div>

        </>
    )
}

export default UserInteraction;