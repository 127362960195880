import React, {  useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import {getEKLanguage} from '../utils/language_utils';
import {API} from '../config';
import "../assets/css/module.css";
import {strings} from '../utils/translated_strings';
import {StateComponent,fetchblocks,fetchdistrict} from '../utils/AreaUtils';




const Signup = () => {

    const [values,setValues] = useState({
        first_name:'',
        last_name:'',
        username:'',
        state:'',
        password:'',
        district:'',
        block:'',
        village_name:'',
        error:'',
        success:false,
    })



    const [districts, setDistricts] = useState([])
    const [blocks, setBlocks] = useState([])


    const signup = (user) => {
        let lang = getEKLanguage();
        return fetch(`${API}/signup/`,{
            method: "POST",
            headers: {
                Accept:'application/json',
                "Content-Type":"application/json",
                "Accept-Language":`${lang}`
            },
            body: JSON.stringify(user)
        })
        .then(response=> {
            return response.json()
        })
        .catch(err => {
        })
    }

    const {first_name,last_name,username,state,password,district,block,village_name,error,success} = values;

    const clickSubmit = (event) => {
        event.preventDefault();
        setValues({...values,error:false,success: false})
        signup({first_name,last_name,username,mobile_number:username,state,password,block,district,village_name})
        .then(data => {
            if(data.detail) {
                setValues({...values,error:data.detail,success: false})
            } else {
                setValues({
                    ...values,
                    first_name:'',
                    last_name:'',
                    username:'',
                    state:'',
                    password:'',
                    district:'',
                    block:'',
                    village_name:'',
                    error:'',
                    success: true
                })
            }
        })
        
    }

    const handleChange = (name) => (event) => {
        setValues({...values,error:false,[name]:event.target.value })
    }
    


    const handleStateChange = (name,func,setFunc) => (event) => {
        setValues({...values,error:false,[name]:event.target.value })
        func(event.target.value).then(response=> {
            return response.json()
        })
        .then(data => {
            if(data.detail){
                setValues({...values,error:data.detail})
            } else {
                
                setFunc(data)
            }
        })
        .catch(err => {
            console.log(err)
        });
    }

    
    const showError = () => (
            (error ?
                <div className="alert alert-danger" >
                    {error}
                </div> : ''
            )
    )

    const showSuccess = () => (
        <div className="alert alert-info status-message"  style={{display: success ? '' : 'none' }}>
            {strings.account_success}
        </div>
    )
    

    const signUpForm = () => (
        <Form className="signup-form pb-2 rounded">
        {showError()}
        {showSuccess()}
        <p className="h2 text-center mb-1 pt-2 fonter">{strings.register_with_us}</p>
        <p className="h3 text-white text-center mb-1 mt-1 pt-0 pb-0 fonter-md">{strings.connect_with_digital_krishi_bazar}</p>
        <p className="h5 text-warning text-center mb-1 mt-1 fonter-md">{strings.why_register_with_us}</p>
        <div className="form-group">
            <FormControl type="text" size="sm" value={first_name} onChange={handleChange('first_name')} placeholder={strings.your_first_name} className="style-signup-boxes mt-2 mx-auto"></FormControl>
            <FormControl type="text" size="sm" value={last_name} onChange={handleChange('last_name')} placeholder={strings.your_last_name} className="style-signup-boxes mt-2 mx-auto"></FormControl>
            <FormControl type="text" size="sm" value={username} onChange={handleChange('username')} placeholder={strings.your_mobile_number} className="style-signup-boxes mt-2 mx-auto"></FormControl>
            <FormControl type="password" size="sm" value={password} onChange={handleChange('password')} placeholder={strings.your_password} className="style-signup-boxes mt-2 mx-auto"></FormControl>
            <FormControl as="select" size="sm" value={state}   onChange={handleStateChange('state',fetchdistrict,setDistricts)} className="style-signup-boxes mt-2 mx-auto">
                <StateComponent />
            </FormControl>
            <FormControl as="select"  onChange={handleStateChange('district',fetchblocks,setBlocks)} className="style-signup-boxes mt-2 mx-auto">
                <option default_value="" hidden >{strings.select_your_district}</option>
                {

                                    districts.map(d => {
                                        return <option key={d.district_name}>{d.district_name}</option>
                                    })
                }
            </FormControl>
            <FormControl as="select"   onChange={handleChange('block')} className="style-signup-boxes mt-2 mx-auto">
                <option default_value="" hidden >{strings.select_your_block}</option>
                {
                    blocks.map(d => {
                        return <option key={d.block_name}>{d.block_name}</option>
                    })
                }
            </FormControl>
            <FormControl type="text" value={village_name} onChange={handleChange('village_name')} className="style-signup-boxes mt-2 mx-auto" placeholder={strings.enter_your_village_name}></FormControl>
            <Button onClick={clickSubmit} type="submit" className="green mx-auto signup-button mt-2 mb-4">Register</Button>
        </div>
    </Form>
    )
    return (
        <React.Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12 col-12">
                        {signUpForm()}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Signup;
