import React, {useState,useEffect} from 'react';
import {Form,Button} from 'react-bootstrap'
import {DisplaySearchResult} from '../core/DisplaySearchResult'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {API} from '../config'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import {getToken,signout} from '../auth/'

const AdsInteraction = ({vid=0}) => {
    const [adsdetails,setAdsDetails] = useState(false)
    const [searchtext,setSearchText] = useState('');

    const triggerSearch = (e) => {
        e.preventDefault()

        let token = getToken()
        var fd = new FormData()
        fd.append('postid',searchtext)
        fd.append('adminoperation','true')
        
        fetch(`${API}/ads/userads/?postid=${searchtext}`,{
            method:'GET',
            headers:{
                Accept:'application/json',
                "Authorization":`Token ${token}`                
            }
        })
        .then(response=>{
            return response.json()
        })
        .then(data => {
            
            setAdsDetails(data.results)
        })
        .catch(err=>{

        })
    }
    const showSuccess = () => {
        return (
            
                success ?
                
                <div className="alert alert-success alert-dismissible fade show col-lg-6 col-12 mx-auto"  role="alert">
                    {success}
                    <button type="button" onClick={(e)=> {
                        e.preventDefault()
                        setSuccess(false)
                    }} className="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>                                    
                </div>
            :
            ''
            
        )
    }

    const showError = () => {
        return (
            error ? 
            <div className="alert alert-warning alert-dismissible fade show col-lg-6 col-12 mx-auto"  role="alert">
                {error}
                <button type="button" onClick={(e)=> {
                        e.preventDefault()
                        setError(false)
                    }} className="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>                
            </div>
            :
            ''
        )
    }

    const loadForm = () =>{
        return (
        <Form className="mx-auto mt-4">

                <Form.Label className="h6">
                    Search post using post id
                </Form.Label>
                <Form.Row className="col-12 mx-auto">
                <Form.Control type="text" 
                onChange={ (e) => {e.preventDefault(); setSearchText(e.target.value)}}
                className="col-lg-8 col-10" placeholder="Type post id"/>

                <Button type="submit" size="sm" className="inline zero-border"  onClick={triggerSearch}>
                                <FontAwesomeIcon icon={faSearch} />
                </Button>

                </Form.Row>
        </Form>
        )
    }
    const [notificationText,setNotificationText] = useState('')
    const [error,setError] = useState(false)
    const [success,setSuccess] = useState(false)
    const onNotificationSubmit = (e) => {
        e.preventDefault()
        let token = getToken()
        var fd = new FormData()
        fd.append('vid',adsdetails[0].user.vid)
        fd.append('notification',notificationText)
        fd.append('adminoperation','true')

        fetch(`${API}/edituserinfo/`,{
            method:'POST',
            headers:{
                Accept:'application/json',
                "Authorization":`Token ${token}`
            },
            body:fd
        }).then(response => {
            return response.json()
        }).then(data=>{
            if(data.detail) {
                if(data.detail.toLowerCase().includes('token')) {
                    signout(()=>{
                        window.location.reload(false)
                    })
                } else {
                    setError(data.detail)
                }
            }             
            else {
                setSuccess(data.notification_success)
            }
        }).catch(err=>{
            setError("Something went wrong!")
        })
    }
    return (
        <>
            <div className="row ">
                <div className="col-lg-6 col-8 mx-auto">
                {loadForm()}
                {showError()}
                {showSuccess()}                
                </div>
            </div>
            {
                adsdetails ?
                <div className="row">
                    <div className="col-12" style={{flexDirection:"row"}}>
                        <DisplaySearchResult enableEditButton={true} resultsToRender={adsdetails} />
                    </div>
                    <div className="col-lg-12 col-12 mx-auto">
                        <Form className="mt-4 col-6" onSubmit={onNotificationSubmit}>
                            <Form.Label >
                                Send notification to {adsdetails[0].user.vid}
                            </Form.Label>
                            <Form.Control as="textarea" rows={3} onChange={(e) => {
                                setNotificationText(e.target.value)
                            }}/>
                            <Button size="sm" className="mt-2 mx-auto" type="submit">send notification</Button>
                        </Form>
                    </div>
                </div>:
                ''
            }
        </>
    )
}

export default AdsInteraction;