import React from 'react';
import Layout from './Layout';
import {Search} from './Search';

const BuyBackHome = () => {
    
    return <Layout>
        
            <Search super_category="BuyBackOffer"/>
        
    </Layout>
}

export default BuyBackHome;