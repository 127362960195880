import React,{useState,useEffect} from 'react';
import {Link} from 'react-router-dom';
import {API} from '../config';

const Footer = () => {
    // const [linkState,setLinkState] = useState({
    //     why_you_should_register : true,
    //     agricultural_news_updates: true,
    //     feedback:true,
    //     success_story:true,
    //     advice:true,
    //     help:true,
    //     site_map:true,
    //     privacy_policy:true
    // })
    // const [loading,setLoading] = useState(true)
    // const [error,setError] = useState(false)
    // useEffect(()=>{
    //     fetch(`${API}/ads/links/`,{
    //         method:"GET",
    //         headers:{
    //             Accept:'application/json',
    //             "Content-Type":"application/json"
    //         }
    //     }).then(response => {
    //         return response.json()
    //     })
    //     .then (data => {
    //         setLinkState(data)
    //     })
    //     .catch(err => {
    //         setError(err)
    //     })
    //     setLoading(false)
    // },[])

    return (
        <>

            <footer className="footer green mt-2">
            <div className="container">
                <div className="row">
                    <div className="col-lg-2 col-12">
                        <ul  className="ul_footer">
                            <li className="mx-auto border-0"><Link to="/">Home</Link></li>
                            <li className="mx-auto border-0"><Link to="/aboutus">About us</Link></li>
                            <li className="mx-auto border-0"><Link to="/contactus">Contact us</Link></li>
                            {/* <li className="mx-auto border-0"><Link to="/signup">Join us</Link></li> */}
                        </ul>
                    </div>
                    <hr style={{color:"white!important"}} />
                    <div className="col-lg-4 col-12">
                        <ul  className="ul_footer">
                            <li className="mx-auto border-0"><Link to="/terms-to-this-platform">Terms and Conditions</Link></li>
                            <li className="mx-auto border-0"><Link to="/legalandprivacypolicy">Privacy Policy</Link></li>
                            <li className="mx-auto border-0"><Link to="/adpostingguidelines">Ad Posting guidelines</Link></li>
                            <li className="mx-auto border-0"><Link to="/trust-and-safety">Trust and Safety</Link></li>
                            
                        </ul>
                    </div> 
                    <hr />
                    <div className="col-lg-3 col-12">
                        <ul  className="ul_footer">
                            <li className="mx-auto border-0"><Link to="/buyerfaq">Buyer FAQs</Link></li>
                            <li className="mx-auto border-0"><Link to="/beverifieduser">Be Verified User</Link></li>
                            <li className="mx-auto border-0"><Link to="/generalfaq">Help</Link></li>
                            
                        </ul>
                    </div>
                                                            
                    </div>
                <div className="row">
                    <div className=" col-12">
                        <p className="h6 mt-2 text-center text-white">Follow us
                        <br />
                        <a href="https://www.facebook.com/ekrishibazaar"><i className="fa fa-facebook-official" style={{fontSize:"48px",color:"white"}}></i></a>
                        <a href="https://www.facebook.com/ekrishibazaar"><i className="ml-2 fa fa-twitter-square" style={{fontSize:"48px",color:"white"}}></i></a>
                        </p>
                        
                    </div>
                </div>
            </div>
            </footer>
        
        </>
    )
}

export default Footer;