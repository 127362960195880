import React from 'react';
import {Carousel} from 'react-bootstrap'


const ReviewPost = ({postData,photos}) => {
    return (

        <div className="container">
        <div className="row m-auto">
                            <div className="col-12 col-lg-4 mt-4 ml-auto">
                                <Carousel >
                                    <Carousel.Item >
                                        <img
                                        className="d-block w-100"
                                        src={photos.photo1}
                                        alt="First slide"
                                        style={{height:"200px",width:"200px"}}
                                        />
                                    </Carousel.Item>
                                    
                                    
                                    <Carousel.Item >
                                        <img
                                        className="d-block w-100"
                                        src={photos.photo2}
                                        alt="Second slide"
                                        style={{height:"200px",width:"200px"}}
                                        />
                                    </Carousel.Item >
                                    
                                    
                                    <Carousel.Item >
                                        <img
                                        className="d-block w-100"
                                        src={photos.photo3}
                                        alt="Third slide"
                                        style={{height:"200px",width:"200px"}}
                                        />
                                    </Carousel.Item>
                                    
                                </Carousel>
                            </div>
            <div className="col-12 col-lg-6 mx-auto mt-2 ">
                
                <h3 className="text-secondary mx-auto mt-4">Selected Details</h3>
                <br />
                <div className="table-responsive">          
                <table className="table">
                    <tbody>
                    <tr>
                        
                    </tr>
                    </tbody>
                    <tbody>
                    <tr>
                        <td>Selected Product Name</td>
                        <td>{postData.product_name}</td>
                    </tr>
                    <tr>
                        <td>Selected Product Breed</td>
                        <td>{postData.product_breed}</td>
                    </tr>
                    <tr>
                        <td>Selected Product Status</td>
                        <td>{postData.product_status}</td>
                    </tr>

                    <tr>
                        <td>Product Quantity</td>
                        <td>{postData.product_quantity} - {postData.product_quantity_by}</td>
                    </tr>

                    <tr>
                        <td>Product Price</td>
                        <td>{postData.product_price} - {postData.product_price_by}</td>
                    </tr>

                    <tr>
                        <td>Product Packaging Availibility</td>
                        <td>{postData.packing_availibility} </td>
                    </tr>
                    <tr>
                        <td>Packaging Type</td>
                        <td>{postData.product_packing_type}</td>
                    </tr>
                    <tr>
                        <td>Product Packaging Charges bear by</td>
                        <td>{postData.packing_charges} </td>
                    </tr>

                    <tr>
                        <td>State</td>
                        <td>{postData.state} </td>
                    </tr>
                    <tr>
                        <td>District</td>
                        <td>{postData.district} </td>
                    </tr>
                    <tr>
                        <td>Block</td>
                        <td>{postData.block} </td>
                    </tr>
                    </tbody>
                </table>
                </div>
            </div>
            </div>
            </div>
    )
}

export default ReviewPost;