import React from 'react';
import {Link} from 'react-router-dom';
import {Button,Carousel} from 'react-bootstrap';
import CardImageCarousal from '../CardImageCarousal';
import {SoldButton,DeleteButton} from '../../utils/SoldOrDeleteButton'
import {API} from '../../config'
const ServiceCard = ({ad,classpassed = "",editButton=false}) => {
    return (
                
        <div className={`card ml-4  p-0 mt-2 ${classpassed}`} >

                        <CardImageCarousal addetail_url={`/item/detail/service-in-rent/${ad.id}/`} ad={ad} />
                   
                                
                    <div className="card-body">
                         <p className="m-1 " >
                             <span className="h6 text-md-left font-weight-bold font-size-card"> Machinary:</span>  <span className="font-size-card font-weight-normal font-size-card">{ad.service_machine.service_machine_name}</span>
                        </p>
                        <p className="m-1"><span className="font-weight-bold font-size-card"> Work : </span> <span className="font-size-card font-weight-normal ">{ad.select_work.work_name}</span></p>
                        <p className="m-1 " ><span className="h6 font-size-card">Price:</span> <span className="font-size-card font-weight-normal font-size-card">{ad.price} {ad.price_by.per}</span> </p>
                        <p className="m-1 " style={{fontSize:"0.8em"}}>State:<span className=" font-weight-normal ">{ad.state.state_name}</span> </p>
                        <p className="m-1 " style={{fontSize:"0.8em"}}>District: <span className=" font-weight-normal ">{ad.district.district_name}</span> </p>
                        <p className="m-1 " style={{fontSize:"0.8em"}}>Block: <span className=" font-weight-normal ">{ad.block.block_name}</span> </p>
                        <p className="m-1 " style={{fontSize:"0.8em"}}>Village: <span className=" font-weight-normal ">{ad.village}</span> </p>                                                
                        <p className="m-1  text-muted small font-size-card "><span className="">Posted on</span> <span className="small">{ad.posted_on}</span></p>
                        
                        
                        <div className="row mx-auto">
                        <Link className="m-auto " to={`/item/detail/service-in-rent/${ad.id}/`}>
                            <Button size="sm" className="text-white mx-auto btn small btn-outline-success mt-2 mx-2 mb-2" variant="success">
                                View Ad
                            </Button>
                        </Link>
                        {
                            editButton ?
                            <>
                            <Link className="m-auto" to={`/edit/item-details/service-in-rent/${ad.id}/`}>
                                <Button size="sm" className="text-white btn  btn-outline-success mt-2 mb-2" variant="success">
                                    Edit
                                </Button>
                            </Link>
                            <SoldButton url={`${API}/ads/serviceinrent/${ad.id}/`} />
                            <DeleteButton url={`${API}/ads/serviceinrent/${ad.id}/`} />                            
                            </>
                            :
                            ""
                        }
                        </div>
                    </div> 
                    </div>


    )
}

export default ServiceCard;