import { API } from '../config';


export const createCategory = (token,category) => {
    
    const fd = new FormData()
    fd.append('category_name',category.category_name)
    fd.append('image',category.category_image)
    console.log(`${API}/ads/add/category/`)
    return fetch(`${API}/ads/add/category/`,{
        method: "POST",
        headers: {
            Accept:'application/json',
              
            Authorization:"Token " + `${token}`
        },
        body: fd
    })
    .then(response=> {
        return response.json()
    })
    .catch(err => {
        console.log(err);
    ;})
};