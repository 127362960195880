import React, {useState, useEffect} from 'react';
import {getEKLanguage} from './language_utils';
import {strings} from '../utils/translated_strings';
import {API} from '../config';


export const StateComponent = () => {
    const [states,setStates] = useState([])

    const fetchStates = () => {
        let lang = getEKLanguage();
        console.log()
        fetch(`${API}/state/`,{
                method: "GET",
                headers: {
                    Accept:'application/json',
                    "Content-Type":"application/json",
                    "Accept-Language":`${lang}`                   
                }
            })
            .then(response=> {
                return response.json()
            })
            .then(data => setStates(data) )
            .catch(err => {
                console.log(err)
            })
    }
    
    useEffect(() => {
        fetchStates();
    }, []) 

    return (
        <React.Fragment>
            <option key="Select Your State">{strings.select_your_state}</option>
            {
                states.map(india_states => {
                        return <option  key={india_states.id}>{india_states.state_name}</option>
                })
            }
        </React.Fragment>
    )
}


export const fetchdistrict = (name) => {
    let lang = getEKLanguage();
    return fetch(`${API}/district/?search=${name}`,{
        method: "GET",
        headers: {
            Accept:'application/json',
            "Content-Type":"application/json",
            "Accept-Language":`${lang}`
        }
    })
}

export const fetchblocks = (name) => {
    let lang = getEKLanguage();
    return fetch(`${API}/block/?search=${name}`,{
        method: "GET",
        headers: {
            Accept:'application/json',
            "Content-Type":"application/json",
            "Accept-Language":`${lang}`
        }
    })
}

export const fetchStates = () => {
    let lang = getEKLanguage();
    return fetch(`${API}/state/`,{
            method: "GET",
            headers: {
                Accept:'application/json',
                "Content-Type":"application/json",
                "Accept-Language":`${lang}`                   
            }
        })
}
