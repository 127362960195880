import React from 'react'
import {Button} from 'react-bootstrap'
import {getToken, signout} from '../auth'

export const SoldButton = ({url}) => {
    return (
        <Button onClick={(e)=>{
            e.preventDefault()
            let token = getToken()
            var fd = new FormData()
            fd.append('sold',true)
            fetch(url,{
                method:'PUT',
                headers:{
                    Accept:'application/json',
                    Authorization:`Token ${token}`
                },
                body:fd
            })
            .then(response=>{
                return response.json()
            })
            .then(data=>{
                if(data.detail.toLowerCase().includes('token')) {
                    signout(()=>{
                        window.location.reload(false)
                    })
                }                 
                window.location.reload(false)
            }).catch(err=>{
                
            })
        }} size="sm" className="text-white btn m-auto btn-outline-success mt-2 mb-2" variant="success">
            Sold
        </Button>

    )
}

export const DeleteButton = ({url}) => {
    return (
        <Button onClick={(e)=>{
            e.preventDefault()
            let token = getToken()

            fetch(url,{
                method:'DELETE',
                headers:{
                    Accept:'application/json',
                    Authorization:`Token ${token}`
                }
            })
            .then(response=>{
                let j = response.json()
                return j
            })
            .then(data=>{
                if(data.detail) {
                    if(data.detail.toLowerCase().includes('token')) {
                        signout(()=>{
                            window.location.reload(false)
                        })
                    } 
                } else {
                    window.location.reload(false)
                }
                
            }).catch(err=>{
                 
            })
        }} size="sm" className="text-white btn m-auto btn-outline-success mt-2 mb-2" variant="success">
            Delete
        </Button>

    )
}

