import {API} from '../../config';
import {getEKLanguage} from '../../utils/language_utils';
import {getToken, isAuthenticated} from '../../auth'

export const fetchChemicalProducts = (chemical_type) => {
    let lang = getEKLanguage()
    
    return fetch(`${API}/ads/chemicalproducts/?search=${chemical_type}`,{
        "method":"GET",
        "headers":{
            Accept:'application/json',
            "Content-Type":"application/json",
            "Accept-Language":`${lang}`,
            
        }
    })
}

export const fetchChemicalTypes = () => {
    let lang = getEKLanguage()

    return fetch(`${API}/ads/chemicaltypes/`,{
        "method":"GET",
        "headers":{
            Accept:'application/json',
            "Content-Type":"application/json",
            "Accept-Language":`${lang}`,
            
        }
    })
}

export const fetchFnPAds = (id) => {
    let lang = getEKLanguage()
    let token = getToken()
    return fetch(`${API}/ads/fertilizersandpestisides/${id}/?toedit=optm/`,{
        "method":"GET",
        "headers":{
            Accept:'application/json',
            "Content-Type":"application/json",
            "Accept-Language":`${lang}`,
            "Authorization":`Token ${token}`
        }
    })
}