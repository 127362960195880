import React , {useState, useEffect} from 'react';
import {API} from '../config';
import {getEKLanguage} from '../utils/language_utils'
import BlogCard from './BlogCard';

const Blog = () => {

    const [bloglist,setBlogList] = useState([])
    const [next_url,setNextUrl] = useState('')
    const [error,setError] = useState(false)
    const [loading, setLoading] = useState(true)
    useEffect(()=>{

        let lang = getEKLanguage();
        fetch(`${API}/blogs/successstory/`,{
            method:"GET",
            headers:{
                Accept:'application/json',
                "Content-Type":"application/json",
                "Accept-Language":`${lang}`                 
            }
        })
        .then(response => {
            return response.json()
        })
        .then(data => {
            setBlogList(data.results);
            setLoading(false);
            setNextUrl(data.next);
            console.log(data.results);
        })
        .catch(err=>{
            console.log("-----------")
            setError(err)
        })

    },[])

    const showError = () => (
        <div className="alert alert-warning">
            <h3>Something went wrong!!</h3>
        </div> 
    )

    const work = () => (
        <>
        {
            error ? 
             showError()
             :
            
             <React.Fragment>
            {
              bloglist.map(d => {
                return (
                    
                    <BlogCard key={d.id} props={d} />
                )
              })
            }
            </React.Fragment>
        }
        </>
    )

    return (
        <React.Fragment>
            {
                loading ?
                ''
                :
                work()                 
            }
        </React.Fragment>
    )
}

export default Blog;