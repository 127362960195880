import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import {isAuthenticated} from './';

const PrivateRoute = ({component:Component, ...rest}) => {
    return (<Route 
        {...rest}
        render={
            props=> isAuthenticated() /*&&  isAuthenticated().is_staff === undefined*/  ? (
                <Component {...props} />
            ) :
            (
                isAuthenticated().is_staff ?
                <Redirect to={{
                    pathname:"/admin/signin",
                    state: { from:props.location }
                }} />
                :
                <Redirect to={{
                    pathname:"/",
                    state: { from:props.location }
                }} />
            )}
    />
    )
}

export default PrivateRoute;