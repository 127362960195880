import React,{useState,useEffect} from 'react';
import {Link} from 'react-router-dom';
import Moment from 'react-moment';
import {Button,Modal} from 'react-bootstrap'

import {API} from '../config'
import Card from './Card';
import CattleCard from './CattlePostAds/CattleCard';
import FnPCard from './FertilizersAndPestisidesAds/FnPCard';
import LabourCard from './LabourInRent/LabourCard'
import MachinaryCard from './MachineryPostAds/MachinaryCard'
import ServiceCard from './ServiceInRentPostAds/ServiceCard'
import TnWCard from './TreeAndWoodAds/TnWCard'
import Layout from './Layout';
import {getToken,signout} from '../auth'
//import ContactForm from 'ContactForm';
const UserOfferInformation = ({offer,postid}) => {

    const createCard = (results,i) => {
        if(results=== null || results === undefined) {
            return
        }
        if(results.type === 'agriculturalads')
        {
            return <Card ad={results} key={i} classpassed="offerwidth" />
        } else if(results.type === 'cattleads')
        {
          return <CattleCard ad={results} key={i}  classpassed="offerwidth"/>
        } else if(results.type === 'fertilizerandpestisidesads') {
          return <FnPCard ad={results}  key={i} classpassed="offerwidth"/>
        } else if(results.type === 'labourinrentads') {
          return <LabourCard ad={results} key={i} classpassed="offerwidth"/>
        } else if(results.type === 'machinaryads') {
          return <MachinaryCard ad={results} key={i} classpassed="offerwidth"/>
        } else if(results.type === 'serviceinrentads') {
          return <ServiceCard ad={results} key={i} classpassed="offerwidth"/>
        } else if(results.type === 'treeandwoodsads') {
          return <TnWCard ad={results} key={i} classpassed="offerwidth"/>
        }
    }
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [ad,setAd] = useState([])
    const [loading,setLoading] = useState(true)
    useEffect(()=>{
        let token = getToken()
        fetch(`${API}/ads/filterads/?postid=${postid}`,{
            method:'GET',
            headers:{
                Accept:'application/json',
                Authorization:`Token ${token}`
            }
        })
        .then(response=>{
            return response.json()
        })
        .then(data=>{
            if(data.detail) {
                if(data.detail.toLowerCase().includes('token')) {
                    signout(()=>{
                        window.location.reload(false)
                    })
                } else {
                    
                }
            }else {
                
                setAd(data.results)
                setLoading(false)
            }
        })
        .catch(err=>{
            
        })
    },[])
    return (
        <>
        {
        loading?'':
        ad.length>0?
            <div className="container mt-2">
                <div className="row flex flex-wrap" style={{border:"solid",borderWidth:"2px"}}>
                
                <div className="col-12 m-0 p-0 " >
                    
                    {
                        offer.fromuser.is_verified === true?
                        <div>
                        <p className="font-weight-normal float-right" style={{backgroundColor:"lightblue"}}>verified</p>
                        </div>
                        :''
                    }

                    <div className="d-flex flex-wrap">
                    
                            <div  className="col-lg-2 col-12 ml-2 my-3 ">
                                <img src={offer.fromuser.image}
                                alt = "user-profile"
                                style={{height:"200px",width:"200px"}}/>
                            </div>

                            <div className="col-lg-3 col-12 mx-auto ml-4 my-3">
                                <p className="h6">Offer By:</p>
                                <p className="m-auto p-0">{offer.fromuser.user.first_name} {offer.fromuser.user.last_name}</p>
                                <p className="m-auto p-0"><span className="h6">VID:</span>{offer.fromuser.vid}</p>
                                <hr />
                                <p><span className="m-0 p-0 h6">Offered Price : </span> {offer.offered_price} </p>
                                <p><span className=" m-0 p-0 h6">Contact Number :</span> {offer.phone_number}</p>
                                <p className="m-auto p-0"><span className="text-muted small">Posted on :
                                <Moment format="YYYY/MM/DD">{offer.fromuser.offer_date}</Moment>
                                </span>
                                </p>
                                <hr />

                                <p className="m-auto p-0"><span className="h6">State:</span> {offer.fromuser.state.state_name} </p>
                                <p className="m-auto p-0"><span className="h6">District:</span> {offer.fromuser.district.district_name} </p>
                                <p className="m-auto p-0"><span className="h6">Block:</span>{offer.fromuser.block.block_name}</p>
                                <p className="m-auto p-0"><span className="h6">Village:</span>{offer.fromuser.village}</p>    
                                
                                <p className="m-auto p-0"><span className="text-muted small">joined on :  
                                <Moment format="YYYY/MM/DD">{offer.fromuser.user.date_joined}</Moment>
                                </span>
                                </p>
                            </div>
                            <div className="col-lg-4 col-12 m-0 p-0" style={{height:"fit-content"}} >
                                {   
                                    ad ?
                                    createCard(ad[0])
                                    //JSON.stringify(ad)
                                    :
                                    ''
                                }
                            </div>                            
                            <div className="col-lg-2 col-12 my-auto">
                                <div className="">
                                    <Link to="/trust-and-safety">
                                        <Button variant="success" className="mb-2 mx-auto" size="sm">Contact Guidelines</Button>
                                    </Link>
                                </div>
                            </div>                                
                            
                    {/* <div className="container mt-4">
                        <div className="row flex flex-wrap">
                            
                    </div>                            
                    </div> */}

                    </div>
                </div>
        
            </div>
            </div>            
        :''    
        }
        </>
    )
}

export default UserOfferInformation;