import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import {isAuthenticated} from './';

const AdminRoute = ({component:Component, ...rest}) => (
    <Route 
        {...rest}
        render={
            props=> isAuthenticated() && isAuthenticated().is_staff ? (
                <Component {...props} />
            ) :
            (
                //JSON.stringify(isAuthenticated())
                <Redirect to={{
                    pathname:"/admin/signin",
                    state: { from:props.location }
                }} />
            )}
    />
)

export default AdminRoute;