import React, {  useState } from 'react';
import Menu from '../core/Menu';
import {isAuthenticated} from '../auth';
import { Redirect } from 'react-router-dom'
import Signup from './SignUp';

const SignupPage = () => {
    const authstatus = isAuthenticated()
    return (
        
        <React.Fragment>
        {
            (authstatus === false) ?
            <>
            <Menu />
            <div className="col-lg-6 mx-auto rounded">
                <Signup />
            </div>
            </>
            :
            <Redirect to="/"></Redirect>
        }
        </React.Fragment>
            
    )
}

export default SignupPage;