import React, {useState,useEffect} from 'react';
import { Col, Row} from 'react-bootstrap';
import {Form,FormControl,Button,Spinner} from 'react-bootstrap';
import {getProductPackaging ,getProduct,getBreed,getStatus,getCategories} from '../utils/Apicore'
import {strings} from '../utils/translated_strings'
//import Category from '../core/Category'
import Menu from '../core/Menu'
//import Link from 'react-dom';
import {API} from '../config';
import {getEKLanguage} from '../utils/language_utils'
import {validateSession,isAuthenticated, signout} from '../auth/'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faSearch } from '@fortawesome/free-solid-svg-icons'

import ReviewPost from './ReviewPost';
import {getAdvertisement} from '../utils/Apicore'
import {fetchStates,fetchdistrict,fetchblocks} from '../utils/AreaUtils'
import {Redirect} from 'react-router-dom';
import Layout from './Layout';


const EditDetails = (props) => {
    const [adposted,setAdPosted] = useState(false)
    const InitState = {
        id:'',
        category_name:'',
        product_name:'',
        product_breed:'',
        product_status:'',
        product_quantity:0,
        product_quantity_by:'',
        product_price:0,
        product_price_by:'',
        packing_availibility:'',
        product_packing_type:'',
        packing_charges:"",
        photo1:"",
        photo2:"",
        photo3:"",
        state:'',
        district:'',
        block:'',
        village:''
    }
    const [data,setData] = useState(InitState)

    const [loading,setLoading] = useState(false)

    const ErrorInitState = {
        category_name_error:'',
        product_name_error:'',
        product_breed_error:'',
        product_status_error:'',
        product_quantity_error:'',
        product_packaging_type_error:'',
        product_price_error:'',
        state_error:'',
        district_error:'',
        block_error:''
    }

    const [errorStates,setErrorStates] = useState(ErrorInitState)
    const [review,setReview] = useState(false)
    const [photos,setPhotos] = useState({
        photo1:"https://via.placeholder.com/300",
        photo2:"https://via.placeholder.com/300",
        photo3:"https://via.placeholder.com/300"
    })
    const [products,setProducts] = useState([])
    const [breeds,setBreeds] = useState([])
    const [categories,setCategories] = useState([])
    const [packagingtype,setPackagingType] = useState([])
    const [productstatus,setProductStatus] = useState([])
    const [error,setError] = useState(false)
    const [selectedCategory,setSelectedCategory] = useState([])
    const [states,setStates] = useState([])
    const [districts,setDistricts] = useState([])
    const [blocks,setBlocks] = useState([])

    const [searchtext,setSearchText] = useState('') 
    
    const [changeit, setChangeit] = useState(false)

    
    const handleStateChange = (name,func,setFunc) => (event) => {
        setData({...data,[name]:event.target.value })
        func(event.target.value).then(response=> {
            return response.json()
        })
        .then(data => setFunc(data))
        .catch(err => {
            console.log(err)
        });
    }

    const destructAndSetAllData = (d) => {
        handleCategorySelection(d.product.category.category_name)
        
        let product_packing = ''
        if(d.product_packaging_type !== null)
            product_packing = d.product_packaging_type.product_packaging_type
        setData({ id:d.id, category_name : d.product.category.category_name, product_name  : d.product.product_name, product_breed : d.product_breed.product_breed,
        product_status : d.product_status.product_status,
        product_breed  : d.product_breed.product_breed,
        product_quantity : d.product_quantity,
        product_quantity_by:d.product_quantity_by,
        product_price:d.price,
        product_price_by:d.product_price_by,
        packing_availibility:d.product_packaging_available,
        product_packing_type:product_packing,
        packing_charges:d.packaging_cost_bearer,
        state :d.state.state_name,
        district :d.district.district_name,
        block:d.block.block_name,
        additional_information:d.additional_information,
        village:d.village
        })
        if(d.product_packing_type === null | d.product_packaging_type === undefined)
            setData({...data,product_packing_type:''})
        // InitImage('photo1',d.product_image1)
        // InitImage('photo2',d.product_image2)
        // InitImage('photo3',d.product_image3)
        
        let photo1, photo2, photo3;
        if(d.product_image1 === null) {
            photo1 = photos.photo1
        } else {
            photo1 = d.product_image1
        }
        if(d.product_image2 === null) {
            photo2 = photos.photo2
        } else {
            photo2 = d.product_image2
        } 
        if(d.product_image3 === null ) {
            photo3 = photos.photo3
        } else {
            photo3 = d.product_image3
        }
        setPhotos({photo1,photo2,photo3})
        
        fetchdistrict(d.state.state_name)
        .then(response => {
            return response.json()
        })
        .then(edistricts => {
            setDistricts(edistricts)
        })
        .catch(err=> {
            setError(err)
        })
        fetchblocks(d.district.district_name)
        .then(response => {
            return response.json()
        })
        .then(eblocks => {
            setBlocks(eblocks)
        })
        .catch(err=> {
            setError(err)
        })
    }

    const InitImage = (pname,pImage) => {
        
        setPhotos({...photos,[pname]:pImage})
        // const reader =new FileReader()
        // reader.onload = () => {
        //     if(reader.readyState === 2) {
        //         setPhotos({...photos,[name]:reader.result})
        //     }
        // }
        // reader.readAsDataURL(pImage)
    }
    useEffect(() => {
        validateSession()
        .then(response => {
            return response.json()
        }).then(data => {
            if(data.detail){
                setError("You are not logged in")    
            }
        }).catch(err=>{
            setError("You are not logged in")    
        })        
        getCategories()
        .then(response => {
            return response.json()
        })
        .then(data => [setCategories(data)])
        .catch(err=> {
            setError(err)
        })

        getStatus()
        .then(response => {
            return response.json()
        })
        .then(data => {setProductStatus(data)})
        .catch(err => {
            setError(err)
        })

        fetchStates().then(response => {
            return response.json()
        }).then(state_data => {
            setStates(state_data)
        }).catch(err => {
            setError(err)
        })

        getProductPackaging()
            .then(response => {
                return response.json()
            })
            .then(data => {
                setPackagingType(data)
            })
            .catch(err=>{
                setError(err)
        })

        setChangeit(false)
        getAdvertisement(props.match.params.id)
        .then(response => {
            return response.json()
        })
        .then(d => {
            if(d.detail) {
                setError(d.detail)
            }
            else {
                destructAndSetAllData(d)
            }
        })
        setLoading(false)
    }, [])

    

    useEffect(()=>{
        getBreed(data.product_name)
        .then(response => {
            return response.json()
        })
        .then(data => {
            setBreeds(data);
        })
    },[data.product_name])   

    

    const getProduct = (category_name) => {
        const lang = getEKLanguage()
        fetch(`${API}/ads/products/?search=${category_name}`,{
            method: "GET",
            headers: {
                Accept:'application/json',
                    "Content-Type":"application/json",
                    "Accept-Language":`${lang}`                   
                }
            })
            .then(response=> {
                return response.json()
            })
            .then(data =>  {
                //setValues({...values,next_url:null,prev_url:null,result_count:data.count })
                setProducts([...products,...data])
            })
            .catch(err => {
                setError(true);
                console.log(err)
            })        
    }
    
    const handleCategorySelection = (selected) => {    
        setData({...data,category_name:selected})
        
        getProduct(selected)
    }

    const handleSelection = (name) => (e) => {
        
        setData({...data,[name]:e.target.value})
    }

    const handleCategory = (name)=> (e) => {
        handleCategorySelection(e.target.value)
        
    }

    const handlePackaging = (name) => (e) => {
        if(e.target.value === "Yes"){
            //fetch packaging type
            
        }
        setData({...data,[name]:e.target.value})
    }

    const handleImageSelection = (name) => (e) => {
        e.preventDefault()
        //let f = e.target.files[0]
        const reader =new FileReader()
        reader.onload = () => {
            if(reader.readyState === 2) {
                setPhotos({...photos,[name]:reader.result})
            }
        }
        reader.readAsDataURL(e.target.files[0])
        setData({...data,[name]:e.target.files[0]})
        //setData({...data,[name]:f})
    }  

    const isValid = () =>{
        let flag = true;
        let category_name_error='';
        let product_name_error='';
        let product_breed_error='';
        let product_status_error='';
        let product_quantity_error='';
        let product_packaging_type_error='';
        let product_price_error='';
        let state_error='';
        let district_error='';
        let block_error='';
        let additional_information_error ='';
        if(data.product_name==='')
        {
            product_name_error="Please select product name";
        }
        if(data.category_name==='')
        {
            category_name_error="Please select category";
        }
        if(data.product_breed==='')
        {
            product_breed_error="Please select product breed";
        }
        if(data.product_quantity <= 0 )
        {
            product_quantity_error="Please enter correct quantity";
        }
        if(data.packing_availibility==='Yes' && data.product_packaging_type === '')
        {
            product_packaging_type_error="Please select product packaging type";
        }
        if(data.product_status==='')
        {
            product_status_error="Please select product status";
        }
        if(data.product_price <= 0) {
            product_price_error="Please enter valid price for your product"
        }
        if(data.state === '')
        {
            state_error="Please select your state name"
        }
        if(data.district ==='')
        {
            district_error = "Please select your district"
        }
        if(data.block === '')
        {
            block_error = 'Please select your block'
        }
        if(data.additional_information.length > 500)
        {
            additional_information_error='Character Limit 500 words'
            flag = false
        }
        setErrorStates({category_name_error,product_name_error,product_breed_error,product_status_error,    product_quantity_error,product_packaging_type_error,product_price_error,state_error,district_error,block_error,additional_information_error})
        return true
    }
    
    const submitAdvertisement = () => {
        let lang = getEKLanguage()
        let token = isAuthenticated()   
        token = token.token
        var fd = new FormData();
        fd.append('product_name',data.product_name)
        fd.append('product_breed',data.product_breed)
        fd.append('product_status',data.product_status)
        fd.append('product_quantity',data.product_quantity)
        fd.append('product_quantity_by',data.product_quantity_by)
        fd.append('product_price',data.product_price)
        fd.append('product_price_by',data.product_price_by)
        fd.append('additional_information' , data.additional_information)
        fd.append('packing_availibility',data.packing_availibility)
        fd.append('product_packing_type',data.product_packing_type)
        fd.append('packing_charges',data.packing_charges)
        fd.append('village',data.village)
        fd.append('photo1',data.photo1)
        fd.append('photo2',data.photo2)
        fd.append('photo3',data.photo3)
        
        fd.append('state',data.state)
        fd.append('district',data.district)
        fd.append('block',data.block)

        fetch(`${API}/ads/agriads/${data.id}/`,{
            method: "PUT",
            headers: {
                Accept:'application/json',
                
                "Accept-Language":`${lang}`,
                Authorization:`Token ${token}`
            },
            body: fd
        })
        .then(response => {
            return response.json()
        })
        .then( data => {
            if(data.detail) {
                if(data.detail.toLowerCase().includes('token')) {
                    signout(()=>{
                        window.location.reload(false)
                    })
                } else {
                    setError(data.detail)
                }
            } else {
             setId(data.id)
            }
        })
        .catch(err => {
                       
            setError(err)
        })
    }
    const [id,setId] = useState(false)
    const handleSubmit = (e) => {
        e.preventDefault();
        if(isValid()){
            setAdPosted(true)
            submitAdvertisement()
            setErrorStates(ErrorInitState)
        }
    }

    const handleReview = (e) => {
        e.preventDefault()
        if(review === true){
            setReview(false)
            return
        }
        if(isValid())
        {
            setReview(true)
        }
        else{
            setReview(false)
        }
    }
    const AdSell = () => {
        return (
            <React.Fragment>

            <Form className="mt-4 ml-auto" onSubmit={handleSubmit}>
                <h3 className="gray heading text-secondary">Edit Advertisement details </h3>
                <hr/>
                <Form.Row>
                <Form.Label column sm="4">
                Selected Category
                </Form.Label>
                

                    <React.Fragment>
                        <p className="h6 text-muted">{data.category_name}</p>
                    </React.Fragment>

                    <div style={{fontSize:"0.8em",color:"red"}}>{errorStates.category_name_error}</div>
                    </Form.Row>
                    <Form.Row>
                        <Form.Label column sm="4">
                                Selected Product name
                        </Form.Label>                
                        <FormControl as="select" value={data.product_name} onChange={handleSelection("product_name")} size="sm" className="col-lg-4 mt-2">                        
                        
                            {
                                products.map(d => {
                                    return <option key={d.id}>{d.product_name}</option>
                                })
                            }
                        </FormControl>            
                        <div style={{fontSize:"0.8em",color:"red"}}>{errorStates.product_name_error}</div>
                    </Form.Row>
                    <Form.Row>
                        <Form.Label column sm="4">
                                Select Product Breed
                        </Form.Label>                
                        <FormControl as="select"  value={data.product_breed} onChange={handleSelection("product_breed")} size="sm" className="col-lg-4 mt-2">
                            
                            {
                                breeds.map(d => {
                                    return <option key={d.id}>{d.product_breed}</option>
                                })
                            } 
                        </FormControl>            
                        <div style={{fontSize:"0.8em",color:"red"}}>{errorStates.product_breed_error}</div>
                    </Form.Row>                    
                    <Form.Row>
                        <Form.Label column sm="4">
                                Select Product Status
                        </Form.Label>                
                        <FormControl size="sm" as="select" value={data.product_status} onChange={handleSelection("product_status")} className="col-lg-4 mt-2">
                            
                            {
                                productstatus.map(d => {
                                    return <option key={d.id}>{d.product_status}</option>
                                })
                            } 
                        </FormControl>            
                        <div style={{fontSize:"0.8em",color:"red"}}>{errorStates.product_status_error}</div>
                    </Form.Row>
                    <Form.Row>
                        <Form.Label column sm="4">
                                Quantity
                        </Form.Label>                
                        <FormControl type="text"  value={data.product_quantity}  onChange={handleSelection("product_quantity")} size="sm" className="mt-2 mr-sm-2" size="sm" style={{width:"60px",height:"40px!important"}} placeholder="Enter"/>
                        <div style={{fontSize:"0.8em",color:"red"}}>{errorStates.product_packaging_type_error}</div>
                        <FormControl as="select" value={data.product_quantity_by} size="sm" onChange={handleSelection("product_quantity_by")} size="sm" className="mt-2 col-lg-2" >
                            <option key="Quintal">Quintal</option> 
                            <option key="Kg">Kg</option> 
                            <option key="Pieces">Pieces</option> 
                            <option key="litre">litre</option> 
                            <option key="Gram">Gram</option> 
                            <option key="Ton">Ton</option> 
                            <option key="Trolley">Trolley</option> 
                            <option key="Truck">Truck</option> 
                        </FormControl>            

                        <div style={{fontSize:"0.8em",color:"red"}}>{errorStates.product_quantity_error}</div>
                    </Form.Row>    
                    <Form.Row>
                        <Form.Label column sm="4">
                                Price
                        </Form.Label>                
                        <FormControl type="text"  value={data.product_price} onChange={handleSelection("product_price")} className="mt-2 mr-sm-2" size="sm" style={{width:"60px",height:"35px!important"}} />
                        <FormControl as="select" size="sm" value={data.product_price_by}  onChange={handleSelection("product_price_by")} className="mt-2 col-lg-2" >
                                <option key="Per Quintal">Per Quintal</option>
                                <option key="Per kg">Per Kg</option>
                                <option key="Per Pieces">Per Pieces</option> 
                                <option key="Per litre">Per litre</option> 
                                <option key="Per Gram">Per Gram</option> 
                                <option key="Per ton">Per Ton</option> 
                                <option key="Per Trolly">Per Trolley</option> 
                                <option key="Per Truck">Per Truck</option> 
                        </FormControl>            
                        <div style={{fontSize:"0.8em",color:"red"}}>{errorStates.product_price_error}</div>
                    </Form.Row>                        
                    <Form.Row>
                        <Form.Label column sm="4">
                                Packing Availibility
                        </Form.Label>                
                        <FormControl  as="select" size="sm" value={data.packing_availibility} onChange={handlePackaging("packing_availibility")} size="sm" className="col-lg-1 mt-2 mr-sm-2" placeholder="Enter">
                            <option>Yes</option>
                            <option >No</option>
                        </FormControl>
                        {
                            data.packing_availibility === "Yes" ?
                            <>
                                <FormControl as="select" size="sm" value={data.product_packing_type} size="sm" onChange={handleSelection("product_packing_type")} size="sm" className="mt-2 col-lg-3" >
                                    <option key="select packing"> Select Product Packaging</option>
                                    {
                                        packagingtype.map(d => {
                                            return <option key={d.id}>{d.product_packaging_type}</option>
                                        })
                                    } 
                                </FormControl>
                                <div style={{fontSize:"0.8em",color:"red"}}>{errorStates.product_packaging_type_error}</div>
                            </>
                                    : ""
                        
                        }
                    </Form.Row>    
                    <Form.Row>
                        <Form.Label column sm="4">
                                Who will pay for packing and loading labour charges
                        </Form.Label>                
                        <FormControl as="select" value={data.packing_charges} onChange={handleSelection("packing_charges")} size="sm" className="col-lg-4 mt-2">
                            <option>Buyer</option>
                            <option>Seller</option>
                        </FormControl>            
                    </Form.Row>
                    <Form.Row>
                        <Form.Label column sm="4">
                                Select Your State
                        </Form.Label>                
                        <FormControl as="select"  value={data.state} onChange={handleStateChange("state",fetchdistrict,setDistricts)} size="sm" className="col-lg-4 mt-2">
                                {
                                    states.map(s => {
                                        return <option key={s.id}>{s.state_name}</option>
                                    })
                                }
                        </FormControl>            
                        <div style={{fontSize:"0.8em",color:"red"}}>{errorStates.state_error}</div>
                    </Form.Row>
                    <Form.Row>
                        <Form.Label column sm="4">
                                Selected Your District
                        </Form.Label>                
                        <FormControl as="select" value={data.district} onChange={handleStateChange("district",fetchblocks,setBlocks)} size="sm" className="col-lg-4 mt-2">
                                
                                {
                                    districts.map(d => {
                                        return <option key={d.id}>{d.district_name}</option>
                                    })
                                }
                        </FormControl>            
                        <div style={{fontSize:"0.8em",color:"red"}}>{errorStates.district_error}</div>
                    </Form.Row>
                    <Form.Row>
                        <Form.Label column sm="4">
                                Selected Your Block
                        </Form.Label>                
                        <FormControl as="select" value={data.block} onChange={handleSelection("block")} size="sm" className="col-lg-4 mt-2">
                                
                                {
                                    blocks.map(d => {
                                        return <option key={d.id}>{d.block_name}</option>
                                    })
                                }
                        </FormControl>            
                        <div style={{fontSize:"0.8em",color:"red"}}>{errorStates.blocks_error}</div>
                    </Form.Row>     
                    
                    <Form.Row>
                        <Form.Label column sm="4">
                                Enter your village name
                        </Form.Label>                
                        <FormControl size="sm" type="text" value={data.village} onChange={handleSelection("village")} size="sm" className="col-lg-4 mt-2" />
                    </Form.Row>                                    
                    
                    <Form.Row>
                        <Form.Label column sm="4">
                            Additional Information
                        </Form.Label>
                    <FormControl as="textarea" style={{resize:"none"}} rows={3}  value={data.additional_information} onChange={handleSelection("additional_information")} className="col-lg-4 mt-2 mr-sm-2" size="sm"  placeholder=""/>
                    <div style={{fontSize:"0.8em",color:"red"}}>{errorStates.additional_information_error}</div>
                    </Form.Row>                                                                            
                    <Form.Row>
                        <Form.Label column sm="4">
                                Upload Images 
                        </Form.Label>
                    </Form.Row>                    
                    <Form.Row className="m-auto">
                        <div className="col-3">
                        <label className=" btn btn-default btn-outline-secondary btn-sm" >
                            Click to Browse <input type="file" hidden onChange={handleImageSelection("photo1")}  className="mt-2"/>
                        </label>
                        <img src={photos.photo1}
                            style={{height:"100px",width:"100px"}} alt="photo1"/>
                        </div>
                        <div className="col-3 mb-2">
                        <label className="btn btn-default btn-outline-secondary btn-sm">
                            Click to Browse <input type="file" hidden onChange={handleImageSelection("photo2")}  className="mt-2"/>
                        </label>
                        <img src={photos.photo2}
                            style={{height:"100px",width:"100px"}} alt="photo2"/>                        
                        </div>
                        {/* //style={{display:"inline-block",width:"100px",height:"100px",backgroundImage:`url(${data.photo3})`}} */}
                        <div className="col-3 mb-2">
                        <label className="btn btn-default btn-outline-secondary btn-sm" >
                            Click to Browse<input type="file" hidden onChange={handleImageSelection("photo3")}  className="mt-2"/>
                        </label>
                        <img src={photos.photo3}
                            style={{height:"100px",width:"100px"}} alt="photo1"/>                        
                        </div>
                        {/* <FormControl type="file" size="sm"  onChange={handleImageSelection("photo1")} name="" className=" mt-2"/>
                        <FormControl type="file" size="sm" style={{display:"inline-block",width:"300px",backgroundImage:`url(${data.photo2})`}} onChange={handleImageSelection("photo2")} name="" className=" mt-2"/>
                        <FormControl type="file" size="sm" style={{display:"inline-block",width:"100px",height:"10px",backgroundImage:`url(${data.photo3})`}} onChange={handleImageSelection("photo3")} name="" className="ml-4 mt-2"/> */}
                           
                    </Form.Row>
                    <div className="form-row mt-4">
                        <div className="col-3">
                            <Button  variant="info" onClick={handleReview}>Review</Button>
                        </div>
                        <div className="col-3 ml-auto">
                        <Button  className=" " type="submit" variant="info">
                                {
                                    adposted?
                                    <>
                                        <Spinner animation="border" role="status">
                                        <span className="sr-only">Saving...</span>
                                        </Spinner>                                    
                                    </>:

                                    <span>Submit</span>
                                }
                            </Button>
                        </div>
                    </div>
            </Form>
            {redirectToItem()}
            </React.Fragment>
        )
    }
    const redirectToItem = () => {
        if(id) {
            return <Redirect to={`/item/detail/agricultural-products/${id}`} />
        }
    } 
    const triggerSearch = (query) => (e) => {
        e.preventDefault();
        handleSearch({searchtext});   
    }    

    const handleSearch = (query) => {
        const lang = getEKLanguage()
        fetch(`${API}/ads/products/?search=`+query.searchtext,{
          method: "GET",
          headers: {
              Accept:'application/json',
                "Content-Type":"application/json",
                "Accept-Language":`${lang}`                   
          }
      })
      .then(response=> {
        return response.json()
      })
      .then(data => [ 
            //setSearchResult({...searchResult,results:data.results,next_url:data.next})
            
            setCategories([data[0].category])
        ])
      .catch(err => {
          
          setError(true);
      })
      
    }

    return (
        <Layout>
        
        {
            loading ? ""
            :
        (
        review ?
            <>
            
            <ReviewPost postData={data} photos={photos}/>
            <div className="container">
                <div className="row">
                <Button  className="m-auto" variant="info" onClick={handleReview}>Go back to edit</Button>
                <Button onClick={handleSubmit} className=" " type="submit" variant="info">
                                {
                                    adposted?
                                    <>
                                        <Spinner animation="border" role="status">
                                        <span className="sr-only">Saving...</span>
                                        </Spinner>                                    
                                    </>:

                                    <span>Submit</span>
                                }
                            </Button>
                </div>
            </div>
            </>
        :
            <>
            {

            error?
            <div className="alert alert-warning col-6 mx-auto">
                <h3>{error}</h3>
            </div>
            :
                    <div className="col-lg-10 col-12 ml-auto" >
                        
            
                            {AdSell()  }
                    </div>
            
            }
            </>
        )
    }
        </Layout>
    )
}

export default EditDetails;