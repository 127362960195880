import React from 'react';
import {
    BrowserRouter,
    Switch,
    Route
  } from "react-router-dom";

import PrivateRoute from './auth/PrivateRoute';
import AdminRoute from './auth/AdminRoute';
import AdminDashboard from './AdminFunctions/AdminDashboard';
import AdminSignIn from './AdminFunctions/AdminSignIn'
import UserDashboard from './core/UserDashboard';
import AddCategory from './AdminFunctions/AddCategory';
import AdDetails from './core/AdDetails'
import PostAd from './core/PostAd'
import Chat from './chat/chat'

import Home from './core/Home';
import SignupPage from './user/SignupPage';
import SigninPage from './user/SigninPage';
import EditDetails from './core/EditDetails'
import CattleEditDetails from './core/CattlePostAds/CattleEditDetails'
import FertilizersAndPesitisidesEditPost from './core/FertilizersAndPestisidesAds/FertilizersAndPesitisidesEditPost'
import FertizersAndPestisidesDetails from './core/FertilizersAndPestisidesAds/FertizersAndPestisidesDetails'
import TreeAndWoodEditAds from './core/TreeAndWoodAds/TreeAndWoodEditAds';
import TreeAndWoodAdDetails from './core/TreeAndWoodAds/TreeAndWoodDetails';

import LabourInRentEditAds from './core/LabourInRent/LabourInRentEditAds'
import LabourInRentDetails from './core/LabourInRent/LabourInRentDetails'

import ServiceInRentDetails from './core/ServiceInRentPostAds/ServiceInRentDetails';
import ServiceInRentEditAds from './core/ServiceInRentPostAds/ServiceInRentEditAds';
import MachineryPostDetails from './core/MachineryPostAds/MachineryPostDetails'
import MachineryEditAds from './core/MachineryPostAds/MachineryEditPost';
import OtherProductsDetails from './core/OtherProducts/OtherProductsDetails'
import OtherProductsEdit from './core/OtherProducts/OtherProductsEdit'

import MyAds from './core/MyAds';
// import AdResponse from './core/AdResponse';
import Blog from './blog/Blog';

import CattleAdDetais from './core//CattlePostAds/CattleAdDetails'

import BuyBackHome from './core/BuyBackHome';
import BuyOfferHome from './core/BuyOffer';
import BeVerifiedUser from './footer/BeVerifiedUser';
import GeneralFAQ from './footer/GeneralFAQ';
import AdPostingGuidelines from './footer/AdPostingGuidelines';
import ItemsNotAllowed from './footer/ItemsNotAllowed';
import TermsOfUseOfthisPlatform from './footer/TermsOfUseOfthisPlatform';
import LegalAndPrivacyPolicy from './footer/LegalAndPrivacyPolicy';
import ResetPassword from './core/ResetPassword'

// import
import Aboutus from './core/Aboutus'
import ContactUs from './core/ContactUs'
import MyOffer from './core/MyOffer'
import Notification from './core/Notifications'
import CustomerProfile from './core/CustomerProfile';
import BuyFAQ from './footer/BuyFAQ';
import TrustAndSafety from './footer/TrustAndSafety';
//import Advertisement from './core/Advertisement';

  const Routes = () => {
      return (
          <BrowserRouter>
              <Switch>
                  <Route path="/admin/signin" exact component={AdminSignIn} />   
                  <AdminRoute path="/admin/dashboard" exact component = {AdminDashboard} />

                  <Route path="/" exact component={Home} />
                  <Route path="/BuyBackOffer" component={BuyBackHome} />
                  <Route path="/BuyOffer" component={BuyOfferHome} />
                  
                  <Route path="/signup" exact component={SignupPage} />
                  <Route path="/item/detail/agricultural-products/:id" exact component={AdDetails} />
                  <Route path="/item/detail/cattles/:id" exact component={CattleAdDetais} />
                  <Route path="/item/detail/fertilizers-and-pestisides/:id" exact component={FertizersAndPestisidesDetails} />
                  <Route path="/item/detail/tree-and-wood/:id" exact component={TreeAndWoodAdDetails} />
                  <Route path="/item/detail/labours-in-rent/:id" exact component={LabourInRentDetails} />
                  <Route path="/item/detail/service-in-rent/:id" exact component={ServiceInRentDetails} />
                  <Route path="/item/detail/agricultural-machinary/:id" exact component={MachineryPostDetails} />
                  <Route path="/item/detail/other-agri-products/:id" component={OtherProductsDetails} />
                  
                  <AdminRoute path="/create/category" exact component = {AddCategory} />
                  <PrivateRoute path="/user/dashboard" exact component = {UserDashboard} />
                  <PrivateRoute path="/postad/:super_category" exact component = {PostAd} />
                  

                  
                  <Route path="/signin" exact component = {SigninPage} />

                  <PrivateRoute path="/edit/item-details/agricultural-products/:id" exact component = {EditDetails} />
                  <PrivateRoute path="/edit/item-details/cattles/:id" exact component = {CattleEditDetails} />
                  <PrivateRoute path="/edit/item-details/fertilizers-and-pestisides/:id" exact component ={FertilizersAndPesitisidesEditPost} />
                  <PrivateRoute path="/edit/item-details/tree-and-wood/:id" exact component ={TreeAndWoodEditAds} />
                  <PrivateRoute path="/edit/item-details/labours-in-rent/:id" exact component={LabourInRentEditAds} />
                  <PrivateRoute path="/edit/item-details/service-in-rent/:id" exact component={ServiceInRentEditAds} />
                  <PrivateRoute path="/edit/item-details/agricultural-machinary/:id" exact component={MachineryEditAds} />
                  <PrivateRoute path="/edit/item-details/other-agri-products/:id" component={OtherProductsEdit} />
                  

                  <Route path="/success-story/"  component = {Blog} />
                  <PrivateRoute path="/mynotifications/"  component = {Notification} />
                  <PrivateRoute path="/myoffers/"  component = {MyOffer} />
                  <PrivateRoute path="/user/myads" component = {MyAds} />

                  
                  
                  <Route path="/customerprofile/:vid" component = {CustomerProfile} />

                  <Route path="/legalandprivacypolicy" component= {LegalAndPrivacyPolicy} />
                  <Route path="/contactus" component = {ContactUs} />
                  <Route path="/Aboutus" component = {Aboutus} />
                  <Route path="/beverifieduser" component = {BeVerifiedUser} />
                  <Route path="/adpostingguidelines" component = {AdPostingGuidelines} />
                  <Route path="/generalfaq" component = {GeneralFAQ} />
                  <Route path="/itemsnotallowed" component = {ItemsNotAllowed} />
                  <Route path="/terms-to-this-platform" component= {TermsOfUseOfthisPlatform} />
                  <Route path="/trust-and-safety" component={TrustAndSafety} />
                  <Route path="/buyerfaq" component={BuyFAQ} />
                  <Route Path="/resetpassword" component={ResetPassword} />
                  
                  
              </Switch>
          </BrowserRouter>
      )
  }
  

  export default Routes;