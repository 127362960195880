import {API} from '../../config';
import {getEKLanguage} from '../../utils/language_utils';
import {getToken, isAuthenticated} from '../../auth'

export const fetchServiceWork = (service) => {
    let lang = getEKLanguage()
    
    return fetch(`${API}/ads/servicework/?search=${service}`,{
        "method":"GET",
        "headers":{
            Accept:'application/json',
            "Content-Type":"application/json",
            "Accept-Language":`${lang}`,
        }
    })
}

export const fetchServiceStatus = () => {
    let lang = getEKLanguage()
    let token = isAuthenticated().token;
    return fetch(`${API}/ads/servicestatus/`,{
        "method":"GET",
        "headers":{
            Accept:'application/json',
            "Content-Type":"application/json",
            "Accept-Language":`${lang}`,
        }
    })
}

export const fetchChargesBy = () => {
    let lang = getEKLanguage()
    return fetch(`${API}/ads/charges/`,{
        "method":"GET",
        "headers":{
            Accept:'application/json',
            "Content-Type":"application/json",
            "Accept-Language":`${lang}`,
        }
    })
}

export const fetchServiceMachine = () => {
    let lang = getEKLanguage()

    return fetch(`${API}/ads/servicemachinary/`,{
        "method":"GET",
        "headers":{
            Accept:'application/json',
            "Content-Type":"application/json",
            "Accept-Language":`${lang}`,
        }
    })
}


export const fetchServiceInRentAds = (id) => {
    let lang = getEKLanguage()
    let token = getToken()
    return fetch(`${API}/ads/serviceinrent/${id}/?toedit=optm/`,{
        "method":"GET",
        "headers":{
            Accept:'application/json',
            "Content-Type":"application/json",
            "Accept-Language":`${lang}`,
            "Authorization":`Token ${token}`
        }
    })
}