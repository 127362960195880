import React,{useState,useEffect} from 'react';
import { Form,FormControl} from 'react-bootstrap';
import {strings} from '../utils/translated_strings';
import { setEKLanguage,getEKLanguage } from './language_utils';
import {API} from '../config'



export const UserLanguage = () => {

    const [langs,setLangs] = useState(['']);
    //const [selectedLang,setSelectedLanguage] = useState('en');

    function fetchLanguages() {
        
        return fetch(`${API}/supportedlanguages/`,{
            method: "GET",
            headers: {
                Accept:'application/json',
                "Content-Type":"application/json"
            },
        })
        .then(response=> {
            return response.json()
        })
        .then(data => [setLangs(data)])
        .catch(err => {
             console.log(err); 
        ;})
    };

    useEffect(() => {
        fetchLanguages();
    }, []);

    const handleChange = (e)  => {
        setEKLanguage(e.target.value);
        window.location.reload(false);
    }

    return (
        <React.Fragment>
            <Form inline className=" mr-auto">
            <FormControl as="select"  size="sm" onChange={e => handleChange(e)}>
                <option value={getEKLanguage()} hidden key="empty">{strings.selectLanguage}</option>
                {
                    langs.map( (l,i)  => { 
                        return <option  value={l.lang_code} key={i} >{l.lang_name}</option>
                    } )
                }
            </FormControl>
            </Form>
            
            
        </React.Fragment>
    )
    
};
