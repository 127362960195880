import LocalizedStrings from 'react-localization';
export const strings = new LocalizedStrings({
    en:{
        english:"English",
        selectLanguage:"Select Your Language",
        phonenumber:"Your Phone Number",
        password:"Password",
        login:"Login",
        register:"Register",
        forgotpassword:"forgot password?",
        select_your_state:"Select Your State*",
        select_your_district:"Select Your District*",
        select_your_block:"Select Your Block*",
        account_success:"Your account is successfully created. Please signin",
        register_with_us:"Register with us",
        connect_with_digital_krishi_bazar:"Connect with Digital Krishi Bazar",
        why_register_with_us:"Why Register with us",
        your_first_name:"Your First Name*",
        your_last_name:"Your Last Name*",
        your_mobile_number:"Your mobile number *",
        your_password:"Your Password",
        enter_your_village_name:"Enter your village name",
        find_agriculture_product_and_many_more:"Find Agriculture product and many more",
        see_more:"See More",
        see_less:"See Less",
        customize_search:"Customize Search",
        update_phone_number:"Update Phone Number",
        user_information:"User Information",
        update_password:"Update Password",
        my_ads:"My Ads",
        user_links:"User Links",
        dashboard:"Dashboard",
        save:"Save",
        select_your_category:"Select your category",
        changeit:"Change it",
        select_product_status:"Select Product Status",
        type_your_product_name_and_get_category:"Type your product name and get category"
    },
    hi: {
        hindi:"हिन्दी",
        selectLanguage:"भाषा चुने",
        phonenumber:"आपका फोन नंबर",
        password:"पासवर्ड",
        login:"लॉगिन",
        register:"पंजीकृत करें",
        forgotpassword:"पासवर्ड भूल गए?",
        select_your_state:"अपना राज्य चुनें?",
        select_your_district:"अपने जिले का चयन करें?",
        select_your_block:"अपना ब्लॉक चुनें",
        account_success:"आपका खाता सफलतापूर्वक बनाया गया है। कृपया साइन इन करें",
        register_with_us:"हमारे साथ रजिस्टर करें",
        connect_with_digital_krishi_bazar:"डिजिटल कृषि बाजार से जुड़ें",
        why_register_with_us:"आपको क्यों रजिस्टर करना चाहिए?",
        your_first_name:"आपका पहला नाम*",
        your_last_name:"आपका अंतिम नाम*",
        your_mobile_number:"आपका मोबाइल नाम *",
        your_password:"आपका पासवर्ड",
        enter_your_village_name:"अपने गांव का नाम डालें",
        find_agriculture_product_and_many_more:"कृषि उत्पाद और कई और अधिक जानकारी प्राप्त करें",
        see_more:"और देखें",
        see_less:"कम देखें",
        customize_search:"अनुकूलित खोजें",
        update_phone_number:"फ़ोन नंबर अपडेट करें",
        user_information:"उपयोगकर्ता के लिए जानकारी",
        update_password:"पासवर्ड अपडेट करें",
        my_ads:"मेरे विज्ञापन",
        user_links:"उपयोगकर्ता लिंक",
        dashboard:"डैशबोर्ड",
        save:"सुरक्षित करें",
        select_your_category:"अपनी श्रेणी का चयन करें",
        changeit:"बदलें",
        select_product_status:"उत्पाद की स्थिति का चयन करें",
        type_your_product_name_and_get_category:"अपने उत्पाद का नाम और श्रेणी प्राप्त करें"
    }
});