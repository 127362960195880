import React,{useState,useEffect} from 'react';
import Moment from 'react-moment';
import {Button,Modal} from 'react-bootstrap'
import OfferForm from './OfferForm'

//import ContactForm from 'ContactForm';
const UserInfoComponent = ({user,postid,category,ad}) => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    useEffect(() => {
        console.log(category)
    }, [])
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                <div className="col-12 m-0 p-0 " style={{border:"solid",borderWidth:"2px"}}>
                    
                    {
                        user.is_verified === true?
                        <div>
                        <p className="font-weight-normal float-right" style={{backgroundColor:"lightblue"}}>verified</p>
                        </div>
                        :''
                    }

                    <div className="d-flex">
                    
                            <div  className="ml-2 my-3 ">
                                <img src={user.image}
                                alt = "user-profile"
                                style={{height:"100px",width:"100px"}}/>
                            </div>

                            <div className="mx-auto my-3">
                                <p className="m-auto p-0">{user.user.first_name} {user.user.last_name}</p>
                                <p className="m-auto p-0"><span className="h6">VID:</span>{user.vid}</p>
                                {
                                    user.show_mobile_number ? 
                                    <p className="m-auto p-0"><span className="h6">Mobile Number:</span>{user.mobile_number}</p>
                                    :
                                    <p className="m-auto p-0"><span className="h6">Mobile Number:</span>**********</p>
                                }
                                <p className="m-auto p-0"><span className="text-muted small">joined on :  
                                <Moment format="YYYY/MM/DD">{user.user.date_joined}</Moment>
                                </span>
                                </p>
                            </div>

                    </div>
                    {category ? 
                        <>
                        <div className="float-left ml-2 mb-2">
                            <a href={`/customerprofile/${user.vid}`}><Button size="sm" variant="success" className="green">view profile</Button></a>
                        </div>
                        <div className="float-right mr-2 mb-2">
                            <Button size="sm" variant="success" className="green" onClick={handleShow}>Make Offer</Button>
                            <Modal
                                show={show}
                                onHide={handleClose}
                                backdrop="static"
                                keyboard={true}
                            >
                                <Modal.Header closeButton className="green">
                                            <Modal.Title className="green text-white font-weight-bold">Make Your Offer</Modal.Title>
                                </Modal.Header>
                                <Modal.Body >
                                    <OfferForm category={category} post_id={postid} vid={user.vid} price={ad.price} />
                                </Modal.Body>
            
                            </Modal>        
                        </div>
                        </>
                        :
                        ''
                    }
            </div>
            </div>
            </div>
            <div className="container-fluid mt-1" >
                <div className="row p-0 m-0">
                <div className="col-12 m-0 p-0 ">
                    <p className="p-0 m-0 float-left">Post Location:</p>
                    <p className="p-0 m-0 float-right" style={{color:"#007BFF"}}>post id : {postid}</p>
                </div>
                </div>
            </div>

            <div className="container-fluid ">
                <div className="row">
                
                <div className="col-12 m-0 p-0 " style={{border:"solid",borderWidth:"2px"}}>

                    <div className="d-flex mt-2">
                

                        <div className="mx-auto my-3">
                            <p className="m-auto p-0"><span className="h6">State:</span> {ad.state.state_name} </p>
                            <p className="m-auto p-0"><span className="h6">District:</span> {ad.district.district_name} </p>
                            <p className="m-auto p-0"><span className="h6">Block:</span>{ad.block.block_name}</p>
                            <p className="m-auto p-0"><span className="h6">Village:</span>{ad.village}</p>    
                        </div>

                    </div>

            </div>
            </div>
            </div>            
        </>
    )
}

export default UserInfoComponent;