import React,{useState,useEffect}  from 'react';
import {Form, FormControl, Button, Col} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import {StateComponent,fetchdistrict,fetchblocks} from '../utils/AreaUtils';
import {strings} from '../utils/translated_strings';
import {API} from '../config';
import { getEKLanguage } from '../utils/language_utils';
import { getBreed, getCategories, getProductByCategory } from '../utils/Apicore';
import { fetchCattleType } from './CattlePostAds/CattleApiCore';
import { fetchMachinery } from './MachineryPostAds/MachineryCoreApi';
import { fetchWoodType } from './TreeAndWoodAds/TreeAndWoodsApiCore';
import { fetchServiceWork } from './ServiceInRentPostAds/ServiceInRentApiCore';
import { fetchLabourExpertise } from './LabourInRent/LabourInRentApiCore';
import { fetchChemicalProducts } from './FertilizersAndPestisidesAds/FertilizersAndPesitisidesApiCore';
import { fetchOtherProductsTypes } from './OtherProducts/OtherProductsApiCore';

const CustomizeSearchPanel = ({setSearchResults}) => {

    const [values,setValues] = useState({
        state:'',
        district:'',
        block:'',
        category:'',
        min_price:'',
        max_price:'',
        product:'',
    })

    const [districts, setDistricts] = useState([])
    const [blocks, setBlocks] = useState([])
    const [categories, setCategories] = useState([])
    const [products, setProducts] = useState([])
    const [error, setError] = useState(false)
    const {state,district,block,category,min_price,max_price,product} = values;

    useEffect(()=>{
        let lang = getEKLanguage();
        getCategoriesInEnglish()
        getCategories()
        .then(response=> {
            return response.json()
        })
        .then(data => setCategories(data))
        .catch(err=>{
            console.log(err)
        })
    },[])
    const handleChange = (name) => (event) => {
        setValues({...values,error:false,[name]:event.target.value })
    }    

    const handleCategoryChange = (name) => (event) => {
        setValues({...values,error:false,[name]:event.target.value })
        let ix = categories.findIndex((c,idx) => {
            if(c.category_name === event.target.value)
            {
                setEngSelectedCategory(engCategories[idx].category_name);
                return idx
            }  
        } )        
    }

    const handleStateChange = (name,func,setFunc) => (event) => {
        setValues({...values,error:false,[name]:event.target.value })
        func(event.target.value).then(response=> {
            return response.json()
        })
        .then(data => setFunc(data))
        .catch(err => {
            console.log(err)
        });
    }

    
    const [engCategories,setEngCategories] = useState([])
    const [engSelectedCategory,setEngSelectedCategory] = useState(false)    
    


    const getCategoriesInEnglish = () => {
      fetch(`${API}/ads/allcategory/`,{
          method: "GET",
          headers: {
              Accept:'application/json',
              "Content-Type":"application/json"
          }
      })
      .then(response => {
          return response.json()
      })
      .then(data => {
              setEngCategories(data)
            //   setEngSelectedCategory(data[0].category_name)
      })
  }    
    useEffect(()=>{
        
        if(engSelectedCategory) {
            
            if(engSelectedCategory.toLowerCase().includes('grain') | engSelectedCategory.toLowerCase().includes('oilseed')   
            | engSelectedCategory.toLowerCase().includes('fruit') | engSelectedCategory.toLowerCase().includes('plant')
            | engSelectedCategory.toLowerCase().includes('pulse') | engSelectedCategory.toLowerCase().includes('vegetable')
            | engSelectedCategory.toLowerCase().includes('flower') | engSelectedCategory.toLowerCase().includes('dairy') ) 
            {
                let new_products=[];
                
                getProductByCategory(values.category)
                .then(response=>{
                    return response.json()
                })
                .then(data => {
                    
                    data.map((d,i)=>{
                        new_products.push(d.product_name)
                    })
                    setProducts(new_products)
                    console.log(new_products)
                })
                .catch(err=>{
                    console.log(err)
                })
            }
            if(engSelectedCategory.toLowerCase().includes('cattle')){
                let new_products = [];
                fetchCattleType()
                .then(response => {
                    return response.json()
                })
                .then(data => {
                    data.map((d,i)=>{
                        new_products.push(d.cattle_name)
                    })
                    setProducts(new_products)
                })
                .catch(err=>{

                })
            }
            if(engSelectedCategory.toLowerCase().includes('machinary')){
                let new_products = [];
                fetchMachinery()
                .then(response=>{
                    return response.json()
                })
                .then(data => {
                    
                    data.map((d,i)=>{
                        new_products.push(d.machine_name)
                    })
                    setProducts(new_products)
                })
                .catch(err=>{

                })
            }
            if(engSelectedCategory.toLowerCase().includes('wood')){
                let new_products = [];
                fetchWoodType()
                .then(response=>{
                    return response.json()
                })
                .then(data => {
                    data.map((d,i)=>{
                        new_products.push(d.wood_name)
                    })
                    setProducts(new_products)
                })
                .catch(err=>{

                })
            }
            if(engSelectedCategory.toLowerCase().includes('service')) {         
                let new_products = [];
                fetchServiceWork()
                .then(response=>{
                    return response.json()
                })
                .then(data => {
                    
                    data.map((d,i)=>{
                        new_products.push(d.work_name)
                    })
                    setProducts(new_products)
                })
                .catch(err=>{

                })
            }
            if(engSelectedCategory.toLowerCase().includes('labour')) {
                let new_products = [];
                fetchLabourExpertise()
                .then(response=>{
                    return response.json()
                })
                .then(data => {
                    data.map((d,i)=>{
                        new_products.push(d.expertise)
                    })
                    setProducts(new_products)
                })
                .catch(err=>{

                })
            }
            if(engSelectedCategory.toLowerCase().includes('fertilizer')) {
                let new_products = [];
                fetchChemicalProducts('')
                .then(response=>{
                    return response.json()
                })
                .then(data => {
                    data.map((d,i)=>{
                        new_products.push(d.chemical_product_name)
                    })
                    setProducts(new_products)
                })
                .catch(err=>{

                })
            }
            if(engSelectedCategory.toLowerCase().includes('other')) {
                let new_products = [];
                fetchOtherProductsTypes()
                .then(response=>{
                    return response.json()
                })
                .then(data => {
                    data.map((d,i)=>{
                        new_products.push(d.type_of_product)
                    })
                    setProducts(new_products)
                })
                .catch(err=>{

                })
            }            
        }
    },[engSelectedCategory])
        
    const searchPanel = () => (
        <Form inline onSubmit={setSearchResults({state,product,district,block,category,min_price,max_price})} >
            
            <FormControl 
                as="select" 
                size="sm"
                defaultValue={state}  
                 onChange={handleStateChange('state',fetchdistrict,setDistricts)} 
                 className="mt-2 ml-auto offset-2 border-green">
                <StateComponent />
            </FormControl>        
            <FormControl as="select" size="sm" onChange={handleStateChange('district',fetchblocks,setBlocks)} className="mt-2 ml-auto border-green">
                <option default_value="" hidden  className="border-green">{strings.select_your_district}</option>
                {

                                    districts.map(d => {
                                        return <option className="border-green" key={d.district_name}>{d.district_name}</option>
                                    })
                }
            </FormControl>
            <FormControl as="select" size="sm"  onChange={handleChange('block')} className="mt-2 mx-auto">
                <option default_value="" hidden >{strings.select_your_block}</option>
                {
                    blocks.map(d => {
                        return <option key={d.block_name}>{d.block_name}</option>
                    })
                }
            </FormControl>                
            <FormControl as="select" size="sm"  onChange={handleCategoryChange('category')} className="mt-2 mx-auto">
                
                    <option default_value="" hidden >Catgory</option>
                    {
                        categories.map((d,i) => {
                            return <option  key={i}>{d.category_name}</option>
                        })
                    }
                
            </FormControl>                            
            
            <FormControl as="select" size="sm"  onChange={handleChange('product')} className="mt-2 mx-auto">
                    
                    <option default_value="" hidden >Products</option>
                    {
                        products.map((d,i) => {
                            return <option className="text-black"  key={i}>{d}</option>
                        })
                    }
                
            </FormControl>                                        
            <FormControl 
                placeholder="Min Price"
                as="input" 
                onChange={(e)=> {setValues({...values,min_price:e.target.value})}} 
                value={min_price} 
                className="mt-2 mx-auto"
                size="sm"
            />
            <FormControl 
                placeholder="Max Price"
                as="input" 
                onChange={(e)=> {setValues({...values,max_price:e.target.value})}} 
                value={max_price} 
                className="mt-2 mx-auto"
                size="sm"
            />            
            <Button type="submit" size="sm" className="mt-2 ml-auto mr-auto zero-order inline" variant="success" >
                                <span className="text-md-left">Search </span><FontAwesomeIcon icon={faSearch} />
            </Button>          
            
        </Form>
    )
    return (
        <>
        <h4 className="text-muted ml-2 bold"> Customize your search </h4>
        <div className="container-fluid">
            
            <div className="row m-lg-auto">
                
                <div className="col-12">
                    {searchPanel()}
                </div>
            </div>
            
        </div>
        </>
    )
}

export default CustomizeSearchPanel;