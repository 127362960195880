import React, {useState,useEffect} from 'react';
import {Carousel,Button}  from 'react-bootstrap';
import Layout from './Layout';
import {API} from '../config';
import {Col,Table} from 'react-bootstrap';
import {isAuthenticated} from '../auth/';
import {Redirect, Link} from 'react-router-dom'
import {getEKLanguage} from '../utils/language_utils'
import UserInfoComponent from './UserInfoComponent'

const AdDetails = (props) => {

    const [data,setData] = useState()
    const [loading,setLoading] = useState(true)
    const [error,setError] = useState(false)
    const [notFound,setNotFound] = useState()
    
    const loadSingleProduct = productId => {
        let lang = getEKLanguage()
        setLoading(true)
        fetch(`${API}/ads/agriads/${productId}/`,{
            method:"GET",
            headers:{
                Accept:'application/json',
                "Content-Type":"application/json",
                "Accept-Language":`${lang}`
            }
        })
        .then(response => {return response.json()})
        .then(data => {
                if(data.error){
                    setError(data.error)
                    setLoading(false)
                } else {
                    setData(data);
                    setLoading(false)
                }
                
        }).catch(err => {
            setError(err)
            setNotFound("This item does not exit")
            
        })        

    }

    useEffect(()=>{
        const productId = props.match.params.id
        loadSingleProduct(productId)
        
    },[])

    const wrapper = React.createRef();

    const handleInterested = () => {
            let check = isAuthenticated()
            
            if(check === false) {
                return <Redirect to="/signin" />                  
            }
        }
    
        const showError = () => {
            return <div className="alert alert-warning">
                <h2>Something went wrong!!</h2>
            </div>
        }
     
 
    return (
        <Layout>
            {
                loading ? 
                <div className="mt-4 row">
                    <h4 className="mx-auto text-secondary">{notFound}</h4>
                </div> 
                :
                error ?

                showError()
                :
                <div className="container-fluid">
                <div className="row">
                    <Col xs={12} lg={4}>
                                <div className="mx-auto mt-4" >
                                <Carousel className="mx-auto" ref={wrapper} style={{height:"300px",width:"300px"}}>
                                    { data.product_image1 !== "" && data.product_image1 ?
                                    <Carousel.Item >
                                        <img
                                        className="d-block w-100"
                                        src={data.product_image1}
                                        alt="First slide"
                                        style={{height:"200px",width:"200px"}}
                                        />
                                    </Carousel.Item>
                                    :
                                    ""
                                    }
                                    { data.product_image2 !== "" && data.product_image2  ?
                                    <Carousel.Item >
                                        <img
                                        className="d-block w-100"
                                        src={data.product_image2}
                                        alt="Second slide"
                                        style={{height:"200px",width:"200px"}}
                                        />
                                    </Carousel.Item >
                                    :
                                    ""
                                    }
                                    { data.product_image3 !== "" && data.product_image3 ?
                                    <Carousel.Item >
                                        <img
                                        className="d-block w-100"
                                        src={data.product_image3}
                                        alt="Third slide"
                                        style={{height:"200px",width:"200px"}}
                                        />
                                    </Carousel.Item>
                                    :""
                                    }
                                </Carousel>
                    </div>
                    <div>
                        <UserInfoComponent postid={data.post_id} user={data.user} ad={data} category={data.product.category.category_name}/>
                    </div>                    
                    </Col>
                    <Col className="mt-4" xs={12} lg={6}>
                    <h3 className="text-secondary mx-auto mt-4">Product Details</h3>
                    <div className="table-responsive">      
                        <table className="table table-hover table-info">
                            <tbody>
                                <tr>
                                    <td>
                                        <h6>Product Name</h6>
                                    </td>
                                    <td>
                                        {data.product.product_name}:{data.price}/{data.product_price_by}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>Product Breed</h6>
                                    </td>
                                    <td>
                                        {data.product_breed.product_breed}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>Product Status</h6>
                                    </td>
                                    <td>
                                        {data.product_status.product_status}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>Quantity</h6>
                                    </td>
                                    <td>
                                        {data.product_quantity} {data.product_quantity_by}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>Packaging Available</h6>
                                    </td>
                                    <td>
                                        {data.product_packaging_available}
                                    </td>
                                </tr>
                                
                                <tr>
                                    <td><h6>Packaging Material</h6></td>
                                    { 
                                        data.product_packaging_type ?
                                        <td>{data.product_packaging_type.product_packaging_type}</td>:
                                        <td>Not Available</td>
                                    }
                                </tr>
                                
                                <tr>
                                    <td><h6>Packaging cost will be paid by</h6></td>
                                    <td>{data.packaging_cost_bearer}</td>
                                </tr>                                                                                                                                                                
                                <tr>
                                    <td><h6>State</h6></td>
                                    <td>{data.state.state_name}</td>
                                </tr>
                                <tr>
                                    <td><h6>District</h6></td>
                                    <td>{data.district.district_name} </td>
                                </tr>
                                <tr>
                                    <td><h6>Block</h6></td>
                                    <td>{data.block.block_name} </td>
                                </tr>
                                <tr>
                                    <td><h6>Posted by</h6></td>
                                    <td>{data.user.user.first_name} {data.user.user.last_name}</td>
                                </tr>
                                <tr>
                                    <td><h6>Additional Information</h6></td>
                                    <td>{data.additional_information}</td>
                                </tr>         
                                                                                                                                               
                            </tbody>
                        </table>
                        </div>
                        {/* <div className="row m-auto">
                                <Button onClick={handleInterested} className="btn-outline-success btn-success text-white m-auto" >Interested</Button>
                            </div> */}
                    </Col>
                </div>
                </div>
            }
        </Layout>
    )
}

export default AdDetails;