import React,{useState,useEffect} from 'react'
import {API} from '../config'
import {getToken,signout} from '../auth/'
import InfiniteScroll from 'react-infinite-scroll-component';
import UserOfferInformation from './UserOfferInformation'

import Layout from './Layout';


const MyOffer = () => {
    const [values,setValues] = useState([]) 
    const [postId,setPostId] = useState()
    const [error,setError] = useState()
    const [next_url,setNextUrl] = useState('')
    const [ads,setAds] = useState([])
    useEffect(() => {
        let token = getToken()
        fetch(`${API}/ads/offer/`,{
            method:'GET',
            headers:{
                Accept:'application/json',
                Authorization:`Token ${token}`
            }
        })
        .then(response=>{
            return response.json()
        })
        .then(data=>{
            if(data.detail) {
                if(data.detail.toLowerCase().includes('token')) {
                    signout(()=>{
                        window.location.reload(false)
                    })
                } else {
                    setError(data.detail)
                }
            } else {
                setValues(data.results)
                setNextUrl(data.next)
            }
        })
        .catch(err=>{
            
        })
    },[])
    
    const handleMoreEvent = () => {
        if (next_url===null)
            return
        let token = getToken()
        fetch(next_url,{
            method:'GET',
            headers:{
                Accept:'application/json',
                Authorization:`Token ${token}`
            }
        })
        .then(response=>{
            return response.json()
        })
        .then(data=>{
            // console.log('handle more event ' , data)
            if(data.detail) {
                if(data.detail.toLowerCase().includes('token')) {
                    signout(()=>{
                        window.location.reload(false)
                    })
                } else {
                    setError(data.detail)
                }
            } else {
                setValues([...values,...data.results])
                setNextUrl(data.next)
            }
        })
        .catch(err=>{
            
        })
    }



    return (
        <Layout>
            <div className="container-fluid mt-2">
                <div className="row">
                    <h1 className="mx-auto h3">My Offers</h1>
                </div>
            </div>
            <InfiniteScroll dataLength={values.length}
                next={handleMoreEvent}
                hasMore={true}
                className="col-lg-12 col-12 flex flex-wrap"
                style={{width:"100%"}}
            >
                <>
                {
                    values.map((d,i) => {
                        if(d){
                        return <UserOfferInformation key={i} postid={d.post_id} offer={d}   />
                        }
                    })
                }
                {/* {JSON.stringify(values)} */}
                
                </>
            </InfiniteScroll>
            </Layout>
    )
}

export default MyOffer;