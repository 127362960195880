import React ,{useState,useEffect} from 'react';
import {Form,FormControl,Button,Spinner} from 'react-bootstrap';
import {Redirect} from 'react-router-dom';
import {fetchServiceInRentAds,fetchServiceWork,fetchServiceStatus,fetchChargesBy, fetchServiceMachine} from './ServiceInRentApiCore';
import ServiceInRentReview from './ServiceInRentReview'

import {getEKLanguage} from '../../utils/language_utils'

import {API} from '../../config'

import {validateSession,isAuthenticated, signout} from '../../auth'
import Layout from '../Layout';
import {strings} from '../../utils/translated_strings'
import {fetchStates,fetchdistrict,fetchblocks} from '../../utils/AreaUtils'

const ServiceInRentPostAds = (props) => {
    const [id,setId] = useState()
    const [adposted,setAdPosted] = useState(false)
    const [servicePostFields,setServicePostFields] = useState({
        select_work : '',
        service_machine_name :'',
        reaching_on_time:'',
        product_price:0,
        price_per:'',
        photo1:"",
        photo2:"",
        photo3:"",
        state:'',
        district:'',
        block:'',
        village:'',          
        super_category:'',
        additional_information:'',
    })    

    const ErrorInitState = {
        select_work_error: '',
        service_machine_name_error:'',
        reaching_on_time_error:'',
        product_price_error:'', 
        price_per_error:'',
        additional_information_error:'', 
        state_error:'',
        district_error:'',
        block_error:'',          
    }     

    const isValid = () => {
        let flag = true;
        let select_work_error = '' ;
        let service_machine_name_error = '';
        let price_per_error = '';
        let reaching_on_time_error = '';
        let product_price_error = '' ;
        let state_error='';
        let district_error='';
        let block_error='';

        if(servicePostFields.service_machine_name === '') {
            service_machine_name_error = "Please select machine type"
            flag = false
        }
        if(servicePostFields.select_work === '') {
            select_work_error = "Please select work"
            flag = false;
        }
        if(servicePostFields.reaching_on_time === '') {
            reaching_on_time_error = "Please select reaching time"
            flag = false;
        }
        if(servicePostFields.product_price === 0 || servicePostFields.product_price === '') {
            product_price_error = "Price must be greator than 0"
            flag = false;
        }
        if(servicePostFields.price_per === '') {
            price_per_error = "Please select pricing option"
            flag = false;
        }
        if(servicePostFields.state === '')
        {
            state_error="Please select your state name"
        }
        if(servicePostFields.district ==='')
        {
            district_error = "Please select your district"
        }
        if(servicePostFields.block === '')
        {
            block_error = 'Please select your block'
        }          
        setServicePostFieldsErrors({state_error,district_error,block_error,service_machine_name_error,price_per_error,product_price_error,select_work_error,reaching_on_time_error})
        return flag
    }

    useEffect(()=>{
        fetchblocks(servicePostFields.district)
        .then(response=>{
            return response.json()
        })
        .then(data=>{
            setBlocks(data)
        })
    },[servicePostFields.district])

    useEffect(()=>{
        fetchdistrict(servicePostFields.state)
        .then(response=>{
            return response.json()
        })
        .then(data=>{
            setDistricts(data)
        })
    },[servicePostFields.state])

    const [states,setStates] = useState([])
    const [districts,setDistricts] = useState([])
    const [blocks,setBlocks] = useState([])

    const handleStateChange = (name,func,setFunc) => (event) => {
        setServicePostFields({...servicePostFields,[name]:event.target.value })
        func(event.target.value).then(response=> {
            return response.json()
        })
        .then(data => setFunc(data))
        .catch(err => {
            console.log(err)
        });
    }     

    const [servicePostFieldsErrors,setServicePostFieldsErrors] = useState(ErrorInitState)

    const [loading,setLoading] = useState(true)
    const [error,setError] = useState(false)
    
    const [works,setWorks] = useState([])
    const [reachingTimes,setReachingTimes] = useState([])
    const [chargesBy,setChargesBy] = useState([])
    const [serviceMachinary,setServiceMachinary] = useState([])

    const [review,setReview] = useState(false)
    const [photos,setPhotos] = useState({
        photo1:"https://via.placeholder.com/300",
        photo2:"https://via.placeholder.com/300",
        photo3:"https://via.placeholder.com/300"
    })

    useEffect(()=>{
        validateSession()
        .then(response => {
            return response.json()
        }).then(data => {
            if(data.detail){
                setError("You are not logged in")    
            }
        }).catch(err=>{
            setError("You are not logged in")    
        })

        

        fetchServiceStatus()
        .then(response => {
            return response.json()
        })
        .then(data => {
            setReachingTimes(data)
            
        })
        .catch(error=>{
            setError(error)
        })   

        fetchServiceMachine().
        then(response => {
            return response.json()
        }).then(data=>{
            setServiceMachinary(data)
        })

        fetchChargesBy().
        then(response => {
            return response.json()
        }).then(data=>{
            setChargesBy(data)
        })
        .catch(error=>{
            setError(error)
        })

        fetchServiceInRentAds(props.match.params.id).
        then(response => {
            return response.json()
        }).then(data=>{
            if(data.detail)
            {
                setError(data.detail)
                setLoading(false)
            }
            else {
                destructAndSetAllData(data)
                setLoading(false)
            }
        }).catch(error=>{
            setError(error)
        })
        setLoading(false)
    },[])

    useEffect(()=>{
        fetchServiceWork(servicePostFields.service_machine_name)
        .then(response => {
            return response.json()
        })
        .then(data => {    
            setWorks(data)
        })
        .catch(error=>{
            setError(error)
        })  
    }
    )  

    const destructAndSetAllData = (d) => {
        fetchStates()
        .then(response=>{
            return response.json()
        })
        .then (data=>{
            setStates(data)
        })

        setServicePostFields({ id:d.id, 
            select_work : d.select_work.work_name,
            service_machine_name :d.service_machine.service_machine_name,
            reaching_on_time:d.reaching_on_time.service_status,
            product_price:d.price,
            price_per:d.price_by.per,
            additional_information:d.additional_information,
            state:d.state.state_name,
            district:d.district.district_name,
            block:d.block.block_name,
            village:d.village,                          
        })

        setPhotos({photo1:d.product_image1,photo2:d.product_image2,photo3:d.product_image3})
    }      
    const user_info = isAuthenticated()   
    const submitAdvertisement = () => {
        let lang = getEKLanguage()
        let token = user_info.token

        var fd = new FormData();

        let photo1 = null
        let photo2 = null
        let photo3 = null
        if(servicePostFields.photo1 !== photos.photo1)
            photo1 = servicePostFields.photo1
        if(servicePostFields.photo2 !== photos.photo2)
            photo2 = servicePostFields.photo2
        if(servicePostFields.photo3 !== photos.photo3)
            photo3 = servicePostFields.photo3

        fd.append('select_work',servicePostFields.select_work)
        fd.append('service_machine_name',servicePostFields.service_machine_name)
        fd.append('reaching_on_time',servicePostFields.reaching_on_time)
        fd.append('product_price',servicePostFields.product_price)
        fd.append('price_per',servicePostFields.price_per)
        fd.append('additional_information',servicePostFields.additional_information)
        fd.append('photo1',servicePostFields.photo1)
        fd.append('photo2',servicePostFields.photo2)
        fd.append('photo3',servicePostFields.photo3) 
        fd.append('state',servicePostFields.state)
        fd.append('district',servicePostFields.district)
        fd.append('block',servicePostFields.block)
        fd.append('village',servicePostFields.village)           

        fetch(`${API}/ads/serviceinrent/${servicePostFields.id}/`,{
            method: "PUT",
            headers: {
                Accept:'application/json',
                "Accept-Language":`${lang}`,
                Authorization:`Token ${token}`
            },
            body: fd
        })
        .then(response => {
            return response.json()
        })
        .then( data => {
            if(data.detail) {
                if(data.detail.toLowerCase().includes('token')) {
                    signout(()=>{
                        window.location.reload(false)
                    })
                } else {
                    setError(data.detail)
                }
            } else {
             setId(data.id)
            }
        })
        .catch(err => {
            setError(err)
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if(isValid()){
            setAdPosted(true)
            submitAdvertisement()
            setServicePostFields(ErrorInitState)
            setReview(false)
        }
    }

    const handleReview = (e) => {
        e.preventDefault()
        if(review === true){
            setReview(false)
            return
        }
        if(isValid())
        {
            setReview(true)
        }
        else{
            setReview(false)
        }
    }    

    const handleSelection = (name) => (e) => {
        setServicePostFields({...servicePostFields,[name]:e.target.value})
    }

    const handleImageSelection = (name) => (e) => {
        e.preventDefault()
        const reader =new FileReader()
        reader.onload = () => {
            if(reader.readyState === 2) {
                setPhotos({...photos,[name]:reader.result})
            }
        }
        reader.readAsDataURL(e.target.files[0])
        setServicePostFields({...servicePostFields,[name]:e.target.files[0]})
    }

    const redirectToItem = () => {
        if(id) {
            return <Redirect to={`/item/detail/service-in-rent/${id}/`} />
        }
    }        

    const ServiceForm = () => (
        <React.Fragment>
        <Form className="mt-4 mx-auto" onSubmit={handleSubmit}>            
            <h3 className="gray heading">Enter details </h3>
            <hr />

                <Form.Row>
                    <Form.Label  column sm="4">
                            Select Type of Service:
                    </Form.Label>                
                    <FormControl as="select" value={servicePostFields.service_machine_name} onChange={handleSelection("service_machine_name")} size="sm" className="col-lg-4 mt-2">                        
                    <option key="Select machine">types of service</option>
                        {
                            serviceMachinary.map((d,id) => {
                                return <option key={id}>{d.service_machine_name}</option>
                            })
                        }
                    </FormControl>            
                    <div className="d-block" style={{fontSize:"0.8em",color:"red"}}>{servicePostFieldsErrors.service_machine_name_error}</div>
                </Form.Row>
                <Form.Row>
                    <Form.Label  column sm="4">
                            Select Work:
                    </Form.Label>                
                    <FormControl as="select" value={servicePostFields.select_work} onChange={handleSelection("select_work")} size="sm" className="col-lg-4 mt-2">                        
                    <option key="Select work">select work</option>
                        {
                            works.map((d,id) => {
                                return <option key={id}>{d.work_name}</option>
                            })
                        }
                    </FormControl>            
                    <div className="d-block" style={{fontSize:"0.8em",color:"red"}}>{servicePostFieldsErrors.select_work_error}</div>
                </Form.Row>
                <Form.Row>
                    <Form.Label  column sm="4">
                            Reaching Time on site:
                    </Form.Label>
                                    
                    <FormControl as="select" value={servicePostFields.reaching_on_time} onChange={handleSelection("reaching_on_time")} size="sm" className="col-lg-4 mt-2">                        
                    <option key="Select timing">select reaching time</option>
                        {
                            reachingTimes.map((d,id) => {
                                return <option key={id}>{d.service_status}</option>
                            })
                        }
                    </FormControl>            
                    <div className="d-block" style={{fontSize:"0.8em",color:"red"}}>{servicePostFieldsErrors.reaching_on_time_error}</div>
                </Form.Row>  
                <Form.Row>
                    <Form.Label  column sm="4">
                            Price:
                    </Form.Label>                
                    <FormControl type="text" value={servicePostFields.product_price} onChange={handleSelection("product_price")} size="sm" className="col-lg-1 mt-2"/>                        
                    <div className="d-block" style={{fontSize:"0.8em",color:"red"}}>{servicePostFieldsErrors.product_price_error}</div>

                    <FormControl as="select" value={servicePostFields.price_per} onChange={handleSelection("price_per")} size="sm" className="col-lg-2 ml-2 mt-2">                        
                    <option key="Select pricing option">pricing option</option>
                        {
                            chargesBy.map((d,id) => {
                                return <option key={id}>{d.per}</option>
                            })
                        }
                    </FormControl>            
                    <div className="d-block" style={{fontSize:"0.8em",color:"red"}}>{servicePostFieldsErrors.price_per_error}</div>
                </Form.Row> 

                <Form.Row>
                        <Form.Label column sm="4">
                                Select Your State
                        </Form.Label>         
                               
                        <FormControl size="sm" as="select"  defaultValue={servicePostFields.state} onChange={handleStateChange("state",fetchdistrict,setDistricts)} className="col-lg-4 mt-2">
                            
                            {
                                states.map(d=>{
                                    return <option key={d.id}>{d.state_name}</option>
                                })
                            }
                        </FormControl>            
                        <div style={{fontSize:"0.8em",color:"red"}}>{servicePostFieldsErrors.state_error}</div>
                    </Form.Row>
                    <Form.Row>
                        <Form.Label column sm="4">
                                Select Your District
                        </Form.Label>                
                        <FormControl size="sm" as="select" defaultValue={servicePostFields.district} onChange={handleStateChange("district",fetchblocks,setBlocks)} className="col-lg-4 mt-2">
                                
                                {
                                    districts.map(d => {
                                        return <option key={d.id}>{d.district_name}</option>
                                    })
                                }
                        </FormControl>            
                        <div style={{fontSize:"0.8em",color:"red"}}>{servicePostFieldsErrors.district_error}</div>
                    </Form.Row>
                    <Form.Row>
                        <Form.Label column sm="4">
                                Select Your Block
                        </Form.Label>                
                        <FormControl size="sm" as="select" defaultValue={servicePostFields.block} onChange={handleSelection("block")} className="col-lg-4 mt-2">
                                
                                {
                                    blocks.map(d => {
                                        return <option key={d.id}>{d.block_name}</option>
                                    })
                                }
                        </FormControl>            
                        <div style={{fontSize:"0.8em",color:"red"}}>{servicePostFieldsErrors.blocks_error}</div>
                    </Form.Row>
                    <Form.Row>
                        <Form.Label column sm="4">
                                Enter your village name
                        </Form.Label>                
                        <FormControl size="sm" type="text" value={servicePostFields.village} onChange={handleSelection("village")} className="col-lg-4 mt-2" />
                    </Form.Row>      

                <Form.Row>
                    <Form.Label column sm="4">
                        Additional Information:
                    </Form.Label>
                    <FormControl as="textarea" style={{resize:"none"}} rows={3}  value={servicePostFields.additional_information} onChange={handleSelection("additional_information")} className="col-lg-4 mt-2 mr-sm-2" size="sm"  placeholder=""/>
                    <div style={{fontSize:"0.8em",color:"red"}}>{servicePostFieldsErrors.additional_information_error}</div>
                </Form.Row>  
                <Form.Row className="m-auto ">
                        <div className="col-2">
                        <label className="mt-2 btn btn-default btn-outline-secondary btn-sm mx-auto" >
                            Click to Browse <input type="file" hidden onChange={handleImageSelection("photo1")}  className="mt-2"/>
                        </label>
                        <img src={photos.photo1}
                            style={{height:"150px",width:"150px"}} alt="photo1"/>
                        </div>
                        <div className="col-2 ml-4">
                        <label className="mt-2 btn btn-default btn-outline-secondary btn-sm mx-auto">
                            Click to Browse <input type="file" hidden onChange={handleImageSelection("photo2")}  className="mt-2"/>
                        </label>
                        <img src={photos.photo2}
                            style={{height:"150px",width:"150px"}} alt="photo2"/>                        
                        </div>
                        {/* //style={{display:"inline-block",width:"100px",height:"100px",backgroundImage:`url(${data.photo3})`}} */}
                        <div className="col-2 ml-4">
                            <label className="btn mt-2 btn-default btn-outline-secondary btn-sm" >
                                Click to Browse<input type="file" hidden onChange={handleImageSelection("photo3")}  className="mt-2"/>
                            </label>
                            <img src={photos.photo3} style={{height:"150px",width:"150px"}} alt="photo3"/>                        
                        </div>
                    </Form.Row>
                    <div className="form-row col-10 mt-4">
                        <div className="col-3">
                            <Button  variant="info" onClick={handleReview}>Review</Button>
                        </div>
                        <div className="col-3 ml-auto">
                        <Button  className=" " type="submit" variant="info">
                                {
                                    adposted?
                                    <>
                                        <Spinner animation="border" role="status">
                                        <span className="sr-only">Saving...</span>
                                        </Spinner>                                    
                                    </>:

                                    <span>Submit</span>
                                }
                            </Button>
                        </div>
                    </div>                                                  
        </Form>
        {redirectToItem()}
        </React.Fragment>
    )

    return (
        
        <Layout>
        {
        review ?
            
            <>

            <ServiceInRentReview postData={servicePostFields} photos={photos}/>
            <div className="container">
                <div className="row">
                <Button  className="m-auto" size="sm" variant="info" onClick={handleReview}>Go back to edit</Button>
                <div className="col-3 ml-auto">
                <Button onClick={handleSubmit} className=" " type="submit" variant="info">
                                {
                                    adposted?
                                    <>
                                        <Spinner animation="border" role="status">
                                        <span className="sr-only">Saving...</span>
                                        </Spinner>                                    
                                    </>:

                                    <span>Submit</span>
                                }
                            </Button>
                        </div>                
                </div>
            </div>
            </>
            :
            loading ? 
            '':
            (
                error?
            <div className="alert alert-warning col-6 mx-auto">
                <h3>{error}</h3>
            </div>
            :
            <div className="col-lg-10 col-12 ml-auto" >
                {ServiceForm()}
            </div>
            )
        }
        </Layout>
              
    )
}

export default ServiceInRentPostAds;