import React,{useState,useEffect} from 'react'
import Layout from './Layout';
import {API} from '../config'
import {getToken} from '../auth'
const CustomerProfile = (props) => {
    const [loading,setLoading] = useState(true)
    const [values,setValues] = useState()
    useEffect(()=>{
        // let token = getToken()        
        let vid = props.match.params.vid
        fetch(`${API}/edituserinfo/?vid=${vid}`,{
            method:'GET',
            headers:{
                Accept:'application/json',
                // Authorization:`Token ${token}`                
            }
        })
        .then(response=>{
            return response.json()
        })
        .then(data=>{
            setValues(data)
            
            setLoading(false)
        })
    },[])
    return (
        <>

        
        <Layout>
        {
            loading?
            ''
            
            :
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 m-0 p-0">
                        <div className="jumbotron m-0 rounded-0  mx-auto d-flex mt-0" style={{height:"300px"}}>
                            <img src={values.image} className="mx-auto" style={{height:"200px",width:"200px"}} />
                            
                        </div>
                        <div className="col-md-8 col-lg-6 col-xl-5 p-0 mx-auto">
                            <h4 className="font-weight-bold my-4 text-center text-black">{values.first_name} {values.last_name}</h4>

                            <p className="font-weight-bold my-4 text-center text-black">VID: <span className="text-muted">{values.vid}</span> </p>
                            {/* <div class="text-muted text-center mb-4">
                                {values.vid}
                            </div> */}

                            <div className="text-muted mb-4">
                                {values.bio}
                            </div>
                            
                            
                            <div className="text-center  mb-4">
                            <h4 className="h5 my-4 text-center text-black m-1">Mobile Number</h4>    
                                {
                                    values.show_mobile_number ? 
                                    values.phone_number:
                                     "**********"
                                }
                            </div>
                            <div className="text-center  mb-4">
                            <h4 className="h5 my-4 text-center text-black m-1">User Location</h4>    
                                <p><span className="h6">Block </span> : {values.block}</p>
                                <p><span className="h6">District</span> : {values.district}</p>
                                <p><span className="h6">State</span> : {values.state}</p> 
                            </div>                            

                            
                        </div>
                                            
                    </div>
                </div>
            </div>            
        }
        </Layout>
        </>
    )
}

export default CustomerProfile;