import {API} from '../../config';
import {getEKLanguage} from '../../utils/language_utils';
import {isAuthenticated,getToken} from '../../auth'

export const fetchWoodType = () => {
    let lang = getEKLanguage()
    
    return fetch(`${API}/ads/woodtype/`,{
        "method":"GET",
        "headers":{
            Accept:'application/json',
            "Content-Type":"application/json",
            "Accept-Language":`${lang}`
        }
    })
}

export const fetchWoodAvailableType = () => {
    let lang = getEKLanguage()
    
    return fetch(`${API}/ads/woodavailabletype/`,{
        "method":"GET",
        "headers":{
            Accept:'application/json',
            "Content-Type":"application/json",
            "Accept-Language":`${lang}`,
        }
    })
}

export const fetchWoodAndTreeAds = (id) => {
    let lang = getEKLanguage()
    let token = getToken()
    return fetch(`${API}/ads/treeandwoodads/${id}/?toedit=optm/`,{
        "method":"GET",
        "headers":{
            Accept:'application/json',
            "Content-Type":"application/json",
            "Accept-Language":`${lang}`,
            "Authorization":`Token ${token}`
        }
    })
}
