import React from 'react'
import Layout from '../core/Layout'


const BuyFAQ = () => {
    return (
        <Layout>
        <div className="container-fluid">
            <div className="row">
                <div className="col-8 mx-auto">


               <h4> Buyer FAQs </h4>

<h5>What does verified user mean?</h5>
<p>
A verified user is a user whose documents has been verified by EKRISHIBAZAAR.COM.
For verification,
</p>
<ol className="number">
<li>the user has submitted their live selfie and photo of Govt Id with photo and address on it, and</li>
<li>EKRISHIBAZAAR.COM has verified these details.</li>
</ol>

<h5>
Why should I trust a verified user/seller?
</h5>
<p>
A verified user has taken the effort to get his verification completed by EKRISHIBAZAAR.COM. This means he or she is a serious seller.
Also since they have shared their personal information with us, they can be targeted by police and other authorities if they are found to be selling wrong product or doing any other fraud on the platform. This means the user is assuring you that he or she is a genuine seller and is selling a quality product.
In short, verified users are serious sellers who are selling genuine product. You should feel safe in transacting with them. And in case anything goes wrong, you will be able to take action using the personal details that can be shared with authorities.
</p>
<h5>
I am talking to an unverified seller? Can EKRISHIBAZAAR.COM verify them?
</h5>
<p>
Yes. You can ask sellers to get themselves verified free of cost. You can share this link to tell them how they can start their verification.
</p>

<h5>
Can I view the information submitted by verified seller?
</h5>
<p>
No. No user or EKRISHIBAZAAR.COM employee can view the information of a verified seller. It can only be shared with authorities who present a proper and valid notice to the same effect.
</p>

<h4>
Buying via EKRISHIBAZAAR.COM
</h4>

<h5>
How do I search for a product?
</h5>
<p>
Eyeing your favorite item. EKRISHIBAZAAR.COM provides you 2 super simple options to find item of your choice.  
</p>
<ol className="number">
<li>Search bar: Finding by item name</li>
<li>Category search: Finding via relevant category </li>
</ol>
Note: Please ensure that you have selected the appropriate location so that you see nearby options first. 

<h6>
Search bar: 
</h6>
<ol className="number">
<li>On the ‘home page’ of EKRISHIBAZAAR.COM App, you’ll find a search bar at the top.  </li>
<li>Type the name of the item you wish to buy. E.x. Rice</li>
<li>Tap on the desired Ad on results page.</li>
<li>The specific Ad will open containing all details & specification by the seller.</li>
<li>You will have 2 options to contact the seller: Chat or Call.</li>
</ol>
 
 <h6>
 Category search:
 </h6>
 Searching the product in the relevant category is quite easy. Follow the steps below to find your desired product or service.  

 <ol className="number">

<li>On the EKRISHIBAZAAR.COM app, tap on the category you want to search. (e.x. Grains, Agricultural machinery etc)</li>
<li>	On the category list choose the relevant category &amp sub-category. E.x. Rice under grains. </li>
<li>You’ll also be prompted with an option to choose a breed &amp quantity, availability of your preference </li>
<li>Tap on filters at the top right corner of the App to apply the desired filter</li>
 </ol>

<br />
Once the product page opens, you can contact seller accordingly as shown in then prior steps (Call or Chat)
<br />
Cool, isn’t it! That’s all & you can now chose to buy a product of your choice.
<br />

<h5>How can I contact a Seller?</h5>
<p>
The way to buy stuff on EKRISHIBAZAAR.COM has changed quite a bit. Firstly, you will not be able to reach a seller unless you're logged into EKRISHIBAZAAR.COM. Everyone must have a profile for security.
Once logged in, you can scroll through ads to your heart's content. If there's anything that tickles your fancy, you can start a Chat or Make an offer right on the ad
</p>
<ol className="number">
<li>You will see the Request seller phone number button/call icon inside the Chat window.</li>
<li>When a you tap on this new button/icon inside the Chat window, it will show a form to you with prefilled data from your profile. The form contains the name and phone number fields which are always editable.</li>
<li>If phone number is not verified, you need to fill the form and OTP verification has to be done.</li>
<li>Then a request will be sent to the Seller to provide a consent to share his/her phone number which is available in the seller profile</li>
<li>Until the non verified business seller responds to the request and if you tap the button/icon again in the chat window, you will be shown a message which states that Request is pending with seller.</li>
<li>If the non verified business seller rejects the request and if you tap the button/icon again in the chat window, you will be shown a message which states that Request is rejected by the seller. A message in the chat window will also be shown which states that Request is rejected by the seller.</li>
<li>If the non verified business seller accepts the request and if you tap on the button/icon again in the chat window, you will be able to call the seller directly. A message in the chat window will also be shown which contains the seller phone number.</li>
</ol>
Note: If you are contacting a Job provider, the same steps are to be followed. However, instead of 'Make an offer', only the 'Chat' option is available. Upon tapping on the Chat option the steps to request phone number are same.

<h5>Why send a message through EKRISHIBAZAAR.COM.?</h5>
<p>
Because it is the surest, recommended source of contact.
Remember! The Seller can always block your messages, so the lack of response to your request is the result of a decision of the Seller, not a system error.
</p>

<h4>Search for an ad based on location</h4>
<h5>
How do I search in my current or another location?
</h5>
<p>
The new EKRISHIBAZAAR.COM app automatically picks up your current location as long as you have enabled location in your phone's settings. Otherwise, you can manually search for a location.
</p>

<h5>
Why is it set to the wrong location?
</h5>
<p>
No worries, it may happen from time to time due to a default setting on your phone or browser. Go in mobile app and see right top, then change your location to desire search.
<br />
Why can't I see any ads in my area?
<br />
Please check your location and search keyword to find ads in your location
<br />
</p>
                                                       or
                                                       <br />
                                                       <p>
There's an easy fix to that. Change the location you're searching in.
</p>


<h5>Points to remember while checking a product?</h5>
<p>
As a buyer it is important to verify your product before making a purchase!
<br />
Things to remember: - 
</p>
<ol className="number">
<li>Product inspection: Physically examine the product. Check for any damage or defects.</li>
<li>Working condition: For any gadgets, machinery, switch on and examine to see they’re functional.</li>
<li>Product Age: if product is on reselling like machinery, gadgets, Cross check the age of the product by asking for the original bill/receipt from the seller</li>
<li>Proofs: Insist on warranty card, receipt, box or slips.</li>
<li>Ad relevance: Ensure that the product matches the description of the Ad.</li>
(In case product not match with description, please note seller ID and make a complaint to us)
<li>Fake products: Beware of any replica products i.e such as clone, first copy.</li>
<li>Accessories: Remember to ask for all related accessories from the seller.   </li>
</ol>

<h5>Can I pay advance to seller?</h5>
<p>
We would strongly recommend not to pay in advance. If you must pay something to have the seller hold the product for you, ensure it is a small & insignificant amount.
Advance payment is the most common technique adopted by fraud sellers to trick buyers and pursue them to make upfront payments for goods, services, etc.
Advance payment frauds – What you must watch for!     
</p>
<ol className="number">
<li>Too good to be true and irresistible offers</li>
<li>Token money demands without meeting in person/physically checking the product</li>
<li>Posing as people from trustworthy & noble professions like Army Personnel, CISF, BSF, Doctors, NGOs, Public services, etc.</li>
<li>Showcase of absolute urgency to sell the item or an emotional appealg. family member is sick and urgent need of money for operation, payment of school fees, etc. </li>
<li>Job guarantee offers and employment schemes requesting an advance fee to secure the job or obtain the materials to perform the job or involving money transfer.</li>
<li>Advance fees to cover any processing charges, pay duty, taxes, courier fees, shipment and conversion charges, etc. </li>
</ol>
Note: We have put in “safety tips” before you start chatting with EKRISHIBAZAAR.COM users to advise you on concluding a safe deal 
<br></br>

<h4>
 Safety Tips: 
 </h4>
<p>
 You can also read the safety tips while chatting with the user. Follow the below steps: 
</p>
<ol className="number">
<li>Once the chat window opens, tap on the “3 dots” on the top right.</li>
<li>On the Ad page, select the ‘Chat’ option.</li>
<li>A smart window will display on upper right. Tap on ‘Safety Tips’ button and tips will be displayed.</li>
</ol>
<h5>

Where should I meet the seller?
</h5>
<p>
It is important that you meet the seller at a safe place. A few basics to follow so that you deal safely and hassle-free:  
</p>
<ol className="number">
<li>Public Places: Meet the seller at secure & public location such as outside of home in village, Offices, Restaurants, etc. where there are enough people around. Do not travel to far-away places or meet at isolated places / localities that you are not aware of.   </li>
<li>Switching Places: Beware of a seller who changes location(s) and asks you to reach a different destination than already decided.</li>
<li>Have company: Also, try to have someone accompany you 😊 to close the deal!</li>
</ol>
Note: we strongly recommend to the buyer or seller –‘Never meet at vacant roads, place, Home or office’

<h5>How can I find Ads that I have seen before?</h5>
<p>
Seen something interesting? If you’re busy you can get back to it, at your convenience.  
<br />
You can mark an Ad as favorite & view it later. Here are the steps to do so:  
<br />
Mark Ad as favorite
<br />
</p>
<ol className="number">
<li>To right of Ads, appears an ‘heart’ icon.</li>
<li>Tap on the heart icon as highlighted below</li>
<li>The ‘heart icon’ will get bold</li>
</ol>
 That’s it! The selected Ads are saved now. Once, the Ads are saved you can access it; simply follow below steps. 

<br />

<h5>View the favorite Ad</h5>
<ol className="number">
<li>Tap on ‘My Ads’ at bottom of App</li>
<li>Once on ‘My Ads’ section, select ‘favorites’ appearing under it.</li>
<li>Finally, the list of saved Ads will appear that have been marked as favorite.</li>
<li>Tap on the desired Ad and proceed with your transaction.</li>
</ol>
 

<h5>How do I search for a product in another location</h5>
<p>
To change the location you're searching in. Simply follow the steps below: 
</p>
<br />
<ol className="number">
<li>When you login to EKRISHIBAZAAR.COM App, the location will be auto-displayed on top left (e.x. Lucknow)</li>
<li>If you wish to change the location, tap the ‘change location’ </li>
<li>A search bar will appear to change the location, state, district, and block etc.</li>
<li>Select the desired location and click next.</li>
</ol>
Note: The new EKRISHIBAZAAR.COM app automatically picks up your current location if you have enabled location in your phone's settings. Otherwise, you can manually search for a location.

                </div>
            </div>
        </div>
        </Layout>
    )
}

export default BuyFAQ;