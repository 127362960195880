import React ,{ useState , useEffect } from 'react';
import {getEKLanguage} from '../utils/language_utils';
import {Image, Row,Col,Button } from 'react-bootstrap';
import {strings} from '../utils/translated_strings';

import {API} from '../config';
import { filterCategories } from '../utils/Apicore';

const getCategory = (category_url) => {
    let lang = getEKLanguage();
    return fetch(category_url,{
        method: "GET",
        headers: {
            Accept:'application/json',
               "Content-Type":"application/json",
               "Accept-Language":`${lang}`
        }
    })
    .then(response=> {
        return response.json()
    })
}
export const Category = ({setCategorySearch, super_category,initCategory=""}) => {

    const [values,setValues] = useState({
        result_count:0,
        results:[],
        next_url:'',
        prev_url:'',
    });

    const [category,setCategory] = useState([])

    const [initState, setInitState] = useState({results:[],next_url:''})

    const [error,setError] = useState(false)

   
    //const [allCategorybrowsed,setAllCategoryBrowsed] = useState(false)

    useEffect(()=>{
        if(super_category === "BuyBackOffer")
        {
            fetchCategory(`${API}/ads/categorylongpage/`)
        } else {
            
            fetchCategory(`${API}/ads/category/`)
        }
    },[]);    

    const fetchCategory = (category_url) => {
            
            getCategory(category_url)
            .then(data => {
                setError(false);
                if(initState.results.length===0)
                {
                    setInitState({results:data.results,next_url:data.next});
                }
                if(super_category === "BuyBackOffer"){
                    var o_categories = filterCategories(data.results);
                    setCategory( [...category,...o_categories]);
                } else {
                    setCategory([...category, ...data.results]);
                    setValues({...values,next_url:data.next,prev_url:data.previous,result_count:data.count });                                
                }      
            } )
            .catch(err => {
                setError(true);
                //setValues({...values,next_url:data.next,prev_url:data.previous,results:data.results,result_count:data.count })
            })
    };
    const clearCategory = () => {
        setCategory([]);
    }
    const handleCategoryLoad = () => {
        if(values.next_url !== null){
            fetchCategory(values.next_url)
        }
    };

    const handleClick = (name) => (e) => {
        setCategorySearch(name);
    }
    const handleCategoryLoadReset = () => {
        clearCategory();
        setValues({...values,next_url:initState.next_url})
        setCategory(initState.results)
    };

    return (
        <React.Fragment>
            <div className="container">
            { error ? 
                    <div className="alert alert-warning"> 
                        <h3>Something went wrong!!</h3>
                    </div>
                    :                    
                    <Row >
                    <div className="container">
                        <div className="row">
                            <h4 className="mx-auto text-muted"> Browse Categories</h4>
                        </div>
                    </div>
                    {   
                        category.map(  r => {
                            return (
                                <React.Fragment key={r.id}>
                                <Col className="mt-4 mb-3 forhover" xs={4} md={2}>
                                <Image className="center mx-auto category-image" src={r.image} roundedCircle  style={{height:"60%",width:"60%",display:"block"}} onClick={handleClick(r.category_name)}/>
                                    
                                    <p className="mt-3 text-center">{r.category_name}</p>
                                </Col>
                                
                                </React.Fragment>
                            )})
                    }
                    </Row>
                }
                {
                    super_category !== "BuyBackOffer" ?
                    <div className="row mt-5">
                            <Button className="btn btn-primary m-auto" onClick=
                                { values.next_url === null ? handleCategoryLoadReset : handleCategoryLoad}
                            >
                                { values.next_url === null ? strings.see_less : strings.see_more }
                            </Button>
                    </div>                
                :
                ''
                }
            </div>

        </React.Fragment>
    )
}

export default Category;
