import Layout from './Layout'
import React from 'react';

const Aboutus = () => {
    return (
        <Layout>
            
            <div class="aboutus-page">
                <div class="container">
                    <div class="row ">
                        <div class="col-6 mx-auto">
                            <h1 className="h2 text-center" style={{color:"darkgreen"}}> About Us</h1>
                            <p>Nowadays everyone talks about farmers, to increase their income.  And as a solution for this, they have two ways, first, increase MSP and second, increase yields but we do not consider both these as complete solutions.  We believe that the farmer needs a middleman free market, where he can get his crop sold at his cost.  Rural market should be made more powerful and digital so that farmers like cities not only have information but also have a competitive market where they can sell their crops, and buy the goods they need. We have often seen in the village That is very similar to that which is not of the use of the retainer, yet due to lack of proper facility to resell, Former cannot sell it while such a farmer who needs him cannot buy it for lack of information and he has to go to the market and have to fix a higher price due to taking a new one.  EKRISHIBAZAAR is the solution to all such problems.  To know more about us, click here</p>
                        </div>
                    </div>
                </div>
                </div>
        </Layout>
    )
}

export default Aboutus;