import {API} from '../../config';
import {getEKLanguage} from '../../utils/language_utils';
import {getToken, isAuthenticated} from '../../auth'

export const fetchLabourExpertise = () => {
    let lang = getEKLanguage()
    
    return fetch(`${API}/ads/labourexpertise/`,{
        "method":"GET",
        "headers":{
            Accept:'application/json',
            "Content-Type":"application/json",
            "Accept-Language":`${lang}`,
            
        }
    })
}

export const fetchLabourCharges = () => {
    let lang = getEKLanguage()
    
    return fetch(`${API}/ads/labourcharges/`,{
        "method":"GET",
        "headers":{
            Accept:'application/json',
            "Content-Type":"application/json",
            "Accept-Language":`${lang}`,
            
        }
    })
}

export const fetchLabourReachingTimeOnSite = () => {
    let lang = getEKLanguage()
    
    return fetch(`${API}/ads/labourreachingtimeonsite/`,{
        "method":"GET",
        "headers":{
            Accept:'application/json',
            "Content-Type":"application/json",
            "Accept-Language":`${lang}`,
            
        }
    })
}


export const fetchAvailableLabour = () => {
    let lang = getEKLanguage()
    
    return fetch(`${API}/ads/labouravailable/`,{
        "method":"GET",
        "headers":{
            Accept:'application/json',
            "Content-Type":"application/json",
            "Accept-Language":`${lang}`,
            
        }
    })
}

export const fetchLabourInRentAds = (id) => {
    let lang = getEKLanguage()
    let token = getToken()
    var fd = new FormData()
    fd.append('toedit', 'optm')   
    return fetch(`${API}/ads/labourinrentads/${id}/?toedit=optm/`,{
        "method":"GET",
        "headers":{
            Accept:'application/json',
            "Content-Type":"application/json",
            "Accept-Language":`${lang}`,
            Authorization:`Token ${token}`
        }
    })
}