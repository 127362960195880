import React,{useEffect,useState} from 'react'
import {API} from '../config'
import {getToken,signout} from '../auth'
import InfiniteScroll from 'react-infinite-scroll-component';
import Layout from './Layout';

const NotificationTable = ({d}) => {
    return ( 
        <>
                    <tr>
                        <td>
                            <h6>{d.from_user}</h6>
                        </td>
                        <td>
                            {d.to_user.user.first_name} {d.to_user.user.last_name}
                        </td>
                        <td>
                            {d.message}
                        </td>
                        <td>
                            {d.notification_date}
                        </td>
                    </tr>
                </>
    )
}

const Notification = () =>{
    const [values,setValues] = useState([])
    const [next_url,setNextUrl] = useState(`${API}/usernotification/`)
    useEffect(()=>{
        let token = getToken()
        fetch(`${API}/usernotification/`,{
            method:'GET',
            headers:{
                Accept:'application/json',
                "Content-Type":"application/json",
                Authorization:`Token ${token}`                  
            }
        })
        .then(response=>{
            return response.json()
        })
        .then(data=>{
            if(data.detail) {
                if(data.detail.toLowerCase().includes('token')) {
                    signout(()=>{
                        window.location.reload(false)
                    })
                } else {
                    
                }
            } else{
                setValues(data.results)
                setNextUrl(data.next)
            }
        })
        .catch(err=>{
            
        })
    },[])

    const handleMoreEvent = () => {
        if (next_url===null)
            return
        let token = getToken()
        fetch(next_url,{
            method:'GET',
            headers:{
                Accept:'application/json',
                Authorization:`Token ${token}`
            }
        })
        .then(response=>{
            return response.json()
        })
        .then(data=>{
            if(data.detail) {
                if(data.detail.toLowerCase().includes('token')) {
                    signout(()=>{
                        window.location.reload(false)
                    })
                } else {
                    
                }
            } else {
                
                setValues([...values,...data.results])
                setNextUrl(data.next)
            }
        })
        .catch(err=>{
            
        })
    }
    
    return (
        <Layout>
            <div className="container-fluid">
                <div className="row">
            
            <InfiniteScroll dataLength={values.length}
                next={handleMoreEvent}
                hasMore={true}
                className="col-lg-12 col-12 flex flex-wrap"
                style={{width:"100%"}}
            >
                <>
                <div className="container mt-2">
                    <div className="row">
                    <h1 className="h3 mx-auto" style={{color:"green"}}>My Notification</h1>
                    </div>
                </div>
                <div className="table-responsive">      
                <table className="table table-hover table-info">
                    <tbody>
                        <tr>
                            <th style={{color:"green"}}>From</th>
                            <th style={{color:"green"}}>To</th>
                            <th style={{color:"green"}}>Message</th>
                            <th style={{color:"green"}}>Notification Date</th>
                        </tr>
                {
                    values.map((d,i) => {
                        return <NotificationTable key={i} d={d} />
                    })
                }
                </tbody>
                </table>
            </div>
                </>
            </InfiniteScroll>
                    
            </div>
            </div>
        </Layout>
    )
}

export default Notification;