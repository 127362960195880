import React from 'react';
import {Link} from 'react-router-dom';
import {Button,Carousel} from 'react-bootstrap';
import CardImageCarousal from '../CardImageCarousal';
import {API} from '../../config'
import {SoldButton, DeleteButton} from '../../utils/SoldOrDeleteButton'
const OtherProductsCard = ({ad,classpassed = "",editButton=false}) => {
    return (
                
                <div className={`card ml-4  p-0  mt-2 ${classpassed}`} >

                        <CardImageCarousal addetail_url={`/item/detail/other-agri-products/${ad.id}/`} ad={ad} />
                   
                                
                    <div className="card-body">
                        <p className="m-1"><span className="font-weight-bold font-size-card"> Product: </span> <span className="font-size-card font-weight-normal ">{ad.types.type_of_product}</span></p>
                        {/* <p className="m-1 " ><span className="h6 text-md-left font-size-card">Machine Brand:</span>  <span className="font-size-card font-weight-normal font-size-card">{ad.machine_brand.machinery_brand}</span></p> */}
                        
                        <p className="m-1 " style={{fontSize:"0.8em"}}>State:<span className=" font-weight-normal ">{ad.state.state_name}</span> </p>

                        <p className="m-1 " style={{fontSize:"0.8em"}}>District: <span className=" font-weight-normal ">{ad.district.district_name}</span> </p>


                        <p className="m-1 " style={{fontSize:"0.8em"}}>Block: <span className=" font-weight-normal ">{ad.block.block_name}</span> </p>

                        <p className="m-1 " style={{fontSize:"0.8em"}}>Village: <span className=" font-weight-normal ">{ad.village}</span> </p>                        
                        <p className="m-1  text-muted small font-size-card"><span className="">Posted on</span> <span className="small">{ad.posted_on}</span></p>
                        
                        <div className="row mx-auto">
                        
                        <Link className="m-auto" to={`/item/detail/other-agri-products/${ad.id}/`}>
                            <Button size="sm" className="text-white mx-auto btn small btn-outline-success mt-2 mx-2 mb-2" variant="success">
                                View Ad
                            </Button>
                        </Link>
                        {
                            editButton ?
                            <>
                            <Link className="m-auto" to={`/edit/item-details/other-agri-products/${ad.id}`}>
                                <Button size="sm" className="text-white btn  btn-outline-success mt-2 mb-2" variant="success">
                                    Edit
                                </Button>
                            </Link>
                            <SoldButton url={`${API}/ads/otheragriproducts/${ad.id}/`} />
                            <DeleteButton url={`${API}/ads/otheragriproducts/${ad.id}/`} />    
                            </>
                            :
                            ""
                        }
                        </div>
                    </div> 
                    </div>


    )
}

export default OtherProductsCard;