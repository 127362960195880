import React, {  useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import {FormControl} from 'react-bootstrap';
import {getEKLanguage} from '../utils/language_utils';
import Layout from '../core/Layout'
import "../assets/css/module.css";
import {strings} from '../utils/translated_strings';
import {Link} from 'react-router-dom';
import { isAuthenticated, signout, signin,authenticate } from '../auth';
import  Menu  from '../core/Menu';


import {Redirect} from 'react-router-dom';
const SigninPage = () => {

    const [loginparams,setLoginParams] =  useState({
        username:'',
        password:'',
        error:'',
        redirectToReferrer:false
    })

    const [errorState,setErrorState] = useState({
        username_error:"",
        password_error:""
    })

    const { username,password,error,redirectToReferrer } = loginparams;
    
    const isValid = () => {
        let username_error = '';
        let password_error = '';
        let flag = true
        if(username.length < 10  || username.length > 10){
            username_error = "Please enter your 10 digit phone number"    
            flag = false
        }

        if(password.length === 0) {
            password_error = "Please enter your password"
            flag = false
        }
        setErrorState({username_error,password_error})
        return flag;
    }

    const clickSubmitLogin = (event) => {
        event.preventDefault();
        if (!isValid())
        {
            return;
        } 
        setErrorState({username_error:'',password_error:''})
        setLoginParams({...loginparams,error:false,redirectToReferrer:false});
        signin({username,password})
        .then(data => {
            if(data.detail) {
                setLoginParams({...loginparams,error:data.detail})
            } else {
                setLoginParams({
                    ...loginparams,
                    error:false,
                    redirectToReferrer:true
                });
                authenticate(data );
            }
        })
        
    };

    const handleChange = (name,func) => (event) => {
        setLoginParams({...loginparams,error:'',[name]:event.target.value })
    }

    
    const showError = () => (
        (error ?
            <div className="alert alert-danger" style={{width:"50%"}}>
                {error}
            </div> : ''
        )
    )

    const renderLoginForm = () => {
        return (
            <>
            
            <Form  className="mt-4 rounded"  style={{backgroundColor:"lightgreen"}}> 
            {
                loginparams.error ?
                <div className="alert alert-danger" >{loginparams.error} </div>
                :
                ''
            }
            <Form.Label className="mt-2 ml-auto pl-3 h3 text-success" htmlFor="username">Please sign-in to continue</Form.Label>
            <hr />
                <div className="col-lg-12 col-12">
                <Form.Row  className="mx-auto">
                    <Form.Label className="mt-2 " htmlFor="username">Phone Number:</Form.Label>
                    <FormControl id="username"  type="text" onChange={handleChange('username')} placeholder={strings.phonenumber} className="m-auto mr-sm-2" />
                    <label htmlFor="username" style={{color:"red"}}>{errorState.username_error}</label>
                </Form.Row>
                </div>           
                <div className="col-lg-12 col-12">
                <Form.Row className="mx-auto">
                        <Form.Label htmlFor="password">Password:</Form.Label>
                        <FormControl   id="password" type="password" onChange={handleChange('password')} placeholder={strings.your_password} className=" mr-sm-2" />
                        <label htmlFor="password" style={{color:"red"}}>{errorState.password_error}</label>
                        <Button onClick={clickSubmitLogin} type="submit" className=" text-white btn-outline-success btn-success text-lg mt-2 mb-2 mr-4 mx-auto" ><strong>{strings.login}</strong></Button>
                        
                </Form.Row>
                </div>
               
            </Form>
            {redirectUser()}
            </>
        )
    }    

    const redirectUser = () => {

        if(redirectToReferrer) {
            return <Redirect to="/" />
        }
    }

    return (
        <>
        <Menu />
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-6 mx-auto rounded">
                {renderLoginForm()}
                </div>
            </div>
        </div>
        </>
    )
}

export default SigninPage;