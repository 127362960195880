import React from 'react';
import {Carousel} from 'react-bootstrap'
import {Link} from 'react-router-dom'

const CardImageCarousal = ({ad,addetail_url}) => {
    return (    
    <>
    <Carousel indicators={false}  style={{height:"150px",width:"100%"}}>
        {ad.product_image1 !== null && ad.product_image1 !== "" && ad.product_image1 !== "undefined" && ad.product_image1?
        
        <Carousel.Item >
            <Link to={addetail_url}>
                <img
                className="d-block mx-auto"
                src={ad.product_image1}
                alt="First slide"
                style={{height:"150px",maxWidth:"100%"}}
                />
            </Link>
        </Carousel.Item>
        :
        ""
        }
        
        {ad.product_image2 !== null && ad.product_image2 !== "" && ad.product_image2 !== "undefined" && ad.product_image1?
        <Carousel.Item >
            <Link to={addetail_url}>
            <img
            className="d-block mx-auto"
            src={ad.product_image2}
            alt="Second slide"
            style={{height:"150px",maxWidth:"100%"}}
            />
            </Link>
        </Carousel.Item >
        :
        ""
        }
        {ad.product_image3 !== null && ad.product_image3 !== "" && ad.product_image3 !== "undefined" && ad.product_image1?
        <Carousel.Item >
            <Link to={addetail_url}>
            <img
            className="d-block mx-auto"
            src={ad.product_image3}
            alt="Third slide"
            style={{height:"150px",maxWidth:"100%"}}
            />
            </Link>
        </Carousel.Item>
        :""
        }
        </Carousel>
    </>
    )
    
}

export default CardImageCarousal;