import { API } from '../config';


export const signup = (user) => {
    return fetch(`${API}/signup/`,{
        method: "POST",
        headers: {
            Accept:'application/json',
            "Content-Type":"application/json",  
        },
        body: JSON.stringify(user)
    })
    .then(response=> {
        return response.json()
    })
    .catch(err => {
        console.log(err);
    ;})
};

export const adminsignin = (user) => {
    return fetch(`${API}/adminsignin/`,{
        method: "POST",
        headers: {
            Accept:'application/json',
            "Content-Type":"application/json"
        },
        body: JSON.stringify(user)
    })
    .then(response=> {
        return response.json()
    })
    .catch(err => {
        console.log(err);
    ;})
}

export const signin = (user) => {
    return fetch(`${API}/signin/`,{
        method: "POST",
        headers: {
            Accept:'application/json',
            "Content-Type":"application/json"
        },
        body: JSON.stringify(user)
    })
    .then(response=> {
        return response.json()
    })
    .catch(err => {
        console.log(err);
    ;})
}
export const getToken = () => {
    if (typeof window === 'undefined'){
        return false
    } 
    if(localStorage.getItem('jwt')) {
        return JSON.parse(localStorage.getItem('jwt'))['token'];
    }
}
export const validateSession = () => {
    let token = getToken()
    return fetch(`${API}/checktoken/`,{
        method:"GET",
        headers :{
            Accept:'application/json',
            'Authorization':`Token ${token}`
        }
    })
}


 export const callingfetch = (t) => {
    return fetch(`${API}/signout/`, {
        method:"POST", 
        headers:{
            Accept:'application/json',
            'Authorization':`Token ${t}`
        }    
    })
 }

export const clearToken = () => {
    if (typeof window !== 'undefined'){
        localStorage.removeItem('jwt');
    }
}
export const signout = (next) => {
    const token = getToken();
    if (typeof window !== 'undefined'){
        localStorage.removeItem('jwt');
        next();
        return callingfetch(token)
        .then(response => {
        }).catch(err => console.log(err));
    }
}
export const authenticate = (data) => {
    if(data.is_blocked){

    }
    if (typeof window !== 'undefined'){
        localStorage.setItem('jwt',JSON.stringify(data))
    }
}

export function isAuthenticated() {
    if (typeof window === 'undefined'){
        return false
    } 
    
    
    if(localStorage.getItem('jwt')) {
        let jwt = JSON.parse(localStorage.getItem('jwt'));

        return jwt
    } else {
        return false;
    }
}
