import React, {useState,useEffect} from 'react';
import {Carousel,Button}  from 'react-bootstrap';
import Layout from '../Layout';
import {API} from '../../config';
import {Col,Table} from 'react-bootstrap';
import {isAuthenticated} from '../../auth/';
import {Redirect, Link} from 'react-router-dom'
import {getEKLanguage} from '../../utils/language_utils'
import UserInfoComponent from '../UserInfoComponent'
//import AliceCarousel from 'react-alice-carousel';
//import "react-alice-carousel/lib/alice-carousel.css";

const TreeAndWoodAdDetails = (props) => {

    const [data,setData] = useState()
    const [loading,setLoading] = useState(true)
    const [error,setError] = useState(false)
    const [notFound,setNotFound] = useState()
    const [interestedButtonClicked,setInterestedButtonClicked] = useState(false)
    const loadSingleProduct = productId => {
        let lang = getEKLanguage()
        setLoading(true)
        fetch(`${API}/ads/labourinrentads/${productId}/`,{
            method:"GET",
            headers:{
                Accept:'application/json',
                "Content-Type":"application/json",
                "Accept-Language":`${lang}`
            }
        })
        .then(response => {return response.json()})
        .then(data => {
                setData(data);
                setLoading(false)
        }).catch(err => {
            setError(err)
            setNotFound("This item does not exit")
        })        
    }

    useEffect(()=>{
        const productId = props.match.params.id
        loadSingleProduct(productId)
        
    },[])


    
    const handleInterested = () => {
        let check = isAuthenticated() 
            
            if(check === false) {
                return <Redirect to="/signin" />                  
            }
            setInterestedButtonClicked(true)
            //Push data in interested table
            // let lang = getEKLanguage()
            // var fd = new FormData()
            
            // fd.append('posted_by',data.user.user.username)
            // fd.append('ad_id',data.id)
            // fd.append('category',data.product.category_name)
            
            // fetch(`${API}/ads/interested/`,{
            //     method:"POST",
            //     headers:{
            //         Accept:'application/json',
            //         "Accept-Language":`${lang}`,
            //         Authorization: `Token ${check.token}`
            //     },
            //     body:fd
            // }).then(response => {
            //     return response.json()
            // })
            // .then(data=>{
            //     setInterestedButtonClicked(true)
            // })
            // .catch(err => {
            //     setError(err.error);
            //     setInterestedButtonClicked(false)
            // })
        }
    

    
    return (
        <Layout>
            {
                loading ? 
                <div className="mt-4 row">
                    <h4 className="mx-auto text-secondary">{notFound}</h4>
                </div> 
                :
                
                <div className="container-fluid">
                <div className="row">
                    <Col xs={12} lg={4}>
                                <div className="mx-auto mt-4" >
                                <Carousel className="mx-auto" style={{height:"300px",width:"300px"}}>
                                    { data.product_image1 !== "" && data.product_image1 ?
                                    <Carousel.Item >
                                        <img
                                        className="d-block w-100"
                                        src={data.product_image1}
                                        alt="First slide"
                                        style={{height:"200px",width:"200px"}}
                                        />
                                    </Carousel.Item>
                                    :
                                    ""
                                    }
                                    { data.product_image2 !== "" && data.product_image2  ?
                                    <Carousel.Item >
                                        <img
                                        className="d-block w-100"
                                        src={data.product_image2}
                                        alt="Second slide"
                                        style={{height:"200px",width:"200px"}}
                                        />
                                    </Carousel.Item >
                                    :
                                    ""
                                    }
                                    { data.product_image3 !== "" && data.product_image3 ?
                                    <Carousel.Item >
                                        <img
                                        className="d-block w-100"
                                        src={data.product_image3}
                                        alt="Third slide"
                                        style={{height:"200px",width:"200px"}}
                                        />
                                    </Carousel.Item>
                                    :""
                                    }
                                </Carousel>
                    </div>
                    <div>
                                <UserInfoComponent postid={data.post_id} user={data.user} ad={data} category={data.labour_expertise.category.category_name}/>
                            </div>                    
                    </Col>
                    <Col className="mt-4" xs={12} lg={6}>
                    <h3 className="text-secondary mx-auto mt-4">Product Details</h3>
                    <div className="table-responsive">      
                        <table className="table table-hover table-info">
                            <tbody>
                                <tr>
                                    <td>
                                        <h6>Available Labour</h6>
                                    </td>
                                    <td>
                                        {data.available_labour.available_labour} 
                                    </td>
                                </tr>
								<tr>
                                    <td>
                                        <h6>Experts</h6>
                                    </td>
                                    <td>
                                        {data.labour_expertise.expertise}
                                    </td>
                                </tr>  
                                <tr>
                                    <td>
                                        <h6>Reaching time on site</h6>
                                    </td>
                                    <td>
                                        {data.reaching_time_on_site.reaching_time_status}
                                    </td>
                                </tr>                                                                                                                                                                                        
                                <tr>
                                    <td>
                                        <h6>Price</h6>
                                    </td>
                                    <td>
                                        {data.price}Rs {data.price_by.per}
                                    </td>
                                </tr>                                 
                                <tr>
                                    <td><h6>Additional Information</h6></td>
                                    <td>{data.additional_information}</td>
                                </tr>
                                <tr>
                                    <td><h6>Posted By</h6></td>
                                    <td>{data.user.user.first_name} {data.user.user.last_name}</td>
                                    
                                </tr>
                            </tbody>
                        </table>
                        </div>
                        {/* <div className="row m-auto">
                                <Button onClick={handleInterested} className="btn-outline-success btn-success text-white m-auto" >Interested</Button>
                            </div> */}
                    </Col>
                </div>
                </div>
            }
        </Layout>
    )
}

export default TreeAndWoodAdDetails;