import React ,{useState,useEffect} from 'react';
import {Form,FormControl,Button,Spinner} from 'react-bootstrap';
import {Redirect} from 'react-router-dom';
import {fetchOtherProductsTypes,fetchOtherProductsAds} from './OtherProductsApiCore';
import OtherProductsReview from './OtherProductsReview'


import {getEKLanguage} from '../../utils/language_utils'

import {API} from '../../config'

import {getToken, signout} from '../../auth'
import {strings} from '../../utils/translated_strings'
import {fetchStates,fetchdistrict,fetchblocks} from '../../utils/AreaUtils'

const OtherAgriProductAds = ({super_category}) => {
    const [id,setId]=useState()
    const [adposted,setAdPosted] = useState(false)
    const [otherPostFields,setOtherPostFields] = useState({
        product:'',
        quantity:0,
        product_price:0,
        per_unit:'',
        photo1:"",
        photo2:"",
        photo3:"",
        state:'',
        district:'',
        block:'',
        village:'',         
        super_category:'',
        additional_information:'',
    })    

    const ErrorInitState = {
        product_error:'' ,
        quantity_error:'',
        packing_error:'',
        product_price_error:'',
        additional_information_error:'', 
        per_unit_error:'',
        state_error:'',
        district_error:'',
        block_error:'',         
    }     

    const isValid = () => {
        let flag = true;
        let product_error = '' ;
        let quantity_error = '';
        
        let product_price_error = '';
        let per_unit_error = '';

        let state_error='';
        let district_error='';
        let block_error='';         
        
        if(otherPostFields.product === '') {
            product_error = "Please select product type"
            flag = false;
        }
        if(otherPostFields.quantity === 0 || otherPostFields.quantity === '') {
            quantity_error = "Quantity must be greator than 0"
            flag = false;
        }
        if(otherPostFields.product_price === 0 || otherPostFields.product_price === '') {
            product_price_error = "Price must be greator than 0"
            flag = false;
        }
        if(otherPostFields.per_unit ===  '') {
            per_unit_error = "Please select unit value"
            flag = false;
        }  
        if(otherPostFields.state === '')
        {
            state_error="Please select your state name"
        }
        if(otherPostFields.district ==='')
        {
            district_error = "Please select your district"
        }
        if(otherPostFields.block === '')
        {
            block_error = 'Please select your block'
        }                
        setOtherPostFieldsErrors({state_error,district_error,block_error,product_error,product_price_error,quantity_error,per_unit_error})
        return flag
    }

    const [otherPostFieldsErrors,setOtherPostFieldsErrors] = useState(ErrorInitState)

    const [loading,setLoading] = useState(true)
    const [error,setError] = useState(false)
    const [types,setTypes] = useState([])
    
    const [review,setReview] = useState(false)
    const [photos,setPhotos] = useState({
        photo1:"https://via.placeholder.com/300",
        photo2:"https://via.placeholder.com/300",
        photo3:"https://via.placeholder.com/300"
    })
    const [states,setStates] = useState([])
    const [districts,setDistricts] = useState([])
    const [blocks,setBlocks] = useState([])

    const handleStateChange = (name,func,setFunc) => (event) => {
        setOtherPostFields({...otherPostFields,[name]:event.target.value })
        func(event.target.value).then(response=> {
            return response.json()
        })
        .then(data => setFunc(data))
        .catch(err => {
            console.log(err)
        });
    }    
    useEffect(()=>{
        fetchStates().
        then(response => {return response.json()})
        .then(data=>{
             setStates(data)
        })
        .catch(err=>{
            setError(err)
        })
                
        fetchOtherProductsTypes()
        .then(response => {
            return response.json()
        })
        .then(data => {
            
            setTypes(data)
        })
        .catch(error=>{
            setError(error)
        })
        setLoading(false)
      
    },[])
    const user_info = getToken()
    const submitAdvertisement = () => {
        let lang = getEKLanguage()
        let token = user_info  
        
        var fd = new FormData();
        fd.append('product',otherPostFields.product)
        fd.append('quantity',otherPostFields.quantity)
        fd.append('per_unit',otherPostFields.per_unit)
        fd.append('product_price',otherPostFields.product_price)
        fd.append('super_category',super_category)
        fd.append('additional_information',otherPostFields.additional_information)
        fd.append('photo1',otherPostFields.photo1)
        fd.append('photo2',otherPostFields.photo2)
        fd.append('photo3',otherPostFields.photo3)
        fd.append('state',otherPostFields.state)
        fd.append('district',otherPostFields.district)
        fd.append('block',otherPostFields.block)
        fd.append('village',otherPostFields.village)          

        fetch(`${API}/ads/otheragriproducts/`,{
            method: "POST",
            headers: {
                Accept:'application/json',
                "Accept-Language":`${lang}`,
                Authorization:`Token ${token}`
            },
            body: fd
        })
        .then(response => {
            return response.json()
        })
        .then( data => {
            if(data.detail) {
                if(data.detail.toLowerCase().includes('token')) {
                    signout(()=>{
                        window.location.reload(false)
                    })
                } else {
                    setError(data.detail)
                }
            } else {
             setId(data.id)
            }
        })
        .catch(err => {
            setError(err)
        })
    }
  
    const handleSubmit = (e) => {
        e.preventDefault();

        if(isValid()){
            setAdPosted(true)
            submitAdvertisement()
            setOtherPostFieldsErrors(ErrorInitState)
            setReview(false)
        }
    }

    const handleReview = (e) => {
        e.preventDefault()
        if(review === true){
            setReview(false)
            return
        }
        if(isValid())
        {
            setReview(true)
        }
        else{
            setReview(false)
        }
    }    

    const handleSelection = (name) => (e) => {
        setOtherPostFields({...otherPostFields,[name]:e.target.value})
    }

    const handleImageSelection = (name) => (e) => {
        e.preventDefault()
        const reader =new FileReader()
        reader.onload = () => {
            if(reader.readyState === 2) {
                setPhotos({...photos,[name]:reader.result})
            }
        }
        reader.readAsDataURL(e.target.files[0])
        setOtherPostFields({...otherPostFields,[name]:e.target.files[0]})
        
    }

    const OtherProductsForm = () => (
        <React.Fragment>
        <Form className="mt-4 mx-auto" onSubmit={handleSubmit}>                   
            <h3 className="gray heading">Enter details </h3>
            <hr />

                <Form.Row>
                    <Form.Label  column sm="4">
                            Select Type of Products:
                    </Form.Label>                
                    <FormControl as="select" value={otherPostFields.product} onChange={handleSelection("product")} size="sm" className="col-lg-4 mt-2">                        
                    <option key="Select products">products</option>
                        {
                            types.map((d,id) => {
                                return <option key={id}>{d.type_of_product}</option>
                            })
                        }
                    </FormControl>            
                    <div className="d-block" style={{fontSize:"0.8em",color:"red"}}>{otherPostFieldsErrors.product_error}</div>
                </Form.Row>
                <Form.Row>
                    <Form.Label  column sm="4">
                            Packing:
                    </Form.Label>                
                    <FormControl type="text" defaultValue={otherPostFields.quantity} onChange={handleSelection("quantity")} size="sm" className="col-lg-1 mt-2" />                        
                    <div className="d-block" style={{fontSize:"0.8em",color:"red"}}>{otherPostFieldsErrors.quantity_error}</div>
                    <FormControl as="select" value={otherPostFields.per_unit} onChange={handleSelection("per_unit")} size="sm" className="col-lg-2 ml-1 mt-2">
                    <option key="Select availability">Available as</option>
                        <option>Gram</option>
                        <option>ml</option>
                        <option>Kg</option>
                        <option>litre</option>
                    </FormControl>            
                    <div className="d-block" style={{fontSize:"0.8em",color:"red"}}>{otherPostFieldsErrors.per_unit_error}</div>
                </Form.Row>

                <Form.Row>
                    <Form.Label  column sm="4">
                            Price:
                    </Form.Label>                
                    <FormControl type="text" value={otherPostFields.product_price} onChange={handleSelection("product_price")} size="sm" className="col-lg-1 mt-2"/>                        
                    <div className="d-block" style={{fontSize:"0.8em",color:"red"}}>{otherPostFieldsErrors.product_price_error}</div>
                </Form.Row> 

                <Form.Row>
                        <Form.Label column sm="4">
                                Select Your State
                        </Form.Label>                
                        <FormControl size="sm" as="select"  value={otherPostFields.state} onChange={handleStateChange("state",fetchdistrict,setDistricts)} className="col-lg-4 mt-2">
                            <option key="Select Your state">{strings.select_your_state}</option>
                            {
                                states.map(d=>{
                                    return <option key={d.id}>{d.state_name}</option>
                                })
                            }
                        </FormControl>            
                        <div style={{fontSize:"0.8em",color:"red"}}>{otherPostFieldsErrors.state_error}</div>
                    </Form.Row>
                    <Form.Row>
                        <Form.Label column sm="4">
                                Select Your District
                        </Form.Label>                
                        <FormControl size="sm" as="select" value={otherPostFields.district} onChange={handleStateChange("district",fetchblocks,setBlocks)} className="col-lg-4 mt-2">
                                <option key="Select Your district">{strings.select_your_district}</option>
                                {
                                    districts.map(d => {
                                        return <option key={d.id}>{d.district_name}</option>
                                    })
                                }
                        </FormControl>            
                        <div style={{fontSize:"0.8em",color:"red"}}>{otherPostFieldsErrors.district_error}</div>
                    </Form.Row>
                    <Form.Row>
                        <Form.Label column sm="4">
                                Select Your Block
                        </Form.Label>                
                        <FormControl size="sm" as="select" value={otherPostFields.block} onChange={handleSelection("block")} className="col-lg-4 mt-2">
                        <option key="Select Your block">{strings.select_your_block}</option>
                                {
                                    blocks.map(d => {
                                        return <option key={d.id}>{d.block_name}</option>
                                    })
                                }
                        </FormControl>            
                        <div style={{fontSize:"0.8em",color:"red"}}>{otherPostFieldsErrors.blocks_error}</div>
                    </Form.Row>
                    <Form.Row>
                        <Form.Label column sm="4">
                                Enter your village name
                        </Form.Label>                
                        <FormControl size="sm" type="text" value={otherPostFields.village} onChange={handleSelection("village")} className="col-lg-4 mt-2" />
                    </Form.Row>                                    

                <Form.Row>
                        <Form.Label column sm="4">
                            Additional Information:
                        </Form.Label>
                    <FormControl as="textarea" style={{resize:"none"}} rows={3}  value={otherPostFields.additional_information} onChange={handleSelection("additional_information")} className="col-lg-4 mt-2 mr-sm-2" size="sm"  placeholder=""/>
                    <div style={{fontSize:"0.8em",color:"red"}}>{otherPostFieldsErrors.additional_information_error}</div>
                </Form.Row>  
                <Form.Row className="m-auto ">
                        <div className="col-2">
                        <label className="mt-2 btn btn-default btn-outline-secondary btn-sm mx-auto" >
                            Click to Browse <input type="file" hidden onChange={handleImageSelection("photo1")}  className="mt-2"/>
                        </label>
                        <img src={photos.photo1}
                            style={{height:"150px",width:"150px"}} alt="photo1"/>
                        </div>
                        <div className="col-2 ml-4">
                        <label className="mt-2 btn btn-default btn-outline-secondary btn-sm mx-auto">
                            Click to Browse <input type="file" hidden onChange={handleImageSelection("photo2")}  className="mt-2"/>
                        </label>
                        <img src={photos.photo2}
                            style={{height:"150px",width:"150px"}} alt="photo2"/>                        
                        </div>
                        {/* //style={{display:"inline-block",width:"100px",height:"100px",backgroundImage:`url(${data.photo3})`}} */}
                        <div className="col-2 ml-4">
                            <label className="btn mt-2 btn-default btn-outline-secondary btn-sm" >
                                Click to Browse<input type="file" hidden onChange={handleImageSelection("photo3")}  className="mt-2"/>
                            </label>
                            <img src={photos.photo3} style={{height:"150px",width:"150px"}} alt="photo3"/>                        
                        </div>
                    </Form.Row>
                    <div className="form-row col-10 mt-4">
                        <div className="col-3">
                            <Button  variant="info" onClick={handleReview}>Review</Button>
                        </div>
                        <div className="col-3 ml-auto">
                        <Button  className=" " type="submit" variant="info">
                                {
                                    adposted?
                                    <>
                                        <Spinner animation="border" role="status">
                                        <span className="sr-only">Saving...</span>
                                        </Spinner>                                    
                                    </>:

                                    <span>Submit</span>
                                }
                            </Button>
                        </div>
                    </div>    
                    
                                                                  
        </Form>
        {redirectToItem()}
        </React.Fragment>
    )
    
    const redirectToItem = () => {
        if(id) {
            return <Redirect to={`/item/detail/other-agri-products/${id}`} />
        }
    }    

    return (
        <>
        
        {
        review ?
            
            <>
            <OtherProductsReview postData={otherPostFields} photos={photos}/>
            <div className="container">
                <div className="row">
                <Button  className="m-auto" variant="info" onClick={handleReview}>Go back to edit</Button>
                <Button onClick={handleSubmit} className=" " type="submit" variant="info">
                                {
                                    adposted?
                                    <>
                                        <Spinner animation="border" role="status">
                                        <span className="sr-only">Saving...</span>
                                        </Spinner>                                    
                                    </>:

                                    <span>Submit</span>
                                }
                            </Button>
                </div>
            </div>
            </>
            :
            loading ? 
            '':
            (error ?
                <div className="alert alert-warning col-6 mx-auto">
                <h3>{error}</h3>
            </div>   
                 :
            <div className="col-lg-10 col-12 ml-auto" >
                {OtherProductsForm()}
            </div>
            )
        }
        </>        
    )
}

export default OtherAgriProductAds;