import React , {useState,useEffect,useRef, useCallback} from 'react';
import { Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import {strings} from '../utils/translated_strings';
import {API} from '../config';
import {DisplaySearchResult} from './DisplaySearchResult';
import {Category} from './Category';
import { getEKLanguage } from '../utils/language_utils';
import CustomizeSearchPanel from './CustomSearchPanel';
// import AgriCustomSearchPanel from './CustomSearchPanel/AgriCustomSearchPanel';
// import CattleCustomSearchPanel from './CustomSearchPanel/CattleCustomSearchPanel';
import {CSSTransition, TransitionGroup} from 'react-transition-group'
import '../assets/css/customsearch.css'
import { Link,Redirect } from 'react-router-dom/cjs/react-router-dom.min';
// import InfiniteScroll from 'react-infinite-scroll-component';
import bg from "../assets/bg.jpeg"
import {isAuthenticated} from '../auth/'
import {getCategories} from '../utils/Apicore'
//const DisplaySearchResult = React.lazy(() => import('./DisplaySearchResult'));


export const Search = ({super_category}) => {
    const [loading,setLoading] = useState(true);
    const [searchResult,setSearchResult] = useState([]);
    const [next_url,setNextUrl] = useState(null)
    const [error,setError] = useState(false);
    const [searchtext,setSearchText] = useState('');
    const isMounted = useRef(true);
    const [selectedCategory,setSelectedCatgory] = useState()

    // const [agriculturalDisplay,setAgriculturalDisplay] = useState(false)
    // const [cattleDisplay,setCattleDisplay] = useState(false)
    // const [fertilizerDisplay,setFertilizerDisplay] = useState(false)
    // const [medicinalPlantDisplay,setMedicinalPlantDisplay] = useState(false)
    // const [dairyProductDisplay,setDairyDisplay] = useState(false)
    
    //const [placeholder,setPlaceHolder] = useState(strings.find_agriculture_product_and_many_more);
    const handleSearch = (query) => {
      let url = `${API}/ads/filterads/?super_category=${super_category}&search=`+query.searchtext
      
      fetchMoreResults(url)  
      .then(response=> {
        return response.json()
      })
      .then(data => {
        clearState([]);
        setTempSearchResult(data.results);
        setNextUrl(data.next);
      })
      .catch(err => {
          setError(true);
      })
      
    }

    const clearState = () => {
       setSearchResult([])
    }

    const [resetSearchResult,setResetSearchResult] = useState(false)
    const rememberResetState = useRef(true)
    const triggerSearch = (query) => (e) => {
        e.preventDefault();
        setLoading(true)
        handleSearch({searchtext});   
        setLoading(false)
    }
    useEffect(() => {  
      setLoading(true)
      handleSearch({searchtext}); 
      setLoading(false)
    }, []);

    
    const handleCategoryFilter = (query) => {
        const searchTextState = [...searchtext]
        searchTextState.searchtext = query
        setSelectedCatgory(query)
        handleSearch(searchTextState)
        setSearchText(searchTextState)       
    }

    const handleCustomSearchResults = (results) => {
          setSearchResult(results)
    }

    const fetchMoreResults = (url) => {

      const lang = getEKLanguage()
      return fetch(url,{
        method: "GET",
        headers: {
            Accept:'application/json',
              "Accept-Language":`${lang}`                   
        }
      })
      
    }

    const [customError,setCustomError] = useState('')
    const isValid = ({state,product,district,block,category,min_price,max_price}) => {
      if(state === '') {
        setCustomError('State not selected')
          return false
      }      

      if(district === '') {
        setCustomError('District not selected')
          return false
      }
      
      if(block===''){
        setCustomError('Block not selected')
          return false
      }
      
      if(category==='') {
        setCustomError('Category not selected')
        return false
      }
      
      if(product === '') {
          setCustomError('Product not selected')
          return false
      }
      if(max_price <= 0 || max_price <= min_price) {
        setCustomError('Max price is not valid')
          return false
      }
      if(min_price <= 0 || min_price >= max_price) {
        setCustomError('Min price is not valid')
        return false
      }
      return true
  }    

    const handleCustomSubmit = (params) => {
      
      // if(isValid(params) === false){
      //     return
      // }
      
      if(params.state === '') {
        setCustomError('State not selected')
        return false
      }      

      if((params.max_price !== '') && (params.max_price <= 0 || params.max_price <= params.min_price)) {
        setCustomError('Max price is not valid')
          return false
      }
      if((params.min_price !== '') && (params.min_price <= 0 || params.min_price >= params.max_price)) {
        setCustomError('Min price is not valid')
        return false
      }
      let query = `super_category=${super_category}`
      if(params.state !== '') {
        query = query + `&state=${params.state}`
      }
      if(params.district !== '') {
        query = query + `&district=${params.district}`
      }
      if(params.block !== '') {
        query = query + `&block=${params.block}`
      }
      if(params.category !== '') {
        query = query + `&category_name=${params.category}`
      }
      if(params.product !== '') {
        query = query + `&product=${params.product}`
      }
      if(params.min_price !== '') {
        query = query + `&product_price_min=${params.min_price}`
      }
      if(params.max_price !== '') {
        query = query + `&product_price_max=${params.max_price}`
      }
      
      setCustomError('')
      //super_category=${super_category}&category_name=${params.category}&product=${params.product}&state=${params.state}&district=${params.district}&block=${params.block}&product_price_min=${params.min_price}&product_price_max=${params.max_price}&product=${params.product}
      const url=`${API}/ads/filterads/?${query}`
      const lang = getEKLanguage()
      return fetchMoreResults(url)
      .then (response => {
          return response.json();
      })
      .then(data=> {
        clearState([])
        setSearchResult(data.results)
        setNextUrl(data.next)
        
      })
      .catch(err => {
          console.log(err)
      })
  }

  function custom_sort(a, b) {
    return new Date(b.posted_on).getTime() - new Date(a.posted_on).getTime();
  }

  const [tempSearchResult,setTempSearchResult] = useState([]);
  useEffect(()=>{
    if(tempSearchResult.length > 0)
      setSearchResult([...tempSearchResult].sort(custom_sort))
  },[tempSearchResult])

  const handleMoreEvent = () => {    
      
      if(next_url !== null) {
          fetchMoreResults(next_url)
          .then(response=> {
            return response.json()
          })
          .then(data => {
            setTempSearchResult([...tempSearchResult,...data.results]);
            setNextUrl(data.next);
          })
          .catch(err => {
              setError(true);
          })
      }
    }

    const [customFilterDisplay,setCustomFilterDisplay] = useState(false)
    const [buyofferredirect,setBuyOfferRedirect] = useState(false)
    const redirectToBuyOrder = () => {
      
      if(buyofferredirect){
        return <Redirect to="/BuyOffer" />
      }
    }

    const redirectToBuyBackOrder = (e) => {
      return <Redirect to="" />
    }

    const redirectToMoreService =(e)=>{
      return <Redirect to="" />
    }

    const redirectButtons = () => (
      <>
      <div className="ml-auto">
        <Button size='sm' variant="success" size="sm" className=" mt-1 mx-1 rounded-0 col-lg-3 col-12" onClick={(e)=>{setBuyOfferRedirect(true)}}>
          Buy Order
        </Button>

      <Link to="/BuyBackOffer">
        <Button size='sm'  className=' mx-1 col-lg-4 col-12 rounded-0 mt-1' size="sm"  variant="success" >Buy Back Order</Button>
      </Link>
      <a href ="https://www.ekrishibazaar.in">
        <Button size='sm'  className=' mx-1 col-lg-3 col-12 rounded-0 mt-1' size="sm"  variant="success" >More Services</Button>
      </a>      
      </div>
      </>
    )

    const [redirectToBuyOffer,setRedirectToBuyOffer] = useState(false)
    const [redirectToBuyBackOffer,setRedirectToBuyBackOffer] = useState(false)

    const RedirectToPostBuyOffer = () => {    
      if(redirectToBuyOffer)
          return <Redirect to="/postad/BuyOffer/" />
    }

    const RedirectToPostBuyBackOffer = () => {

      if(redirectToBuyBackOffer)
      {
        return <Redirect to="/postad/BuyBackOffer/" />
      }
    }
    useEffect(() => {
      handleMoreEvent();
    }, [next_url])

    return (
        <React.Fragment>
            {redirectToBuyOrder()}
            {RedirectToPostBuyOffer()}
            {RedirectToPostBuyBackOffer()}
            <div className="container-fluid">
                <div className="row " >
                    <div className=" col-lg-6 mx-auto col-12 " >
                        <Form className="form-signin ">
                            <Form.Row className="mt-2 col-12 flex" >
                            <Form.Control value={searchtext}  type="text" className = "mx-0 col-10 col-lg-8   zero-border"
                            placeholder={strings.find_agriculture_product_and_many_more} 
                            onChange={ (e) => { setSearchText(e.target.value)}} />
                            <Button type="submit" className="inline zero-border"  onClick={triggerSearch({searchtext})}>
                                <FontAwesomeIcon icon={faSearch} />
                            </Button>
                            </Form.Row>
                                       
                        </Form>
                    </div>      
                    
                    <div className=" mt-2 col-lg-4 col-12 flex " >
                    {
                                super_category=== "Sellads"?
                                redirectButtons()
                                :
                                super_category === "BuyOffer"?
                                  <Button onClick={(e)=> {e.preventDefault();  setRedirectToBuyOffer(true)}} variant="success" size="sm"> Post buying offer </Button>
                                :
                                  <Button  onClick={(e)=> {e.preventDefault(); setRedirectToBuyBackOffer(true)}} variant="success" size="sm">Post your buying back offer</Button>
                            }    
                    </div>
                </div>
            </div>
            {
                        isAuthenticated() ?
                        ''
                        :
                        <div className="container-fluid">
                            <div className="row">
                                <img src={bg} style={{width:"100%",maxHeight:"500px"}}/>   
                            </div>
                        </div>
            }         
            {/* <Category  super_category={super_category} setCategorySearch={cty => handleCategoryFilter(cty)} initCategory={cty => setSelectedCatgory(cty)}/>
            <CustomizeSearchPanel setSearchResults={(cty) => (e) => {
                    
                    e.preventDefault() 
                    handleCustomSubmit(cty)
                  } 
                }/>             */}
            <div className="mt-4">   
            <Category  super_category={super_category} setCategorySearch={cty => handleCategoryFilter(cty)} initCategory={cty => setSelectedCatgory(cty)}/>
            

            <div className="container-fluid mt-2">
              <div className="row">
                <div className="col-12">
                    <Button size="md" variant="success"  className="  rounded-1 text-white float-right  my-0" onClick={(e)=>{e.preventDefault();  setCustomFilterDisplay(!customFilterDisplay)}}>
                                    Filter    
                    </Button>
                </div>
                

                
            {
            customFilterDisplay ?
            <>
            
                <CustomizeSearchPanel selectedCategory={selectedCategory} setSearchResults={(cty) => (e) => {
                    
                    e.preventDefault() 
                    handleCustomSubmit(cty)
                  } 
                }/>
                <div className="col-12">
                {
                  customError?
                <p className="mx-auto" style={{color:"red",fontSize:"0.8em",textAlign:"center"}}>{customError}</p>
                :
                ''
                }
                </div>
              
            </>
            : ''
            }
            </div>
            </div>
            </div>
            <br />
            
            
            { loading ? 
                <p>loading...</p>
                :
                <>
                <div className="container-fluid p-0">
                <h4 className="m-2 text-muted">Fresh Recommendation</h4>
                    <div className="infinite row m-0">
                   
                    <DisplaySearchResult resultsToRender={searchResult} />
                    {/*<InfiniteScroll dataLength={searchResult.length}
                      next={handleMoreEvent}
                      hasMore={true}
                      className="col-lg-12 col-12 flex-wrap"
                      style={{width:"100%",display:"flex"}}
                    >
                        <DisplaySearchResult resultsToRender={searchResult} />
                    </InfiniteScroll>
                         <div className="row mt-2 mx-auto">
                        <Button className="btn btn-primary " onClick= {handleMoreEvent}>
                            {strings.see_more}
                        </Button>
                        </div> */}
                    </div>
                </div>
                {/* <div className="container-fluid ">
                  <div className=" col-lg-3 m-0">
                      <AgriCustomSearchPanel setSearchResults={cty => handleCustomSearchResults(cty)} selectedcategory={selectedCategory}/>
                  </div>
                  <div className="col-lg-4 mx-0">
                    <DisplaySearchResult resultsToRender={searchResult} />


                    <Button className="btn btn-primary m-auto" onClick= {handleMoreEvent}>
                        {strings.see_more}
                    </Button>

                  </div>
                </div>   */}
                </>
            }
            
        </React.Fragment>
    )
}