import React ,{useState,useEffect} from 'react';
import {Form,FormControl,Button,Spinner} from 'react-bootstrap';
import {Redirect} from 'react-router-dom';
import { fetchChemicalProducts, fetchChemicalTypes,fetchFnPAds } from './FertilizersAndPesitisidesApiCore';
import FertilizersAndPesitisidesReviewPost from './FertilizersAndPesitisidesReviewPost'
import Layout from '../Layout';
import {getEKLanguage} from '../../utils/language_utils'

import {API} from '../../config'

import {validateSession,isAuthenticated, signout} from '../../auth'
// import FnPCard from './FnPCard';
import {strings} from '../../utils/translated_strings'
import {fetchStates,fetchdistrict,fetchblocks} from '../../utils/AreaUtils'

const FertilizersAndPestisidesEditPost = (props) => {

    const [fertilizersAndPestisidesPostFields,setfertilizersAndPestisidesPostFields] = useState({
        id:'',
        chemical_product : '',
        chemical_type:'',
        packing:'',
        product_price:'',
        unit_per:'',
        photo1:"",
        photo2:"",
        photo3:"",
        state:'',
        district:'',
        block:'',
        village:'',         
        additional_information:'',
    })    

    const ErrorInitState = {
        chemical_product_error : '',
        chemical_type_error:'',
        packing_error:'',
        product_price_error:'', 
        unit_per_error:'',
        state_error:'',
        district_error:'',
        block_error:'',          
    }     

    const [adposted,setAdPosted] = useState(false)
    useEffect(()=>{
        fetchblocks(fertilizersAndPestisidesPostFields.district)
        .then(response=>{
            return response.json()
        })
        .then(data=>{
            setBlocks(data)
        })
    },[fertilizersAndPestisidesPostFields.district])

    useEffect(()=>{
        fetchdistrict(fertilizersAndPestisidesPostFields.state)
        .then(response=>{
            return response.json()
        })
        .then(data=>{
            setDistricts(data)
        })
    },[fertilizersAndPestisidesPostFields.state])

    const isValid = () => {
        let flag = true;
        let chemical_product_error = '' ;
        let chemical_type_error = '';
        let packing_error = '';
        let unit_per_error = '';
        let product_price_error = '' ;
        
        if(fertilizersAndPestisidesPostFields.chemical_product === '') {
            chemical_product_error = "Please select type of product"
            flag = false
        }
        if(fertilizersAndPestisidesPostFields.chemical_type === '') {
            chemical_type_error = "Please select type of fertilizer or pestiside"
            flag = false;
        }
        if(fertilizersAndPestisidesPostFields.packing === '' | fertilizersAndPestisidesPostFields.packing <= 0) {
            packing_error = "Please enter valid packing"
            flag = false;
        }
        if(fertilizersAndPestisidesPostFields.unit_per === '' ) {
            unit_per_error = "Please enter unit of packing"
            flag = false;
        }
        if(fertilizersAndPestisidesPostFields.product_price === 0 || fertilizersAndPestisidesPostFields.product_price === '') {
            product_price_error = "Price must be greator than 0"
            flag = false;
        }
        setfertilizersAndPestisidesPostFieldsErrors({chemical_product_error,chemical_type_error,packing_error,unit_per_error,product_price_error})
        return flag
    }

    const handleCategorySelection = (selected) => {    
        setfertilizersAndPestisidesPostFields({...fertilizersAndPestisidesPostFields,category_name:selected})
        
        //getProduct(selected)
    }

    const [fertilizersAndPestisidesPostFieldsErrors,setfertilizersAndPestisidesPostFieldsErrors] = useState(ErrorInitState)

    const [loading,setLoading] = useState(true)
    const [error,setError] = useState(false)
    const [id,setId] = useState(false)
    const [chemicalTypes,setChemicalTypes] = useState([])
    const [chemicalProducts,setChemicalProducts] = useState([])

    const [review,setReview] = useState(false)
    const [photos,setPhotos] = useState({
        photo1:"https://via.placeholder.com/300",
        photo2:"https://via.placeholder.com/300",
        photo3:"https://via.placeholder.com/300"
    })

    const redirectToItem = () => {
        if(id) {
            return <Redirect to={`/item/detail/fertilizers-and-pestisides/${id}`} />
        }
    }    

    const destructAndSetAllData = (d) => {
        fetchStates()
        .then(response=>{
            return response.json()
        })
        .then (data=>{
            setStates(data)
        })

        setfertilizersAndPestisidesPostFields({ id:d.id, 
        category : d.chemical_product.category.category_name, 
        chemical_product  : d.chemical_product.chemical_product_name, 
        chemical_type  : d.chemical_type.type_of_chemical, 
        packing : d.packing,
        product_price:d.price,
        additional_information:d.additional_information,
        unit_per : d.per_unit,
        state:d.state.state_name,
        district:d.district.district_name,
        block:d.block.block_name,
        village:d.village,        
        // photo1:d.product_image1,
        // photo2:d.product_image2,
        // photo3:d.product_image3
        })
    
        setPhotos({photo1:d.product_image1,photo2:d.product_image2,photo3:d.product_image3})
    }

    useEffect(()=>{
        validateSession()
        .then(response => {
            return response.json()
        }).then(data => {
            if(data.detail){
                setError("You are not logged in")    
            }
        }).catch(err=>{
            setError("You are not logged in")    
        })        


        fetchChemicalTypes()
        .then(response => {
            return response.json()
        })
        .then(data => {
            setChemicalTypes(data)
            
        })
        .catch(error=>{
            setError(error)
        })
        fetchFnPAds(props.match.params.id)
        .then(response=>{
            return response.json()
        })
        .then(data => {
            if(data.detail)
            {
                
                setError(true)
                setLoading(false)
            }
            else {
                destructAndSetAllData(data)
                setLoading(false)
            }
        })
        .catch(err=>{
            console.log(err)
            setError(err)
        })
        
    },[])

    useEffect(()=>{
        fetchChemicalProducts(fertilizersAndPestisidesPostFields.chemical_type)
        .then(response => {
            return response.json()
        })
        .then(data => {
            setChemicalProducts(data)
        })
        .catch(error=>{
            setError(error)
        })
    },[fertilizersAndPestisidesPostFields.chemical_type])

    const submitAdvertisement = () => {
        let lang = getEKLanguage()
        let token = isAuthenticated()   
        token = token.token
        var fd = new FormData();

        let photo1 = null
        let photo2 = null
        let photo3 = null
        if(fertilizersAndPestisidesPostFields.photo1 !== photos.photo1)
            photo1 = fertilizersAndPestisidesPostFields.photo1
        if(fertilizersAndPestisidesPostFields.photo2 !== photos.photo2)
            photo2 = fertilizersAndPestisidesPostFields.photo2
        if(fertilizersAndPestisidesPostFields.photo3 !== photos.photo3)
            photo3 = fertilizersAndPestisidesPostFields.photo3
        
        
        fd.append('chemical_product',fertilizersAndPestisidesPostFields.chemical_product)
        fd.append('chemical_type',fertilizersAndPestisidesPostFields.chemical_type)
        fd.append('packing',fertilizersAndPestisidesPostFields.packing)
        fd.append('product_price',fertilizersAndPestisidesPostFields.product_price)
        fd.append('unit_per',fertilizersAndPestisidesPostFields.unit_per)
        fd.append('additional_information',fertilizersAndPestisidesPostFields.additional_information)
        fd.append('photo1',fertilizersAndPestisidesPostFields.photo1)
        fd.append('photo2',fertilizersAndPestisidesPostFields.photo2)
        fd.append('photo3',fertilizersAndPestisidesPostFields.photo3)
        fd.append('state',fertilizersAndPestisidesPostFields.state)
        fd.append('district',fertilizersAndPestisidesPostFields.district)
        fd.append('block',fertilizersAndPestisidesPostFields.block)
        fd.append('village',fertilizersAndPestisidesPostFields.village)            
        
        fetch(`${API}/ads/fertilizersandpestisides/${fertilizersAndPestisidesPostFields.id}/`,{
            method: "PUT",
            headers: {
                Accept:'application/json',
                "Accept-Language":`${lang}`,
                Authorization:`Token ${token}`
            },
            body: fd
        })
        .then(response => {
            return response.json()
        })
        .then( data => {
            if(data.detail) {
                if(data.detail.toLowerCase().includes('token')) {
                    signout(()=>{
                        window.location.reload(false)
                    })
                } else {
                    setError(data.detail)
                }
            } else {
             setId(data.id)
            }            
        })
        .catch(err => {
            
            setError(err)
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if(isValid()){
            setAdPosted(true)
            submitAdvertisement()
            
            setfertilizersAndPestisidesPostFields(ErrorInitState)
            setReview(false)
        }
    }

    const handleReview = (e) => {
        e.preventDefault()
        if(review === true){
            setReview(false)
            return
        }
        if(isValid())
        {
            setReview(true)
        }
        else{
            setReview(false)
        }
    }    

    const handleSelection = (name) => (e) => {
        setfertilizersAndPestisidesPostFields({...fertilizersAndPestisidesPostFields,[name]:e.target.value})
    }

    const handleImageSelection = (name) => (e) => {
        e.preventDefault()
        const reader =new FileReader()
        reader.onload = () => {
            if(reader.readyState === 2) {
                setPhotos({...photos,[name]:reader.result})
            }
        }
        reader.readAsDataURL(e.target.files[0])
        setfertilizersAndPestisidesPostFields({...fertilizersAndPestisidesPostFields,[name]:e.target.files[0]})
    }

    const [states,setStates] = useState([])
    const [districts,setDistricts] = useState([])
    const [blocks,setBlocks] = useState([])

    const handleStateChange = (name,func,setFunc) => (event) => {
        setfertilizersAndPestisidesPostFields({...fertilizersAndPestisidesPostFields,[name]:event.target.value })
        func(event.target.value).then(response=> {
            return response.json()
        })
        .then(data => setFunc(data))
        .catch(err => {
            console.log(err)
        });
    }

    const FAndPForm = () => (
        <React.Fragment>
        <Form className="mt-4 mx-auto" onSubmit={handleSubmit}>            
            <h3 className="gray heading">Enter details </h3>
            <hr />

                <Form.Row>
                    <Form.Label  column sm="4">
                            Select Type of Product:
                    </Form.Label>                
                    <FormControl as="select" value={fertilizersAndPestisidesPostFields.chemical_type} onChange={handleSelection("chemical_type")} size="sm" className="col-lg-4 mt-2">                        
                    <option key="Select chemical type">type of product</option>
                        {
                            chemicalTypes.map((d,id) => {
                                return <option key={id}>{d.type_of_chemical}</option>
                            })
                        }
                    </FormControl>            
                    <div className="d-block" style={{fontSize:"0.8em",color:"red"}}>{fertilizersAndPestisidesPostFieldsErrors.chemical_type_error}</div>
                </Form.Row>
                <Form.Row>
                    <Form.Label  column sm="4">
                            Type of Fertilizer or pestiside:
                    </Form.Label>                
                    <FormControl as="select" value={fertilizersAndPestisidesPostFields.chemical_product} onChange={handleSelection("chemical_product")} size="sm" className="col-lg-4 mt-2">                        
                    <option key="Select chemical product">products</option>
                        {
                            chemicalProducts.map((d,id) => {
                                return <option key={id}>{d.chemical_product_name}</option>
                            })
                        }
                    </FormControl>            
                    <div className="d-block" style={{fontSize:"0.8em",color:"red"}}>{fertilizersAndPestisidesPostFieldsErrors.chemical_product_name_error}</div>
                </Form.Row>
                <Form.Row>
                    <Form.Label  column sm="4">
                            Packing:
                    </Form.Label>

                    <FormControl type="text" value={fertilizersAndPestisidesPostFields.packing} onChange={handleSelection("packing")} size="sm" className="col-lg-1 mt-2"/>                                        
                    <div className="d-block" style={{fontSize:"0.8em",color:"red"}}>{fertilizersAndPestisidesPostFieldsErrors.packing_error}</div>
                    <FormControl as="select" value={fertilizersAndPestisidesPostFields.unit_per} onChange={handleSelection("unit_per")} size="sm" className="col-lg-2 ml-2 mt-2">                        
                        <option>Gram</option>
                        <option>ml</option>
                        <option>Kg</option>
                        <option>litre</option>
                    </FormControl>            
                    <div className="d-block" style={{fontSize:"0.8em",color:"red"}}>{fertilizersAndPestisidesPostFieldsErrors.unit_per_error}</div>
                </Form.Row>  
                <Form.Row>
                    <Form.Label  column sm="4">
                            Price:
                    </Form.Label>                
                    <FormControl type="text" value={fertilizersAndPestisidesPostFields.product_price} onChange={handleSelection("product_price")} size="sm" className="col-lg-1 mt-2"/>                        
                    <div className="d-block" style={{fontSize:"0.8em",color:"red"}}>{fertilizersAndPestisidesPostFieldsErrors.product_price_error}</div>
                </Form.Row> 

                <Form.Row>
                        <Form.Label column sm="4">
                                Select Your State
                        </Form.Label>                
                        <FormControl size="sm" as="select"  defaultValue={fertilizersAndPestisidesPostFields.state} onChange={handleStateChange("state",fetchdistrict,setDistricts)} className="col-lg-4 mt-2">
                            
                            {
                                states.map(d=>{
                                    return <option key={d.id}>{d.state_name}</option>
                                })
                            }
                        </FormControl>            
                        <div style={{fontSize:"0.8em",color:"red"}}>{fertilizersAndPestisidesPostFieldsErrors.state_error}</div>
                    </Form.Row>
                    <Form.Row>
                        <Form.Label column sm="4">
                                Select Your District
                        </Form.Label>                
                        <FormControl size="sm" as="select" defaultValue={fertilizersAndPestisidesPostFields.district} onChange={handleStateChange("district",fetchblocks,setBlocks)} className="col-lg-4 mt-2">
                                
                                {
                                    districts.map(d => {
                                        return <option key={d.id}>{d.district_name}</option>
                                    })
                                }
                        </FormControl>            
                        <div style={{fontSize:"0.8em",color:"red"}}>{fertilizersAndPestisidesPostFieldsErrors.district_error}</div>
                    </Form.Row>
                    <Form.Row>
                        <Form.Label column sm="4">
                                Select Your Block
                        </Form.Label>                
                        <FormControl size="sm" as="select" defaultValue={fertilizersAndPestisidesPostFields.block} onChange={handleSelection("block")} className="col-lg-4 mt-2">
                                
                                {
                                    blocks.map(d => {
                                        return <option key={d.id}>{d.block_name}</option>
                                    })
                                }
                        </FormControl>            
                        <div style={{fontSize:"0.8em",color:"red"}}>{fertilizersAndPestisidesPostFieldsErrors.blocks_error}</div>
                    </Form.Row>
                    <Form.Row>
                        <Form.Label column sm="4">
                                Enter your village name
                        </Form.Label>                
                        <FormControl size="sm" type="text" value={fertilizersAndPestisidesPostFields.village} onChange={handleSelection("village")} className="col-lg-4 mt-2" />
                    </Form.Row>      
                    

                <Form.Row>
                    <Form.Label column sm="4">
                        Additional Information:
                    </Form.Label>
                    <FormControl as="textarea" style={{resize:"none"}} rows={3}  value={fertilizersAndPestisidesPostFields.additional_information} onChange={handleSelection("additional_information")} className="col-lg-4 mt-2 mr-sm-2" size="sm"  placeholder=""/>
                    <div style={{fontSize:"0.8em",color:"red"}}>{fertilizersAndPestisidesPostFieldsErrors.additional_information_error}</div>
                </Form.Row>  
                <Form.Row className="m-auto ">
                        <div className="col-2">
                        <label className="mt-2 btn btn-default btn-outline-secondary btn-sm mx-auto" >
                            Click to Browse <input type="file" hidden onChange={handleImageSelection("photo1")}  className="mt-2"/>
                        </label>
                        <img src={photos.photo1}
                            style={{height:"150px",width:"150px"}} alt="photo1"/>
                        </div>
                        <div className="col-2 ml-4">
                        <label className="mt-2 btn btn-default btn-outline-secondary btn-sm mx-auto">
                            Click to Browse <input type="file" hidden onChange={handleImageSelection("photo2")}  className="mt-2"/>
                        </label>
                        <img src={photos.photo2}
                            style={{height:"150px",width:"150px"}} alt="photo2"/>                        
                        </div>
                        {/* //style={{display:"inline-block",width:"100px",height:"100px",backgroundImage:`url(${data.photo3})`}} */}
                        <div className="col-2 ml-4">
                            <label className="btn mt-2 btn-default btn-outline-secondary btn-sm" >
                                Click to Browse<input type="file" hidden onChange={handleImageSelection("photo3")}  className="mt-2"/>
                            </label>
                            <img src={photos.photo3} style={{height:"150px",width:"150px"}} alt="photo3"/>                        
                        </div>
                    </Form.Row>
                    <div className="form-row col-10 mt-4">
                        <div className="col-3">
                            <Button  variant="info" onClick={handleReview}>Review</Button>
                        </div>
                        <div className="col-3 ml-auto">
                        <Button  className=" " type="submit" variant="info">
                                {
                                    adposted?
                                    <>
                                        <Spinner animation="border" role="status">
                                        <span className="sr-only">Saving...</span>
                                        </Spinner>                                    
                                    </>:

                                    <span>Submit</span>
                                }
                            </Button>     
                        </div>
                    </div>                                                  
        </Form>
        {redirectToItem()}
        </React.Fragment>
    )

    return (
        <>
        <Layout>
        {
        review ?
            
            <>
            <FertilizersAndPesitisidesReviewPost postData={fertilizersAndPestisidesPostFields} photos={photos}/>
            <div className="container">
                <div className="row">
                <Button  className="m-auto" variant="info" onClick={handleReview}>Go back to edit</Button>
                <div className="col-3 ml-auto">
                <Button onClick={handleSubmit} className=" " type="submit" variant="info">
                                {
                                    adposted?
                                    <>
                                        <Spinner animation="border" role="status">
                                        <span className="sr-only">Saving...</span>
                                        </Spinner>                                    
                                    </>:

                                    <span>Submit</span>
                                }
                            </Button>     
                </div>                
                </div>
            </div>
            </>
            :
            loading ? 
            '':
            (
                error?
            <div className="alert alert-warning col-6 mx-auto">
                <h3>{error}</h3>
            </div>
            :
            <div className="col-lg-10 col-12 ml-auto" >
                    {FAndPForm()}                
            </div>
            )
        }
        </Layout>
        </>        
    )
}

export default FertilizersAndPestisidesEditPost;