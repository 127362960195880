import { getToken } from '../../auth';
import {API} from '../../config';
import {getEKLanguage} from '../../utils/language_utils';


export const fetchMachinery = (id) => {
    let lang = getEKLanguage()
    
    return fetch(`${API}/ads/agriculturalmachinary/`,{
        "method":"GET",
        "headers":{
            Accept:'application/json',
            "Content-Type":"application/json",
            "Accept-Language":`${lang}`,
            
        }
    })
}

export const fetchMachineBrand = () => {
    let lang = getEKLanguage()
    
    return fetch(`${API}/ads/machinebrand/`,{
        "method":"GET",
        "headers":{
            Accept:'application/json',
            "Content-Type":"application/json",
            "Accept-Language":`${lang}`,
            
        }
    })
}

export const fetchMachineStatus = () => {
    let lang = getEKLanguage()
    
    return fetch(`${API}/ads/machinestatus/`,{
        "method":"GET",
        "headers":{
            Accept:'application/json',
            "Content-Type":"application/json",
            "Accept-Language":`${lang}`,
            
        }
    })
}

export const fetchWarrantyStatus = () => {
    let lang = getEKLanguage()
    
    return fetch(`${API}/ads/warrantstatus/`,{
        "method":"GET",
        "headers":{
            Accept:'application/json',
            "Content-Type":"application/json",
            "Accept-Language":`${lang}`,
            
        }
    })
}


export const fetchMachinaryAds = (id) => {
    let lang = getEKLanguage()
    var fd = new FormData()
    let token = getToken()
    fd.append('toedit', 'optm')   
    return fetch(`${API}/ads/agricultralmachinaryads/${id}/?toedit=optm/`,{
        "method":"GET",
        "headers":{
            Accept:'application/json',
            "Content-Type":"application/json",
            "Accept-Language":`${lang}`,
            "Authorization":`Token ${token}`
        }
    })
}
