import React from 'react';
import {Navbar,Nav} from 'react-bootstrap';
// import Layout from './Layout';
// import {API} from '../config';
import { isAuthenticated,signout } from '../auth';
import logo from "../assets/Logo_BRAND.png";

const NavBarBrand = () => {
    return (
        <Navbar.Brand href={
            isAuthenticated() && isAuthenticated().is_staff ?
           "/admin/dashboard":
           "/admin/signin"
       }>
        <img
            src={logo}
            width="40"
            height="40"
            className="d-inline-block align-top"
            alt="eKrishiMandi"
        />
    </Navbar.Brand>
    )
}

const AdminNavbar = () => {
    
    return (
    <Navbar  expand="lg" className="green" >
        <NavBarBrand />
        <Navbar.Toggle className="text-white" aria-controls="basic-navbar-nav" />
        <Navbar.Collapse className="text-white" id="basic-navbar-nav">
            <Nav className="mr-auto">
            <Nav.Link className="text-white" href=
            {
                 isAuthenticated() && isAuthenticated().is_staff ?
                "/admin/dashboard":
                "/admin/signin"
            }
            >Home</Nav.Link>
            {
                 isAuthenticated() && isAuthenticated().is_staff ?
                 <Nav.Link className="text-white" onClick={(e)=>{
                     signout(() => {
                        window.location.reload(false);
                    })
                 }}>Signout</Nav.Link>:
                 <Nav.Link className="text-white" href="/admin/signin">Signin</Nav.Link>
            }
            
            </Nav>
        </Navbar.Collapse>
    </Navbar>    
    )
}

export default AdminNavbar;