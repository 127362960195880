import React, {useState} from 'react';
import { isAuthenticated } from '../auth';
import DashBoardLayout from '../core/DashBoardLayout';
import {createCategory} from './AdminApi';
import {Form,Image} from 'react-bootstrap';

const AddCategory = () => {
    const [name,setName] = useState({
        category_name:"",
        category_image:""
    });
    const [error,setError] = useState(false);
    const [success,setSuccess] = useState(false)
    const {user,token} = isAuthenticated();

    const handleChange  = (e) => {
        setError('')
        setName({...name,category_name:e.target.value})
    }

    const fileUpload = (e) => {
        if(name.category_name === '')
        {
            setError("Please enter category name first")
        }
        else {
            console.log((e.target.value))
            //let u = window.URL.createObjectURL();
            setName({...name,category_image:(e.target.files[0])})

            // const mediaStream = new MediaStream();
            // const video = document.getElementById('video-player');
            // video.srcObject = mediaStream;
            //setName({...name,category_image:})
        }
    }

    const clickSubmit = (e) => {
        e.preventDefault();
        setError('')
        setSuccess(false)
        if(name.category_name==='')
        {
            setError('Please enter category name')
        }
        else if(name.category_image === '')
        {
            setError('Please select category image')
        } else {
            createCategory(token,name)
        }

    }
    const showError = () => (
        (error ?
            <div className="alert alert-danger"> {error} </div>
            :
            ""
        )
    )

    const newCategoryForm = () => (
        <>
        {showError()}
        <Form onSubmit={clickSubmit}>
            <div className="form-group">
                <label className="text-muted">Name</label>
                <input 
                    type="text"
                    className="form-control"
                    value={name.category_name}
                    onChange={handleChange}
                    autoFocus
                />
                  <Form.File 
                        id="image-file"
                        label="Select Category file"
                        custom
                        disp
                        onChange={fileUpload}
                        accept="image/*"
                    />

            </div>
            <button type="submit" className="btn btn-outline-primary">
                    Create Category
            </button>
        </Form>
        </>
    )
    return (
        <DashBoardLayout
            title="Add a new Category"
            description={`G'day ${name}, ready to add a new category?`}
        >

            <div className="row">
                <div className="col-md-3">
                    
                        {
                            (name.category_image ? 
                                <Image rounded className="offset-2" src={name.category_image} /> : ''
                            )
                        }
                    
                </div> 
                <div className="col-md-8 ">{newCategoryForm()}</div>
            </div>
        </DashBoardLayout>
    )
}

export default AddCategory;