import React from 'react';
import {Carousel} from 'react-bootstrap'


const MachineReviewPost = ({postData,photos}) => {
    return (

        <div className="container">
        <div className="row m-auto">
                            <div className="col-12 col-lg-4 mt-4 ml-auto">
                                <Carousel >
                                    <Carousel.Item >
                                        <img
                                        className="d-block w-100"
                                        src={photos.photo1}
                                        alt="First slide"
                                        style={{height:"200px",width:"200px"}}
                                        />
                                    </Carousel.Item>
                                    
                                    
                                    <Carousel.Item >
                                        <img
                                        className="d-block w-100"
                                        src={photos.photo2}
                                        alt="Second slide"
                                        style={{height:"200px",width:"200px"}}
                                        />
                                    </Carousel.Item >
                                    
                                    
                                    <Carousel.Item >
                                        <img
                                        className="d-block w-100"
                                        src={photos.photo3}
                                        alt="Third slide"
                                        style={{height:"200px",width:"200px"}}
                                        />
                                    </Carousel.Item>
                                    
                                </Carousel>
                            </div>
            <div className="col-12 col-lg-6 mx-auto mt-2 ">
                
                <h3 className="text-secondary mx-auto mt-4">Selected Details</h3>
                <br />
                <div className="table-responsive">          
                <table className="table">
                    <tbody>
                    <tr>
                        <td>Selected Type of machinary</td>
                        <td>{postData.machine_name}</td>
                    </tr>
                    <tr>
                        <td>Selected Product Brand</td>
                        <td>{postData.machine_brand}</td>
                    </tr>
                    <tr>
                        <td>Status of Product</td>
                        <td>{postData.machine_status}</td>
                    </tr>
                    <tr>
                        <td>Used for</td>
                        <td>{postData.months_in_use}</td>
                    </tr>
                    <tr>
                        <td>Warranty Status</td>
                        <td>{postData.warranty_status} {postData.remaining_warranty_month} Month</td>
                    </tr>
                    <tr>
                        <td>Price</td>
                        <td>{postData.product_price}</td>
                    </tr>
                    <tr>
                        <td>State</td>
                        <td>{postData.state}</td>
                    </tr>
                    <tr>
                        <td>District</td>
                        <td>{postData.district}</td>
                    </tr>
                    <tr>
                        <td>Block</td>
                        <td>{postData.block}</td>
                    </tr>
                    <tr>
                        <td>Village</td>
                        <td>{postData.village}</td>
                    </tr>                                                                                
                    <tr>
                        <td>Additional Information</td>
                        <td>{postData.additional_information}</td>
                    </tr>
                    </tbody>
                </table>
                </div>
            </div>
            </div>
            </div>
    )
}

export default MachineReviewPost;