import React from 'react';
import Layout from './Layout';
import {Search} from './Search';

const BuyOfferHome = () => {
    
    return <Layout>
        
            <Search super_category="BuyOffer"/>
        
    </Layout>
}

export default BuyOfferHome;