import React,{useState,useRef,useEffect} from 'react';
import Layout from './Layout'
import {API} from '../config';
import {Redirect} from 'react-router-dom';
import {clearToken, isAuthenticated, validateSession} from '../auth/'

const ResetPassword = () => {
    const [otp,setOTP] = useState('')
    const [otpMatch,setOTPMatched] = useState(false)
    const [requestorPhoneNumber,setRequestorPhoneNumber] = useState('')
    const [error,setError] = useState(false)
    const [success,setSuccess] = useState(false)
    const [password,setPassword] = useState('')
    const [repassword,setRePassword] = useState('')
    const getOTP = (e) =>{
        
        e.preventDefault()
        setError('')

        if(requestorPhoneNumber.length<10){
            return
        }

        fetch(`${API}/resetpassword/?phone_number=${requestorPhoneNumber}`,{
            method:"GET",
            headers:{
                Accept:'application/json',
                "Content-Type":"application/json"
            }
        }).then(response=>{
            return response.json()
        }).then(data=>{
            
            if(data.detail){
                setError(data.detail)
            }
            else {
                setOTP(data.otp)
            }
            
        }).catch(err=>{
            setError("Something went wrong")
        })
    }

    const handleChange = (e) => {
        e.preventDefault();
        setRequestorPhoneNumber(e.target.value);
    }
    const handlePassword = (e) => {
        e.preventDefault();
        setPassword(e.target.value)
    }
    const handleRePassword = (e) => {
        e.preventDefault()
        setRePassword(e.target.value)
    }
    const verifyPassword = (e) =>{
        e.preventDefault()
        if(password!==repassword) {
            setError('Both password are not matching!!. Please check')
        } else {
            setError('')
        }
    }
    const otpRequestForm = () => (
        <>
        
            
                            <form onSubmit={getOTP} id="register-form" role="form" className="form">
            
                              <div className="form-group">
                                <div className="input-group">
                                  <span className="input-group-addon"><i className="glyphicon glyphicon-envelope color-blue"></i></span>
                                  
                                  <input onChange={handleChange} type="tel" id="phone" name="phone" placeholder="enter your phone number" className="form-control form-control-sm" pattern="[0-9]{10}" required />
                                  <br/>
        
                                  {/* <input id="phone_number" name="phone_number" placeholder="phone number" className="form-control"  type="phone" /> */}
                                </div>
                              </div>
                              {
                                  otpMatch === false?
                              <div className="form-group">
                                <button className="btn btn-sm btn-primary btn-block"  type="submit" >Get OTP</button>
                              </div>
                              :''
                                }
                              <input type="hidden" className="hide" name="token" id="token" value="" /> 
                            </form>
            
                          
        </>
    )

    
    const getPasswordReset = (e) => {
        e.preventDefault()
        
        if(password !== repassword) {
            setError("Password is not matching!!")
            return
        }
        var fd = new FormData();
        fd.append('password',password)
        fd.append('phone_number',requestorPhoneNumber)
        fd.append('otp',otp)
                
        fetch(`${API}/resetpassword/`,{
            method:"POST",
            headers:{
                Accept:'application/json',
            },
            body:fd
        }).then(response=>{
            return response.json()
        }).then(data=>{
            if(data.detail){

            } else {
                setSuccess('Your Password was reset!!');
                stopCounterWithOutRefresh();
                homePageRedirectionCounterStart()
            }
        })
    }
    

    useEffect(()=>{
        if(success){
            homePageRedirectionCounterStart();
        }
    },[otpMatch])
    const [seconds1, setSeconds1] = useState(0)

    const interval1 = useRef(null)
    const [doneProcessing,setDoneProcessing] = useState(false)
    const redirectToHome = () => {
        if(doneProcessing || loggedIn) {
            return <Redirect to={"/"} />
        }
    }
  
    useEffect(() => { if (seconds1 === 5) 
        {
            setDoneProcessing(true);
            clearInterval(interval1.current) 
            
        }  
    }, [seconds1])
  
    const homePageRedirectionCounterStart = () => interval1.current = setInterval(() => {
      setSeconds1(prevState => prevState + 1)
    }, 1000)    

    const resetPasswordForm = () => {
        return (
            <>
            <br />
            <div className="text-center">
            <h2 className="text-center">Enter new password</h2>            
            <form onSubmit={getPasswordReset} role="form" className="form">
                <input placeholder="Password" className="form-control form-control-sm" type="password" name="password" id="password" onChange={handlePassword}/>
                <br />
                <input placeholder="Re-enter Password" className="form-control form-control-sm" type="password" name="confirm_password" id="confirm_password" onChange={handleRePassword}/>
                <br />
                <button className="btn btn-sm btn-primary btn-block" type="submit" name="submit"    id="submit" > Reset Password</button>
            </form>
            <p>Refreshing Page in 60 seconds . Seconds Passed {seconds}</p>
            </div>
            </>
        )
    }

    const handleOtpCheck = (e) => {
        e.preventDefault()
        if(otp===verifyOTP) {
            setOTPMatched(true)
        } else {
            setOTPMatched(false);
            setError("Wrong OTP");
        }
    }

    const [verifyOTP,setVerifyOtp] = useState('')
    const handleOTPEntry = (e) => {
        e.preventDefault()
        setVerifyOtp(e.target.value)
    }
    const otpForm = () => {
        return (
            <div className="text-center">
            
            <h2 className="text-center">Enter OTP to verify</h2>
            <form role="form" className="inline form">
                <input placeholder="OTP" className="form-control form-control-sm" type="text" name="otp" id="password" onChange={handleOTPEntry}/>
                <br />
                <button className="btn btn-sm btn-primary btn-block" onClick={handleOtpCheck} type="submit" >Verify OTP</button>
            </form>
            </div>
        )
    }

    useEffect(()=>{
        
        if(otpMatch){
            
            startCounter();
        }
    },[otpMatch])
    const [seconds, setSeconds] = useState(0)

    const interval = useRef(null)
  
    useEffect(() => { if (seconds === 60) stopCounter() }, [seconds])
  
    const startCounter = () => interval.current = setInterval(() => {
      setSeconds(prevState => prevState + 1)
    }, 1000)

    const stopCounterWithOutRefresh = () => {
        clearInterval(interval.current)
    }
  
    const stopCounter = () => {
        if(success===false){
            window.location.reload(false)
        }
        //clearInterval(interval.current)
    }
    const showError = () => (
        (error ?
            <div className="alert alert-danger" >
                {error}
            </div> : ''
        )
)

const showSuccess = () => (
    <div className="alert alert-info status-message"  style={{display: success ? '' : 'none' }}>
        {success}
    </div>
)
const [loggedIn,setLoggedIn] = useState(false)


const redirectIfLoggedIn = () => {
    if(isAuthenticated()) {
        clearToken();
        window.location.reload(false)
    }        
}

return (
        <Layout>
<div className="form-gap"></div>
        <div className="container">
            <div className="row">
                <div className="col-md-4 mx-auto">
                    <div className="panel panel-default">
                      <div className="panel-body">
                        <div className="text-center">
                          <h3><i className="fa fa-lock fa-4x"></i></h3>

                          <div className="panel-body">  
                            {redirectIfLoggedIn()}
                            {redirectToHome()}
                            {showError()}
                            {showSuccess()}

                                {
                                    success === false?
                                    <>
                                    <h2 className="text-center">Forgot Password?</h2>
                                    <p>Enter your phone number and press Get OTP to get OTP on your phone </p>
                                    {otpRequestForm()}
                                    </>
                                    :
                                    ''
                                }
                                {
                                    (otp !== '' ) && (success===false)?
                                    otpForm()
                                    :
                                    ''
                                }
                                {
                                    otpMatch && (success===false) ?
                                    resetPasswordForm()
                                    :
                                    ''
                                }
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
            </div>
        </div>   
             
        </Layout>

     )
}

export default ResetPassword;