import React from 'react';
import { isAuthenticated }   from '../auth';
import { Tabs,Tab } from 'react-bootstrap';
import AdminDashboardBase from './AdminDashboardBase';
import UserInteraction from './UserInteraction';
import AdsInteraction from './AdsInteraction';
import SendNotification from './SendNotification';
import UserReports from './UserReports';
import AdsReports from './AdsReports'

const AdminDashboard = () => {

        return (
            <AdminDashboardBase 
                title="Dashboard"
                description="Admin"
                className="container-fluid"
            >

                <Tabs defaultActiveKey="userrealatedaction" transition={false} id="noanim-tab-example">
                    <Tab eventKey="userrealatedaction" title="User Action">
                        <UserInteraction />
                    </Tab>
                    <Tab eventKey="adsrelatedaction" title="Ads Action">
                        <AdsInteraction />
                    </Tab>
                    <Tab eventKey="sendnotification" title="Send Notifications">
                        <SendNotification />
                    </Tab> 
                    <Tab eventKey="userreport" title="User Report">
                        <UserReports />
                    </Tab>                                           
                    <Tab eventKey="adsreport" title="Ads Report">
                        <AdsReports />
                    </Tab>                                                              
                </Tabs>
                        
                {/* <Tabs defaultActiveKey="home" transition={false} id="noanim-tab-example">
                    <Row>
                        <Col sm={3}>
                        <Nav variant="pills" className="flex-column">
                            <Nav.Item>
                            <Nav.Link eventKey="first">User related operation</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                            <Nav.Link eventKey="second">Ads Operation</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        </Col>
                        <Col sm={9}>
                        <Tab.Content>
                            <Tab.Pane eventKey="first">
                            <UserInteraction />
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                            <AdsInteraction />
                            </Tab.Pane>
                        </Tab.Content>
                        </Col>
                    </Row>
                </Tabs> */}

            </AdminDashboardBase>
        )
}


export default AdminDashboard;
