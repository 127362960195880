import React,{ useState } from 'react';

import {Nav,Button, Form, FormControl, Modal, ButtonGroup ,Dropdown, NavDropdown} from 'react-bootstrap/';
import {Link,Redirect} from 'react-router-dom';

import Signup from './SignUp';
import { isAuthenticated, signout, signin,authenticate } from '../auth';

import {strings} from '../utils/translated_strings';

const submitButtonAnimation = () => {
    return {color:"#28A745"};
}

const UserAuthMenu = () => {

    const [loginparams,setLoginParams] =  useState({
        username:'',
        password:'',
        error:'',
        redirectToReferrer:false
    })
    
    const [redirecttopostad,setRedirecttopostad]=useState(false)
    const { username,password,error,redirectToReferrer } = loginparams;

    const handleChange = (name,func) => (event) => {
        setLoginParams({...loginparams,error:'',[name]:event.target.value })
    }

    const clickSubmitLogin = (event) => {
        event.preventDefault();
        setLoginParams({...loginparams,error:false,redirectToReferrer:false});
        signin({username,password})
        .then(data => {
            
            if(data.detail)
            {
                setLoginParams({...loginparams,error:data.detail})
            }
            else{
                setLoginParams({
                    ...loginparams,
                    error:false,
                    redirectToReferrer:true
                });
                authenticate(data);            
            }
        })
        .catch(err => {
            
        })
        
    };
    const showError = () => (
        (error ?
            <div className="alert alert-danger " style={{width:"50%"}}>
                {error}
            </div> : ''
        )
    )

    

    const redirectUser = () => {

        if(redirectToReferrer) {
            window.location.reload(false);
        }
    }
    const Register = () => {
        const [show, setShow] = useState(false);
      
        const handleClose = () => setShow(false);
        const handleShow = () => setShow(true);
      
        return (
          <>
          <Link to="/resetpassword">
            <Button size="sm" className="text-white text-center green text-center  border-0 btn btn-outline-success success" onMouseEnter={submitButtonAnimation}> 
                  {strings.forgotpassword} 
            </Button>  
            </Link>
            <Button size="sm" className="text-white text-center text-center green border-0  btn btn-outline-success success" onClick={handleShow} onMouseEnter={submitButtonAnimation}> 
              {strings.register}
            </Button>
    
            <Modal
              show={show}
              onHide={handleClose}
              backdrop="static"
              keyboard={true}
            >
              
              <Modal.Body >
              <button type="button" onClick={handleClose} className="close"><span className="signup-model-close" aria-hidden="true" style={{zIndex:'1'}}>×</span><span className="sr-only">Close</span></button>
                <Signup />
              </Modal.Body>
              
            </Modal>
          </>
        );
      }

      const OfferNotificationMenu = () => (
          <>
            <NavDropdown 
            as={ButtonGroup}
            key='down'
            id='dropdown-button-drop-down'
            variant="success"
            title={<i className="far fa-bell"></i>}
            className="btn-block text-white"
            style={{color:"white!important"}}
            size="sm"
        >
        
            <NavDropdown.Item href="/myoffers" style={{backgroundColor:"white"}} className="mx-auto" size="sm" eventKey="1">
                
                    My Offers
                
            </NavDropdown.Item>
            <NavDropdown.Item href="/mynotifications" style={{backgroundColor:"white"}}  size="sm" eventKey="2">
                    Notifications
            </NavDropdown.Item>
        </NavDropdown>
    </>
      )
      const UserMenu = () => (
        <React.Fragment>
            <NavDropdown 
                as={ButtonGroup}
                key='down'
                id='dropdown-button-drop-down'
                variant="success"
                title='My Account'
                className="btn-block text-white mx-auto"
                style={{color:"white!important"}}
                size="sm"
            >
                
                <NavDropdown.Item href={`/customerprofile/${saved_vid}`} style={{backgroundColor:"white",color:"#0069D9"}} size="sm" eventKey="1">
                    My Profile
                </NavDropdown.Item>

                <NavDropdown.Item href="/user/myads" style={{backgroundColor:"white",color:"#0069D9"}} size="sm" eventKey="3">
                    
                    My Ads
                    
                </NavDropdown.Item>
                
                <NavDropdown.Item href="/user/dashboard" style={{backgroundColor:"white",color:"#0069D9"}} size="sm" eventKey="3">
                    
                    Settings
                    
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item  size="sm" style={{backgroundColor:"white",color:"#0069D9"}} eventKey="4"><span onClick={() => signout(() => {
                    window.location.reload(false);
                })}>Signout</span></NavDropdown.Item>
            </NavDropdown>
            
      </React.Fragment>
    )      

    const HelpCenterMenu = () => (
        <>
          <NavDropdown 
          as={ButtonGroup}
          key='down'
          id='dropdown-button-drop-down'
          variant="success"
          title="Help Center"
          className="btn-block text-white col-4 helpcenter"
          style={{color:"white!important",width:"200px!important",display:"block"}}
          size="sm"
      >
      
          <NavDropdown.Item href="/terms-to-this-platform" style={{backgroundColor:"white",color:"#0069D9"}} size="sm" eventKey="1">
              
                Terms and Conditions
              
            </NavDropdown.Item>
          <NavDropdown.Item href="/adpostingguidelines" style={{backgroundColor:"white",color:"#0069D9"}} size="sm" eventKey="2">
                
                    Ad Posting guidelines
                
            </NavDropdown.Item>
            <NavDropdown.Item href="/itemsnotallowed" style={{backgroundColor:"white",color:"#0069D9"}} size="sm" eventKey="3">
                
                    Prohibited product list
                
            </NavDropdown.Item>
            <NavDropdown.Item href="/legalandprivacypolicy" style={{backgroundColor:"white",color:"#0069D9"}}  size="sm" eventKey="4">
                
                    Legal and Privacy Policy
                
            </NavDropdown.Item>
            <NavDropdown.Item href="/trust-and-safety" style={{backgroundColor:"white",color:"#0069D9"}} size="sm" eventKey="5">
                    Trust and Safety
            </NavDropdown.Item>   
            <NavDropdown.Item href="/buyerfaq" style={{backgroundColor:"white",color:"#0069D9"}} size="sm" eventKey="6">
                
                    Buyer FAQs
                
            </NavDropdown.Item>   
            <NavDropdown.Item href="/generalfaq" style={{backgroundColor:"white",color:"#0069D9"}} size="sm" eventKey="7">
                
                    General FAQs
                
            </NavDropdown.Item> 
            <NavDropdown.Item href="/beverifieduser" style={{backgroundColor:"white",color:"#0069D9"}} size="sm" eventKey="8">
                
                    Be Verfied User
                
            </NavDropdown.Item> 
            <NavDropdown.Divider />
            <NavDropdown.Item href="/aboutus" style={{backgroundColor:"white",color:"#0069D9"}}  size="sm" eventKey="9">
                
                    About Us
                
            </NavDropdown.Item>                                                                                                          
            <NavDropdown.Item href="/contactus" style={{backgroundColor:"white",color:"#0069D9"}} size="sm" eventKey="10">
                
                    Contact Us
                
            </NavDropdown.Item>             
      </NavDropdown>
  </>
    )

    const renderLoginForm = () => {
        return (
            <>
            <Form  className="ml-auto float-right" inline>            
                <div className="form-group " >
                    <FormControl size="sm"  type="text" onChange={handleChange('username')} placeholder={strings.phonenumber} className=" mr-sm-2" />
                    <FormControl size="sm" type="password" onChange={handleChange('password')} placeholder={strings.your_password} className=" mr-sm-2" />
                    <Button onClick={clickSubmitLogin} type="submit" size="sm" className="submitButton  text-white btn btn-outline-success btn-success border-0 text-lg mr-4" ><strong>{strings.login}</strong></Button>
                </div>
                {Register()}
            </Form>
            {redirectUser()}
            </>
        )
    }
    const RedirectToPost = () => {    
        if(redirecttopostad)
            return <Redirect to="/postad/Sellads/" />
    }
    const  saved_vid = isAuthenticated().vid
    return (
        <React.Fragment>
            
            {showError()}
            {
            !isAuthenticated() ?
                <React.Fragment>
                {renderLoginForm()}
                
                </React.Fragment>
                 :
                 <>
                 <Nav className="mr-auto">
                 {/* <Nav.Item className="mx-lg-auto mb-2" size="sm">
                     <Button variant="success"size="md"  className="text-white text-center border-0 btn-block green">
                     <i className='far fa-comment-alt mr-1' ></i>
                     
                         Chat
                      </Button>
                 </Nav.Item> */}
                <Nav.Item className="mx-lg-auto mb-2" >
                    <OfferNotificationMenu />
                </Nav.Item>                    
                 <Nav.Item className="mx-lg-auto offset-lg-4 col-12 col-lg-2 mb-2" size="sm" style={{overflow:"auto",zIndex:"100"}}>
                    <Button onClick={()=> {setRedirecttopostad(true)}} variant="primary" size="sm" className="mt-2 mx-auto border-0 rounded-0 text-white btn-block" >
                    <i className="fas fa-camera"></i>
                        <span className="inline">  Sell</span>
                    </Button>
                 </Nav.Item>
                 <Nav.Item className="mx-lg-auto mb-2" >
                    <HelpCenterMenu />
                </Nav.Item>                 
                 <Nav.Item className="mx-lg-auto mb-2" >
                    <UserMenu />
                </Nav.Item>
             
                 </Nav>
                {RedirectToPost()}
                </>
             }  
        </React.Fragment>
    )
}

export default UserAuthMenu;