import React, {useState,useEffect} from 'react';
import { Col, Row} from 'react-bootstrap';
import {Form,FormControl,Button,Spinner} from 'react-bootstrap';
import {getProductPackaging ,getProduct,getBreed,getStatus,getCategories} from '../../utils/Apicore'
import {strings} from '../../utils/translated_strings'

//import Category from '../core/Category'
import Menu from '../Menu'
//import Link from 'react-dom';
import {API} from '../../config';
import {getEKLanguage} from '../../utils/language_utils'
import {getToken, signout} from '../../auth'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faSearch } from '@fortawesome/free-solid-svg-icons'
import SearchCategories from '../SearchCategories'
import ReviewPost from '../ReviewPost';


import {fetchStates,fetchdistrict,fetchblocks} from '../../utils/AreaUtils'
import  {Redirect}  from 'react-router-dom';


const AgriculturalProductPost = ({props,super_category}) => {
    const [adposted,setAdPosted] = useState(false)
    const InitState = {
        category_name:props,
        product_name:'',
        product_breed:'',
        product_status:'',
        product_quantity:0,
        product_quantity_by:'Quintal',
        product_price:0,
        product_price_by:'Per Quintal',
        packing_availibility:'No',
        product_packing_type:'',
        packing_charges:"Buyer",
        photo1:"",
        photo2:"",
        photo3:"",
        state:'',
        district:'',
        block:'',
        village:'',
        additional_information:'',
    }
    const [data,setData] = useState(InitState)

    const ErrorInitState = {
        category_name_error:'',
        product_name_error:'',
        product_breed_error:'',
        product_status_error:'',
        product_quantity_error:'',
        product_packaging_type_error:'',
        product_price_error:'',
        state_error:'',
        district_error:'',
        block_error:'',
        additional_information_error:''
    }

    const [errorStates,setErrorStates] = useState(ErrorInitState)
    const [review,setReview] = useState(false)
    const [photos,setPhotos] = useState({
        photo1:"https://via.placeholder.com/300",
        photo2:"https://via.placeholder.com/300",
        photo3:"https://via.placeholder.com/300"
    })
    const [products,setProducts] = useState([])
    const [breeds,setBreeds] = useState([])
    const [packagingtype,setPackagingType] = useState([])
    const [productstatus,setProductStatus] = useState([])
    const [error,setError] = useState(false)

    const [states,setStates] = useState([])
    const [districts,setDistricts] = useState([])
    const [blocks,setBlocks] = useState([])
    const [loading,setLoading] = useState(true)

    const handleStateChange = (name,func,setFunc) => (event) => {
        setData({...data,[name]:event.target.value })
        func(event.target.value).then(response=> {
            return response.json()
        })
        .then(data => setFunc(data))
        .catch(err => {
            console.log(err)
        });
    }
    
    const user_related_info = getToken()

    const getProduct = (category_name) => {
        const lang = getEKLanguage()
        fetch(`${API}/ads/products/?search=${category_name}`,{
            method: "GET",
            headers: {
                Accept:'application/json',
                    "Content-Type":"application/json",
                    "Accept-Language":`${lang}`                   
                }
            })
            .then(response=> {
                return response.json()
            })
            .then(data =>  {
                
                setProducts(data);
                setLoading(false)
            })
            .catch(err => {
                setError(true);
            })        
    }    

    useEffect(()=>{
        
        fetchStates().
        then(response => {return response.json()})
        .then(data=>{return setStates(data)})
        .catch(err=>{
            setError(err)
        })
        getStatus()
        .then(response => {
            return response.json()
        })
        .then(data => {setProductStatus(data)})
        .catch(err => {
            setError(err)
        })
        
        getProduct(props)
    },[props])

    

    

    useEffect(()=>{
            
        getBreed(data.product_name)
        .then(response => {
            return response.json()
        })
        .then(data => {
            setBreeds(data);
        })
    },[data.product_name])   

    


    
    const handleCategorySelection = (selected) => {    
        setData({...data,category_name:selected.category_name})
        
        
    }

    const handleSelection = (name) => (e) => {
        setData({...data,[name]:e.target.value})
    }

    const handleCategory = (name)=> (e) => {
        handleCategorySelection(e.target.value)
    }

    const handlePackaging = (name) => (e) => {
        if(e.target.value === "Yes"){
            //fetch packaging type
            getProductPackaging()
            .then(response => {
                return response.json()
            })
            .then(data => {
                setPackagingType(data)
            })
            .catch(err=>{
                setError(err)
            })
        }
        setData({...data,[name]:e.target.value})
    }

    const handleImageSelection = (name) => (e) => {
        e.preventDefault()
        //let f = e.target.files[0]
        const reader =new FileReader()
        reader.onload = () => {
            if(reader.readyState === 2) {
                setPhotos({...photos,[name]:reader.result})
            }
        }
        reader.readAsDataURL(e.target.files[0])
        setData({...data,[name]:e.target.files[0]})
        
        //setData({...data,[name]:f})
    }  

    const isValid = () =>{
        let flag = true;
        let category_name_error='';
        let product_name_error='';
        let product_breed_error='';
        let product_status_error='';
        let product_quantity_error='';
        let product_packaging_type_error='';
        let product_price_error='';
        let state_error='';
        let district_error='';
        let block_error='';
        let additional_information_error='';
        if(data.product_name==='')
        {
            product_name_error="Please select product name";
            flag = false
        }
        if(data.category_name==='')
        {
            category_name_error="Please select category";
            flag = false
        }
        if(data.product_breed==='')
        {
            product_breed_error="Please select product breed";
            flag = false
        }
        if(data.product_quantity <= 0 )
        {
            product_quantity_error="Please enter correct quantity";
            flag = false
        }
        if(data.packing_availibility==='Yes' && data.product_packaging_type === '')
        {
            product_packaging_type_error="Please select product packaging type";
            flag = false
        }
        if(data.product_status==='')
        {
            product_status_error="Please select product status";
            flag = false
        }
        if(data.product_price <= 0) {
            product_price_error="Please enter valid price for your product"
            flag = false
        }
        if(data.state === '')
        {
            state_error="Please select your state name"
            flag = false
        }
        if(data.district ==='')
        {
            district_error = "Please select your district"
            flag = false
        }
        if(data.block === '')
        {
            block_error = 'Please select your block'
            flag = false
        }
        if(data.additional_information.length > 500)
        {
            additional_information_error='Character Limit 500 words'
            flag = false
        }
        
        setErrorStates({category_name_error,product_name_error,product_breed_error,product_status_error,    product_quantity_error,product_packaging_type_error,product_price_error,state_error,district_error,block_error,additional_information_error})
        
        return flag
    }
    const [id,setId] = useState(false)

    const redirectToItem = () => {
        
        if(id) {
            
            return <Redirect to={`/item/detail/agricultural-products/${id}`} />
        }
    }

    const submitAdvertisement = () => {
        let lang = getEKLanguage()
        let token = user_related_info   

        var fd = new FormData();
        fd.append('product_name',data.product_name)
        fd.append('product_breed',data.product_breed)
        fd.append('product_status',data.product_status)
        fd.append('product_quantity',data.product_quantity)
        fd.append('product_quantity_by',data.product_quantity_by)
        fd.append('product_price',data.product_price)
        fd.append('product_price_by',data.product_price_by)
        fd.append('packing_availibility',data.packing_availibility)
        fd.append('product_packing_type',data.product_packing_type)
        fd.append('packing_charges',data.packing_charges)
        fd.append('additional_information',data.additional_information)
        fd.append('photo1',data.photo1)
        fd.append('photo2',data.photo2)
        fd.append('photo3',data.photo3)
        fd.append('super_category',super_category)
        
        fd.append('state',data.state)
        fd.append('district',data.district)
        fd.append('block',data.block)
        fd.append('village',data.village)

        fetch(`${API}/ads/agriads/`,{
            method: "POST",
            headers: {
                Accept:'application/json',
                "Accept-Language":`${lang}`,
                Authorization:`Token ${token}`
            },
            body: fd
        })
        .then(response => {
            return response.json()
        })
        .then( data => {
            if(data.detail) {
                if(data.detail.toLowerCase().includes('token')) {
                    signout(()=>{
                        window.location.reload(false)
                    })
                } else {
                    setError(data.detail)
                }
            } else {
             setId(data.id)
            }
        })
        .catch(err => {
            setError(err) 
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if(isValid()){
            setAdPosted(true)
            submitAdvertisement()
            setErrorStates(ErrorInitState)
        }
    }

    const handleReview = (e) => {
        e.preventDefault()
        if(review === true){
            setReview(false)
            return
        }
        if(isValid())
        {
            setReview(true)
        }
        else{
            setReview(false)
        }
    }
    const AdSell = () => {
        return (
            <React.Fragment>

            <Form className="mt-4 ml-auto" onSubmit={handleSubmit}>
                <h3 className="gray heading">Enter details </h3>
                <hr/>

                    <Form.Row>
                        <Form.Label column sm="4">
                                Selected Product name
                        </Form.Label>                
                        <FormControl size="sm" as="select" value={data.product_name} onChange={handleSelection("product_name")} className="col-lg-4 mt-2">                        
                        <option key="Select your Product Name">Select Your Product Name</option>
                            {
                                products.map(d => {
                                    return <option key={d.id}>{d.product_name}</option>
                                })
                            }
                        </FormControl>            
                        <div style={{fontSize:"0.8em",color:"red"}}>{errorStates.product_name_error}</div>
                    </Form.Row>
                    <Form.Row>
                        <Form.Label column sm="4">
                                Select Product Breed
                        </Form.Label>                
                        <FormControl size="sm" as="select"  value={data.product_breed} onChange={handleSelection("product_breed")} className="col-lg-4 mt-2">
                            <option key="Select Breed" value="">Select Breed</option>
                            {
                                breeds.map(d => {
                                    return <option key={d.id}>{d.product_breed}</option>
                                })
                            } 
                        </FormControl>            
                        <div style={{fontSize:"0.8em",color:"red"}}>{errorStates.product_breed_error}</div>
                    </Form.Row>                    
                    <Form.Row>
                        <Form.Label column sm="4">
                                Select Product Status
                        </Form.Label>                
                        <FormControl size="sm" as="select" value={data.product_status} onChange={handleSelection("product_status")} className="col-lg-4 mt-2">
                            <option key="Select Status">Select Status</option>
                            {
                                productstatus.map(d => {
                                    return <option key={d.id}>{d.product_status}</option>
                                })
                            } 
                        </FormControl>            
                        <div style={{fontSize:"0.8em",color:"red"}}>{errorStates.product_status_error}</div>
                    </Form.Row>
                    <Form.Row>
                        <Form.Label column sm="4">
                                Quantity
                        </Form.Label>                
                        <FormControl size="sm" type="text" value={data.product_quantity}  onChange={handleSelection("product_quantity")} className="mt-2 mr-sm-2 col-lg-1" size="sm" placeholder="Enter"/>
                        <div style={{fontSize:"0.8em",color:"red"}}>{errorStates.product_packaging_type_error}</div>
                        <FormControl size="sm" as="select" value={data.product_quantity_by} onChange={handleSelection("product_quantity_by")}   className="mt-2 col-lg-2" >
                            <option key="Quintal">Quintal</option> 
                            <option key="Kg">Kg</option> 
                            <option key="Pieces">Pieces</option> 
                            <option key="litre">litre</option> 
                            <option key="Gram">Gram</option> 
                            <option key="Ton">Ton</option> 
                            <option key="Trolley">Trolley</option> 
                            <option key="Truck">Truck</option> 
                        </FormControl>            

                        <div style={{fontSize:"0.8em",color:"red"}}>{errorStates.product_quantity_error}</div>
                    </Form.Row>    
                    <Form.Row>
                        <Form.Label column sm="4">
                                Price
                        </Form.Label>                
                        <FormControl size="sm" type="text"  value={data.product_price} onChange={handleSelection("product_price")} className="mt-2 mr-sm-2 col-lg-1" size="sm"  placeholder="Enter"/>
                        <FormControl size="sm" as="select" value={data.product_price_by}  onChange={handleSelection("product_price_by")} className="mt-2 col-lg-2" >
                                <option key="Per Quintal">Per Quintal</option> 
                                <option key="Per Kg">Per Kg</option>
                                <option key="Per Pieces">Per Pieces</option> 
                                <option key="Per litre">Per litre</option> 
                                <option key="Per Gram">Per Gram</option> 
                                <option key="Per ton">Per Ton</option> 
                                <option key="Per Trolly">Per Trolley</option> 
                                <option key="Per Truck">Per Truck</option> 
                        </FormControl>            
                        <div style={{fontSize:"0.8em",color:"red"}}>{errorStates.product_price_error}</div>
                    </Form.Row>                        
                    <Form.Row>
                        <Form.Label column sm="4">
                                Packing Availibility
                        </Form.Label>                
                        <FormControl size="sm"  as="select" value={data.packing_availibility} onChange={handlePackaging("packing_availibility")} size="sm" className="mt-2 mr-sm-2 col-lg-1" placeholder="Enter">
                            <option>Yes</option>
                            <option >No</option>
                        </FormControl>
                        {
                            data.packing_availibility === "Yes"?
                            <>
                                <FormControl as="select" value={data.product_packaging_type} onChange={handleSelection("product_packing_type")} size="sm" className="mt-2 col-lg-2" >
                                    <option default_value="" hidden >Select Product Packaging</option>
                                    {
                                        packagingtype.map(d => {
                                            return <option key={d.id}>{d.product_packaging_type}</option>
                                        })
                                    } 
                                </FormControl>
                                <div style={{fontSize:"0.8em",color:"red"}}>{errorStates.product_packaging_type_error}</div>
                            </>
                                    : ""
                        
                        }
                    </Form.Row>    
                    <Form.Row>
                        <Form.Label column sm="4">
                                Who will pay for packing and loading labour charges
                        </Form.Label>                
                        <FormControl size="sm" as="select" value={data.packing_charges} onChange={handleSelection("packing_charges")} className="col-lg-4 mt-2">
                            <option>Buyer</option>
                            <option>Seller</option>
                        </FormControl>            
                    </Form.Row>
                    <Form.Row>
                        <Form.Label column sm="4">
                                Select Your State
                        </Form.Label>                
                        <FormControl size="sm" as="select"  value={data.state} onChange={handleStateChange("state",fetchdistrict,setDistricts)} className="col-lg-4 mt-2">
                            <option key="Select Your state">{strings.select_your_state}</option>
                            {
                                states.map(d=>{
                                    return <option key={d.id}>{d.state_name}</option>
                                })
                            }
                        </FormControl>            
                        <div style={{fontSize:"0.8em",color:"red"}}>{errorStates.state_error}</div>
                    </Form.Row>
                    <Form.Row>
                        <Form.Label column sm="4">
                                Select Your District
                        </Form.Label>                
                        <FormControl size="sm" as="select" value={data.district} onChange={handleStateChange("district",fetchblocks,setBlocks)} className="col-lg-4 mt-2">
                                <option key="Select Your District">{strings.select_your_district}</option>
                                {
                                    districts.map(d => {
                                        return <option key={d.id}>{d.district_name}</option>
                                    })
                                }
                        </FormControl>            
                        <div style={{fontSize:"0.8em",color:"red"}}>{errorStates.district_error}</div>
                    </Form.Row>
                    <Form.Row>
                        <Form.Label column sm="4">
                                Select Your Block
                        </Form.Label>                
                        <FormControl size="sm" as="select" value={data.block} onChange={handleSelection("block")} className="col-lg-4 mt-2">
                                <option key="Select Your Block">{strings.select_your_block}</option>
                                {
                                    blocks.map(d => {
                                        return <option key={d.id}>{d.block_name}</option>
                                    })
                                }
                        </FormControl>            
                        <div style={{fontSize:"0.8em",color:"red"}}>{errorStates.block_error}</div>
                    </Form.Row>
                    <Form.Row>
                        <Form.Label column sm="4">
                                Enter your village name
                        </Form.Label>                
                        <FormControl size="sm" type="text" value={data.village} onChange={handleSelection("village")} className="col-lg-4 mt-2" />
                    </Form.Row>                    
                    <Form.Row>
                        <Form.Label column sm="4">
                            Additional Information
                        </Form.Label>
                    <FormControl as="textarea" style={{resize:"none"}} rows={3}  value={data.additional_information} onChange={handleSelection("additional_information")} className="col-lg-4 mt-2 mr-sm-2" size="sm"  placeholder=""/>
                    <div style={{fontSize:"0.8em",color:"red"}}>{errorStates.additional_information_error}</div>
                    </Form.Row>                                                            
                    <Form.Row>
                        <Form.Label column sm="4">
                                Upload Images 
                        </Form.Label>
                    </Form.Row>                    
                    <Form.Row className="m-auto ">
                        <div className="col-2">
                        <label className="mt-2 btn btn-default btn-outline-secondary btn-sm mx-auto" >
                            Click to Browse <input type="file" hidden onChange={handleImageSelection("photo1")}  className="mt-2"/>
                        </label>
                        <img src={photos.photo1}
                            style={{height:"150px",width:"150px"}} alt="photo1"/>
                        </div>
                        <div className="col-2 ml-4">
                        <label className="mt-2 btn btn-default btn-outline-secondary btn-sm mx-auto">
                            Click to Browse <input type="file" hidden onChange={handleImageSelection("photo2")}  className="mt-2"/>
                        </label>
                        <img src={photos.photo2}
                            style={{height:"150px",width:"150px"}} alt="photo2"/>                        
                        </div>
                        {/* //style={{display:"inline-block",width:"100px",height:"100px",backgroundImage:`url(${data.photo3})`}} */}
                        <div className="col-2 ml-4">
                            <label className="btn mt-2 btn-default btn-outline-secondary btn-sm" >
                                Click to Browse<input type="file" hidden onChange={handleImageSelection("photo3")}  className="mt-2"/>
                            </label>
                            <img src={photos.photo3} style={{height:"150px",width:"150px"}} alt="photo3"/>                        
                        </div>
                    </Form.Row>
                    <div className="form-row col-10 mt-4">
                        <div className="col-3">
                            <Button  variant="info" onClick={handleReview}>Review</Button>
                        </div>
                        <div className="col-3 ml-auto">
                        <Button  className=" " type="submit" variant="info">
                                {
                                    adposted?
                                    <>
                                        <Spinner animation="border" role="status">
                                        <span className="sr-only">Saving...</span>
                                        </Spinner>                                    
                                    </>:

                                    <span>Submit</span>
                                }
                            </Button>
                        </div>
                    </div>    

            </Form>
            {redirectToItem()}
            </React.Fragment>
        )
    }



    return (
        <React.Fragment>
        {
        review ?
            <>
            
            
            <ReviewPost postData={data} photos={photos}/>
            <div className="container">
                <div className="row">
                <Button  className="m-auto" variant="info" onClick={handleReview}>Go back to edit</Button>
                <Button onClick={handleSubmit} className=" " type="submit" variant="info">
                                {
                                    adposted?
                                    <>
                                        <Spinner animation="border" role="status">
                                        <span className="sr-only">Saving...</span>
                                        </Spinner>                                    
                                    </>:

                                    <span>Submit</span>
                                }
                            </Button>
                </div>
            </div>

            </>
            :
            <>
                {
                    loading ?
                    
                              ''
                            :
                            <div className="col-10 ml-auto" >
                            {AdSell()}
                            </div>
                }
            </>
        }
        </React.Fragment>
    )
}

export default AgriculturalProductPost;