import React from 'react'
import Layout from '../core/Layout'


const AdPostingGuidelines = () => {
    return (
        <Layout>
        <div className="container-fluid">
            <div className="row">
                <div className="col-8 mx-auto">

<h5>Posting an Ad on EKRISHIBAZAAR.COM for the first time? Simply, follow the below mentioned steps:</h5>
<ol className="number">
    <li>On the home screen, tap on the “Sell” button at the bottom of App.
    <p className="font-weight-bold">(Note: if you not logged in yet then application will ask to login first before posting ad)</p>
    </li>
    <li>
    Select a category and subcategory. (E.g. Category -- Grains and Sub-category 1 – Rice, Subcategory 2 - Basmati)  
    </li>
    <li>
    Enter all the necessary details like the Quantity, status, price, an appropriate title and detailed description of the product. Tip: A good description concisely yet aptly describes your item.
    <span className="font-weight-bold">Note:</span> A “title” can only contain 70 characters and “description” is limited to 250 characters.
    </li>
    
    <li>Insert Images: You can either take a real time photo of the product by clicking on “Take a picture” or add images from photos gallery
        <p><span className="font-weight-bold">Note:</span> You can add a maximum of 6 images for both Agricultural machinery category while a maximum of 3 photos in other categories.</p>
    </li>
    <li>
    Next step is to Set the desired price for the product.
    Please ensure to keep a relevant price according to the product condition & corresponding market value.
    </li>
    
    <li>
        Confirm Location: You can use your current location or search for a preferred location.
    </li>
    <li>
        Your Ad will shortly be live on the site. You will receive a Pop-Up as shown below with a preview of how the Ad would be live on the App. 
    </li>
    
</ol>

That is easy, isn’t it? You can also share your Ad(s) with friends. 

<h5>How many ads can I post in a month?</h5>
<p>
Please note that every category has a certain limit where-in you can post the Ad for free. Kindly refer to the threshold for Free Limits across India in different categories:   
Guideline will come soon… 
</p>

<h5>How do I know if people are seeing my ads?</h5>
<p>
On an Ad page, you can see an “eye shaped” icon mentioning the number of views next to it. This shows that your Ad has been seen by as many people visiting EKRISHIBAZAAR.COM. 
<span className="font-weight-bold">Note:</span> There’s also a Heart icon near no. of views. This icon refers to the number of times your Ad is added to favorites by other users on the platform.
</p>
<h5>How do I get maximum replies?</h5>
<p>To get good responses for your Ad, please ensure the below: -</p>
<ul className="circle">
    <li>A comprehensive description: Mention the relevant Ad details such as title/description and ensure to provide complete information such as item specification, size, shape, colour, quantity and design, etc.</li>
    <li>Clear resolution photograph(s): Always upload a clear image showing the item properly. Please ensure that the image is original and reflects the item as described. Do not include any stock or human image.</li>

    <li>Competitive pricing: Please ensure to keep realistic pricing for your Ad. It should reflect the market value of the item and correspond to actual item condition.</li>
    <li>Appropriate category: Ensure to post the Ad in relevant category. E.g. post Wheet in grains and not vegetables.</li>
    <li>Premium Services: You have the option to opt for paid services such as featured Ad or boost to top. They give more visibility to your Ad & help receive much more responses. </li>
</ul>

<h5>Can I share my ad with my friends?</h5>
<p>You can share your Ad with friends and family to spread the word. Follow the steps below:  </p>
<ol className="number">
    
<li>After you have logged in to EKRISHIBAZAAR.COM account, Go to My Ads      </li>
<li>Select the Ad that you wish to share.</li>
<li>Click on the “share” button to share the Ad on other platforms.</li>
</ol>


<h5>How to edit/delete an ad?</h5>
<p>If you wish to modify or delete an existing Ad, you can do so by following the below steps: -</p>
<ol className="number">
    <li>After you have logged in to EKRISHIBAZAAR.COM account, Go to My Ads</li>
    <li>Select the “Active Ads” under “My Ads” section. You will see “three dots” icon on the right side of an Ad.</li>
    <li>Once you tap on the three dots a pop-up will appear. You will find the option to “Edit, Deactivate, Delete or Mark as Sold” the Ad.</li>
    <li>Edit Ad: If you select “EDIT”, you will be able to change the price, description, images etc</li>
    <li>Deactivate Ad: If you wish to make your Ad inactive for some time, you may deactivate your Ad</li>
    <li>Delete Ad: Upon selecting “DELETE” a confirmation pop-up will appear.</li>
</ol>


<h5>Where can I see the Ads that I posted?</h5>
<p>It’s easy to go to the Ads you have posted. Please follow the steps as stated below:</p>
<p>
Firstly, ensure you are logged in the app with the user details you used while posting the Ads. If unsure, then log out and log in again.  
</p>
<ol className="number">
<li>On the EKRISHIBAZAAR.COM App home screen, tap on My Ads section:</li>
<li>Once on the “Ads” section, select “View All”.</li>
<li>Your list of Ads will be displayed under “View All”</li>
</ol>

<p><span className="font-weight-bold">Note:</span> Live Ads are available under “Active Ads” category. Other category consists of rejected ads, under quality check, expired Ads or Ads deleted by user.   </p>

<h5>Why isn't my ad live?</h5>

<p>Ad not live on EKRISHIBAZAAR.COM? This could be due to few reasons as mentioned below:</p>
<ol className="number">
<li>The Ad was rejected: If the Ad doesn’t meet our posting guidelines, it will be not go live on our site. </li>
<li>Additionally, you’ll be communicated of the specific error in your Ad, so that you can edit and re-post the Ad as per the instructions. You’ll also receive a notification for the same. </li>
<li>Ad not processed / pending: Before going live on the platform, every Ad goes through a quality check. This is done to ensure that only genuine Ads are live on the platform and to keep spammers at bay.</li>
<li>You would have received the below message at time of posting the Ad. Hence, we request you to wait while the Ad is being processed. </li>
<li>Not appearing in search results: You might not be searching the Ad correctly. Use the below tips to find your Ad: - </li>
<li>Choose the correct sub-category and category</li>
<li>Select the exact location i.e. Block, District and state.</li>
<li>You can also filter your search by price & brand (if apply)</li>
<li>Double check your spelling: Remember to add specific words or the exact title of your ad </li>
</ol>

<h5>Why did my Ad get rejected?</h5>
<p>Firstly, we apologize for any inconvenience faced due to this.  </p>
<p>
To ensure good user experience for both Buyer & Sellers, we keep monitoring the Ads on the platform. Following could be some of the potential reasons why your ad did not go live:-    
</p>
<p>
Note: For detailed information on our posting rules, please read details provided below.
</p>
<p className="font-weight-bold">Ban Reason: Forbidden Products </p>
<p>
There are products that EKRISHIBAZAAR.COM does not allow since it may violate the Law or EKRISHIBAZAAR.COM policies. Sellers need to comply with items considered legal or valid for sale in India.
For further details, please read this article: Items not allowed on EKRISHIBAZAAR.COM
</p>
<p className="font-weight-bold">Ban Reason: Invalid price </p>
<p>
To avoid false pricing on the platform and safeguard buying experience for Buyers on EKRISHIBAZAAR.COM, we only allow ads that have a realistic/reasonable price for the item being sold. Setting an appropriate price for your item helps relevant buyer discover your item and contact you to make a deal.
For further details, please read this article: Rejection Reason - Invalid price
</p>

<p className="font-weight-bold">Ban Reason: Bad Title </p>
<p>
While posting an Ad please ensure that you keep the title to the point and related to the item. Do not use any profane words, phone numbers or special characters in the title.   
</p>
<p className="font-weight-bold">Ban Reason: Bad Description </p>
<p>
Your Ad should contain all relevant information about the product or service you are offering such as functionality, specifications, measurement, color, age utility etc. Please note offensive words, religious bias, slandering, phone number (only in case it is different from registered mobile numbers) is not allowed and Ad will be rejected.
</p>
<p className="font-weight-bold">Ban Reason: Diplicate Ad </p>
<p>
Your Ad could be rejected if the title, description or image of your Ad matches with another Ad posted in the same Location.
Note: If you post a similar Ad that you have posted within the previous 30 days, the Ad would still get rejected.
</p>
<p className="font-weight-bold">Ban Reason: Suspicious Ad  </p>
<p>
These are services or products that we do not allow on EKRISHIBAZAAR.COM for legal or ethical reasons.    
</p>
<p className="font-weight-bold">Ban Reason: Invalid image  </p>
<p>
It is important that you post Ads with appropriate images. Make sure that the images are real, clear and of good resolution. Additionally, please note that it should not be a stock image, contain any phone number, obscene picture or human images.
</p>
For further assistance please Contact EKRISHIBAZAAR.COM Customer Service Team
Mark as Sold: If you have successful sold your item, you can select “MARK AS SOLD”.

<h5>Why am I not seeing my chats?</h5>
<p>
    <ol className="number">
        <li>
    Are you logged in to EKRISHIBAZAAR.COM? First things first - make sure you're logged into your EKRISHIBAZAAR.COM profile with your user credentials (Phone no/Facebook/Google).
    </li>
    <li>Still issue persists, no probs! Try the easy troubleshooting as explained below:</li>
    <li><span className="font-weight-bold">Update App:</span> For App please make sure you are using the updated version and if the problem persists, clear app cache and re-attempt. To update the latest version click here.</li>
    
    <li><span className="font-weight-bold">Clear web Cookies:</span> Cookies are magic, but not in a phone application 😊for reasons that go beyond being logged into your EKRISHIBAZAAR.COM profile, it's usually because you need to clear your cookies on the Web. </li> 
    <li><span className="font-weight-bold">No chats received:</span> If you are a seller, it might be possible you have not been contacted by a prospective buyer yet. That means you need to renovate your Ad, give a little touch up and make it more attractive to generate desired responses.                    </li>
    </ol>
</p>
<h5>Steps to check chat: </h5>
<p>
Now this might sound silly, but are you following the correct procedure. In-case, you do not know how to check your chats, please follow the steps below.
</p>
<ol className="number">
    <li>
On the home page of App, you’ll see “Chats” tab at bottom left
</li>
<li>
All your chats will populate here. It consists of your chats posed as a seller or buyer under the header “Buying” & “Selling” Your unread chats will also show an unread count against it, highlighted.
</li>
<li>
 Lastly, if you’re still unsure just do a good log-out and re-login. Check out the option below under “Settings”:
 </li>
 </ol>
                </div>
            </div>
        </div>
        </Layout>
    )
}

export default AdPostingGuidelines;