import React,{useEffect} from 'react';

import { Navbar, Nav} from 'react-bootstrap/'

import { Link } from 'react-router-dom';
import "../assets/css/module.css";
import logo from "../assets/Logo_BRAND.png";

import UserAuthMenu  from "../user/UserAuthMenu";


//import { createBrowserHistory } from 'history';


import {UserLanguage } from '../utils/UserLanguage';
import {getEKLanguage,setEKLanguage} from '../utils/language_utils';
import {strings} from '../utils/translated_strings';

//const history = createBrowserHistory()



let def_lang = getEKLanguage();
if(def_lang) { setEKLanguage(def_lang); } else {setEKLanguage('en');} 
strings.setLanguage(getEKLanguage())

const NavBarBrand = () => {
    return (
        <Navbar.Brand href="/">
        <img
            src={logo}
            width="100"
            height="65"
            className="d-inline-block align-top"
            alt="eKrishiMandi"
        />
    </Navbar.Brand>
    )
}

const Menu = () => {
  
    useEffect(()=>{
      window.addEventListener('scroll',()=>{
        const isTop = window.scrollY > 100
        const nav = document.getElementById('nav')
        if(isTop) {
          nav.classList.add('scrolled')
        } else {
          nav.classList.remove('scrolled')
        }
      })
    })
    return (

<React.Fragment>
  <Navbar id="nav"  expand="lg" className="nav green" variant="dark">
    <NavBarBrand />
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      
      <div className="input-group mr-auto">
      <div className="container-fluid">
        <div className="row" style={{width:"100%"}}>
          <div className="col-lg-2 mt-2 col-12 mt-1">
            <UserLanguage />
          </div>
          
          <div className="col-lg-10 col-12 float-right mt-1">
            <UserAuthMenu />
          </div>
        </div>
      </div>
      
      </div>
    </Navbar.Collapse>
  </Navbar>
   </React.Fragment>
    )
}

export default Menu;
