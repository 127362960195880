import React from 'react';
import {Link} from 'react-router-dom';
import {Button} from 'react-bootstrap';

const BlogCard = ({props}) => {
    return (
        <>
        <div className="mt-4 col-xs-auto ml-auto mr-auto mb-3 ">
                <div className="card offset-1" style={{width: "14rem" , borderWidth:"2px" }}>
                        {/* <CardImageCarousal ad={ad} /> */}
                    <div className="card-body">
                    <p className="m-1">
                            <img src={props.image} style={{maxHeight:"100%",maxWidth:"100%"}} />
                            <span className="h5">{props.heading}:</span> <span className="h6 text-muted">{props.heading}</span> 
                        </p>
                        <div className="row mx-auto">
                        
                        <Link className="m-auto" to={`/success-story/${props.id}/${props.heading}`}>
                            <Button size="sm" className="text-white mx-auto btn small btn-outline-success mt-2 mx-2 mb-2" variant="success">
                                View Ad
                            </Button>
                        </Link>
                        </div>
                    </div>
                    </div>
                </div>
        </>

    )
}

export default BlogCard;