import React,{useState,useEffect} from 'react'
import {API} from '../config'
import {getToken,signout} from '../auth/'
import {fetchStates,fetchblocks,fetchdistrict} from '../utils/AreaUtils';
import {strings} from '../utils/translated_strings'
import {Form,Button} from 'react-bootstrap'

const UserReports = () => {
    const [states,setStates] = useState([])
    const [districts,setDistricts] = useState([])
    const [blocks,setBlocks] = useState([])
    const [to,setTo] = useState('all')

    useEffect(()=>{
        fetchStates()
        .then(response=>{
            return response.json()
        })
        .then(data=>{
            setStates(data)
        })
        .catch(err=>{

        })
    },[])
    const [locationError,setLocationError] = useState()
    const [error,setError] = useState(false)
    const [usercount,setUserCount] = useState(0)
    const[locationParam,setLocationParam] = useState({
        state:'',
        district:'',
        block:''
    })
    const handleNormal = (input) => {
        
        let token = getToken()
        var fd = new FormData()
        if (input==='all' | input === 'blocked'){
            fd.append('to',input)
        }
        else {
            if(locationParam.state.length === 0){
                setLocationError('select state')
                return
            }
            fd.append('state',locationParam.state)
            if(location.district > 0)
                fd.append('district',locationParam.district)
            if(location.block > 0 ) 
                fd.append('block',locationParam.block)
        }

        fetch(`${API}/edituserinfo/?adminoperation=true`,{
            method:'POST',
            headers:{
                Accept:'application/json',
                "Authorization":`Token ${token}`
            },
            body:fd
        }).then(response => {
            return response.json()
        }).then(data=>{
            if(data.detail) {
                if(data.detail.toLowerCase().includes('token')) {
                    signout(()=>{
                        window.location.reload(false)
                    })
                } else {
                  
                }
            } else{            
                setUserCount(data.usercount)
            }
            
        }).catch(err=>{
            
          
        })
    }

    const handleStateChange = (name,func,setFunc) => (event) => {
        setLocationParam({...locationParam,[name]:event.target.value})
        func(event.target.value).then(response=> {
            return response.json()
        })
        .then(data => {
                setFunc(data)
        })
        .catch(err => {
            console.log(err)
        });
    }    

    const [location,setLocation] = useState(false)
    
    const setLocationForm = () => {
        return(
        <>

        <Form.Control as="select" size="sm" value={locationParam.state} onChange={handleStateChange('state',fetchdistrict,setDistricts)} className="col-4 mt-2">
            <option key="Select Your State">{strings.select_your_state}</option>
            {
                states.map((india_states,i) => {
                        return <option  key={i}>{india_states.state_name}</option>
                })
            }
        </Form.Control>
        
        <Form.Control as="select" size="sm" value={locationParam.district} onChange={handleStateChange('district',fetchblocks,setBlocks)} className="col-4 mt-2">
            <option key="Select Your district">{strings.select_your_district}</option>
            {
                districts.map((district,i) => {
                        return <option  key={i}>{district.district_name}</option>
                })
            }
            
        </Form.Control>
            
        <Form.Control as="select" size="sm" value={locationParam.block} onChange={e=>{setLocationParam({...locationParam,block:e.target.value})}} className="col-4 mt-2">
        <option key="Select Your block">{strings.select_your_block}</option>
        {
            blocks.map((block,i) => {
                    return <option  key={i}>{block.block_name}</option>
            })
        }
        </Form.Control>
        </>
        )
    }

    return (
        <div className="row ">
            <div className="col-12 col-lg-8 mx-auto">
                <Form className="my-4 mx-auto" >
                    <Button className="ml-2" onClick={e=>{
                        e.preventDefault()
                        setLocation(false)
                        handleNormal('all')
                    }}
                     size="sm">All</Button>
                    <Button onClick={e=>{
                        e.preventDefault()
                        setLocation(false)
                        handleNormal('blocked')
                    }} className="ml-2" size="sm">Blocked</Button>
                    <Button onClick={e=>{
                        e.preventDefault()
                        setLocation(true)
                        setTo(false)
                    }} className="ml-2" size="sm">Location</Button>
                    {
                        location ?
                        <Form inline onSubmit={
                        (e) =>
                        {
                            e.preventDefault() 
                            handleNormal('')
                        }}>
                            {setLocationForm()}
                            <Button type="submit" size="sm" variant="success">Check</Button>
                        </Form>
                        :
                        ''
                    }
                </Form>
                <p>User Count: {usercount} </p>
            </div>
        </div>
    )
}

export default UserReports;