import React, {useState,useEffect} from 'react';
import {Carousel,Button}  from 'react-bootstrap';
import Layout from '../Layout';
import {API} from '../../config';
import {Col,Table} from 'react-bootstrap';
import {isAuthenticated} from '../../auth/';
import {Redirect, Link} from 'react-router-dom'
import {getEKLanguage} from '../../utils/language_utils'
import UserInfoComponent from '../UserInfoComponent'


const CattleAdDetails = (props) => {

    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
      setIndex(selectedIndex);
    };

    const [data,setData] = useState()
    const [loading,setLoading] = useState(true)
    const [error,setError] = useState(false)
    const [notFound,setNotFound] = useState()
    const [interestedButtonClicked,setInterestedButtonClicked] = useState(false)
    const loadSingleProduct = productId => {
        let lang = getEKLanguage()
        setLoading(true)
        
        fetch(`${API}/ads/cattleads/${productId}/`,{
            method:"GET",
            headers:{
                Accept:'application/json',
                "Content-Type":"application/json",
                "Accept-Language":`${lang}`
            }
        })
        .then(response => {return response.json()})
        .then(data => {
                
                if(data.detail) {
                    setError(data.detail)
                    setLoading(false)
                 }
                 else 
                 {
                    setData(data);
                    setLoading(false)
                 }
        }).catch(err => {
            setError(err)
            setNotFound("This item does not exit")
        })        
    }

    useEffect(()=>{
        const productId = props.match.params.id
        loadSingleProduct(productId)
        
    },[])



    const handleInterested = () => {
            let check = isAuthenticated("cattleAdDetails")
            
            if(check === false) {
                return <Redirect to="/signin" />                  
            }
            setInterestedButtonClicked(true)
        }
    

    
    return (
        <Layout>
            {
                loading ? 
                ''
                :
                ( error ?
                    <div className="container">
                    <div className="mt-4 row">
                        <h4 className="mx-auto text-secondary">{error}</h4>
                    </div>
                </div> 
                :
                <div className="container-fluid">
                <div className="row">
                    <Col xs={12} lg={4}>
                                <div className="mx-auto mt-4" >
                                <Carousel className="mx-auto" style={{height:"300px",width:"300px"}}>
                                    { data.product_image1 !== "" && data.product_image1 ?
                                    <Carousel.Item >
                                        <img
                                        className="d-block w-100"
                                        src={data.product_image1}
                                        alt="First slide"
                                        style={{height:"200px",width:"200px"}}
                                        />
                                    </Carousel.Item>
                                    :
                                    ""
                                    }
                                    { data.product_image2 !== "" && data.product_image2  ?
                                    <Carousel.Item >
                                        <img
                                        className="d-block w-100"
                                        src={data.product_image2}
                                        alt="Second slide"
                                        style={{height:"200px",width:"200px"}}
                                        />
                                    </Carousel.Item >
                                    :
                                    ""
                                    }
                                    { data.product_image3 !== "" && data.product_image3 ?
                                    <Carousel.Item >
                                        <img
                                        className="d-block w-100"
                                        src={data.product_image3}
                                        alt="Third slide"
                                        style={{height:"200px",width:"200px"}}
                                        />
                                    </Carousel.Item>
                                    :""
                                    }
                                </Carousel>
                            </div>
                            <div>
                                
                                <UserInfoComponent postid={data.post_id} user={data.user} ad={data}  category={data.cattle_type.category.category_name}/>
                            </div>
                    </Col>
                    <Col className="mt-4" xs={12} lg={6}>
                    <h3 className="text-secondary mx-auto mt-4">Product Details</h3>
                    <div className="table-responsive">      
                        <table className="table table-hover table-info">
                            <tbody>
                                <tr>
                                    <td>
                                        <h6>Type of Cattle</h6>
                                    </td>
                                    <td>
                                        {data.cattle_type.cattle_name} {data.price} Rs - {data.price_fixed.price_status}
                                    </td>
                                </tr>
								<tr>
                                    <td>
                                        <h6>Cattle Breed</h6>
                                    </td>
                                    <td>
                                        {data.cattle_breed.cattle_breed}
                                    </td>
                                </tr>                                                                                                                                                                                          
                                <tr>
                                    <td>
                                        <h6>Number of births</h6>
                                    </td>
                                    <td>
                                        {data.number_of_child}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>Milk/Day</h6>
                                    </td>
                                    <td>
                                        {data.milk_produced.milk_per_day}
                                    </td>
                                </tr>      
                                <tr>
                                    <td>
                                        <h6>If Preganent</h6>
                                    </td>
                                    <td>
                                        {data.if_preganent}
                                    </td>
                                </tr>
                                {
                                    data.if_preganent === "Yes" ? 
                                    <tr>
                                        <td><h6>Months in preganency</h6></td>
                                        <td>{data.preganency_month}</td>
                                    </tr>
                                    :''
                                }  
                                <tr>
                                    <td><h6>Additional Information</h6></td>
                                    <td>{data.additional_information}</td>
                                </tr>
                                <tr>
                                    <td><h6>Posted By</h6></td>
                                    <td>{data.user.user.first_name} {data.user.user.last_name}</td>
                                    
                                </tr>
                            </tbody>
                        </table>
                        </div>
                        {/* <div className="row m-auto">
                                <Button onClick={handleInterested} className="btn-outline-success btn-success text-white m-auto" >Interested</Button>
                            </div> */}
                    </Col>
                </div>
                </div>                            
                )
                
            }
        </Layout>
    )
}

export default CattleAdDetails;
